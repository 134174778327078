import { render, staticRenderFns } from "./RemoveCollabWarningModal.vue?vue&type=template&id=fcc065ce&scoped=true&"
import script from "./RemoveCollabWarningModal.vue?vue&type=script&lang=js&"
export * from "./RemoveCollabWarningModal.vue?vue&type=script&lang=js&"
import style0 from "./RemoveCollabWarningModal.vue?vue&type=style&index=0&id=fcc065ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcc065ce",
  null
  
)

export default component.exports