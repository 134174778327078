<template>
  <div class="profile-field">
    <div v-show="editMode">
      <slot
        class="pt-0"
        outlined
        :value="value"
        :loading="loading"
        :label="label"
        :disabled="readonly"
      />
    </div>
    <div v-show="showValue" :aria-hidden="!showValue">
      <slot name="view" :value="value" :label="label">{{ value }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileField',
  props: {
    value: {
      type: [String, Array, Object, Number, Boolean],
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideValues: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    showValue: function () {
      if (this.hideValues) {
        return false
      }
      return !this.editMode
    }
  }
}
</script>

<style lang="scss">
.profile-field {
  &:first-of-type {
    margin-top: 0;
  }
}
</style>
