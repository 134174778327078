<template>
  <div class="search">
    <v-text-field
      ref="labelInput"
      v-model="term"
      :label="label"
      :placeholder="placeholder"
      :disabled="loading"
      rounded
      filled
      prepend-inner-icon="search"
      @input="input"
    />
  </div>
</template>

<script>
import debounce from 'debounce'

export default {
  name: 'Search',
  props: {
    label: {
      type: String,
      default: 'Search'
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
    value: {
      type: String,
      default: ''
    },
    debounce: {
      type: Boolean,
      default: true
    },
    debounceTime: {
      type: Number,
      default: 700
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      term: null,
      debounced: null
    }
  },
  computed: {
    loading() {
      if (this.isLoading) {
        return true
      }
      this.clearSearch()
      this.focusInput()
      return false
    }
  },
  created() {
    this.term = this.value
    this.debounced = debounce(
      function (term) {
        this.$emit('input', term)
      },
      this.debounce ? this.debounceTime : 0
    )
  },
  methods: {
    input(term) {
      this.debounced(term)
    },
    focusInput() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.labelInput.focus()
        })
      })
    },
    clearSearch() {
      if (!this.value) this.term = ''
    }
  }
}
</script>

<style lang="scss">
.search {
  .v-text-field--rounded {
    border-radius: 4px;
  }

  .v-input__prepend-inner {
    padding-right: 19px !important;
  }
}
</style>
