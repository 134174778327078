<template>
  <div>
    <profile-form
      v-slot="{ editMode, loading }"
      hide-clear
      :action-function="onSubmit"
      class="mt-6"
      @cancel="clearForm"
    >
      <fieldset>
        <legend>Payor Enrollments</legend>
        <profile-field
          :edit-mode="editMode"
          class="mt-4 pl-4"
          label="Group Id"
          :loading="loading"
        >
          <template #default="attrs">
            <v-text-field
              v-bind="attrs"
              v-model="groupId"
              name="medallion_id"
              placeholder="Group Id"
              :disabled="client.medallion_id != null"
              item-text="display"
              item-value="value"
            />
          </template>
          <template #view>
            <data-row label="Group Id" :value="groupId" />
          </template>
        </profile-field>
      </fieldset>
    </profile-form>
  </div>
</template>

<script>
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import DataRow from '@/components/common/DataRow.vue'
import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE as clientNamespace } from '@/store/clients/state'

const { mapActions: mappedClientActions } =
  createNamespacedHelpers(clientNamespace)

export default {
  name: 'PayerEnrollments',
  components: {
    ProfileField,
    ProfileForm,
    DataRow
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      groupId: ''
    }
  },
  async created() {
    this.groupId = this.client.medallion_id
  },
  methods: {
    ...mappedClientActions(['editClient']),
    clearForm() {
      this.groupId = this.client.medallion_id
    },
    async onSubmit() {
      if (!this.client.medallion_id) {
        try {
          await this.editClient({
            clientId: this.client.id,
            payload: {
              medallion_id: this.groupId
            }
          })
        } catch (e) {
          let message = 'Unable to save group id. '
          if (e.response) {
            message += e.response.data.messages[0]
          } else {
            message += e.message
          }
          this.$store.commit('SET_SNACKBAR', {
            message,
            show: true
          })
          this.clearForm()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
