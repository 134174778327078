<template>
  <div class="clinician-view">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <view-header :breadcrumbs="breadcrumbs" :loading="loading">
      <clinician-header :clinician.sync="clinician" />
    </view-header>
    <div v-if="!loading">
      <tabs>
        <template #summary>
          <clinician-summary
            :clinician.sync="clinician"
            :consults="activeConsults"
          />
        </template>
        <template #credentialing>
          <clinician-credentialing :clinician="clinician" />
        </template>
        <template #clients>
          <clinician-enrollments class="mt-4" :clinician="clinician" />
        </template>
        <template #profile>
          <clinician-profile class="mt-4" :clinician.sync="clinician" />
        </template>
        <template #collab-agreements>
          <clinician-collab-agreements
            class="mt-4"
            :clinician.sync="clinician"
          />
        </template>
        <template #schedule>
          <clinician-schedule :clinician="clinician" />
        </template>
        <template #history>
          <history
            :id="clinicianId"
            class="mt-4"
            :force-reload="reloadEvents"
            entity-type="clinician"
            title="Event and Activity History"
            @event-reload="reloadEvents = false"
          />
        </template>
        <template #earnings>
          <clinician-earnings class="mt-4" :clinician="clinician" />
        </template>
        <template #performance>
          <clinician-performance class="mt-4" :clinician.sync="clinician" />
        </template>
        <template #notes>
          <clinician-notes class="mt-4" :clinician-id="clinician.uuid" />
        </template>
      </tabs>
    </div>
  </div>
</template>

<script>
import ClinicianEnrollments from '@/components/clinicians/ClinicianEnrollments.vue'
import ClinicianHeader from '@/components/clinician/ClinicianHeader.vue'
import ClinicianSummary from '@/components/clinicians/ClinicianSummary.vue'
import ClinicianSchedule from '@/components/clinicians/ClinicianSchedule.vue'
import ClinicianProfile from '@/components/clinicians/ClinicianProfile.vue'
import ClinicianNotes from '@/components/clinicians/ClinicianNotes.vue'
import History from '@/components/common/History.vue'
import ClinicianEarnings from '@/components/clinicians/ClinicianEarnings.vue'
import Tabs from '@/components/common/Tabs.vue'
import ViewHeader from '@/components/common/ViewHeader.vue'
import ClinicianCollabAgreements from '@/components/clinicians/ClinicianCollabAgreements.vue'
import ClinicianCredentialing from '@/components/clinicians/ClinicianCredentialing.vue'
import ClinicianPerformance from '@/components/clinicians/ClinicianPerformance.vue'

export default {
  name: 'ClinicianView',
  components: {
    Tabs,
    ClinicianProfile,
    ClinicianSchedule,
    ClinicianSummary,
    ClinicianEnrollments,
    ClinicianHeader,
    History,
    ClinicianEarnings,
    ClinicianNotes,
    ViewHeader,
    ClinicianCollabAgreements,
    ClinicianCredentialing,
    ClinicianPerformance
  },
  props: {
    clinicianId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      clinician: null,
      activeConsults: [],
      reloadEvents: false
    }
  },
  computed: {
    breadcrumbs() {
      if (this.clinician) {
        return [
          { to: '/clinicians', display: 'Clinicians' },
          {
            to: `/clinicians/${this.clinicianId}`,
            display: this.clinician.name
          }
        ]
      }
      return [
        { to: '/clinicians', display: 'Clinicians' },
        {
          to: `/clinicians/${this.clinician?.id}`,
          display: this.clinician?.name
        }
      ]
    }
  },
  watch: {
    clinicianId() {
      this.loadClinician()
      this.loadActiveConsults()
    }
  },
  created() {
    this.loadClinician()
    this.loadActiveConsults()
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    async loadClinician() {
      this.loading = true
      this.clinician = await this.$clinicians.show(this.clinicianId)
      this.loading = false

      if (this.$route.params.id !== this.clinician.id) {
        // Since we're already on this route, all this will do is change the ID in the URL
        this.$router.replace({
          params: {
            ...this.$route.params,
            id: this.clinician.id
          }
        })
      }
    },
    async loadActiveConsults() {
      this.activeConsults = await this.$clinicians.activeConsults(
        this.clinicianId
      )
    }
  }
}
</script>

<style lang="scss">
.full-calendar {
  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
.unconfirmed-event {
  filter: brightness(0.7);
}
</style>
