<template>
  <v-card-text class="px-0">
    <v-alert dense outlined text class="pa-4" icon="mdi-alert-circle-outline">
      <span class="font-weight-bold"
        >Please upload coverage blocks to determine when clinicians can schedule
        shifts for {{ clientName }}
      </span>
      <br />
      This feature should only be used for clients that want to limit the # of
      clinicians in a shift. Blocks should still fall within the client's
      contractually defined operating hours
      <br />
    </v-alert>
  </v-card-text>
</template>

<script>
export default {
  name: 'CoverageBlocksUploadMessage',
  props: {
    clientName: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
