import axios from 'axios'

const Coverages = {
  install(Vue) {
    Vue.prototype.$coverages = new Vue({
      methods: {
        async getTemplateText() {
          const { data } = await axios.get('/admin/coverage_blocks/template', {
            responseType: 'text'
          })
          return data
        },
        async batchCreate(id, coverages) {
          const { data } = await axios.post(
            `/admin/consult_rates/${id}/coverage_blocks/batch`,
            { coverage_blocks: coverages }
          )
          return data
        }
      }
    })
  }
}

export default Coverages
