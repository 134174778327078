<template>
  <v-card>
    <v-card-title class="headline"> {{ title }} </v-card-title>
    <v-card-text>
      <v-alert
        dense
        outlined
        icon="mdi-alert-circle-outline"
        class="text-color--orange-900 warning-alert mt-4 mb-4"
      >
        {{ content }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="close"> Cancel </v-btn>
      <v-btn color="primary" rounded @click="confirm"> Confirm </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'WarningModal',
  props: {
    content: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>
<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 0px;
}
.warning-alert {
  background-color: $orange-50 !important;
  border-color: $orange-600 !important;
}
</style>
