import axios from 'axios'

const ActivityRates = {
  install(Vue) {
    Vue.prototype.$activityRates = new Vue({
      methods: {
        async getById(id) {
          const { data } = await axios.get(`/admin/activity_rates/${id}`)
          return data
        },
        async edit(id, activity_rate) {
          const response = await axios.patch(`/admin/activity_rates/${id}`, {
            activity_rate
          })
          return response.data
        }
      }
    })
  }
}

export default ActivityRates
