import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import DataDog from './plugins/DataDog'
import authConfig from '@/config/auth'
import Clinicians from './services/Clinicians'
import Consults from './services/Consults'
import Opportunities from './services/Opportunities'
import Clients from './services/Clients'
import ConsultRates from './services/ConsultRates'
import ConsultRateTiers from './services/ConsultRateTiers'
import ActivityRates from './services/ActivityRates'
import Forecast from './services/Forecast'
import Enrollments from './services/Enrollments'
import Feedback from './services/Feedback'
import Coverages from './services/Coverages'
import MiscTransfers from './services/MiscTransfers'
import ConsultImports from './services/ConsultImports'
import RoutingReports from './services/RoutingReports'
import Stripe from './services/Stripe'
import CollabAgreements from './services/CollabAgreements'
import StateCollabRequirements from './services/StateCollabRequirements'
import VueGtag from 'vue-gtag'
import Payments from './services/Payments'
import ConfigValues from './services/ConfigValues'
import OptOuts from './services/OptOuts'

import { Auth0Plugin } from '@/plugins/auth0'
import { initializeStatsig } from '@/services/Statsig'

import './assets/scss/_all.scss'

Vue.config.productionTip = false

initializeStatsig()

Vue.use(DataDog)
Vue.use(Clinicians)
Vue.use(Consults)
Vue.use(Opportunities)
Vue.use(Clients)
Vue.use(Enrollments)
Vue.use(ConsultRates)
Vue.use(ConsultRateTiers)
Vue.use(ActivityRates)
Vue.use(Forecast)
Vue.use(Feedback)
Vue.use(Coverages)
Vue.use(MiscTransfers)
Vue.use(ConsultImports)
Vue.use(RoutingReports)
Vue.use(Stripe)
Vue.use(CollabAgreements)
Vue.use(StateCollabRequirements)
Vue.use(Payments)
Vue.use(ConfigValues)
Vue.use(OptOuts)

const options = {
  domain: authConfig.auth0.domain,
  clientId: authConfig.auth0.clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  },
  authorizationParams: {
    audience: authConfig.auth0.audience
  }
}

Vue.use(Auth0Plugin, options)

Vue.use(
  VueGtag,
  {
    config: { id: 'UA-109031398-4' }
  },
  router
)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
