import axios from 'axios'

const Pagination = {
  getAll: async (url) => {
    const response = await axios.get(url, { params: { items: 100 } })
    let payload = response.data
    let results = payload.data
    while (payload.links?.next) {
      const addl = await axios.get(payload.links.next, {
        params: { items: 100 }
      })
      payload = addl.data
      results = results.concat(payload.data)
    }
    response.data.data = results
    return response
  },
  /**
   * `getCurrentPageStartElement` returns the start element of the current page.
   * Note that this returns the 1-based index of the starting element, not the element itself.
   * @param {number} currentPage
   * @param {number} pageSize
   * @example getCurrentPageStartElement(1, 20); // 1
   * @example getCurrentPageStartElement(2, 20); // 21
   */
  getCurrentPageStartElement: (currentPage, pageSize) =>
    currentPage === 1 ? 1 : (currentPage - 1) * pageSize + 1
}

export default Pagination
