<template>
  <v-autocomplete
    v-model="selected"
    :error-messages="errorMessage"
    :loading="loadingConsultRates"
    :items="clientsAndRates"
    return-object
    clearable
    prepend-icon="mdi-hospital-box"
    :disabled="disabled"
    v-on="listeners"
  >
    <template #label>
      <h4 class="consult-rate-selector__label">Consult Rate</h4>
    </template>
    <template #item="{ item }">
      <hr v-if="item.disabled" class="ma-0" />
      <client-label v-else-if="item.client" v-bind="item">
        {{ item.name }}
      </client-label>
      <client-label v-else :name="item.name">
        {{ item.text }}
      </client-label>
    </template>
  </v-autocomplete>
</template>

<script>
import ClientLabel from '@/components/common/ClientLabel'
import { CLIENT, RATE } from '@/components/forecasts/ForecastCalendar'
export default {
  name: 'ConsultRateSelector',
  components: {
    ClientLabel
  },
  props: {
    disabled: Boolean,
    multiClient: Boolean,
    clientId: {
      type: [String, Number],
      default: undefined
    },
    clinicianId: {
      type: [String, Number],
      default: undefined
    }
  },
  data() {
    return {
      loadingConsultRates: false,
      consultRates: [],
      clinicianEnrollments: {},
      error: null
    }
  },
  computed: {
    listeners() {
      // handle change event ourselves using computed route query changes
      const { change, ...rest } = this.$listeners
      return rest
    },
    errorMessage() {
      return this.error?.message
    },
    clientsAndRates() {
      // need the ids to be strings otherwise the query param -> select conversion doesn't work well
      let currentClient
      return this.consultRates.reduce((acc, val) => {
        if (
          !Object.keys(this.clinicianEnrollments).length ||
          this.clinicianEnrollments[val.id]
        ) {
          if (this.multiClient && currentClient !== val.client.id) {
            if (acc.length !== 0) {
              acc.push({
                disabled: true,
                class: 'filter-divider'
              })
            }
            acc.push({
              type: CLIENT,
              text: `${val.client.name} (All Rates)`,
              value: `${CLIENT}:${val.client.id}`,
              ...val.client
            })
            currentClient = val.client.id
          }
          acc.push({
            type: RATE,
            text: this.multiClient
              ? `${val.client.name} - ${val.name}`
              : val.name,
            value: `${RATE}:${val.id}`,
            ...val
          })
        }
        return acc
      }, [])
    },
    selected: {
      set(selected) {
        if (!selected) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              client: undefined,
              rate: undefined
            }
          })
        } else if (this.$route.query[selected.type] !== selected.id) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              // overwrite any currently set client and rate keys
              ...{ [CLIENT]: undefined, [RATE]: undefined },
              // replace with our key
              [selected.type]: selected.id
            }
          })
        }
      },
      get() {
        if (this.$route.query[RATE]) {
          return `${RATE}:${this.$route.query[RATE]}`
        } else if (this.$route.query[CLIENT]) {
          return `${CLIENT}:${this.$route.query[CLIENT]}`
        }
        return null
      }
    }
  },
  watch: {
    clientId() {
      this.getConsultRates()
    },
    clinicianId(newVal) {
      if (newVal) {
        this.getEnrollments()
      } else {
        this.clinicianEnrollments = {}
      }
    },
    '$route.query': {
      handler: function (value, oldValue) {
        if (oldValue) {
          if (value[RATE] !== oldValue[RATE]) {
            this.$emit('change', { type: RATE, id: value[RATE] })
          } else if (value[CLIENT] !== oldValue[CLIENT]) {
            this.$emit('change', { type: CLIENT, id: value[CLIENT] })
          }
        } else if (value[RATE]) {
          this.$emit('change', { type: RATE, id: value[RATE] })
        } else if (value[CLIENT]) {
          this.$emit('change', { type: CLIENT, id: value[CLIENT] })
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getConsultRates()
  },
  methods: {
    async getEnrollments() {
      try {
        this.loadingConsultRates = true
        const response = await this.$clinicians.getEnrollments(this.clinicianId)
        this.clinicianEnrollments = response.data.reduce((acc, enrollment) => {
          acc[enrollment.consult_rate.id] = enrollment
          return acc
        }, {})
      } catch (e) {
        this.error = e
      } finally {
        this.loadingConsultRates = false
      }
    },
    async getConsultRates() {
      try {
        this.loadingConsultRates = true
        const response = this.clientId
          ? await this.$clients.getConsultRates(this.clientId)
          : await this.$consultRates.get()
        this.consultRates = response.data
      } catch (e) {
        this.error = e
      } finally {
        this.loadingConsultRates = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.consult-rate-selector {
  &__label {
    font-size: size(12);
    line-height: size(16);
    color: #666;
    text-transform: uppercase;
    @include font-monospaced;
  }
}
</style>
