<template>
  <modal
    v-model="showModalLocal"
    :confirm="cropImage"
    :close="closeModal"
    :width="1200"
    :height="1200"
    :disable-primary="!isImageCorrectSize"
    @click:outside="closeModal"
  >
    <template #title> Crop your image </template>
    <template #content>
      <p>Crop your image to be smaller than 1000px by 1000px</p>
      <cropper
        ref="cropper"
        class="cropper"
        :src="image"
        :auto-zoom="true"
        :stencil-size="{
          width: 1000,
          height: 1000
        }"
        image-restriction="stencil"
        @change="change"
      />
      <p class="mt-3">
        Current Image size:
        <span :class="acceptedDimensionClass">
          {{ height }}px by {{ width }}px
        </span>
      </p>
    </template>

    <template #primary> Crop Photo </template>
  </modal>
</template>

<script>
import Modal from '@/components/common/Modal.vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  name: 'ProfileImageCropper',
  components: {
    Cropper,
    Modal
  },
  props: {
    image: {
      type: String,
      default: ''
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      height: null,
      width: null
    }
  },
  computed: {
    isImageCorrectSize() {
      return this.height < 1000 && this.width < 1000
    },
    acceptedDimensionClass() {
      return this.isImageCorrectSize ? 'accepted' : 'not-accepted'
    },
    showModalLocal: {
      get: function () {
        return this.showModal
      },
      set: function (value) {
        return this.$emit('update:showModal', value)
      }
    }
  },
  methods: {
    change({ coordinates }) {
      this.height = coordinates.height
      this.width = coordinates.width
    },
    cropImage() {
      const { canvas } = this.$refs.cropper.getResult()
      this.$emit('image-cropped', canvas.toDataURL(), canvas)
    },
    closeModal() {
      this.$emit('close-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.not-accepted {
  color: red;
}

.accepted {
  color: green;
}
</style>
