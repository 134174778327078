<template>
  <section>
    <div>
      <v-row>
        <v-col class="notes">
          <h6 class="mb-6">Notes</h6>
          <div v-if="!notes.length">No notes found for this clinician.</div>
          <v-row v-for="note in notes" :key="note.created_at" class="wrapper">
            <v-col cols="9">
              <div class="metadata">
                <span>
                  {{ note.created_at | format }}
                </span>
                <span>
                  <v-icon color="#737380" small>mdi-account-outline</v-icon>
                  {{ note.author }}
                </span>
              </div>
              <div class="note">
                {{ note.note }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical />
        <v-col class="create" cols="3">
          <h6 class="mb-6">Add a Note</h6>
          <v-textarea
            v-model="newNote"
            class="mb-4"
            label="About the clinician"
            hint="Please refrain from adding information regarding quality of patient care, personality traits, judgements, or bias."
            outlined
          />
          <v-btn
            :loading="saving"
            class="mr-4 primary button"
            rounded
            @click="saveNote"
          >
            Add Note
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ClinicianNotes',
  filters: {
    format: function (date) {
      return moment(date).format('MMMM D, YYYY')
    }
  },
  props: {
    clinicianId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      notes: [],
      newNote: '',
      saving: false
    }
  },
  async mounted() {
    try {
      this.notes = await this.$clinicians.getNotes(this.clinicianId)
    } catch (e) {
      let message = 'Unable to find notes. '
      if (e.response) {
        message += e.response.data.messages[0]
      } else {
        message += e.message
      }
      this.$store.commit('SET_SNACKBAR', {
        message,
        show: true
      })
    }
  },
  methods: {
    async saveNote() {
      this.saving = true

      try {
        const response = await this.$clinicians.createNote(
          this.clinicianId,
          this.newNote
        )
        this.notes = [response, ...this.notes]
      } catch (e) {
        let message = 'Unable to save note. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.saving = false
        this.newNote = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notes {
  margin-right: 30px;
}

.wrapper {
  border-bottom: 1px solid #e3e3e3;
  padding: 0 5px;

  &:last-child {
    border-bottom: none;
  }
}

.metadata {
  color: #747474;
  display: block;
}

.note {
  padding-top: 10px;
  font-size: size(16);
  word-wrap: break-word;
  width: 1000px;
}

.create {
  margin-left: 30px;
  height: 500px;
}

.button {
  float: right;
}
</style>
