import { orderTimesWithinDay } from '@/utils/Date'
import OPERATING_HOUR_TYPES from '@/utils/OperatingHourTypes'

function dayToIndexMap() {
  return {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  }
}

function indexToDayMap() {
  return {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  }
}

function convertToArray(operatingHours) {
  return Object.entries(operatingHours).reduce((result, [day, entries]) => {
    const capitalizedDayString = day.charAt(0).toUpperCase() + day.slice(1)
    result[dayToIndexMap()[capitalizedDayString]] = entries
    return result
  }, new Array(7))
}

function indexToDayOfWeek(sortedTimes) {
  return sortedTimes.reduce((result, entries, day) => {
    result[indexToDayMap()[day]] = [...entries]
    return result
  }, {})
}

function addSwitchToObjectValues(operatingHours) {
  return Object.entries(operatingHours).reduce((result, [day, entries]) => {
    result[day] = { times: entries, switch: entries.length > 0 }
    return result
  }, {})
}

function setToArrayOfEmptyIfNoValues(orderedDaysOfWeek) {
  if (!orderedDaysOfWeek.includes()) return orderedDaysOfWeek
  else return [[], [], [], [], [], [], []]
}

function combineCoverageBlocksTimes(times) {
  let newCombindedArray = []
  newCombindedArray = times.reduce((result, current, index) => {
    if (index === 0) {
      result.push(current)
      return result
    }

    const prev = result[result.length - 1]
    if (current.start_at <= prev.end_at) {
      const newBlock = {
        start_at:
          current.start_at < prev.start_at ? current.start_at : prev.start_at,
        end_at: prev.end_at > current.end_at ? prev.end_at : current.end_at
      }
      result.pop()
      result.push(newBlock)
    } else {
      result.push(current)
    }
    return result
  }, [])

  return newCombindedArray
}

function getSelectedOperatingHourType(sortedTimes, data) {
  const daytimeHours = Object.entries(sortedTimes).every((x) => {
    return (
      x[1].length === 1 &&
      x[1][0].start_at == '07:00:00' &&
      x[1][0].end_at == '21:00:00'
    )
  })
  const fullHours = Object.entries(sortedTimes).every((x) => {
    return (
      x[1].length === 1 &&
      x[1][0].start_at == '00:00:00' &&
      x[1][0].end_at == '00:00:00'
    )
  })

  if (Object.keys(data).length === 0) return ''
  else if (daytimeHours) return OPERATING_HOUR_TYPES.OPERATING_HOUR_TYPE.DAYTIME
  else if (fullHours)
    return OPERATING_HOUR_TYPES.OPERATING_HOUR_TYPE.FULL_COVERAGE
  else return OPERATING_HOUR_TYPES.OPERATING_HOUR_TYPE.CUSTOM
}

function getDaylightOperatingHours() {
  const daylightCoverageHours = [{ start_at: '07:00', end_at: '21:00' }]
  let daylightOperatingHours = {}
  Object.keys(dayToIndexMap()).forEach((key) => {
    daylightOperatingHours[key] = {
      times: daylightCoverageHours,
      switch: true
    }
  })
  return daylightOperatingHours
}
function getOperatingHoursToFullTime() {
  const fullDayHours = [{ start_at: '00:00', end_at: '00:00' }]
  let fullDayOperatingHours = {}
  Object.keys(dayToIndexMap()).forEach((key) => {
    fullDayOperatingHours[key] = { times: fullDayHours, switch: true }
  })
  return fullDayOperatingHours
}

const getters = {
  orderDayTimes({ data }) {
    const orderedDaysOfWeek = convertToArray(structuredClone(data))
    const organizedTimes = orderTimesWithinDay(orderedDaysOfWeek)
    return structuredClone(setToArrayOfEmptyIfNoValues(organizedTimes))
  },
  combinedTimes(_, { orderDayTimes }) {
    const indexedData = indexToDayOfWeek(orderDayTimes)
    for (const [key] of Object.entries(indexedData)) {
      indexedData[key] = combineCoverageBlocksTimes(indexedData[key])
    }

    return structuredClone(indexedData)
  },
  addSwitchToObjectValues(_, { combinedTimes }) {
    return structuredClone(addSwitchToObjectValues(combinedTimes))
  },
  getSelectedOperatingHourType({ data }, { combinedTimes }) {
    return getSelectedOperatingHourType(combinedTimes, data)
  },
  getDaylightOperatingHours() {
    return getDaylightOperatingHours()
  },
  getOperatingHoursToFullTime() {
    return getOperatingHoursToFullTime()
  }
}

export default getters
