import Tiers from '@/utils/ConsultRateTiers'

const ConsultRateTiers = {
  methods: {
    findTier(state) {
      let tierName = ''
      for (const tier of Object.keys(Tiers)) {
        if (Tiers[tier].states.includes(state.toUpperCase())) {
          tierName = Tiers[tier].name
          break
        }
      }
      return tierName
    },
    getTierStates(tier) {
      return Tiers[tier].states.map((state) => state.toLowerCase())
    }
  }
}

export default ConsultRateTiers
