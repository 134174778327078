<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    content-class="new-clinician-dialog"
  >
    <template #activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title class="new-clinician flex align-center mr-2"
          ><v-icon class="mr-2">mdi-account-plus-outline</v-icon> New
          Clinician</v-list-item-title
        >
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="d-flex align-center">
        <v-btn icon class="mr-3" @click="close">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
        <span class="headline">Create New Clinician</span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field
            v-model="createInputs.name"
            label="Clinician Name*"
            :rules="[...rules.required, ...rules.name]"
            clearable
          />
          <v-text-field
            v-model="createInputs.email"
            label="Email*"
            :rules="[...rules.required, ...rules.email]"
            clearable
          />
          <v-autocomplete
            v-model="createInputs.practitioner_type"
            label="Practitioner Type*"
            :items="practitionerTypesOptions"
            :rules="rules.required"
            clearable
          />
          <v-text-field
            v-model="createInputs.npi"
            label="NPI"
            :rules="rules.validNpi"
            clearable
          />
          <v-text-field
            v-model="createInputs.phone"
            :rules="rules.phone"
            label="Phone"
            clearable
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <span v-if="modalError" class="modal-error">{{ modalError }}</span>
        <v-spacer />
        <v-btn text @click="close"> Cancel </v-btn>
        <v-btn
          color="primary"
          rounded
          :loading="loading"
          :disabled="!valid"
          @click="confirm"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Regex from '@/utils/Regex'
import { validNpi } from '@/utils/npi'
import PractitionerTypes from '@/utils/PractitionerTypes'
import ValidationMixin from '@/mixins/Validation'

export default {
  name: 'AddClinicianDialog',
  mixins: [ValidationMixin],
  data() {
    return {
      loading: false,
      error: null,
      modalError: null,
      createInputs: {},
      dialog: false,
      practitionerTypesOptions: PractitionerTypes,
      rules: {
        email: [(v) => (v && Regex.EMAIL.test(v)) || 'Must be a valid email'],
        name: [
          (v) => {
            return (
              (v?.length >= 3 && v.includes(' ')) ||
              'First name and last name required'
            )
          }
        ],
        phone: [(v) => (v && Regex.PHONE.test(v)) || !v],
        validNpi: validNpi,
        required: [
          (v) => {
            return v?.toString().length > 0 || ''
          }
        ]
      },
      valid: false
    }
  },
  computed: {
    addButtonDisabled() {
      return this.$route.path === '/Clinicians/state-requirements'
    }
  },
  async created() {
    this.fetchClinicianTypes()
  },
  methods: {
    close() {
      this.dialog = false
    },
    async fetchClinicianTypes() {
      const types = await this.$clinicians.getClinicianFilters()
      this.practitionerOptions = types.practitioner_types.sort((a, b) =>
        a.display.localeCompare(b.display)
      )
    },
    closeDialog(value) {
      this.dialog = value
      this.$emit('close')
    },
    async confirm() {
      try {
        this.results = await this.$clinicians.createTestClinician(
          this.createInputs
        )
        this.$router.push({ path: `/clinicians/${this.results.data.uuid}` })
        this.$store.commit('SET_SNACKBAR', {
          message: 'Success! Clinician was created.',
          show: true
        })
        this.close()
        return this.results
      } catch (error) {
        this.modalError = error.response.data.messages[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-clinician {
  font-size: size(16) !important;
}
</style>
