const COLLAB_AGREEMENT_STATUS = {
  SIGNED: 'Signed',
  COLLAB_REMOVED: 'Collab Removed',
  COLLAB_REMOVED_OBTAINED_AUTONOMY: 'Collab Removed - Obtained Autonomy'
}

const COLLAB_AGREEMENT_TYPE = {
  TOTAL: 'Total',
  PRESCRIBING: 'Prescribing',
  NON_PRESCRIBING: 'Non-prescribing'
}

const REVIEW_STATUS_REASON = {
  NP_LICENSE_EXPIRED: 'NP License Expired',
  PHYSICIAN_LICENSE_EXPIRED: 'Physician License Expired',
  NP_LICENSE_UNAPPROVED: 'NP License Unapproved',
  PHYSICIAN_LICENSE_UNAPPROVED: 'Physician License Unapproved',
  NP_ACCOUNT_SUSPENDED: 'NP Account Suspended',
  PHYSICIAN_ACCOUNT_SUSPENDED: 'Physician Account Suspended',
  PENDING_EXTERNAL_VERIFICATION: 'Pending External Verification'
}

const REVIEW_STATUS = {
  NO_CONFLICT: 'No Conflict',
  NEEDS_REVIEW: 'Needs Review',
  TIMEOUT: 'Timeout',
  RESOLVED: 'Resolved'
}

const SUPERVISING_CLINICINAN_OPTIONS = {
  NOT_REQUIRED: 'Not Required',
  SAME_AS_NURSE_PRACTITIONER: 'Same as Nurse Practitioner'
}

export default {
  COLLAB_AGREEMENT_STATUS,
  COLLAB_AGREEMENT_TYPE,
  REVIEW_STATUS_REASON,
  REVIEW_STATUS,
  SUPERVISING_CLINICINAN_OPTIONS
}
