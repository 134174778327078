<template>
  <div>
    <profile-field
      v-model="coverageBlockRequired"
      label="Coverage Type"
      :edit-mode="editMode"
    >
      <template #default="attrs">
        <v-autocomplete
          v-model="coverageBlockRequired"
          class="pt-4"
          v-bind="attrs"
          name="coverageBlockRequired"
          :items="coverageBlocksRequiredOptions"
          item-text="display"
          item-value="value"
        />
      </template>
      <template #view="attrs">
        <data-row v-bind="attrs">
          {{ displayCoverageBlocksValue }}
        </data-row>
      </template>
    </profile-field>
  </div>
</template>

<script>
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import DataRow from '@/components/common/DataRow.vue'
import OPERATING_HOUR_TYPES from '@/utils/OperatingHourTypes'

import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE as clientNamespace } from '@/store/clients/state'

const { mapGetters: mappedClientGetters } =
  createNamespacedHelpers(clientNamespace)

export default {
  name: 'ClientDetails',
  components: {
    ProfileField,
    DataRow
  },
  props: {
    clientId: {
      type: String,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    },
    coverageBlockRequiredData: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      coverageBlocksRequiredOptions: Object.values(
        OPERATING_HOUR_TYPES.COVERAGE_TYPE
      )
    }
  },
  computed: {
    coverageBlockRequired: {
      get() {
        return this.coverageBlockRequiredData
      },
      set(newValue) {
        this.$emit('switchedCoverageTypes', newValue)
      }
    },
    displayCoverageBlocksValue() {
      return this.coverageBlockRequired ===
        OPERATING_HOUR_TYPES.COVERAGE_TYPE.OPERATING_HOURS.value
        ? OPERATING_HOUR_TYPES.COVERAGE_TYPE.OPERATING_HOURS.display
        : OPERATING_HOUR_TYPES.COVERAGE_TYPE.COVERAGE_BLOCKS.display
    }
  },
  watch: {
    coverageBlockRequiredData: {
      handler: function () {
        this.coverageBlockRequired = this.coverageBlockRequiredData
      }
    }
  },
  methods: {
    ...mappedClientGetters(['clientById'])
  }
}
</script>

<style lang="scss" scoped></style>
