var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.editMode)?_c('div',_vm._l((_vm.consultRateDetails),function(property){return _c('data-row',{key:property.label,attrs:{"label":property.label,"value":property.value}})}),1):_c('div',[_c('data-row',{attrs:{"label":"Id","value":_vm.consultRate.id}}),_c('data-row',{attrs:{"label":"Uuid","value":_vm.consultRate.uuid}}),_c('data-row',{attrs:{"label":"Client","value":_vm.consultRate.client.name}}),_c('data-row',{attrs:{"label":"Modality","value":_vm.consultRate.modality}}),(!_vm.notInstant)?_c('data-row',{attrs:{"label":"Consult Type","value":_vm.consultRate.consult_type}}):_vm._e(),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',[_c('v-text-field',{attrs:{"label":"Consult Rate Name","value":_vm.displayValue('name'),"outlined":"","rules":[_vm.rules.name],"clearable":""},on:{"input":function($event){return _vm.updateInput('name', $event)}}}),_c('v-select',{attrs:{"label":"Practitioner Types","value":_vm.displayValue('practitioner_types'),"outlined":"","chips":"","multiple":"","items":_vm.practitionerTypesOptions,"hint":"Determines what practitioner types can enroll in this consult rate.","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('practitioner_types', $event)}}}),_c('v-text-field',{attrs:{"label":"Cost to Client","value":_vm.displayValue('cost_to_client'),"outlined":"","rules":[
          _vm.requiredField,
          _vm.twoDecimalsAllowed,
          _vm.positiveValue(_vm.displayValue('cost_to_client'), 'Cost to client')
        ],"clearable":"","prefix":"$","hint":"Determines the base cost to clients per consult once completed.","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('cost_to_client', $event)}}}),_c('v-text-field',{attrs:{"label":"Paid to Provider","value":_vm.displayValue('paid_to_provider'),"outlined":"","rules":[
          _vm.positiveValue(_vm.displayValue('paid_to_provider'), 'Paid to provider')
        ],"clearable":"","prefix":"$","hint":"Determines the base pay per clinician per consult once completed.","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('paid_to_provider', $event)}}}),_c('v-text-field',{attrs:{"label":"SLA (seconds)","value":_vm.displayValue('sla_in_seconds'),"outlined":"","rules":[
          _vm.slaLength(_vm.displayValue('sla_in_seconds'), _vm.consultRate.modality)
        ],"clearable":"","hint":"Determines the SLA given per consult.","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('sla_in_seconds', $event)}}}),_c('v-text-field',{attrs:{"label":"Assignment Lifetime (seconds)","value":_vm.displayValue('assignment_lifetime'),"outlined":"","rules":[_vm.rules.assignment_lifetime],"clearable":"","hint":"The time allowed for assignment.","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('assignment_lifetime', $event)}}}),(_vm.notInstant)?_c('v-text-field',{attrs:{"label":"Estimated Duration (seconds)","value":_vm.displayValue('estimated_duration_in_seconds'),"outlined":"","rules":[_vm.rules.estimated_duration],"clearable":"","hint":"Determines the length of a consult. Will impact current and future bookings if changed","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('estimated_duration_in_seconds', $event)}}}):_vm._e(),(_vm.notAsyncInstant)?_c('v-text-field',{attrs:{"label":"Advance Notice (seconds)","value":_vm.displayValue('advance_notice_in_seconds'),"outlined":"","rules":[
          _vm.positiveValue(
            _vm.displayValue('advance_notice_in_seconds'),
            'Advance notice'
          ),
          _vm.noDecimalsAllowed
        ],"clearable":"","hint":"Determines the advance notice for a consult","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('advance_notice_in_seconds', $event)}}}):_vm._e(),(_vm.notAsyncInstant)?_c('v-text-field',{attrs:{"label":"Buffer Before Duration (seconds)","value":_vm.displayValue('buffer_before_duration_in_seconds'),"outlined":"","rules":[
          _vm.positiveValue(
            _vm.displayValue('buffer_before_duration_in_seconds'),
            'Buffer before duration'
          )
        ],"clearable":"","hint":"Affects the amount of time before a scheduled sync. Can also impact clinician shifts","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('buffer_before_duration_in_seconds', $event)}}}):_vm._e(),(_vm.notAsyncInstant)?_c('v-text-field',{attrs:{"label":"Buffer After Duration (seconds)","value":_vm.displayValue('buffer_after_duration_in_seconds'),"outlined":"","rules":[
          _vm.positiveValue(
            _vm.displayValue('buffer_after_duration_in_seconds'),
            'Buffer after duration'
          )
        ],"clearable":"","hint":"Affects the amount of time before a scheduled sync. Can also impact clinician shifts","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('buffer_after_duration_in_seconds', $event)}}}):_vm._e(),(_vm.notAsyncInstant)?_c('v-text-field',{attrs:{"label":"Patient No Show Wait Time (seconds)","value":_vm.displayValue('patient_no_show_wait_in_seconds'),"outlined":"","rules":[_vm.rules.patient_no_show],"clearable":"","hint":"Used to calculate at what time the patient no show option is shown","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('patient_no_show_wait_in_seconds', $event)}}}):_vm._e(),(_vm.notInstant)?_c('v-autocomplete',{attrs:{"label":"Consult Type","value":_vm.displayValue('consult_type'),"items":_vm.typeOptions,"item-text":"display","item-value":"value","outlined":"","clearable":"","hint":"Consult Type: Determines the workflow of the consult.","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('consult_type', $event)}}}):_vm._e(),_c('v-autocomplete',{attrs:{"label":"Compensation Unit","value":_vm.displayValue('compensation_unit'),"items":_vm.compensationOptions,"item-text":"display","item-value":"value","outlined":"","clearable":"","hint":"Hourly compensation requires the Payments team to manually process each consult with this type.","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('compensation_unit', $event)}}}),_c('v-text-field',{attrs:{"label":"Autocomplete","value":_vm.displayValue('autocomplete_in_days'),"outlined":"","rules":[
          _vm.positiveValue(_vm.displayValue('autocomplete_in_days'), 'autocomplete')
        ],"clearable":"","hint":"Number of days before a consult will be autocompleted after the last chat is sent.","persistent-hint":""},on:{"input":function($event){return _vm.updateInput('autocomplete_in_days', $event)}}}),_c('footer',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"plain":"","rounded":""},on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"depressed":"","rounded":"","color":"primary","loading":_vm.saving,"disabled":_vm.shouldDisable},on:{"click":_vm.handleConfirm}},[_vm._v(" Save Changes ")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }