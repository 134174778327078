<template>
  <router-link v-if="to" class="text-decoration-none black--text" :to="to">
    <div class="clinician-label">
      <auto-avatar :name="name" />
      <span>{{ name }}</span>
    </div>
  </router-link>
  <div v-else class="clinician-label" @click="$emit('click')">
    <auto-avatar v-if="name" :name="name" />
    <span class="ml-2">{{ name || 'None' }}</span>
  </div>
</template>

<script>
import AutoAvatar from '@/components/common/AutoAvatar'

export default {
  name: 'ClinicianLabel',
  components: {
    AutoAvatar
  },
  props: {
    // TODO: make a logical default and remove the ESLint exception
    // eslint-disable-next-line vue/require-default-prop
    name: {
      type: String
    },
    // TODO: make a logical default and remove the ESLint exception
    // eslint-disable-next-line vue/require-default-prop
    to: {
      type: String
    }
  }
}
</script>

<style lang="scss">
.clinician-label {
  display: flex;
  align-items: center;
  span {
    flex-grow: 1;
  }
}
</style>
