const Modal = {
  data() {
    return {
      saving: false,
      modalError: ''
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async handleConfirm() {
      try {
        this.saving = true
        this.modalError = ''
        await this.action()
        this.close()
        this.$emit('confirm')
      } catch (e) {
        this.modalError =
          this.error || 'Unable to perform action. Please try again.'
      } finally {
        this.saving = false
      }
    }
  }
}

export default Modal
