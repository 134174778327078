/*
Sets a $clinician variable on Vue.prototype allowing it to be accessed throughout app
*/

import axios from 'axios'
import moment from 'moment'
import { formatDate, getStartOfMonth, getEndOfMonth } from '@/utils/Date'
import Pagination from '@/utils/Pagination'

const Clinicians = {
  install(Vue) {
    Vue.prototype.$clinicians = new Vue({
      methods: {
        async getFieldValues(fieldName) {
          const response = await axios.get(`/admin/${fieldName}`)
          return response.data
        },
        async getClinicianFilters() {
          const response = await axios.get('/admin/clinicians/filters')
          return response.data
        },
        async index(params) {
          const response = await axios.get('/admin/clinicians', {
            params: params
          })
          return response.data
        },
        async show(id) {
          const response = await axios.get(`/admin/clinicians/${id}`)
          return response.data
        },
        async update(id, data) {
          const response = await axios.patch(`/admin/clinicians/${id}`, data)
          return response.data
        },
        async unpause(id) {
          const response = await axios.post(
            `/admin/clinicians/${id}/unpause`,
            {}
          )
          return response.data
        },
        async available(id) {
          const response = await axios.post(
            `/admin/clinicians/${id}/available`,
            {}
          )
          return response.data
        },
        async unavailable(id) {
          const response = await axios.post(
            `/admin/clinicians/${id}/unavailable`,
            {}
          )
          return response.data
        },
        async getEnrollments(id) {
          const response = await Pagination.getAll(
            `/admin/clinicians/${id}/enrollments`
          )
          return response.data
        },
        async getEnrollmentSummary(id) {
          const response = await Pagination.getAll(
            `/admin/clinicians/${id}/enrollments/summary`
          )
          return response.data
        },
        async getCoverageBlocks(id, start, end, time_zone) {
          const {
            data: { data }
          } = await axios.get(`/admin/clinicians/${id}/coverage_blocks`, {
            params: {
              start,
              end,
              time_zone
            }
          })
          return data
        },
        async getNextShift(id, to) {
          const start = moment().toISOString()
          const {
            data: { data }
          } = await axios.get(`/admin/clinicians/${id}/shifts`, {
            params: {
              page_size: 1,
              page: 1,
              start,
              end: to
            }
          })
          return data.length ? data[0] : null
        },
        async getShifts(id, start, end) {
          const {
            data: { data }
          } = await axios.get(`/admin/clinicians/${id}/shifts`, {
            params: {
              start,
              end
            }
          })
          return data
        },
        async getBookings(id, start, end) {
          const {
            data: { data }
          } = await axios.get(`/admin/clinicians/${id}/consults/bookings`, {
            params: {
              start,
              end
            }
          })
          return data.map(({ booking, id, client }) => ({
            ...booking,
            id,
            client
          }))
        },
        deleteShift(id) {
          return axios.delete(`/admin/shifts/${id}`)
        },
        async updateShift(id, params) {
          return await axios.patch(`/admin/shifts/${id}`, params)
        },
        async copyClinicianClientShifts(id, params) {
          await axios
            .post(`/admin/clinicians/${id}/shifts/copy_client`, params)
            .catch((error) => {
              if (error.response) {
                throw new Error(JSON.stringify(error.response.data))
              }
            })
        },
        async createShifts(id, shift) {
          const {
            data: { data }
          } = await axios.post(`/admin/clinicians/${id}/shifts/batch`, {
            shift
          })
          return data
        },
        async activeConsults(id) {
          const { data } = await axios.get(
            `/admin/clinicians/${id}/consults/current`
          )
          return data.data
        },
        async batchUpdate(payload) {
          const { data } = await axios.post('/admin/clinicians/batch', payload)
          return data.data
        },
        async syncVerifiable(id) {
          return await axios.patch(`/admin/clinicians/${id}/sync`)
        },
        async history(id, page) {
          let config = {}
          if (page) {
            config = { params: { page } }
          }

          const { data } = await axios.get(
            `/admin/clinicians/${id}/history`,
            config
          )
          return data
        },
        async getEarningsSummary(id) {
          const { data } = await axios.get(
            `admin/clinicians/${id}/earnings/summary`
          )
          return data
        },
        async getEarnings(
          id,
          year,
          month,
          page = 1,
          cursor = true,
          next = null,
          previous = null
        ) {
          const format = 'YYYY-MM-DD'
          const start = formatDate(
            getStartOfMonth({ year, month: month - 1 }),
            format
          )
          const end = formatDate(
            getEndOfMonth({ year, month: month - 1 }),
            format
          )
          if (start && end) {
            const { data } = await axios.get(
              `/admin/clinicians/${id}/earnings`,
              {
                params: {
                  start,
                  end,
                  page,
                  cursor_on: cursor,
                  next,
                  previous
                }
              }
            )
            return data
          } else {
            console.error(
              `Invalid date. year: ${year}. month: ${month}. start: ${start}. end:${end}.`
            )
            throw new Error('Invalid dates')
          }
        },
        async getNotes(id) {
          const { data } = await axios.get(`/admin/clinicians/${id}/notes`)
          return data
        },
        async createNote(id, note) {
          const { data } = await axios.post(`/admin/clinicians/${id}/notes`, {
            note
          })
          return data
        },
        async getCollabAgreement(uuid, state, date) {
          const { data } = await axios.get(
            `/admin/clinicians/${uuid}/collab-agreements/${state}`,
            {
              params: date
                ? {
                    date
                  }
                : {}
            }
          )

          if (data.data === null) return null

          const supervising_clinician = data.included.find(
            (el) => el.type === 'supervising_clinician'
          )
          return {
            id: data.data.id,
            ...data.data.attributes,
            supervising_clinician: {
              id: supervising_clinician.id,
              name: supervising_clinician.attributes.name
            }
          }
        },
        async getClinicianCollabAgreements(id) {
          const { data } = await axios.get(
            `/admin/clinicians/${id}/collab-agreements`
          )
          return data
        },
        async uploadPhoto(photo, uuid) {
          const { name, type } = photo
          let newPhotoUrl
          await axios
            .post(`/admin/clinicians/${uuid}/photo`, { name, type })
            .then(async (response) => {
              const data = new FormData()
              Object.keys(response.data.fields).forEach((key) =>
                data.append(key, response.data.fields[key])
              )
              data.append('file', photo)

              const uninterceptedAxios = axios.create()
              await uninterceptedAxios.post(response.data.url, data)

              const updateResponse = await this.update(uuid, {
                photo: response.data.fields.key
              })
              newPhotoUrl = updateResponse.photo
            })
          return newPhotoUrl
        },
        async createTestClinician(params) {
          const response = await axios.post(
            `/admin/clinicians/create_test`,
            params
          )
          return response
        },
        async getPayerEnrollments(id) {
          const { data } = await axios.get(
            `/admin/clinicians/${id}/payer_enrollments`
          )
          return data
        },
        async getMetrics(id) {
          const response = await axios.get(`/admin/clinicians/${id}/metrics`)
          return response.data
        },
        async getCliniciansMetrics(params) {
          const response = await axios.get('/admin/clinicians/metrics', {
            params: params
          })
          return response.data
        }
      }
    })
  }
}

export default Clinicians
