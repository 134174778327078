import axios from 'axios'
import Pagination from '@/utils/Pagination'

const Clients = {
  install(Vue) {
    Vue.prototype.$clients = new Vue({
      methods: {
        async index(params) {
          const response = await axios.get('/admin/clients', { params })
          return response.data
        },
        async getConsultRates(id) {
          const { data } = await Pagination.getAll(
            `/admin/clients/${id}/consult_rates`
          )
          return data
        },
        async getClinicians(id, params) {
          const response = await axios.get(`/admin/clients/${id}/clinicians`, {
            params
          })
          return response.data
        },
        async show(id) {
          const response = await axios.get(`/admin/clients/${id}`)
          return response.data
        },
        async getShiftBlocks(id, start, end, state) {
          const response = await axios.get(
            `/admin/clients/${id}/shift_blocks`,
            { params: { start, end, state } }
          )
          return response.data
        },
        async getCoverageBlocks(id, start, end, state) {
          const { data } = await axios.get(
            `/admin/clients/${id}/coverage_blocks`,
            { params: { start, end, state } }
          )
          return data
        },
        async getCoverageBlocksUpdatedAt(id) {
          const response = await axios.get(
            `/admin/clients/${id}/coverage_blocks/last_uploaded_at`
          )
          return response
        },
        async getForecast(id, params) {
          const response = await axios.get(`/admin/clients/${id}/forecasts`, {
            params
          })
          return response.data
        },
        async getAllClients(params) {
          const { data } = await Pagination.getAll('/admin/clients', {
            params: params
          })
          return data
        },
        async createConsultRate(id, consult_rate) {
          const response = await axios.post(
            `/admin/clients/${id}/consult_rates`,
            { consult_rate }
          )
          return response.data
        }
      }
    })
  }
}

export default Clients
