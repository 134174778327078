<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1">
        Select Rate
      </v-stepper-step>
      <v-divider />
      <v-stepper-step :complete="step > 2" step="2">
        Upload File
      </v-stepper-step>
      <v-divider />
      <v-stepper-step :complete="step > 3" step="3">
        Check Errors
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card>
          <v-card-text>
            <consult-rates-select-table
              v-model="consultRates"
              :client-id="clientId"
              show="sync"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn depressed rounded @click="lastStep"> Back </v-btn>
            <v-btn depressed rounded color="primary" @click="nextStep">
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <upload-step
        step="2"
        template-file-name="schedule"
        :get-template="getTemplate"
        :handle-upload="handleUpload"
        @upload-errors="handleUploadErrors"
        @upload-success-count="successCount = $event"
      />

      <check-errors-step
        step="3"
        error-file-name="schedule"
        :errors="errors"
        :success-count="successCount"
        @action-back="handleBackAction"
        @action-close="handleCloseAction"
      />
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import UploadStep from '@/components/csv-upload/UploadStep.vue'
import CheckErrorsStep from '@/components/csv-upload/CheckErrorsStep.vue'
import ConsultRatesSelectTable from '@/components/clients/ConsultRatesSelectTable.vue'

export default {
  name: 'ScheduleUpload',
  components: {
    ConsultRatesSelectTable,
    UploadStep,
    CheckErrorsStep
  },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      step: 1,
      errors: '',
      consultRates: [],
      successCount: 0
    }
  },
  methods: {
    getTemplate() {
      return this.$coverages.getTemplateText()
    },
    handleUploadErrors(errors) {
      this.errors = errors
      this.nextStep()
    },
    handleUpload(payload) {
      const uploadResultPromises = this.consultRates.map((rate) => {
        return this.$coverages.batchCreate(rate.id, payload)
      })
      return Promise.all(uploadResultPromises).then((uploadResults) => {
        return uploadResults.reduce(
          (result, current) => {
            return {
              data: [...result.data, ...current.data],
              meta: {
                failures: result.meta.failures + current.meta.failures,
                successes: result.meta.successes + current.meta.successes,
                warnings: result.meta.warnings + current.meta.warnings
              }
            }
          },
          { data: [], meta: { failures: 0, successes: 0, warnings: 0 } }
        )
      })
    },
    handleBackAction() {
      this.lastStep()
    },
    handleCloseAction() {
      this.$emit('input', false)
    },
    nextStep() {
      this.step += 1
    },
    lastStep() {
      this.step -= 1
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .schedule-upload {
  &__table > .v-data-table__wrapper > table > tbody > tr > td {
    white-space: nowrap;
  }
}

.headers {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}
</style>
