const CLIENT_ID = process.env.VUE_APP_CLIENT_ID
const ISSUER = process.env.VUE_APP_ISSUER
const MESSAGES_URL = process.env.VUE_APP_MESSAGES_URL
const GOOGLE_IDP = process.env.VUE_APP_GOOGLE_IDP

const DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID
const AUDIENCE = process.env.VUE_APP_AUTH0_AUDIENCE

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    scope: ['openid', 'profile', 'email'],
    testing: {
      disableHttpsCheck: false
    },
    googleIdp: GOOGLE_IDP,
    pkce: true
  },
  resourceServer: {
    messagesUrl: MESSAGES_URL
  },
  auth0: {
    domain: DOMAIN,
    clientId: AUTH0_CLIENT_ID,
    audience: AUDIENCE
  }
}
