<template>
  <div class="consult-view">
    <div>
      <div class="d-flex">
        <view-header :breadcrumbs="breadcrumbs" :loading="loading">
          {{ consultIdText }}
        </view-header>
        <v-spacer />
        <v-menu v-if="actions.length && !loading" offset-y>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" rounded v-bind="attrs" v-on="on">
              Actions
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <div v-for="action in actions" :key="action.text">
              <manually-reassign
                v-if="action.text === 'assign'"
                :consult-id="consult.id"
                @reassigned="handleAssignConsult"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title class="text-capitalize">
                      <v-icon class="mr-2">
                        {{ action.icon }}
                      </v-icon>
                      {{ action.text }} Consult
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </manually-reassign>
              <manually-rebook
                v-else-if="action.text === 'rebook'"
                :consult-id="consult.id"
                @rebooked="handleRebookConsult"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title class="text-capitalize">
                      <v-icon class="mr-2">
                        {{ action.icon }}
                      </v-icon>
                      {{ action.text }} Consult
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </manually-rebook>
              <v-dialog v-else v-model="showDialog[action.text]" width="500">
                <template #activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title class="text-capitalize">
                      <v-icon class="mr-2">
                        {{ action.icon }}
                      </v-icon>
                      {{ action.text }} Consult
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-card>
                  <v-card-title class="headline text-capitalize">
                    {{ action.text }} Consult
                  </v-card-title>
                  <v-card-text>
                    Are you sure you want to {{ action.text }} this consult?
                    <div v-if="action.detailText" class="mt-4">
                      {{ action.detailText }}
                    </div>
                  </v-card-text>
                  <v-select
                    v-if="action.text == 'void'"
                    v-model="voidReasonId"
                    label="Reason for voiding consult"
                    outlined
                    :items="consultVoidReasons"
                    item-value="id"
                    name="status"
                    :menu-props="{ maxHeight: 250 }"
                  />
                  <v-textarea
                    v-model="reasonText"
                    label="Describe the issue"
                    outlined
                    class="mb-4"
                    auto-grow
                  />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :disabled="performingAction"
                      class="text-capitalize text-color--success"
                      rounded
                      depressed
                      @click="showDialog[action.text] = false"
                    >
                      Go back
                    </v-btn>
                    <v-btn
                      :disabled="
                        performingAction ||
                        (action.text == 'void' && invalidConsultVoidReason) ||
                        invalidReasonText
                      "
                      class="text-capitalize text-color--danger"
                      rounded
                      depressed
                      @click="action.action"
                    >
                      {{ action.text }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-list>
        </v-menu>
      </div>
      <hr class="mb-0" />
      <summary-layout>
        <template #default>
          <v-skeleton-loader
            v-if="loading"
            type="article, paragraph"
            min-height="300"
          />
          <div v-else>
            <v-row>
              <v-col>
                <span class="consult-view__details__label">Status</span>
                <h5 class="consult-view__details__value">
                  {{ consult.status }}
                </h5>
              </v-col>
              <v-col>
                <span class="consult-view__details__label"
                  >Originating State</span
                >
                <h5 class="consult-view__details__value uppercase">
                  {{ consult.state }}
                </h5>
              </v-col>
            </v-row>
            <div class="consult-view__details__data">
              <data-row
                v-if="consult.booking"
                label="Scheduled Start At"
                :value="consult.booking.scheduled_start_at | format"
              />
              <data-row
                label="SLA"
                :value="sla | duration"
                class="text-capitalize"
              />
              <data-row label="UUID" :value="consult.uuid" copy />
              <data-row label="Task Id" :value="consult.task_id" copy />
              <data-row label="URL" :value="consult.consult_url" copy />
              <data-row label="Created" :value="consult.created_at | format" />
              <data-row label="Payer UUID" :value="payer_entity.uuid" copy />
              <data-row label="Payer Name" :value="payer_entity.name" />
              <data-row
                label="External Id"
                :value="consult.external_id ? consult.external_id : 'N/A'"
                copy
              />
              <data-row
                v-if="consult.properties.service_area"
                label="Service Area"
                :value="consult.properties.service_area"
              />
              <data-row v-if="consult.properties.severity" label="Severity">
                {{ formatSeverity(consult.properties.severity) }}
              </data-row>
              <data-row
                v-if="consult.properties.stat !== null"
                label="Stat"
                :value="consult.properties.stat"
                class="text-capitalize"
              />
            </div>
          </div>
          <v-divider class="ml-0 mt-11 mb-12" />
          <history
            :id="consultId"
            :force-reload="reloadEvents"
            entity-type="consult"
            title="Event and Activity History"
            @event-reload="reloadEvents = false"
          />
        </template>
        <template #aside>
          <v-skeleton-loader v-if="loading" type="list-item-avatar@3" />
          <div v-else>
            <p class="consult-view__associations__label">Client</p>
            <router-link
              class="text-decoration-none"
              :to="`/clients/${consult.client.id}`"
            >
              <div class="consult-view__associations__box">
                <client-label
                  :name="consult.client.name"
                  :uuid="consult.client.uuid"
                />
              </div>
            </router-link>
            <p class="consult-view__associations__label">Consult Rate</p>
            <router-link
              class="text-decoration-none"
              :to="`/consult-rates/${consult.consult_rate.id}`"
            >
              <div
                class="consult-view__associations__box consult-view__associations__box--consult-rate"
              >
                <strong>{{ consult.consult_rate.display_name }}</strong>
                <span>{{ consult.consult_rate.modality }}</span>
              </div>
            </router-link>
            <p class="consult-view__associations__label">Assigned Clinician</p>
            <div class="consult-view__associations__box">
              <clinician-label :name="clinician.name" :to="clinicianLink" />
            </div>
            <p class="consult-view__associations__label">
              Supervising Clinician
            </p>
            <div class="consult-view__associations__box">
              <clinician-label
                :name="supervisingClinician.name"
                :to="supervisingClinicianLink"
              />
            </div>
          </div>
        </template>
      </summary-layout>
    </div>
  </div>
</template>

<script>
import Loading from '@/mixins/Loading'
import ClinicianLabel from '@/components/common/ClinicianLabel.vue'
import SummaryLayout from '@/components/layout/Summary.vue'
import ClientLabel from '@/components/common/ClientLabel.vue'
import DataRow from '@/components/common/DataRow.vue'
import { Duration, Format } from '@/filters/Moment'
import ManuallyReassign from '@/components/consult/ManuallyReassign'
import ManuallyRebook from '@/components/consult/ManuallyRebook'
import History from '@/components/common/History.vue'
import ViewHeader from '@/components/common/ViewHeader.vue'
import CONSULT_VOID_REASONS from '@/utils/ConsultVoidReasons'

export default {
  name: 'ConsultView',
  components: {
    ViewHeader,
    SummaryLayout,
    ManuallyReassign,
    ManuallyRebook,
    ClinicianLabel,
    ClientLabel,
    DataRow,
    History
  },
  filters: {
    Duration,
    Format
  },
  mixins: [Loading],
  props: {
    consultId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showDialog: {
        assign: false,
        start: false,
        complete: false,
        void: false,
        hold: false
      },
      performingAction: false,
      consult: null,
      collabAgreement: null,
      reloadEvents: false,
      loading: true,
      consultVoidReasons: CONSULT_VOID_REASONS,
      voidReasonId: null,
      reasonText: null,
      payer_entity: null
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { to: '/consults', display: 'Consults' },
        { to: `/consults/${this.consultId}`, display: this.consultId }
      ]
    },
    clinician() {
      if (this.consult?.assignment?.clinician_id) {
        return {
          id: this.consult.assignment.clinician_id,
          name: this.consult.assignment.clinician_name,
          uuid: this.consult.assignment.clinician_uuid
        }
      } else if (this.consult?.booking?.clinician_id) {
        return {
          id: this.consult.booking.clinician_id,
          name: this.consult.booking.clinician_name,
          uuid: this.consult.booking.clinician_uuid
        }
      }
      return { id: null, name: null, uuid: null }
    },
    supervisingClinician() {
      if (this.collabAgreement) {
        return {
          id: this.collabAgreement.supervising_clinician.id,
          name: this.collabAgreement.supervising_clinician.name
        }
      }
      return { id: null, name: null }
    },
    supervisingClinicianLink() {
      return this.collabAgreement
        ? `/clinicians/${this.collabAgreement.supervising_clinician.id}`
        : null
    },
    clinicianLink() {
      return this.clinician.id ? `/clinicians/${this.clinician.id}` : null
    },
    sla() {
      return this.consult.consult_rate.sla_in_seconds
    },
    assignable() {
      return (
        this.consult?.status !== 'booked' &&
        this.consult?.permitted_transitions.some(
          (transition) =>
            transition.state === 'assigned' || transition.state === 'assignable'
        )
      )
    },
    rebookable() {
      return (
        this.consult?.status === 'booked' &&
        this.consult?.permitted_transitions.some(
          (transition) => transition.state === 'assignable'
        )
      )
    },
    startable() {
      return (
        this.consult?.status !== 'started' &&
        this.consult?.assignment &&
        this.consult?.permitted_transitions.some(
          (transition) => transition.state === 'started'
        )
      )
    },
    voidable() {
      return (
        !['canceled', 'completed', 'voided'].includes(this.consult?.status) &&
        this.consult?.permitted_transitions.some(
          (transition) => transition.state === 'voided'
        )
      )
    },
    completable() {
      return (
        this.consult?.status !== 'completed' &&
        this.consult?.assignment &&
        this.consult?.permitted_transitions.some(
          (transition) => transition.state === 'completed'
        )
      )
    },
    holdable() {
      return (
        this.consult?.status !== 'held' &&
        this.consult?.permitted_transitions.some(
          (transition) => transition.state === 'held'
        )
      )
    },
    unassignable() {
      return (
        ['held', 'started', 'deferred'].includes(this.consult?.status) &&
        this.consult?.assignment &&
        this.consult?.client?.integration_type == 'unified' &&
        this.consult?.permitted_transitions.some(
          (transition) => transition.state === 'assignable'
        )
      )
    },
    actions() {
      const actions = []
      if (this.assignable) {
        actions.push({
          text: 'assign',
          icon: 'mdi-account-arrow-left'
        })
      }
      if (this.rebookable) {
        actions.push({
          text: 'rebook',
          icon: 'mdi-account-arrow-left'
        })
      }
      if (this.startable) {
        actions.push({
          text: 'start',
          icon: 'mdi-clock-start',
          detailText:
            'The Client system normally Starts the consult via API. Only start the consult manually if you are sure the consult has actually started.',
          action: this.handleStartConsult
        })
      }
      if (this.completable) {
        actions.push({
          text: 'complete',
          icon: 'mdi-calendar-check',
          detailText:
            'The Client system normally Completes the consult via API. Only complete the consult manually if you are sure the consult has actually been completed.',
          action: this.handleCompleteConsult
        })
      }
      if (this.holdable) {
        actions.push({
          text: 'hold',
          icon: 'mdi-pause-circle',
          detailText:
            'Holding a consult removes it from Wheel’s queue and releases the clinician to be assigned to other consults. The consult can then be started, completed, or canceled as necessary.',
          action: this.handleHoldConsult
        })
      }
      if (this.voidable) {
        actions.push({
          text: 'void',
          icon: 'mdi-cancel',
          detailText:
            'Voiding will remove the consult from Wheel’s queue and notify the client that we will not complete the consult. The clinician will NOT be paid. If a clinician actually completed the work, please go back and then Assign, Start, and Complete the consult.',
          action: this.handleVoidConsult
        })
      }
      if (this.unassignable) {
        actions.push({
          text: 'unassign',
          icon: 'mdi-account-arrow-right',
          detailText:
            'Unassigning a consult will remove the assigned clinician from the consult, making it eligible for manual or auto reassignment. The unassigned clinician will not be paid for this consult.\n' +
            'Please indicate why you are unassigning the consult below.',
          action: this.handleUnassignConsult
        })
      }

      return actions
    },
    consultIdText() {
      return this.consult?.id
    },
    consultVoidCodeObject() {
      let consultVoidObject = CONSULT_VOID_REASONS.find(
        (reason) => reason.id == this.voidReasonId
      )
      return consultVoidObject ? consultVoidObject : null
    },
    invalidConsultVoidReason() {
      if (this.consultVoidCodeObject == null) return true
      return this.consultVoidCodeObject.text.toLowerCase() == 'other'
        ? this.reasonText == null || this.reasonText == ''
        : false
    },
    invalidReasonText() {
      if (this.consultVoidCodeObject !== null) return false
      return this.reasonText == null || this.reasonText == ''
    }
  },
  watch: {
    consultId() {
      this.loadConsult(true)
    }
  },
  created() {
    this.loadConsult()
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    async loadConsult(reloadEvents = false) {
      await this.withLoading(this, this.fetchConsultAndCollab)
      this.reloadEvents = reloadEvents
    },
    async fetchConsultAndCollab() {
      this.consult = await this.$consults.show(this.consultId)
      if (this.clinician.uuid)
        this.collabAgreement = await this.$clinicians.getCollabAgreement(
          this.clinician.uuid,
          this.consult.state,
          this.consult.completed_at ?? this.consult.started_at
        )
      await this.buildPayerEntity()
    },
    async buildPayerEntity() {
      if (this.consult.payer_entity !== undefined) {
        this.payer_entity = {
          uuid: this.consult.payer_entity.uuid,
          name: this.consult.payer_entity.name
        }
      } else {
        this.payer_entity = {
          uuid: null,
          name: null
        }
      }
    },
    async handleAssignConsult(clinician) {
      const message = `Consult assigned to ${clinician.name}`
      this.loadConsult(true)
      this.$store.commit('SET_SNACKBAR', {
        message,
        show: true
      })
    },
    async handleRebookConsult(clinician) {
      const message = `Consult rebooked with ${clinician.name}`
      this.loadConsult(true)
      this.$store.commit('SET_SNACKBAR', {
        message,
        show: true
      })
    },
    async handleStartConsult() {
      let message = 'Consult Started'

      this.performingAction = true
      try {
        await this.$consults.start(this.consultId, this.reasonText)
        this.loadConsult(true)
      } catch (e) {
        if (e.response) {
          message = e.response.data.messages[0]
        } else {
          message = `Could not start consult. ${e.message}`
        }
      } finally {
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })

        this.performingAction = false
        this.showDialog.start = false
        this.reasonText = null
      }
    },
    async handleCompleteConsult() {
      let message = 'Consult Completed'

      this.performingAction = true
      try {
        await this.$consults.complete(this.consultId, this.reasonText)
        this.loadConsult(true)
      } catch (e) {
        if (e.response) {
          message = e.response.data.messages[0]
        } else {
          message = `Could not complete consult. ${e.message}`
        }
      } finally {
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })

        this.performingAction = false
        this.showDialog.complete = false
        this.reasonText = null
      }
    },
    async handleVoidConsult() {
      let message = 'Consult Voided'

      this.performingAction = true
      try {
        await this.$consults.void(
          this.consultId,
          this.consultVoidCodeObject.value,
          this.reasonText
        )
        this.loadConsult(true)
      } catch (e) {
        if (e.response) {
          message = e.response.data.messages[0]
        } else {
          message = `Could not void consult. ${e.message}`
        }
      } finally {
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })

        this.performingAction = false
        this.showDialog.void = false
        this.reasonText = null
      }
    },
    async handleHoldConsult() {
      let message = 'Consult Held'

      this.performingAction = true
      try {
        await this.$consults.hold(this.consultId, this.reasonText)
        this.loadConsult(true)
      } catch (e) {
        message = 'Could not hold consult. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
      } finally {
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })

        this.performingAction = false
        this.showDialog.hold = false
        this.reasonText = null
      }
    },
    async handleUnassignConsult() {
      let message = 'Consult Unassigned'

      this.performingAction = true
      try {
        await this.$consults.unassign(this.consultId, this.reasonText)
        this.loadConsult(true)
      } catch (e) {
        if (e.response) {
          message = e.response.data.messages[0]
        } else {
          message = `Could not unassign consult. ${e.message}`
        }
      } finally {
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })

        this.performingAction = false
        this.showDialog.hold = false
        this.reasonText = null
      }
    },
    formatSeverity(severity) {
      return severity ? `Severity ${severity}` : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog .v-card {
  padding: size(24);
}

.consult-view {
  height: 100%;

  h2 {
    padding-top: 1.5rem;
  }

  .consult-view__details {
    &__label {
      display: block;
      font-family: 'Apercu Bold', serif;
      letter-spacing: 0.2px;
      color: rgba(24, 24, 24, 0.6);
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
    }

    &__value {
      text-transform: capitalize;
    }

    &__data {
      margin-top: 3.5rem;
    }

    .v-divider {
      max-width: calc(100% + 3rem);
    }
  }

  .consult-view__associations {
    &__label {
      color: rgba(24, 24, 24, 0.6);
      font-size: 0.875rem;
      margin-bottom: 0.875rem;
    }

    &__box {
      color: black;
      border: 1px solid rgba(24, 24, 24, 0.12);
      border-radius: 0.25rem;
      padding: 1rem;
      margin-bottom: 1.75rem;
    }

    &__box--consult-rate {
      strong {
        color: black;
        display: block;
        font-size: 1rem;
      }

      span {
        color: rgba(24, 24, 24, 0.6);
        font-size: 0.875rem;
        text-transform: capitalize;
      }
    }
  }
}
</style>
