<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="article, paragraph"
      min-height="300"
    />
    <div v-else>
      <new-client-operating-hours-message
        v-if="showNewClientMessage"
        :client-name="client.name"
        class="pt-8"
        @editModeForOperatingHours="editModeOn"
      />
      <operating-hours
        :client-id="clientId"
        @editModeForOperatingHours="editModeOn"
      />
      <payer-enrollments :client="client" />
      <marketplace-settings :client="client" />
    </div>
  </div>
</template>

<script>
import OperatingHours from './OperatingHours.vue'
import NewClientOperatingHoursMessage from './NewClientOperatingHoursMessage.vue'
import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE as operatingHoursNamespace } from '@/store/operatingHours/state'
import { NAMESPACE as clientNamespace } from '@/store/clients/state'
import PayerEnrollments from './PayerEnrollments.vue'
import MarketplaceSettings from '@/components/clients/MarketplaceSettings.vue'

const { mapActions, mapState } = createNamespacedHelpers(
  operatingHoursNamespace
)
const { mapGetters: mappedClientGetters } =
  createNamespacedHelpers(clientNamespace)

export default {
  name: 'ClientDetails',
  components: {
    MarketplaceSettings,
    OperatingHours,
    NewClientOperatingHoursMessage,
    PayerEnrollments
  },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      operatingHours: {},
      client: {},
      inEditMode: false,
      coverageBlocksRequired: false,
      hasOperatingHours: false
    }
  },
  computed: {
    ...mapState({ rawOperatingHours: 'data' }),
    showNewClientMessage() {
      return (
        !this.hasOperatingHours &&
        !this.coverageBlocksRequired &&
        !this.inEditMode
      )
    }
  },
  async created() {
    this.operatingHours = structuredClone(
      await this.operatingHoursForClient({
        clientId: this.clientId,
        params: { time_zone: 'America/Chicago' }
      })
    )

    this.client = structuredClone(await this.clientById()(this.clientId))
    this.isLoading = false
    this.hasOperatingHours = Object.entries(this.rawOperatingHours).length > 0

    this.coverageBlocksRequired = this.client.coverage_blocks_required
  },
  methods: {
    ...mapActions(['operatingHoursForClient']),
    ...mappedClientGetters(['clientById']),
    async editModeOn(editMode) {
      this.inEditMode = editMode
      this.coverageBlocksRequired = structuredClone(
        await this.clientById()(this.clientId)
      ).coverage_blocks_required
      this.hasOperatingHours =
        Object.entries(await this.rawOperatingHours).length > 0
    }
  }
}
</script>
