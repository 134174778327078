<template>
  <v-card>
    <v-card-title class="headline"> Edit {{ typeTitle }} </v-card-title>
    <v-card-text>
      <v-alert color="red" dense outlined text icon="mdi-alert-circle-outline">
        Confirmation with Engineering, Payments, and Legal is required before
        editing a new consult rate!
      </v-alert>
    </v-card-text>
    <v-card-text>
      <p class="text-lg-body-1">
        Making changes to
        <span class="font-weight-bold">{{ typeTitle }}: {{ name }}</span> will
        impact clinician enrollments and any consults created with the
        {{ typeTitle }}. Are you sure you want to make changes to the
        {{ typeTitle }}?
      </p>
    </v-card-text>
    <v-card-actions>
      <span v-if="modalError" class="modal-error">{{ modalError }}</span>
      <v-spacer />
      <v-btn text @click="close"> Cancel </v-btn>
      <v-btn color="primary" rounded :loading="saving" @click="handleConfirm">
        Continue
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Modal from '@/mixins/Modal'

export default {
  name: 'EditWarningModal',
  mixins: [Modal],
  props: {
    name: {
      type: String,
      required: true
    },
    typeTitle: {
      type: String,
      required: true
    }
  },
  methods: {
    async action() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 0px;
}
</style>
