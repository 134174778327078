<template>
  <v-card-text class="px-0">
    <v-alert
      dense
      outlined
      text
      :color="collabStatusRemoved ? '' : 'red'"
      class="pa-4"
      icon="mdi-alert-circle-outline"
    >
      <span>
        <span class="font-weight-bold">{{ collabStatusTitle }}</span>
        <br />
        <span v-if="!collabStatusRemoved"
          >The collab will auto-set to “removed” within
          <span class="font-weight-bold">{{ daysTillRemoved }}</span
          >. To initiate sooner, manually change the collab status to “removed”.
          <span
            v-if="collab.review_status_reason !== 'Physician Account Suspended'"
          >
            If an error has occurred, visit
            <a
              v-if="verifiableUrl"
              class="red-link"
              target="_blank"
              replace
              rel="”noopener"
              noreferrer
              :href="verifiableUrl"
              >Verifiable</a
            >
            <span v-else>Verifiable</span>
            to confirm the license status.</span
          >
        </span>
        <br />
        <v-dialog
          v-if="showVerifyExternalCollab"
          v-model="dialog"
          max-width="600"
        >
          <template #activator="{ on }">
            <v-btn
              color="primary"
              depressed
              rounded
              class="pa-4 mt-2 mr-4"
              v-on="on"
            >
              Verify External Clinician
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline">
              Verify External Clinician
            </v-card-title>
            <v-card-text>
              Confirm that this external clinician has been verified.
            </v-card-text>
            <v-alert v-if="error" text type="error">
              {{ error }}
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn text @click="close"> Cancel </v-btn>
              <v-btn
                color="primary"
                rounded
                @click="handleVerifyExternalCollab"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </span>
    </v-alert>
  </v-card-text>
</template>

<script>
import COLLAB_AGREEMENT_TYPES from '@/utils/CollabAgreementTypes'
export default {
  name: 'CollabAgreementStatusMessages',
  props: {
    collab: {
      type: Object,
      required: true
    },
    daysTillRemoved: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      dialog: false,
      error: null
    }
  },
  computed: {
    showVerifyExternalCollab() {
      return (
        this.collab.external &&
        this.collab.review_status ==
          COLLAB_AGREEMENT_TYPES.REVIEW_STATUS.NEEDS_REVIEW &&
        this.collab.review_status_reason ==
          COLLAB_AGREEMENT_TYPES.REVIEW_STATUS_REASON
            .PENDING_EXTERNAL_VERIFICATION
      )
    },
    collabStatusRemoved() {
      return (
        this.collab.status ===
        COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_STATUS.COLLAB_REMOVED
      )
    },
    verifiableUrl() {
      const verifiableBaseUrl = process.env.VUE_APP_VERIFIABLE_WEBSITE_URL
      if (
        this.collab.review_status_reason ===
        COLLAB_AGREEMENT_TYPES.REVIEW_STATUS_REASON.NP_LICENSE_EXPIRED
      ) {
        if (
          !this.collab.license_supervised?.external_id ||
          !this.collab.supervised_clinician?.verifiable_id
        )
          return null
        return `${verifiableBaseUrl}/provider-detail/${this.collab.supervised_clinician.verifiable_id}/license/${this.collab.license_supervised.external_id}`
      }

      if (
        !this.collab.supervising_clinician?.verifiable_id ||
        !this.collab.license_supervising?.external_id
      )
        return null
      return `${verifiableBaseUrl}/provider-detail/${this.collab.supervising_clinician.verifiable_id}/license/${this.collab.license_supervising.external_id}`
    },
    collabStatusTitle() {
      if (
        this.collab.review_status_reason ===
        COLLAB_AGREEMENT_TYPES.REVIEW_STATUS_REASON.PHYSICIAN_LICENSE_EXPIRED
      ) {
        if (this.collabStatusRemoved)
          return 'Collab was removed due to Supervising Physician’s license expiring'
        return 'Collab is inactive due to Supervising Physician’s license expiring'
      }
      if (
        this.collab.review_status_reason ===
        COLLAB_AGREEMENT_TYPES.REVIEW_STATUS_REASON.NP_LICENSE_EXPIRED
      ) {
        if (this.collabStatusRemoved)
          return 'Collab is removed due to Nurse Practitioner’s license expiring'
        return 'Collab is inactive due to Nurse Practitioner’s license expiring'
      }

      if (
        this.collab.review_status_reason ===
        COLLAB_AGREEMENT_TYPES.REVIEW_STATUS_REASON.PHYSICIAN_LICENSE_UNAPPROVED
      ) {
        if (this.collabStatusRemoved)
          return 'Collab is removed due to Supervising Physician’s license being unapproved'
        return 'Collab is inactive due to Supervising Physician’s license being unapproved'
      }

      if (
        this.collab.review_status_reason ===
        COLLAB_AGREEMENT_TYPES.REVIEW_STATUS_REASON.NP_LICENSE_UNAPPROVED
      )
        return 'Collab is removed due to Nurse Practitioner’s license being unapproved'

      if (
        this.collab.review_status_reason ===
        COLLAB_AGREEMENT_TYPES.REVIEW_STATUS_REASON.PHYSICIAN_ACCOUNT_SUSPENDED
      ) {
        if (this.collabStatusRemoved)
          return 'Collab is removed due to suspended Supervising Physician'
        return 'Collab is inactive due to suspended Supervising Physician'
      }

      if (
        this.collab.review_status_reason ===
        COLLAB_AGREEMENT_TYPES.REVIEW_STATUS_REASON
          .PENDING_EXTERNAL_VERIFICATION
      ) {
        if (this.collabStatusRemoved)
          return 'Collab is removed due to pending verification of the external clinician'
        return 'Collab is inactive due to pending verification of the external clinician'
      }

      if (
        this.collab.review_status_reason ===
        COLLAB_AGREEMENT_TYPES.REVIEW_STATUS_REASON.NP_ACCOUNT_SUSPENDED
      )
        return 'Collab is removed due to suspended Nurse Practitioner'

      return ''
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.error = false
    },
    async handleVerifyExternalCollab() {
      try {
        await this.$collabAgreements.verifyExternalCollabAgreement(
          this.collab.id
        )
        this.$emit('reloadCollab')
        this.close()
      } catch (e) {
        this.error = e.message
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.red-link {
  color: red;
}
</style>
