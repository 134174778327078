const CONSULT_VOID_REASONS = [
  {
    text: 'Assigned Sex Ineligible',
    value: 'ASSIGNED_SEX_AT_BIRTH_INELIGIBLE_FOR_CONDITION',
    id: 1
  },
  { text: 'ID Verification Error', value: 'ID_VERIFICATION_FAILURE', id: 2 },
  { text: 'Missed SLA', value: 'MISSED_SLA', id: 3 },
  { text: 'Age Ineligible', value: 'AGE_INELIGIBLE_FOR_CONDITION', id: 4 },
  { text: 'Unsupported State', value: 'UNSUPPORTED_STATE', id: 5 },
  { text: 'Unsupported Condition', value: 'UNSUPPORTED_CONDITION', id: 6 },
  { text: 'Integration Error', value: 'INTEGRATION_ERROR', id: 7 },
  { text: 'Duplicate Visit', value: 'DUPLICATE_VISIT', id: 8 },
  { text: 'Non US Phone Number', value: 'NON_US_PHONE_NUMBER', id: 9 },
  {
    text: 'Repeat visit for a condition which repeat visits are not supported',
    value: 'OTHER',
    id: 10
  },
  { text: 'Inappropriate Behavior', value: 'OTHER', id: 11 },
  { text: 'Customer no Show', value: 'CUSTOMER_NO_SHOW', id: 13 },
  {
    text: 'Customer Video Call Tech Failure',
    value: 'CUSTOMER_VIDEO_CALL_TECH_FAILURE',
    id: 14
  },
  {
    text: 'Provider Video Call Tech Failure',
    value: 'PROVIDER_VIDEO_CALL_TECH_FAILURE',
    id: 15
  },
  { text: 'Other', value: 'OTHER', id: 12 }
]

export default CONSULT_VOID_REASONS
