<template>
  <v-stepper-content :step="step">
    <v-card id="check-errors-content" class="box-shadow-none">
      <v-card-text class="px-0 pt-0">
        <p :class="{ uploadSuccess: errorCount === 0 }">
          <v-icon v-if="errorCount === 0">mdi-check</v-icon>
          {{ successCount }} of {{ totalCount }} rows
          {{ actionName }} successfully.<span v-if="successCount > 0">{{
            successWarning
          }}</span>
        </p>
        <v-alert
          v-if="errorCount > 0"
          color="red"
          dense
          outlined
          text
          icon="mdi-alert-circle-outline"
        >
          Please review the rows below and fix any errors. ONLY re-upload the
          error rows after fixing them - not all rows.
        </v-alert>
        <v-row v-if="errorCount > 0" class="headers text-capitalize">
          <v-col>Success /Failure</v-col>
          <v-col v-for="header of templateHeaders" :key="header">
            {{ header }}
          </v-col>
        </v-row>
        <v-row v-for="(item, index) in parsedErrors" :key="index">
          <v-col>
            <v-icon v-if="isSuccess(item)" color="success"
              >mdi-check-bold</v-icon
            >
            <v-icon v-else color="error">mdi-close</v-icon>
          </v-col>
          <v-col v-if="item.message">
            {{ item.message }}
          </v-col>
          <v-col v-for="header in templateHeaders" :key="header">
            {{ item[header] }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card-actions>
      <a
        v-if="errorCount > 0"
        :href="`data:application/octet-stream,${encodeURIComponent(errors)}`"
        :download="`${errorFileName}_errors.csv`"
      >
        <v-btn depressed rounded color="primary"> Download Errors </v-btn>
      </a>
      <v-spacer />
      <v-btn depressed rounded @click="handleBack"> Back </v-btn>
      <v-btn depressed rounded color="primary" @click="handleClose">
        Close
      </v-btn>
    </v-card-actions>
  </v-stepper-content>
</template>

<script>
import Papa from 'papaparse'

export default {
  name: 'CheckErrorsStep',
  props: {
    errorFileName: {
      type: String,
      default: 'upload'
    },
    step: {
      type: [Number, String],
      required: true
    },
    errors: {
      type: String,
      default: ''
    },
    successCount: {
      type: Number,
      default: 0
    },
    actionName: {
      type: String,
      default: 'uploaded'
    },
    successWarning: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hideHeaders: ['status']
    }
  },
  computed: {
    parsedErrors() {
      const { data } = Papa.parse(this.errors, {
        header: true
      })
      return data
    },
    errorCount() {
      return this.parsedErrors.length
    },
    totalCount() {
      return this.errorCount + this.successCount
    },
    templateHeaders() {
      if (this.parsedErrors.length) {
        return Object.keys(this.parsedErrors[0]).filter(
          (val) => !this.hideHeaders.includes(val)
        )
      }
      return []
    }
  },
  methods: {
    isSuccess(item) {
      return item.status >= 200 && item.status < 300
    },
    handleBack() {
      this.$emit('action-back')
    },
    handleClose() {
      this.$emit('action-close')
    }
  }
}
</script>

<style>
.v-card.v-sheet .row {
  border-bottom: solid 1px #e3e3e3;
  color: #141419;
}
.v-card.box-shadow-none {
  box-shadow: none !important;
}
.v-card.v-sheet .row.headers {
  font-size: 12px;
  color: #5c5c62;
}
.v-card p.uploadSuccess {
  color: #141419;
  font-weight: 600;
  font-size: 16px;
}
.v-card .v-icon.mdi-check {
  color: green;
}
#check-errors-content {
  max-height: 60vh;
  overflow-y: auto;
}
#check-errors-content .v-card__text {
  width: calc(100% - 20px);
}
</style>
