import moment from 'moment'

/** Returns a formatted date, or false if the date argument is not valid */
export function formatDate(date, format = 'MMMM DD, YYYY') {
  return moment(date).isValid() && moment(date).format(format)
}

export function getDateFromNow(value, unit, date = new Date()) {
  return moment(date).add(value, unit).toDate()
}

export function getStartOfMonth(date) {
  return moment(date).startOf('month')
}

export function getEndOfMonth(date) {
  return moment(date).endOf('month')
}

export function toUtc(time) {
  return moment.utc(time)
}

export function formatDatepicker(date = new Date()) {
  return formatDate(date, 'YYYY-MM-DD')
}

export function humanize(time, unit = 'seconds', suffix = false) {
  return moment.duration(time, unit).humanize(suffix)
}

export function getMonthName(date) {
  return moment(date).format('MMMM')
}

export function convertToLocal(hour, zoneOffset) {
  return (24 + hour + zoneOffset / 60) % 24
}

export function getTimezone(date) {
  return String(moment(date)._d).split(' ')[5]
}

export function entireHour(date, hour) {
  const zoneOffset = moment().utcOffset()
  return {
    start: moment(date)
      .startOf('day')
      .add(convertToLocal(hour, zoneOffset) * 60, 'minute')
      .toDate(),
    end: moment(date)
      .startOf('day')
      .add(convertToLocal(hour, zoneOffset) * 60 + 59, 'minute')
      .toDate()
  }
}

export function orderTimesWithinDay(orderedDaysOfWeek) {
  return orderedDaysOfWeek.map((time) => {
    return time.sort((a, b) => {
      if (a.start_at < b.start_at) {
        return -1
      } else if (a.start_at > b.start_at) {
        return 1
      } else {
        return 0
      }
    })
  })
}
