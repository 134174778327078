<template>
  <v-dialog v-model="dialog" max-width="900" content-class="cohort-dialog">
    <template #activator="{ on, attrs }">
      <v-list-item :disabled="disabled" v-bind="attrs" v-on="on">
        <v-list-item-title class="add-to-cohort flex align-center mr-2"
          ><v-icon class="mr-2">mdi-account-multiple-plus-outline</v-icon> Add
          Cohort</v-list-item-title
        >
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="d-flex align-center">
        <v-btn icon class="mr-3" @click="close">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
        <span class="header"> Add Cohort </span>
        <v-btn
          class="primary ml-auto"
          rounded
          depressed
          :loading="loading"
          :disabled="!selectedCohorts.length"
          @click="tag"
        >
          Add
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selectedCohorts"
          prepend-inner-icon="mdi-account-multiple-outline"
          dense
          color="grey"
          outlined
          deletable-chips
          chips
          multiple
          :items="cohorts"
        />
        <span v-if="error" class="cohort-dialog__error-text">{{ error }}</span>
        <ul v-if="taggingErrors">
          <li
            v-for="err in taggingErrors"
            :key="err.clinician"
            class="cohort-dialog__error-text"
          >
            {{ err.message }}
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CohortDialog',
  props: {
    clinicians: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      error: null,
      taggingErrors: null,
      cohorts: [],
      selectedCohorts: [],
      dialog: false
    }
  },
  mounted() {
    this.getCohorts()
  },
  methods: {
    close() {
      this.dialog = false
      this.error = null
      this.taggingErrors = null
      this.loading = false
      this.selectedCohorts = []
    },
    handleResponses(responses) {
      let success = true
      this.taggingErrors = null

      const failedUpdates = responses.filter(({ status }) => status !== 200)
      if (failedUpdates.length) {
        success = false
        this.taggingErrors = failedUpdates.map((response) => {
          const pos = response.resource.lastIndexOf('/')
          const clinicianUuid = response.resource.substring(pos + 1)
          const clinicianName = this.clinicians.find(
            ({ uuid }) => uuid === clinicianUuid
          ).name
          return {
            message: `Error updating ${clinicianName}'s cohorts: ${response.message}`,
            clinician: clinicianUuid
          }
        })
      }
      return success
    },
    async tag() {
      const payload = this.clinicians.map((clinician) => ({
        uuid: clinician.id,
        cohorts: this.selectedCohorts
      }))
      this.loading = true
      try {
        this.error = null
        const response = await this.$clinicians.batchUpdate(payload)
        const success = this.handleResponses(response)
        if (success) {
          this.close()
        }
      } catch (e) {
        this.error =
          'There was an issue assigning these clinicians cohorts. Please try again.'
      } finally {
        this.loading = false
      }
    },
    async getCohorts() {
      try {
        this.loading = true
        this.cohorts = await this.$clinicians.getFieldValues('cohorts')
      } catch (e) {
        this.error =
          'There was an issue loading cohorts for clinicians. Please close and try again.'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-to-cohort {
  font-size: size(16) !important;
}

.cohort-dialog.v-dialog .v-card {
  padding: 24px;
}
.cohort-dialog {
  &__error-text {
    color: #bf211e;
  }
}

.header {
  @include text-h5;
}
</style>
