<template>
  <tr :key="enrollment.id">
    <th
      scope="row"
      class="horizontal-fixed pr-0"
      :class="
        enrollment.clinician_available
          ? 'enrollments-table-row__available'
          : 'enrollments-table-row__unavailable'
      "
    >
      <slot />
    </th>
    <th
      scope="row"
      class="enrollments-table-row__priority horizontal-fixed text-end"
      :class="
        enrollment.clinician_available
          ? 'enrollments-table-row__available'
          : 'enrollments-table-row__unavailable'
      "
    >
      <div class="d-flex flex-row align-center">
        <v-select
          v-model="enrollmentLocal.priority"
          class="text-sm-body-2 select-priority"
          :disabled="savingEnrollment || savingPriority"
          :items="priorities"
          @change="updateEnrollmentPriority(enrollment)"
        >
          <template #selection="{ item }">
            <v-progress-circular
              v-if="savingPriority"
              indeterminate
              :size="24"
              color="primary"
            />
            <span v-else>
              {{ item.text }}
            </span>
          </template>
        </v-select>
        <div>
          <v-spacer />
          <v-menu offset-y :disabled="savingEnrollment">
            <template #activator="{ on, attrs }">
              <div class="d-flex flex-row">
                <span class="mx-auto pl-2 text-sm-body-2">{{
                  states.length
                }}</span>
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  class="ml-2"
                  :disabled="savingEnrollment"
                  v-on="on"
                >
                  <v-icon>more_horiz</v-icon>
                </v-btn>
              </div>
            </template>
            <v-list>
              <v-list-item
                link
                @click="updateEnrollmentStates(enrollment, true)"
              >
                <v-list-item-title>Turn on all states</v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="updateEnrollmentStates(enrollment, false)"
              >
                <v-list-item-title>Turn off all states</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="deleteEnrollment(enrollment)">
                <v-list-item-title> Remove Enrollment </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </th>
    <td v-if="savingEnrollment" :colspan="states.length">
      <v-progress-linear indeterminate />
    </td>
    <td
      v-for="state in states"
      v-else
      :key="state"
      class="enrollments-table-row__state"
    >
      <v-progress-circular
        v-if="savingStates[state]"
        indeterminate
        :size="24"
        color="primary"
      />
      <v-checkbox
        v-else-if="stateShown[state]"
        v-model="enrollmentLocal.included_states"
        class="mt-0 pt-0"
        :ripple="false"
        :color="enrollment.clinician_available ? 'success' : 'error'"
        on-icon="mdi-check"
        off-icon="mdi-minus"
        hide-details
        :value="state"
        @change="updateEnrollmentState(enrollmentLocal, state)"
      />
    </td>
  </tr>
</template>

<script>
export default {
  name: 'EnrollmentTableRow',
  props: {
    enrollment: {
      type: Object,
      required: true
    },
    states: {
      type: Array, // array of lower case state abbreviations
      required: true
    }
  },
  data() {
    return {
      savingPriority: false,
      savingStates: {},
      savingEnrollment: false,
      priorities: [
        { value: 2, text: '+2' },
        { value: 1, text: '+1' },
        { value: 0, text: '0' },
        { value: -1, text: '-1' },
        { value: -2, text: '-2' }
      ]
    }
  },
  computed: {
    stateShown() {
      return this.states.reduce((acc, state) => {
        acc[state] =
          !this.enrollment.clinician ||
          this.enrollment.clinician.licenses.includes(state)
        return acc
      }, {})
    },
    enrollmentLocal: {
      get: function () {
        return this.enrollment
      },
      set: function (value) {
        return this.$emit('update:enrollment', value)
      }
    }
  },
  methods: {
    updateEnrollmentPriority: async function (enrollment) {
      this.savingPriority = true
      this.$emit('update:errorMessage')
      try {
        const response = await this.$enrollments.update(enrollment.id, {
          priority: enrollment.priority
        })
        // eslint-disable-next-line
        this.enrollment = Object.assign({}, this.enrollment, response)
      } catch (err) {
        const message =
          err.response?.data?.messages[0] || err || 'Unknown error'
        this.$emit('update:errorMessage', message)
      } finally {
        this.savingPriority = false
      }
    },
    updateEnrollmentState: async function (enrollment, state) {
      this.$set(this.savingStates, state, true)
      // this logic assumes the enrollment.included_states is hooked up to the model
      // Vue will update the model before it calls this function.
      const response = enrollment.included_states.includes(state)
        ? await this.$enrollments.addState(enrollment.id, state)
        : await this.$enrollments.removeState(enrollment.id, state)
      this.$emit('update:enrollment', response)
      this.$set(this.savingStates, state, false)
      this.savingStates[state] = false
    },
    updateEnrollmentStates: async function (enrollment, enable) {
      this.savingEnrollment = true
      this.$emit('update:errorMessage')
      try {
        const response = await this.$enrollments.update(enrollment.id, {
          included_states: enable
            ? Object.keys(this.stateShown).filter(
                (state) => this.stateShown[state]
              )
            : []
        })
        this.$emit('update:enrollment', response)
      } catch (err) {
        const message =
          err.response?.data?.messages[0] || err || 'Unknown error'
        this.$emit('update:errorMessage', message)
      } finally {
        this.savingEnrollment = false
      }
    },
    deleteEnrollment(enrollment) {
      this.$emit('delete:enrollment', enrollment)
    }
  }
}
</script>

<style lang="scss" scoped>
.enrollments-table-row {
  &__available {
    background: $clinician-available;
  }
  &__unavailable {
    background: $clinician-unavailable;
  }

  &__priority {
    ::v-deep {
      & .v-select__selections {
        & input {
          display: none;
        }
      }
      & .v-input__control > .v-input__slot {
        &:before {
          content: none;
        }
        &:after {
          content: none;
        }
      }
    }
  }

  &__state {
    text-align: center;

    ::v-deep .v-input--selection-controls__input {
      margin-right: 0;
    }

    ::v-deep .v-input__slot {
      justify-content: center;
    }

    &:hover {
      background: rgba(46, 79, 37, 0.08);
    }
  }
}

.select-priority {
  &:not(:hover) ::v-deep .v-input__icon {
    visibility: hidden;
  }
}

.v-input {
  flex: inherit;
  margin-right: auto;
}
</style>
