import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './state'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

const getters = {
  clients({ data }) {
    return data
  },
  clientMap({ data }) {
    return data.data.reduce(
      (accum, cur) => ({
        ...accum,
        [cur.uuid]: cur.name
      }),
      {}
    )
  },
  clientById:
    ({ data }) =>
    (clientId) => {
      return data.data.find((client) => client.id == clientId)
    }
}

export const mappedGetters = mapGetters(Object.keys(getters))
export default getters
