<template>
  <div class="state-collab-requirement-view">
    <view-header :breadcrumbs="breadcrumbs" :loading="loading" class="clear-mb">
      <h2 class="pt-6">
        {{ stateNameData.name }} ({{ stateNameData.abbreviationUpper }})
      </h2>
      <hr class="mb-0" />
    </view-header>

    <summary-layout>
      <template #default>
        <v-dialog
          v-model="editWarningModal"
          max-width="600px"
          @click:outside="handleClose"
        >
          <state-collab-requirement-edit-warning-modal
            v-if="editWarningModal"
            v-model="editWarningModal"
            type-title="State Requirement"
            :name="stateNameData.name"
            @close="handleClose"
            @confirm.once="onEdit"
          />
        </v-dialog>

        <v-dialog
          v-model="notRequiredWarningModal"
          max-width="734px"
          @click:outside="handleClose"
        >
          <state-collab-not-required-warning-modal
            v-if="notRequiredWarningModal"
            v-model="notRequiredWarningModal"
            title="Save changes to Collab Required"
            :state-name="stateNameData.name"
            :collab-ids="removeCollabIds"
            @close="handleClose"
            @confirm.once="onEdit"
          />
        </v-dialog>

        <v-dialog
          v-model="maxSupervisionsModal"
          max-width="734px"
          @click:outside="handleClose"
        >
          <state-collab-max-relationship-warning-modal
            v-if="maxSupervisionsModal"
            v-model="maxSupervisionsModal"
            :is-total-collab-limit="isTotalCollabsLimit"
            :state="stateNameData.name"
            :supervision-values="supervisionValues"
            :collab-data="collabData"
            @close="handleClose"
            @getRemovedConsultIds="getRemovedConsultIds"
          />
        </v-dialog>
        <v-skeleton-loader
          v-if="loading"
          type="article, paragraph"
          min-height="300"
        />
        <div v-else>
          <profile-form
            ref="form"
            v-slot="{ editMode }"
            :action-function="onSubmit"
            hide-clear
            class="ml-n4 pl-4 mr-n4 mt-n4"
            @cancel="clearForm"
          >
            <fieldset>
              <legend>State Requirements</legend>
              <profile-field
                v-model="stateCollabRequirement.collab_required_type"
                :edit-mode="editMode"
                class="mt-4"
                label="Collab Required"
                :loading="loading"
              >
                <template #default="attrs">
                  <v-autocomplete
                    v-bind="attrs"
                    name="collab_required_type"
                    :items="collabRequiredTypeOptions"
                    @change="onChange($event)"
                  />
                </template>
                <template #view="attrs">
                  <data-row v-bind="attrs" />
                </template>
              </profile-field>
              <profile-field
                v-model="stateCollabRequirement.chart_review"
                :edit-mode="editMode"
                label="Chart Reviews"
                :loading="loading"
              >
                <template #default="attrs">
                  <v-autocomplete
                    v-model="stateCollabRequirement.chart_review"
                    v-bind="attrs"
                    name="chart_review"
                    :items="chartReviewOptions"
                    :disabled="isDisabled"
                  />
                </template>
                <template #view="attrs">
                  <data-row v-bind="attrs" />
                </template>
              </profile-field>
              <profile-field
                v-model="stateCollabRequirement.meeting_requirements"
                :edit-mode="editMode"
                label="Meeting Requirements"
                :loading="loading"
              >
                <template #default="attrs">
                  <v-text-field
                    v-model="stateCollabRequirement.meeting_requirements"
                    v-bind="attrs"
                    name="meeting_requirements"
                    :disabled="isDisabled"
                  />
                </template>
                <template #view="attrs">
                  <data-row v-bind="attrs" />
                </template>
              </profile-field>
            </fieldset>
            <fieldset>
              <legend>Supervising Physician</legend>
              <div class="subtitle-1">
                States can limit the amount of Nurse Practitioners a Supervising
                Physician can supervise at one time.
              </div>
              <profile-field :edit-mode="editMode" class="mt-4">
                <template #default="attrs">
                  <v-autocomplete
                    v-bind="attrs"
                    v-model="collabLimit"
                    name="collab_limit"
                    :items="collabLimitOptions"
                    :disabled="isDisabled"
                    label="Collab Limit"
                  />
                </template>
              </profile-field>

              <div v-if="isTotalCollabsLimit">
                <profile-field
                  v-model="stateCollabRequirement"
                  :edit-mode="editMode"
                  class="mt-4"
                  label="Max Number of Supervisions"
                  :loading="loading"
                >
                  <template #default="attrs">
                    <v-autocomplete
                      v-model="stateCollabRequirement.max_total_supervisions"
                      class="ml-8"
                      v-bind="attrs"
                      name="max_total_supervisions"
                      :items="maxSupervisionAmount"
                      :rules="[rules.requiredMaxTotalSupervisions]"
                      :disabled="isDisabled"
                    />
                  </template>
                  <template #view="attrs">
                    <data-row
                      v-bind="attrs"
                      v-model="stateCollabRequirement.max_total_supervisions"
                      class="clear-text-transform"
                    />
                  </template>
                </profile-field>
              </div>

              <div v-else-if="!isTotalCollabsLimit">
                <profile-field
                  v-model="stateCollabRequirement"
                  :edit-mode="editMode"
                  class="mt-4"
                  label="Max Number of Prescribing"
                  :loading="loading"
                >
                  <template #default="attrs">
                    <v-autocomplete
                      v-model="
                        stateCollabRequirement.max_prescribing_supervisions
                      "
                      class="ml-8"
                      v-bind="attrs"
                      name="max_prescribing_supervisions"
                      :items="maxSupervisionAmount"
                      :rules="[rules.requiredMaxPrescriptionSupervisions]"
                      :disabled="isDisabled"
                    />
                  </template>
                  <template #view="attrs">
                    <data-row
                      v-bind="attrs"
                      v-model="
                        stateCollabRequirement.max_prescribing_supervisions
                      "
                      class="clear-text-transform"
                    />
                  </template>
                </profile-field>
                <profile-field
                  v-model="stateCollabRequirement"
                  :edit-mode="editMode"
                  class="mt-4"
                  label="Max Number of Non-Prescribing"
                  :loading="loading"
                >
                  <template #default="attrs">
                    <v-autocomplete
                      v-model="
                        stateCollabRequirement.max_nonprescribing_supervisions
                      "
                      :rules="[rules.requiredMaxPrescriptionSupervisions]"
                      class="ml-8"
                      v-bind="attrs"
                      name="max_nonprescribing_supervisions"
                      :items="maxSupervisionAmount"
                      :disabled="isDisabled"
                    />
                  </template>
                  <template #view="attrs">
                    <data-row
                      v-bind="attrs"
                      v-model="
                        stateCollabRequirement.max_nonprescribing_supervisions
                      "
                      class="clear-text-transform"
                    />
                  </template>
                </profile-field>
              </div>
              <div v-else>
                <profile-field
                  v-model="stateCollabRequirement.max_total_supervisions"
                  :edit-mode="editMode"
                  class="mt-4"
                  label="Max Number of Supervisions"
                  :loading="loading"
                >
                  <template #default="attrs">
                    <v-autocomplete
                      class="ml-8"
                      v-bind="attrs"
                      name="max_total_supervisions"
                      :items="maxSupervisionAmount"
                      :rules="[rules.requiredMaxTotalSupervisions]"
                      :disabled="isDisabled"
                    />
                  </template>
                  <template #view="attrs">
                    <data-row v-bind="attrs" class="clear-text-transform" />
                  </template>
                </profile-field>
              </div>
              <profile-field
                v-model="stateCollabRequirement"
                :edit-mode="editMode"
                label="Max Supervision Notes"
                :loading="loading"
              >
                <template #default="attrs">
                  <v-text-field
                    v-model="stateCollabRequirement.max_supervision_notes"
                    class="ml-8"
                    v-bind="attrs"
                    name="max_supervision_notes"
                    :disabled="isDisabled"
                  />
                </template>
                <template #view="attrs">
                  <data-row
                    v-bind="attrs"
                    v-model="stateCollabRequirement.max_supervision_notes"
                  />
                </template>
              </profile-field>
            </fieldset>
            <fieldset>
              <legend class="pt-10">Nurse Practitioner</legend>
              <profile-field
                v-model="stateCollabRequirement.max_payback_amount"
                :edit-mode="editMode"
                class="mt-4"
                label="Monthly Payback Maximum"
                :loading="loading"
              >
                <template #default="attrs">
                  <v-text-field
                    v-model="stateCollabRequirement.max_payback_amount"
                    type="number"
                    v-bind="attrs"
                    name="max_payback_amount"
                    :rules="[rules.maxMonthlyPayment]"
                    :disabled="isDisabled"
                  />
                </template>
                <template #view="attrs">
                  <data-row
                    v-if="attrs.value"
                    v-bind="attrs"
                    v-model.number="stateCollabRequirement.max_payback_amount"
                  >
                    ${{ attrs.value }}
                  </data-row>
                  <data-row v-else v-bind="attrs">
                    {{ attrs.value }}
                  </data-row>
                </template>
              </profile-field>
            </fieldset>
            <fieldset>
              <legend class="pt-10">Notes</legend>
              <profile-field
                v-model="stateCollabRequirement.notes"
                :edit-mode="editMode"
                class="mt-4"
                label="Notes"
                :loading="loading"
              >
                <template #default="attrs">
                  <pre><v-textarea
                    v-model="stateCollabRequirement.notes"
                    v-bind="attrs"
                    :disabled="isDisabled"
                  /></pre>
                </template>
                <template #view="attrs">
                  <pre v-if="attrs.value"><p>{{ attrs.value }}</p></pre>
                  <p v-else>No notes found for this State Requirement</p>
                </template>
              </profile-field>
            </fieldset>
          </profile-form>
        </div>
        <paginated-history
          :id="stateNameData.abbreviationLower"
          class="mt-4"
          :force-reload="reloadEvents"
          entity-type="stateRequirement"
          title="Events and Activity History"
          @event-reload="reloadEvents = false"
        />
      </template>
    </summary-layout>
  </div>
</template>

<script>
import Loading from '@/mixins/Loading'
import SummaryLayout from '@/components/layout/Summary.vue'
import DataRow from '@/components/common/DataRow.vue'
import UNITED_STATES from '@/utils/UnitedStates'
import STATE_REQUIREMENT_TYPES from '@/utils/StateRequirementTypes'
import COLLAB_AGREEMENT_TYPES from '@/utils/CollabAgreementTypes'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import Regex from '@/utils/Regex'
import StateCollabRequirementEditWarningModal from './StateCollabRequirementsEditWarningModal.vue'
import StateCollabMaxRelationshipWarningModal from './StateCollabMaxRelationshipWarningModal.vue'
import ViewHeader from '@/components/common/ViewHeader.vue'
import PaginatedHistory from '@/components/common/PaginatedHistory.vue'
import StateCollabNotRequiredWarningModal from './StateCollabNotRequiredWarningModal.vue'

export default {
  name: 'StateCollabRequirementView',
  components: {
    SummaryLayout,
    DataRow,
    ProfileForm,
    ProfileField,
    StateCollabRequirementEditWarningModal,
    StateCollabMaxRelationshipWarningModal,
    StateCollabNotRequiredWarningModal,
    ViewHeader,
    PaginatedHistory
  },
  mixins: [Loading],
  props: {
    state: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      supervisionValues: {},
      collabData: {},
      editWarningModal: false,
      maxSupervisionsModal: false,
      notRequiredWarningModal: false,
      stateCollabRequirement: {},
      removeCollabIds: [],
      updatedStateCollabRequirement: {},
      stateNameData: UNITED_STATES[this.state.toUpperCase()],
      beforeFormEditData: {},
      reloadEvents: false,
      loading: true,
      isDisabled: false,
      maxSupervisionAmount: [],
      collabLimitOptions: Object.values(
        STATE_REQUIREMENT_TYPES.COLLAB_LIMIT_TYPES
      ),
      collabLimit: '',
      collabRequiredTypeOptions: Object.values(
        STATE_REQUIREMENT_TYPES.COLLAB_REQUIRED_TYPES
      ),
      chartReviewOptions: Object.values(
        STATE_REQUIREMENT_TYPES.CHART_REVIEW_OPTIONS
      ),
      filters: {
        status: [
          COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_STATUS.SIGNED,
          COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_STATUS
            .COLLAB_REMOVED_OBTAINED_AUTONOMY
        ],
        state: this.state
      },
      rules: {
        requiredMaxTotalSupervisions: (val) => {
          const collabType =
            STATE_REQUIREMENT_TYPES.COLLAB_LIMIT_TYPES.TOTAL_COLLAB
          if (
            this.stateCollabRequirement.collab_required_type !==
            STATE_REQUIREMENT_TYPES.COLLAB_REQUIRED_TYPES.NO
          ) {
            if (this.collabLimit === collabType && !val) return false
          }

          return true
        },
        requiredMaxPrescriptionSupervisions: (val) => {
          const collabType =
            STATE_REQUIREMENT_TYPES.COLLAB_LIMIT_TYPES.COLLAB_TYPE
          if (
            this.stateCollabRequirement.collab_required_type !==
            STATE_REQUIREMENT_TYPES.COLLAB_REQUIRED_TYPES.NO
          ) {
            if (this.collabLimit === collabType && !val) {
              return false
            }
          }
          return true
        },
        maxMonthlyPayment: (val) => {
          if (
            this.stateCollabRequirement.collab_required_type !==
            STATE_REQUIREMENT_TYPES.COLLAB_REQUIRED_TYPES.NO
          ) {
            if (!Regex.MONETARY.test(val)) return false
            if (val < 0 || val > 500) return false
          }
          return true
        }
      }
    }
  },
  computed: {
    isTotalCollabsLimit() {
      return (
        this.collabLimit ===
        STATE_REQUIREMENT_TYPES.COLLAB_LIMIT_TYPES.TOTAL_COLLAB
      )
    },
    breadcrumbs() {
      return [
        { to: '/collabs/state-requirements', display: 'State Requirements' },
        {
          to: `/collabs/state-requirements/${this.stateNameData.abbreviationUpper}`,
          display: `${this.stateNameData.name} (${this.stateNameData.abbreviationUpper})`
        }
      ]
    }
  },
  watch: {
    state() {
      this.loadStateRequirement(true)
    },
    collabLimit() {
      if (this.isTotalCollabsLimit) {
        this.stateCollabRequirement.max_nonprescribing_supervisions = null
        this.stateCollabRequirement.max_prescribing_supervisions = null
      } else this.stateCollabRequirement.max_total_supervisions = null

      if (this.$refs.form) {
        this.$nextTick(this.$refs.form.getFormRef().validate)
      }
    }
  },
  created() {
    this.loadStateRequirement()
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  methods: {
    async loadStateRequirement(reloadEvents = false) {
      this.withLoading(this, this.fetchStateRequirement)
      this.reloadEvents = reloadEvents
    },
    async fetchStateRequirement() {
      const stateCollabData =
        await this.$stateCollabRequirements.getStateCollabRequirements(
          this.state
        )
      this.stateCollabRequirement = stateCollabData.data
      this.beforeFormEditData = { ...this.stateCollabRequirement }
      this.isDisabled =
        this.stateCollabRequirement.collab_required_type ===
        STATE_REQUIREMENT_TYPES.COLLAB_REQUIRED_TYPES.NO
          ? true
          : false
      this.collabLimit = this.stateCollabRequirement.collab_limit_type
      this.maxSupervisionAmount = ['No Limit']
      for (let i = 1; i <= 20; i++) {
        this.maxSupervisionAmount.push(i.toString())
      }
    },
    handleClose() {
      this.clearForm()
      this.editWarningModal = false
      this.maxSupervisionsModal = false
      this.notRequiredWarningModal = false
    },
    clearForm() {
      this.stateCollabRequirement = { ...this.beforeFormEditData }
      this.supervisionValues = {}
      this.updatedStateCollabRequirement = {}
      this.removeCollabIds = []
      this.collabLimit = this.stateCollabRequirement.collab_limit_type
      if (this.isTotalCollabsLimit) {
        this.stateCollabRequirement.max_nonprescribing_supervisions = null
        this.stateCollabRequirement.max_prescribing_supervisions = null
      } else this.stateCollabRequirement.max_total_supervisions = null
      this.isDisabled =
        this.stateCollabRequirement.collab_required_type ===
        STATE_REQUIREMENT_TYPES.COLLAB_REQUIRED_TYPES.NO
          ? true
          : false
    },
    onChange(event) {
      const noCollabRequired = STATE_REQUIREMENT_TYPES.COLLAB_REQUIRED_TYPES.NO
      if (event === noCollabRequired) {
        this.isDisabled = true
        const uuid = this.stateCollabRequirement.uuid
        this.stateCollabRequirement = {}
        this.stateCollabRequirement.collab_required_type = noCollabRequired
        this.stateCollabRequirement.uuid = uuid
      } else {
        this.isDisabled = false
        this.stateCollabRequirement.collab_required_type = event

        if (!this.collabLimit || !this.stateCollabRequirement.chart_review) {
          this.collabLimit =
            STATE_REQUIREMENT_TYPES.COLLAB_LIMIT_TYPES.TOTAL_COLLAB
          this.stateCollabRequirement.chart_review =
            STATE_REQUIREMENT_TYPES.CHART_REVIEW_OPTIONS.YES
        }
      }
      if (this.$refs.form) {
        this.$nextTick(this.$refs.form.getFormRef().validate)
      }
    },
    getRemovedConsultIds(value) {
      this.updatedStateCollabRequirement.collabs_to_remove_ids = value

      this.onEdit()
    },
    async onEdit() {
      try {
        const stateCollabBeforeUpdateObjectCompare = {
          ...this.stateCollabRequirement
        }
        const updateStateCollabObjectCompare = {
          ...this.updatedStateCollabRequirement
        }
        delete stateCollabBeforeUpdateObjectCompare.uuid
        delete stateCollabBeforeUpdateObjectCompare.type
        delete stateCollabBeforeUpdateObjectCompare.links
        delete stateCollabBeforeUpdateObjectCompare.id
        delete stateCollabBeforeUpdateObjectCompare.end_date
        stateCollabBeforeUpdateObjectCompare.max_payback_amount = Number(
          stateCollabBeforeUpdateObjectCompare.max_payback_amount
        )
        delete updateStateCollabObjectCompare.collab_limit

        if (
          Object.entries(this.stateCollabRequirement).toString() !==
          Object.entries(this.beforeFormEditData).toString()
        ) {
          const updatedData =
            await this.$stateCollabRequirements.updateStateCollabRequirement(
              this.stateCollabRequirement.uuid,
              this.updatedStateCollabRequirement
            )
          this.stateCollabRequirement = updatedData.data
          this.reloadEvents = true

          this.beforeFormEditData = { ...this.stateCollabRequirement }
          this.collabLimit = this.stateCollabRequirement.collab_limit_type
          this.updatedStateCollabRequirement = {}
        }
      } catch (e) {
        this.$dd.addError(e)
      }
    },
    setUpdatedProperties(updatedProperties) {
      updatedProperties.state = this.state
      updatedProperties.notes = this.stateCollabRequirement.notes

      updatedProperties.max_payback_amount = Number(
        updatedProperties.max_payback_amount
      )
      updatedProperties.collab_limit_type = this.collabLimit

      if (this.isTotalCollabsLimit) {
        updatedProperties.max_nonprescribing_supervisions = null
        updatedProperties.max_prescribing_supervisions = null
      } else updatedProperties.max_total_supervisions = null

      this.updatedStateCollabRequirement = updatedProperties
    },
    async checkCollabRequiredValidation() {
      const validateCollabs =
        await this.$stateCollabRequirements.validateCollabsForStateRequirement(
          this.stateCollabRequirement.uuid
        )

      if (validateCollabs.validationFailed) {
        this.updatedStateCollabRequirement.collabs_to_remove_ids =
          validateCollabs.impactedCollabUuids
        this.removeCollabIds = validateCollabs.impactedCollabIds
        this.notRequiredWarningModal = true
      } else this.editWarningModal = true
    },
    async checkStateRequirementValidation() {
      const validateData =
        await this.$stateCollabRequirements.validateStateCollabRequirement(
          this.stateCollabRequirement.uuid,
          this.updatedStateCollabRequirement
        )

      if (validateData.validationFailed)
        this.setMaxSupervisionData(validateData)
      else this.editWarningModal = true
    },
    async onSubmit(updatedProperties) {
      this.setUpdatedProperties(updatedProperties)

      if (
        this.updatedStateCollabRequirement.collab_required_type ===
          STATE_REQUIREMENT_TYPES.COLLAB_REQUIRED_TYPES.NO &&
        this.beforeFormEditData.collab_required_type !==
          STATE_REQUIREMENT_TYPES.COLLAB_REQUIRED_TYPES.NO
      )
        await this.checkCollabRequiredValidation()
      else await this.checkStateRequirementValidation()
    },
    async setMaxSupervisionData(validateData) {
      this.setSupervisionValues(validateData)

      this.filters.collabType = this.getCollabTypes()
      this.filters.maxPrescribingSupervisions =
        this.supervisionValues.currentPrescribingSupervisions
      this.filters.maxNonPrescribingSupervisions =
        this.supervisionValues.currentNonPrescribingSupervisions
      this.filters.maxTotalSupervisions =
        this.supervisionValues.currentTotalSupervisions

      this.collabData = await this.$collabAgreements.getMaxSupervisionData(
        this.filters
      )

      this.editWarningModal = false
      this.maxSupervisionsModal = true
    },
    setSupervisionValues(validateData) {
      this.supervisionValues = {
        currentTotalSupervisions:
          this.stateCollabRequirement.max_total_supervisions,
        currentPrescribingSupervisions:
          this.stateCollabRequirement.max_prescribing_supervisions,
        currentNonPrescribingSupervisions:
          this.stateCollabRequirement.max_nonprescribing_supervisions,
        previousTotalSupervisions:
          this.beforeFormEditData.max_total_supervisions,
        previousPrescribingSupervisions:
          this.beforeFormEditData.max_prescribing_supervisions,
        previousNonPrescribingSupervisions:
          this.beforeFormEditData.max_nonprescribing_supervisions,
        countTotalSuperviorsOver:
          validateData.impactedSupervisors.supervisorIds.total?.length,
        countPrescribingSuperviorsOver:
          validateData.impactedSupervisors.supervisorIds.prescribing?.length,
        countNonPrescribingSuperviorsOver:
          validateData.impactedSupervisors.supervisorIds.nonPrescribing?.length
      }
    },
    getCollabTypes() {
      if (this.isTotalCollabsLimit)
        return [
          COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_TYPE.NON_PRESCRIBING,
          COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_TYPE.PRESCRIBING
        ]

      const collabTypes = []

      if (this.supervisionValues.countPrescribingSuperviorsOver)
        collabTypes.push(
          COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_TYPE.PRESCRIBING
        )
      if (this.supervisionValues.countNonPrescribingSuperviorsOver)
        collabTypes.push(
          COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_TYPE.NON_PRESCRIBING
        )

      return collabTypes
    }
  }
}
</script>

<style lang="scss" scoped>
.state-collab-requirement-view {
  height: 100%;

  .clear-mb {
    margin-bottom: 0px !important;
  }

  pre {
    font-family: 'Apercu Regular', sans-serif;
  }

  .clear-text-transform {
    &::v-deep .data-row__label {
      text-transform: none;
    }
  }

  pre {
    white-space: pre-wrap;
  }
}
</style>
