<template>
  <div v-if="error" class="error__container">
    <div>
      <h1>Looks like an error occured while trying to log in</h1>
      <h2>For more context check the error message below</h2>
      <div class="error__message">
        {{ error.message }}
      </div>
      <a href="/login">Try to Login Again</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OktaCallback',
  data: function () {
    return {
      error: null,
      errorTitle: 'Error'
    }
  },
  async beforeMount() {
    await this.$auth.handleLoginRedirect().catch((e) => {
      this.error = e
      if (e.name) this.errorTitle = e.name
    })
  }
}
</script>
