<template>
  <div class="routing-score" :class="rangeClass">{{ score }}</div>
</template>

<script>
export default {
  name: 'RoutingScore',
  components: {},
  props: {
    score: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    rangeClass() {
      if (this.score < 0.4) {
        return 'low'
      } else if (this.score > 0.7) {
        return 'high'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.routing-score {
  @include font-monospaced;
  font-size: size(12);
  font-weight: 700;
  background-color: #f6ae43;
  color: #141419;
  padding: size(2) size(6);
  border-radius: size(4);

  &.low {
    background-color: #bf211e;
    color: white;
  }

  &.high {
    background-color: #4a7e3c;
    color: white;
  }
}
</style>
