<template>
  <div>
    <v-card v-if="!showNext">
      <v-card-title class="headline"> Select shift type </v-card-title>
      <v-radio-group v-model="shiftType" class="shift-radio">
        <v-radio label="Standard shift" value="standard" />
        <v-radio label="On-call shift" value="on_call" />
      </v-radio-group>
      <v-card-actions>
        <span v-if="modalError">{{ modalError }}</span>
        <v-spacer />
        <v-btn color="primary" text @click="close"> Cancel </v-btn>
        <v-btn
          color="primary"
          rounded
          :disabled="!shiftType"
          @click="showNext = true"
        >
          Next
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="showNext">
      <v-card-title class="headline">
        Select the clients for {{ shiftType | coverageDisplay }} shift
      </v-card-title>

      <v-checkbox
        v-for="client in clinician.clients"
        :key="client.id"
        v-model="selectedClients"
        :value="client.id"
        class="client-checkbox"
      >
        <template #label>
          {{ client.name }}
        </template>
      </v-checkbox>
      <v-btn rounded @click="selectAll"> Select All </v-btn>

      <v-card-actions>
        <span v-if="modalError">{{ modalError }}</span>
        <v-spacer />
        <v-btn color="primary" text @click="close"> Cancel </v-btn>
        <v-btn
          color="primary"
          rounded
          :disabled="selectedClients.length === 0 || savingShifts"
          :loading="saving"
          @click="handleConfirm"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ShiftModal from '@/components/clinicians/schedule/ShiftModal'

export default {
  name: 'ShiftCreateModal',
  mixins: [ShiftModal],
  props: {
    clinician: {
      type: Object,
      required: true
    },
    shift: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showNext: false,
      savingShifts: false,
      selectedClients: [],
      shiftType: '',
      error: 'Unable to create shifts, please try again.'
    }
  },
  mounted() {
    this.savingShifts = false
    this.shiftType = ''
    this.showNext = false
    this.selectedClients = []
  },
  methods: {
    async action() {
      return await this.$clinicians.createShifts(this.clinician.id, {
        start_at: this.shift.start,
        end_at: this.shift.end,
        client_ids: this.selectedClients,
        shift_type: this.shiftType
      })
    },
    selectAll() {
      this.selectedClients = this.clinician.clients.map((client) => client.id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
