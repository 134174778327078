const UNITED_STATES = {
  AL: { abbreviationLower: 'al', abbreviationUpper: 'AL', name: 'Alabama' },
  AK: { abbreviationLower: 'ak', abbreviationUpper: 'AK', name: 'Alaska' },
  AZ: { abbreviationLower: 'az', abbreviationUpper: 'AZ', name: 'Arizona' },
  AR: { abbreviationLower: 'ar', abbreviationUpper: 'AR', name: 'Arkansas' },
  CA: { abbreviationLower: 'ca', abbreviationUpper: 'CA', name: 'California' },
  CO: { abbreviationLower: 'co', abbreviationUpper: 'CO', name: 'Colorado' },
  CT: { abbreviationLower: 'ct', abbreviationUpper: 'CT', name: 'Connecticut' },
  DC: {
    abbreviationLower: 'dc',
    abbreviationUpper: 'DC',
    name: 'D.C. (District of Columbia)'
  },
  DE: { abbreviationLower: 'de', abbreviationUpper: 'DE', name: 'Delaware' },
  FL: { abbreviationLower: 'fl', abbreviationUpper: 'FL', name: 'Florida' },
  GA: { abbreviationLower: 'ga', abbreviationUpper: 'GA', name: 'Georgia' },
  HI: { abbreviationLower: 'hi', abbreviationUpper: 'HI', name: 'Hawaii' },
  ID: { abbreviationLower: 'id', abbreviationUpper: 'ID', name: 'Idaho' },
  IL: { abbreviationLower: 'il', abbreviationUpper: 'IL', name: 'Illinois' },
  IN: { abbreviationLower: 'in', abbreviationUpper: 'IN', name: 'Indiana' },
  IA: { abbreviationLower: 'ia', abbreviationUpper: 'IA', name: 'Iowa' },
  KS: { abbreviationLower: 'ks', abbreviationUpper: 'KS', name: 'Kansas' },
  KY: { abbreviationLower: 'ky', abbreviationUpper: 'KY', name: 'Kentucky' },
  LA: { abbreviationLower: 'la', abbreviationUpper: 'LA', name: 'Louisiana' },
  ME: { abbreviationLower: 'me', abbreviationUpper: 'ME', name: 'Maine' },
  MD: { abbreviationLower: 'md', abbreviationUpper: 'MD', name: 'Maryland' },
  MA: {
    abbreviationLower: 'ma',
    abbreviationUpper: 'MA',
    name: 'Massachusetts'
  },
  MI: { abbreviationLower: 'mi', abbreviationUpper: 'MI', name: 'Michigan' },
  MN: { abbreviationLower: 'mn', abbreviationUpper: 'MN', name: 'Minnesota' },
  MS: { abbreviationLower: 'ms', abbreviationUpper: 'MS', name: 'Mississippi' },
  MO: { abbreviationLower: 'mo', abbreviationUpper: 'MO', name: 'Missouri' },
  MT: { abbreviationLower: 'mt', abbreviationUpper: 'MT', name: 'Montana' },
  NE: { abbreviationLower: 'ne', abbreviationUpper: 'NE', name: 'Nebraska' },
  NV: { abbreviationLower: 'nv', abbreviationUpper: 'NV', name: 'Nevada' },
  NH: {
    abbreviationLower: 'nh',
    abbreviationUpper: 'NH',
    name: 'New Hampshire'
  },
  NJ: { abbreviationLower: 'nj', abbreviationUpper: 'NJ', name: 'New Jersey' },
  NM: { abbreviationLower: 'nm', abbreviationUpper: 'NM', name: 'New Mexico' },
  NY: { abbreviationLower: 'ny', abbreviationUpper: 'NY', name: 'New York' },
  NC: {
    abbreviationLower: 'nc',
    abbreviationUpper: 'NC',
    name: 'North Carolina'
  },
  ND: {
    abbreviationLower: 'nd',
    abbreviationUpper: 'ND',
    name: 'North Dakota'
  },
  OH: { abbreviationLower: 'oh', abbreviationUpper: 'OH', name: 'Ohio' },
  OK: { abbreviationLower: 'ok', abbreviationUpper: 'OK', name: 'Oklahoma' },
  OR: { abbreviationLower: 'or', abbreviationUpper: 'OR', name: 'Oregon' },
  PA: {
    abbreviationLower: 'pa',
    abbreviationUpper: 'PA',
    name: 'Pennsylvania'
  },
  PR: { abbreviationLower: 'pr', abbreviationUpper: 'PR', name: 'Puerto Rico' },
  RI: {
    abbreviationLower: 'ri',
    abbreviationUpper: 'RI',
    name: 'Rhode Island'
  },
  SC: {
    abbreviationLower: 'sc',
    abbreviationUpper: 'SC',
    name: 'South Carolina'
  },
  SD: {
    abbreviationLower: 'sd',
    abbreviationUpper: 'SD',
    name: 'South Dakota'
  },
  TN: { abbreviationLower: 'tn', abbreviationUpper: 'TN', name: 'Tennessee' },
  TX: { abbreviationLower: 'tx', abbreviationUpper: 'TX', name: 'Texas' },
  UT: { abbreviationLower: 'ut', abbreviationUpper: 'UT', name: 'Utah' },
  VT: { abbreviationLower: 'vt', abbreviationUpper: 'VT', name: 'Vermont' },
  VA: { abbreviationLower: 'va', abbreviationUpper: 'VA', name: 'Virginia' },
  WA: { abbreviationLower: 'wa', abbreviationUpper: 'WA', name: 'Washington' },
  WV: {
    abbreviationLower: 'wv',
    abbreviationUpper: 'WV',
    name: 'West Virginia'
  },
  WI: { abbreviationLower: 'wi', abbreviationUpper: 'WI', name: 'Wisconsin' },
  WY: { abbreviationLower: 'wy', abbreviationUpper: 'WY', name: 'Wyoming' }
}

export default UNITED_STATES
export const UNITED_STATES_ARRAY = Object.values(UNITED_STATES)
