<template>
  <v-stepper v-model="step" class="pa-10">
    <v-card-title class="h6 pa-0 font-weight-bold">
      Add Collaboration Agreement
      <v-spacer />
      <v-btn icon class="mr-3" aria-label="close button" @click="close">
        <v-icon color="black">mdi-close</v-icon>
      </v-btn>
      <v-alert
        v-if="validPayment.error && step === 2"
        class="mb-0 mt-8"
        color="red"
        dense
        outlined
        text
        icon="mdi-alert-circle-outline"
      >
        {{ validPayment.errorMsg }}
      </v-alert>
    </v-card-title>
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card-title> Agreement Details </v-card-title>
        <v-card-text>
          <v-form ref="formStepOne" v-model="isFormValid">
            <v-autocomplete
              v-if="externalCollabFlag"
              v-model="category"
              :clearable="false"
              color="grey"
              outlined
              :items="collabCategoriesDisplay"
              :rules="rules.required"
              required
              label="Collab Category"
              item-disabled="disabled"
              item-value="abbreviationLower"
              item-text="name"
            >
              <template #item="{ item }">
                <div class="disabled">
                  <span>{{ item }}</span>
                </div>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-model="addCollabData.state"
              :clearable="false"
              color="grey"
              outlined
              :items="states"
              :rules="rules.required"
              required
              label="State"
              item-disabled="disabled"
              item-value="abbreviationLower"
              item-text="name"
            >
              <template #item="{ item }">
                <div class="disabled">
                  <span>{{ item.name }} ({{ item.abbreviationUpper }})</span>
                  <span v-if="item.disabled" class="ml-4 disabled"
                    >No Collab Required</span
                  >
                </div>
              </template>
            </v-autocomplete>
            <v-autocomplete
              v-if="!isExternal"
              v-model="addCollabData.status"
              :clearable="false"
              color="grey"
              outlined
              :items="collabStatuses"
              :rules="rules.required"
              required
              label="Collab Status"
              item-value="text"
              item-text="text"
            />
            <v-autocomplete
              v-model="addCollabData.collab_type"
              :clearable="false"
              color="grey"
              outlined
              :items="collabTypes"
              :rules="rules.required"
              required
              label="Collab Type"
              item-value="value"
              item-text="text"
            />
          </v-form>
        </v-card-text>

        <div class="button-section">
          <v-btn class="mr-4" depressed rounded @click="close"> Cancel </v-btn>
          <v-btn
            color="primary"
            depressed
            rounded
            :disabled="!isFormValid"
            @click="nextStep"
          >
            Next
          </v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-card-title> Nurse Practitioner </v-card-title>
        <v-form ref="formStepTwo" v-model="isSecondFormValid">
          <v-card-text>
            <v-autocomplete
              v-model="addCollabData.supervised_clinician_uuid"
              :clearable="false"
              color="grey"
              outlined
              :items="nursePractioners"
              :loading="loadingNps"
              :search-input.sync="searchNps"
              hide-no-data
              hide-selected
              placeholder="Start typing to Search"
              :rules="rules.required"
              required
              label="Nurse Practitioner"
              item-value="id"
              item-text="name"
            />
            <v-autocomplete
              v-if="!isExternal"
              v-model="addCollabData.payback_model"
              :clearable="false"
              color="grey"
              outlined
              :items="paybackModels"
              :rules="rules.required"
              required
              label="Payback Model"
              item-value="value"
              item-text="display"
              @change="validateMaximumPaybackOverride"
            />
            <v-text-field
              v-if="!isExternal"
              :value="maxPaybackAmount"
              :rules="rules.validMonthlyMaximum"
              :disabled="disableMonthlyMaximum"
              label="Monthly Payback Maximum"
              type="number"
              class="mt-2 mb-4"
              name="max_payback_amount_override"
              prefix="$"
              outlined
              @change="maxPaybackAmountValueChanged"
            />
          </v-card-text>

          <v-card-title class="pt-0"> Supervising Physician </v-card-title>
          <v-card-text v-if="isExternal && addCollabData.supervising_clinician">
            <v-text-field
              v-model="addCollabData.supervising_clinician.name"
              label="Name"
              required
              outlined
              :rules="rules.required"
            />
            <v-text-field
              v-model="addCollabData.supervising_clinician.npi"
              type="number"
              label="NPI"
              outlined
              required
              :rules="[...rules.required, ...rules.validNpi]"
            />
            <v-text-field
              v-model="addCollabData.supervising_clinician.email"
              label="Email"
              :rules="rules.email"
              outlined
            />
          </v-card-text>
          <v-card-text v-else>
            <v-autocomplete
              v-model="addCollabData.supervising_clinician_uuid"
              :clearable="false"
              color="grey"
              outlined
              :items="supervisingCliniciansWithAgreements"
              :loading="loadingSupervisingPhysicians"
              :search-input.sync="searchSupervisingPhysicians"
              hide-no-data
              hide-selected
              placeholder="Start typing to Search"
              :rules="rules.required"
              required
              label="Supervising Physician"
              item-value="supervising_clinician_uuid"
              item-text="supervising_clinician_name"
            >
              <template slot="item" slot-scope="{ item }">
                <span>{{ item.supervising_clinician_name }}</span>
                <span
                  v-if="item.supervisions != undefined"
                  class="ml-2 availible-supervisions"
                >
                  {{ `${item.supervisions} supervisions available` }}
                </span>
              </template>
            </v-autocomplete>
            <v-text-field
              v-model="addCollabData.supervising_clinician_payment"
              class="mt-2 mb-4"
              label="Payment"
              prefix="$"
              :disabled="disablePayment"
              :rules="rules.validPayment"
              outlined
            />
          </v-card-text>
        </v-form>

        <div class="button-section">
          <v-btn class="mr-4" depressed rounded @click="backStep"> Back </v-btn>
          <v-btn
            color="primary"
            depressed
            rounded
            :disabled="!isSecondFormValid"
            @click="saveStep"
          >
            Save
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Regex from '@/utils/Regex'
import {
  PAYMENT_MODELS_ARRAY,
  PAYMENT_MODELS_ENUM
} from '@/utils/PaymentModels'
import {
  COLLAB_AGREEMENT_CATEGORY_ARRAY,
  COLLAB_AGREEMENT_CATEGORY
} from '@/utils/CollabCategory'
import { validNpi } from '@/utils/npi'
import { UNITED_STATES_ARRAY } from '@/utils/UnitedStates'
import CollabAgreementTypes from '@/utils/CollabAgreementTypes'
import debounce from 'lodash.debounce'

export default {
  name: 'AddCollab',
  props: {
    dialog: Boolean
  },
  data() {
    return {
      step: 1,
      isFormValid: false,
      isSecondFormValid: false,
      stateRequirements: [],
      states: [],
      collabStatuses: [],
      nursePractioners: [],
      searchNps: null,
      loadingNps: false,
      paybackModels: PAYMENT_MODELS_ARRAY,
      collabCategories: COLLAB_AGREEMENT_CATEGORY_ARRAY,
      supervisingPhysicians: [],
      searchSupervisingPhysicians: null,
      loadingSupervisingPhysicians: false,
      collabTypes: [],
      category: null,
      externalCollabFlag: process.env.VUE_APP_EXTERNAL_COLLABS_FEATURE_FLAG,
      addCollabData: {
        state: '',
        status: null,
        supervised_clinician_uuid: '',
        payback_model: '',
        supervising_clinician_uuid: null,
        supervising_clinician_payment: '',
        collab_type: null,
        max_payback_amount_override: null,
        supervising_clinician: null
      },
      rules: {
        email: [(v) => (v && Regex.EMAIL.test(v)) || !v],
        validNpi: validNpi,
        validPayment: [
          () => {
            if (this.isCollabRemovedDueToObtainedAutonomy) {
              return true
            }
            if (
              !Regex.MONETARY.test(
                this.addCollabData.supervising_clinician_payment
              )
            ) {
              return ''
            }
            if (
              this.addCollabData.supervising_clinician_payment < 0 ||
              this.addCollabData.supervising_clinician_payment > 10000
            ) {
              return ''
            }
            return true
          }
        ],
        required: [
          (v) => {
            return v?.toString().length > 0 || ''
          }
        ],
        validMonthlyMaximum: [
          (v) => {
            if (
              !Regex.POSITIVE_WHOLE_NUMBER.test(v) ||
              !Regex.MAXIMUM_TWO_DECIMAL_PLACES.test(v) ||
              v > 600
            ) {
              return 'Number must be non-negative and have a maximum of 2 decimal places.'
            }
            return true
          }
        ]
      }
    }
  },
  computed: {
    validPayment() {
      let errorMsg
      const payment = this.addCollabData.supervising_clinician_payment
      const error =
        (payment && !Regex.MONETARY.test(payment)) ||
        (payment &&
          Regex.MONETARY.test(payment) &&
          (payment < 0 || payment > 10000))
      if (payment && !Regex.MONETARY.test(payment)) {
        errorMsg = 'Invalid payment. Please add a value less than $10,000.'
      } else if (
        payment &&
        Regex.MONETARY.test(payment) &&
        (payment < 0 || payment > 10000)
      ) {
        errorMsg =
          "Supervising Physician payment can't be greater than $10,000."
      } else {
        errorMsg = ''
      }
      return {
        error,
        errorMsg
      }
    },
    supervisingCliniciansWithAgreements() {
      const returnValue = this.supervisingPhysicians.map((clinician) => {
        let supervisions
        if (this.addCollabData.collab_type === 'Prescribing') {
          if (
            clinician.max_prescribing_supervisions &&
            clinician.max_prescribing_supervisions != 'No Limit'
          ) {
            supervisions =
              clinician.max_prescribing_supervisions -
              clinician.total_active_perscribing_collab_agreements
          }
        } else {
          if (
            clinician.max_nonprescribing_supervisions &&
            clinician.max_nonprescribing_supervisions != 'No Limit'
          ) {
            supervisions =
              clinician.max_nonprescribing_supervisions -
              clinician.total_active_nonperscribing_collab_agreements
          }
        }

        if (
          supervisions === undefined &&
          clinician.max_total_supervisions &&
          clinician.max_total_supervisions != 'No Limit'
        ) {
          supervisions =
            clinician.max_total_supervisions -
            clinician.total_active_collab_agreements
        }

        return {
          ...clinician,
          supervisions,
          disabled:
            this.addCollabData.status == 'Signed' &&
            typeof supervisions == 'number' &&
            supervisions <= 0
        }
      })

      if (this.isCollabRemovedDueToObtainedAutonomy) {
        returnValue.unshift(
          {
            disabled: false,
            supervising_clinician_name:
              CollabAgreementTypes.SUPERVISING_CLINICINAN_OPTIONS.NOT_REQUIRED,
            supervising_clinician_uuid:
              CollabAgreementTypes.SUPERVISING_CLINICINAN_OPTIONS.NOT_REQUIRED
          },
          {
            disabled: false,
            supervising_clinician_name:
              CollabAgreementTypes.SUPERVISING_CLINICINAN_OPTIONS
                .SAME_AS_NURSE_PRACTITIONER,
            supervising_clinician_uuid:
              CollabAgreementTypes.SUPERVISING_CLINICINAN_OPTIONS
                .SAME_AS_NURSE_PRACTITIONER
          }
        )
      }

      return returnValue
    },
    maxPaybackAmount() {
      return (
        this.addCollabData.max_payback_amount_override ??
        this.getDefaultMaxPaybackAmount()
      )
    },
    disableMonthlyMaximum() {
      return (
        this.addCollabData.payback_model ==
        PAYMENT_MODELS_ENUM.PAID_BY_WHEEL.value
      )
    },
    disablePayment() {
      return this.isCollabRemovedDueToObtainedAutonomy
    },
    isExternal() {
      return (
        this.externalCollabFlag &&
        this.category === COLLAB_AGREEMENT_CATEGORY.EXTERNAL.display
      )
    },
    collabCategoriesDisplay() {
      return this.collabCategories.map((val) => val.display)
    },
    isCollabRemovedDueToObtainedAutonomy() {
      return (
        this.addCollabData.status ===
        CollabAgreementTypes.COLLAB_AGREEMENT_STATUS
          .COLLAB_REMOVED_OBTAINED_AUTONOMY
      )
    },
    spNotRequired() {
      return (
        this.addCollabData.supervising_clinician_uuid ===
        CollabAgreementTypes.SUPERVISING_CLINICINAN_OPTIONS.NOT_REQUIRED
      )
    },
    isSpSameAsNp() {
      return (
        this.addCollabData.supervising_clinician_uuid ===
        CollabAgreementTypes.SUPERVISING_CLINICINAN_OPTIONS
          .SAME_AS_NURSE_PRACTITIONER
      )
    }
  },
  watch: {
    searchNps: {
      handler: debounce(async function (name) {
        try {
          this.loadingNps = true
          const response = await this.$collabAgreements.getSupervisedClinicians(
            name
          )
          this.nursePractioners = response.data
        } finally {
          this.loadingNps = false
        }
      }, 500),
      immediate: true
    },
    'addCollabData.state': {
      async handler() {
        if (!this.loadingSupervisingPhysicians) {
          try {
            this.loadingSupervisingPhysicians = true
            this.supervisingPhysicians =
              await this.$collabAgreements.supervisingCliniciansWithAgreements(
                this.addCollabData.state
              )
          } finally {
            this.loadingSupervisingPhysicians = false
          }
        }
      }
    },
    'addCollabData.max_payback_amount_override': {
      handler() {
        /*
         * What this watcher does is the following:
         * - If the user inputs the same amount as the State Max Payback Amount, it sets the
         * max_payback_amount_override variable to null, meaning that we want to use the default state amount
         * - If the user previously have selected a "Paid by Wheel" Payback Model and inputs something on the
         * Payback Override input (different than 0 and the state default), we want to unset it to say
         * "Hey, you're setting a Payback Override. Paid by Wheel is not a valid Payment Model that accepts
         * an override. Please select another model" kind of thing.
         */
        if (
          Number(this.addCollabData.max_payback_amount_override) ===
          Number(this.getDefaultMaxPaybackAmount())
        ) {
          this.addCollabData.max_payback_amount_override = null
        } else if (
          Number(this.addCollabData.max_payback_amount_override) !==
            Number(this.getDefaultMaxPaybackAmount()) &&
          Number(this.addCollabData.max_payback_amount_override) > 0 &&
          this.addCollabData.payback_model ===
            PAYMENT_MODELS_ENUM.PAID_BY_WHEEL.value
        ) {
          this.addCollabData.payback_model = ''
        }
      }
    },
    'addCollabData.status': {
      handler() {
        if (!this.isCollabRemovedDueToObtainedAutonomy) {
          if (this.spNotRequired || this.isSpSameAsNp) {
            this.addCollabData.supervising_clinician_payment = null
            this.addCollabData.supervising_clinician_uuid = null
          }
        } else {
          this.addCollabData.supervising_clinician_payment = null
        }
      }
    },
    'addCollabData.supervising_clinician.npi': {
      handler() {
        this.setExternalSupervisingClinicianEmail()
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    setExternalSupervisingClinicianEmail() {
      if (this.isExternal) {
        this.addCollabData.supervising_clinician.email =
          this.addCollabData.supervising_clinician.npi + '@wheel.com'
        if (!this.addCollabData.supervising_clinician.npi)
          this.addCollabData.supervising_clinician.email = null
      }
    },
    close() {
      this.step = 1

      this.$refs.formStepOne.reset()
      this.$refs.formStepTwo.reset()
      this.addCollabData.max_payback_amount_override = null

      this.$emit('close', false)
    },
    backStep() {
      this.step -= 1
    },
    async nextStep() {
      this.step += 1
      if (this.step == 2 && this.isExternal) {
        this.addCollabData.supervising_clinician = {}
      }
    },
    async saveStep() {
      try {
        const createCollabPayload = { ...this.addCollabData }
        createCollabPayload.supervising_clinician_payment =
          +createCollabPayload.supervising_clinician_payment
        createCollabPayload.max_payback_amount_override =
          createCollabPayload.max_payback_amount_override || null
        createCollabPayload.category = this.category

        if (this.addCollabData.supervising_clinician) {
          createCollabPayload.supervising_clinician.npi =
            +createCollabPayload.supervising_clinician.npi
        }
        let supervisingClinicianUuid =
          this.addCollabData.supervising_clinician_uuid
        if (this.isCollabRemovedDueToObtainedAutonomy) {
          if (this.isSpSameAsNp) {
            supervisingClinicianUuid =
              this.addCollabData.supervised_clinician_uuid
          }
          if (this.spNotRequired) {
            supervisingClinicianUuid = null
          }
        }

        if (this.isExternal && !this.addCollabData.supervising_clinician?.email)
          this.setExternalSupervisingClinicianEmail()
        createCollabPayload.supervising_clinician_uuid =
          supervisingClinicianUuid
        await this.$collabAgreements.createConsultAgreement(createCollabPayload)
      } catch (e) {
        let message = 'Unable to save consult agreement. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.close()
      }
    },
    async getData() {
      await this.getStateRequirements()
      const stateRequirementsAbbreviationArray = this.stateRequirements.map(
        (stateReq) => stateReq.state
      )
      this.states = UNITED_STATES_ARRAY.map((state) => {
        if (
          stateRequirementsAbbreviationArray.includes(state.abbreviationLower)
        ) {
          return { ...state, disabled: false }
        } else {
          return {
            ...state,
            disabled: true
          }
        }
      })

      const collabStatusesResponse =
        await this.$collabAgreements.getCollabStatuses()
      this.collabStatuses = collabStatusesResponse.map((status) => ({
        value: status[0],
        text: status[1]
      }))

      this.collabTypes = await this.$collabAgreements.getCollabTypes()
    },
    getAvailableSupervisions(clinician) {
      let total
      if (this.addCollabData.collab_type === 'Prescribing') {
        if (
          clinician.max_prescribing_supervisions &&
          clinician.max_prescribing_supervisions != 'No Limit'
        ) {
          total =
            clinician.max_prescribing_supervisions -
            clinician.total_active_perscribing_collab_agreements
        }
      } else {
        if (
          clinician.max_nonprescribing_supervisions &&
          clinician.max_nonprescribing_supervisions != 'No Limit'
        ) {
          total =
            clinician.max_nonprescribing_supervisions -
            clinician.total_active_nonperscribing_collab_agreements
        }
      }

      if (
        total === undefined &&
        clinician.max_total_supervisions &&
        clinician.max_total_supervisions != 'No Limit'
      ) {
        total =
          clinician.max_total_supervisions -
          clinician.total_active_collab_agreements
      }

      return total
    },
    getDefaultMaxPaybackAmount() {
      const defaultMaxPaybackAmount = this.stateRequirements.find(
        ({ state }) => state === this.addCollabData.state
      )
      return defaultMaxPaybackAmount?.max_payback_amount
    },
    maxPaybackAmountValueChanged(value) {
      if (!Number(value)) {
        this.addCollabData.payback_model =
          PAYMENT_MODELS_ENUM.PAID_BY_WHEEL.value
      }
      this.addCollabData.max_payback_amount_override = value
    },
    async getStateRequirements() {
      const { data } =
        await this.$stateCollabRequirements.getAllStateCollabRequirements()
      this.stateRequirements = data.filter(
        (stateReq) => stateReq.collab_required_type != 'No'
      )
    },
    validateMaximumPaybackOverride() {
      if (
        this.addCollabData.payback_model ===
        PAYMENT_MODELS_ENUM.PAID_BY_WHEEL.value
      ) {
        this.addCollabData.max_payback_amount_override = 0
      } else {
        this.addCollabData.max_payback_amount_override =
          this.getDefaultMaxPaybackAmount()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog {
  border-radius: 8px !important;

  .v-card__title {
    padding-top: 32px;
    padding-left: 0;
    font-family: 'Ogg Bold', serif;
    font-weight: 700;
    font-size: 24px;

    .v-btn {
      margin-right: -12px !important;
    }

    .v-alert__wrapper {
      font-family: 'Apercu Regular', Apercu, sans-serif;

      .v-alert__icon {
        margin-right: 10px;
      }
    }
  }

  .v-stepper__items {
    padding: 0px;

    .v-stepper__content {
      padding: 0px;

      .v-card__title {
        font-size: 18px;
      }

      .v-text-field__details {
        margin-bottom: 0 !important;
      }

      .v-input__append-inner {
        margin-top: 14px !important;
        text-align: center;
      }

      .v-card__text {
        padding-left: 0;
        padding-top: 0;
        padding-right: 0;
      }

      .v-text-field {
        margin-top: 0 !important;
      }

      .button-section {
        float: right;
      }
    }
  }
}

.disabled {
  font-size: 14px !important;
}

.availible-supervisions {
  color: #a2a2a7;
}
</style>
