<template>
  <v-card class="pa-10">
    <v-card-title class="headline pa-0 pb-8"> {{ typeTitle }} </v-card-title>
    <v-card-text class="pa-0 pb-4">
      <v-alert
        dense
        outlined
        icon="mdi-alert-circle-outline"
        class="text-color--orange-900 warning-alert pa-4"
      >
        Nurse Practitioner’s license will be removed
      </v-alert>
    </v-card-text>
    <v-card-text class="pa-0">
      <p class="text-lg-body-1">
        Changing a collab status to
        <span class="font-weight-bold">Removed</span>
        will impact the clinician’s license. Are you sure you want to change the
        status of this collab?
      </p>
    </v-card-text>
    <v-card-actions class="pa-0 pt-8">
      <span v-if="modalError" class="modal-error">{{ modalError }}</span>
      <v-spacer />
      <v-btn text @click="close"> Cancel </v-btn>
      <v-btn color="primary" rounded :loading="saving" @click="handleConfirm">
        Remove
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Modal from '@/mixins/Modal'

export default {
  name: 'RemoveCollabWarningModal',
  mixins: [Modal],
  props: {
    typeTitle: {
      type: String,
      required: true
    }
  },
  methods: {
    async action() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 0px;
}

.warning-alert {
  background-color: $orange-50 !important;
  border-color: $orange-600 !important;
}
</style>
