<template>
  <v-card>
    <v-card-title class="headline"> Edit {{ typeTitle }} </v-card-title>
    <v-card-text>
      <v-alert
        dense
        outlined
        icon="mdi-alert-circle-outline"
        class="text-color--orange-900 warning-alert"
      >
        Confirmation with Ops, Legal, and Payments is required before editing a
        state’s requirements!
      </v-alert>
    </v-card-text>
    <v-card-text>
      <p class="text-lg-body-1">
        Making changes to <span class="font-weight-bold">{{ name }}</span> can
        impact a clinician’s pay. Are you sure you want to make changes to the
        state’s requirements?
      </p>
    </v-card-text>
    <v-card-actions>
      <span v-if="modalError" class="modal-error">{{ modalError }}</span>
      <v-spacer />
      <v-btn text @click="close"> Cancel </v-btn>
      <v-btn color="primary" rounded :loading="saving" @click="handleConfirm">
        Continue
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Modal from '@/mixins/Modal'

export default {
  name: 'StateCollabRequirementEditWarningModal',
  mixins: [Modal],
  props: {
    name: {
      type: String,
      required: true
    },
    typeTitle: {
      type: String,
      required: true
    }
  },
  methods: {
    async action() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 0px;
}

.warning-alert {
  background-color: $orange-50 !important;
  border-color: $orange-600 !important;
}
</style>
