<template>
  <v-data-table
    v-bind="allAttrs"
    disable-pagination
    :value="value"
    show-select
    v-on="$listeners"
    @input="$emit('input', $event)"
    @click:row="handleRowClick"
    @dblclick:row="go"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>

    <template #item.actions="scope">
      <v-btn icon :to="link(scope.item)">
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import NavigateTableRow from '@/mixins/NavigateTableRow'

export default {
  name: 'SelectableDataTable',
  mixins: [NavigateTableRow],
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    allAttrs() {
      const { headers, ...attrs } = this.$attrs

      return {
        headers: [
          ...this.$attrs.headers,
          { text: '', value: 'actions', sortable: false }
        ],
        ...attrs
      }
    }
  },
  methods: {
    handleRowClick(row, { isSelected, select }) {
      select(!isSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep td {
  cursor: pointer;
}
</style>
