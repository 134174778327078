const UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
// Email regex is pretty permissive by design
const EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const PHONE = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/

const MONETARY = /(?=.*?\d)^(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/

const POSITIVE_WHOLE_NUMBER = /^[0-9]\d*/

const MAXIMUM_TWO_DECIMAL_PLACES = /^\-?[0-9]+(?:\.[0-9]{1,2})?$/

export default {
  UUID,
  EMAIL,
  PHONE,
  MONETARY,
  POSITIVE_WHOLE_NUMBER,
  MAXIMUM_TWO_DECIMAL_PLACES
}
