<template>
  <div class="collab-details">
    <div class="d-flex justify-space-between align-center mb-n9">
      <view-header :breadcrumbs="breadcrumbs" :loading="viewLoading">
        <div class="d-flex pt-6">
          <div>
            <h3>
              {{
                collab.supervising_clinician
                  ? collab.supervising_clinician.name
                  : 'Not Required'
              }}
            </h3>
            <p class="collab-details__clinician-title">
              <span v-if="collab.external">External </span>Supervising Physician
            </p>
          </div>
          <div class="px-4">
            <h3>&</h3>
          </div>
          <div>
            <h3>{{ collab.supervised_clinician.name }}</h3>
            <p class="collab-details__clinician-title">Nurse Practitioner</p>
          </div>
        </div>
        <v-divider class="double-screen-width" />
      </view-header>
    </div>
    <main class="pt-12">
      <collab-agreement-status-messages
        v-if="
          collab.review_status_reason && collab.review_status !== 'Resolved'
        "
        :collab="collab"
        :days-till-removed="daysTillRemoved"
        @reloadCollab="loadCollab"
      />
      <v-dialog
        v-model="showRemovedCollabStatusModal"
        max-width="600px"
        @click:outside="handleClose"
      >
        <remove-collab-warning-modal
          v-if="showRemovedCollabStatusModal"
          v-model="showRemovedCollabStatusModal"
          type-title="Remove Collab"
          @close="handleClose"
          @confirm.once="updateData"
        />
      </v-dialog>

      <profile-form
        v-slot="{ editMode, loading }"
        :action-function="onSubmit"
        :disable-edit="isEditDisabled"
        hide-clear
        class="clinician-info-form ml-n4 pl-4 mr-n4"
        @cancel="clearForm"
      >
        <fieldset>
          <legend>Agreement Details</legend>
          <profile-field
            v-model="collabCategoryDisplayText"
            label="Category"
            :edit-mode="editMode"
            class="mt-4"
            readonly
            :loading="loading"
          >
            <template #default="attrs">
              <v-text-field v-bind="attrs" />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" />
            </template>
          </profile-field>
          <profile-field
            v-model="collab.id"
            label="Collab ID"
            :edit-mode="editMode"
            class="mt-4"
            readonly
            :loading="loading"
          >
            <template #default="attrs">
              <v-text-field v-bind="attrs" />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" />
            </template>
          </profile-field>
          <profile-field
            v-model="collab.state"
            :edit-mode="editMode"
            label="State"
            :loading="loading"
            readonly
          >
            <template #default="attrs">
              <v-text-field v-bind="attrs" name="state" />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs">
                <state-chips :states="[attrs.value]" class="state-chip" />
                <span class="state-text text-uppercase">{{ attrs.value }}</span>
              </data-row>
            </template>
          </profile-field>
          <profile-field
            v-if="!collab.external"
            v-model="collab.payback_model"
            :edit-mode="editMode"
            label="Payback Model"
            :loading="loading"
            :readonly="isReadOnly"
          >
            <template #default="attrs">
              <v-autocomplete
                v-bind="attrs"
                v-model="paybackModelDefault"
                name="payback_model"
                :items="collabPaybackModelOptions"
                item-text="display"
                item-value="value"
                @input="onFieldChange($event, 'payback_model')"
              />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs">
                {{ paybackModelDisplayText }}
              </data-row>
            </template>
          </profile-field>
          <profile-field
            v-model="collab.collab_type"
            :edit-mode="editMode"
            label="Collab Type"
            :loading="loading"
            readonly
          >
            <template #default="attrs">
              <v-text-field v-bind="attrs" />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" />
            </template>
          </profile-field>
          <profile-field
            v-model="collab.status"
            :edit-mode="editMode"
            label="Collab Status"
            :loading="loading"
          >
            <template #default="attrs">
              <v-autocomplete
                v-model="collab.status"
                v-bind="attrs"
                name="status"
                :items="collabStatusOptions"
                :error-messages="collabStatusError"
                :rules="[rules.requiresValue]"
                @input="onFieldChange($event, 'status')"
              />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" />
            </template>
          </profile-field>
          <profile-field
            v-model="collabStartDate"
            :edit-mode="editMode"
            label="Start Date"
            :loading="loading"
          >
            <template #default="attrs">
              <v-menu
                v-model="startDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                @input="onFieldChange($event, 'start_date')"
              >
                <template #activator="{ on, activatorAttrs }">
                  <v-text-field
                    name="start_date"
                    v-bind="{ ...attrs, ...activatorAttrs }"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="collab.start_date"
                  no-title
                  @input="startDatePicker = false"
                />
              </v-menu>
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" />
            </template>
          </profile-field>
          <profile-field
            v-if="!collab.external"
            v-model="stateRequirements.chart_review"
            :edit-mode="editMode"
            label="Chart Reviews"
            :loading="loading"
            readonly
          >
            <template #default="attrs">
              <v-text-field v-bind="attrs" />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" />
            </template>
          </profile-field>
          <profile-field
            v-if="!collab.external"
            v-model="stateRequirements.meeting_requirements"
            :edit-mode="editMode"
            label="Meeting Requirements"
            :loading="loading"
            readonly
          >
            <template #default="attrs">
              <v-text-field v-bind="attrs" />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" />
            </template>
          </profile-field>
        </fieldset>

        <fieldset>
          <legend>Nurse Practitioner</legend>
          <profile-field
            v-model="collab.supervised_clinician"
            :edit-mode="editMode"
            label="Nurse Practitioner"
            :loading="loading"
            readonly
          >
            <template #default="attrs">
              <v-text-field v-bind="attrs" :value="attrs.value.name" />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" :value="attrs.value.name">
                <a
                  class="text-decoration-underline"
                  @click="() => navigateToProfile(attrs.value.id)"
                >
                  {{ attrs.value.name }}
                </a>
              </data-row>
            </template>
          </profile-field>
          <profile-field
            v-if="!collab.external"
            :value="maxPaybackAmount"
            :edit-mode="editMode"
            label="Monthly Payback Maximum"
            :loading="loading"
          >
            <template #default="attrs">
              <v-text-field
                v-bind="attrs"
                type="number"
                name="max_payback_amount_override"
                @change="maxPaybackAmountValueChanged"
                @input="onFieldChange($event, 'max_payback_amount_override')"
              />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs"> ${{ attrs.value || '0.00' }} </data-row>
            </template>
          </profile-field>
          <profile-field
            v-model="collab.supervised_clinician.hour_agreement"
            :edit-mode="editMode"
            label="Hour Agreement"
            :loading="loading"
            readonly
          >
            <template #default="attrs">
              <v-text-field v-bind="attrs" />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs"> {{ attrs.value || '-' }} </data-row>
            </template>
          </profile-field>
        </fieldset>

        <fieldset>
          <legend>
            {{ collab.external ? 'External ' : '' }}Supervising Physician
          </legend>
          <profile-field
            v-if="!collab.external"
            v-model="collab.supervising_clinician"
            :edit-mode="editMode"
            label="Supervising Physician"
            readonly
          >
            <template #default="attrs">
              <v-text-field v-bind="attrs" :value="attrs.value.name" />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" :value="attrs.value.name">
                <a
                  class="text-decoration-underline"
                  @click="() => navigateToProfile(attrs.value.id)"
                >
                  {{ attrs.value.name }}
                </a>
              </data-row>
            </template>
          </profile-field>
          <profile-field
            v-else
            v-model="collab.supervising_clinician.name"
            :edit-mode="editMode"
            label="External Supervising Physician"
            :readonly="isReadOnly"
          >
            <template #default="attrs">
              <v-text-field
                v-bind="attrs"
                :value="attrs.value"
                :rules="[rules.requiresValue]"
                name="name"
                @input="onFieldChange($event, 'name')"
              />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" :value="attrs.value">
                {{ attrs.value }}
              </data-row>
            </template>
          </profile-field>
          <profile-field
            v-if="collab.external"
            v-model="collab.supervising_clinician.email"
            :edit-mode="editMode"
            label="External Supervising Physician Email"
            :readonly="isReadOnly"
          >
            <template #default="attrs">
              <v-text-field
                v-bind="attrs"
                :value="attrs.value"
                name="email"
                :rules="[rules.email]"
                @input="onFieldChange($event, 'email')"
              />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" :value="attrs.value">
                {{ attrs.value }}
              </data-row>
            </template>
          </profile-field>
          <profile-field
            v-if="collab.external"
            v-model="collab.supervising_clinician.npi"
            :edit-mode="editMode"
            label="External Supervising Physician npi"
            readonly
          >
            <template #default="attrs">
              <v-text-field v-bind="attrs" :value="attrs.value" />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs" :value="attrs.value">
                {{ attrs.value }}
              </data-row>
            </template>
          </profile-field>
          <profile-field
            v-if="!collab.external"
            v-model="collab.supervising_clinician_payment"
            label="Payment"
            :edit-mode="editMode"
            :readonly="isReadOnly"
          >
            <template #default="attrs">
              <v-text-field
                v-bind="attrs"
                type="number"
                name="supervising_clinician_payment"
                :rules="[rules.supervisingClinicianPaybackRule]"
                @input="onFieldChange($event, 'supervising_clinician_payment')"
              />
            </template>
            <template #view="{ value, label }">
              <data-row :label="label"> ${{ value }} </data-row>
            </template>
          </profile-field>
        </fieldset>

        <fieldset>
          <legend>Notes</legend>
          <profile-field v-model="collab.notes" :edit-mode="editMode">
            <template #default="attrs">
              <v-textarea
                v-bind="attrs"
                aria-label="notes"
                name="notes"
                @input="onFieldChange($event, 'notes')"
              />
            </template>
            <template #view="{ value }">
              <p style="white-space: pre">{{ value }}</p>
            </template>
          </profile-field>
        </fieldset>
      </profile-form>
    </main>
    <paginated-history
      :id="collabId"
      class="mt-4"
      :force-reload="reloadEvents"
      entity-type="collabAgreement"
      title="Events and Activity History"
      @event-reload="reloadEvents = false"
    />
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow.vue'
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import StateChips from '@/components/common/StateChips'
import ViewHeader from '@/components/common/ViewHeader.vue'
import Regex from '@/utils/Regex'
import { deserialize } from 'deserialize-json-api'
import RemoveCollabWarningModal from './RemoveCollabWarningModal.vue'
import COLLAB_AGREEMENT_TYPES from '@/utils/CollabAgreementTypes'
import { COLLAB_AGREEMENT_CATEGORY } from '@/utils/CollabCategory'
import moment from 'moment'
import CollabAgreementStatusMessages from './CollabAgreementStatusMessages.vue'

import { formatDate, toUtc } from '@/utils/Date'
import PAYMENT_MODELS from '@/utils/PaymentModels'
import PaginatedHistory from '@/components/common/PaginatedHistory.vue'

export default {
  name: 'CollabAgreementView',
  components: {
    DataRow,
    ProfileField,
    ProfileForm,
    StateChips,
    ViewHeader,
    RemoveCollabWarningModal,
    CollabAgreementStatusMessages,
    PaginatedHistory
  },
  props: {
    collabId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      viewLoading: true,
      collabStatusLoading: true,
      collabStatusError: null,
      isEditDisabled: false,
      reloadEvents: false,
      isReadOnly: false,
      showRemovedCollabStatusModal: false,
      updatedData: {},
      collab: {
        state: '',
        status: null,
        payback_model: null,
        supervising_clinician_payment: null,
        start_date: null,
        notes: null,
        max_payback_amount_override: null,
        supervising_clinician: {
          id: null,
          name: null,
          email: null
        },
        supervised_clinician: {
          id: null,
          name: null,
          hour_agreement: null
        }
      },
      daysTillRemoved: null,
      collabPaybackModelOptions: Object.values(PAYMENT_MODELS),
      collabStatusOptions: [],
      stateRequirements: {
        max_payback_amount: null,
        meeting_requirements: null,
        chart_review: null
      },
      startDatePicker: false,
      updatedKeys: [],
      beforeFormEditData: {},
      rules: {
        supervisingClinicianPaybackRule: (val) => {
          if (!Regex.MONETARY.test(val)) return false
          if (val < 0 || val > 10000) return false
          return true
        },
        requiresValue: (val) => {
          return !!val
        },
        email: (val) => {
          return !!Regex.EMAIL.test(val)
        }
      }
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { to: '/collabs/agreements', display: 'All Collab Agreements' },
        {
          to: `/collabs/agreements/${this.collabId}`,
          display: `${this.collab?.supervising_clinician?.name} & ${this.collab?.supervised_clinician.name}`
        }
      ]
    },
    collabStartDate() {
      return this.collab.start_date
        ? formatDate(toUtc(this.collab.start_date), 'MM/DD/YYYY')
        : ''
    },
    paybackModelDefault: {
      get() {
        return Number(this.collab.payback_model)
      },
      set(val) {
        this.collab.payback_model = val
      }
    },
    paybackModelDisplayText() {
      const paybackModelType = Number(this.collab.payback_model)

      return PAYMENT_MODELS[paybackModelType].display
    },
    maxPaybackAmount() {
      return this.collab.max_payback_amount_override
        ? this.collab.max_payback_amount_override
        : this.stateRequirements.max_payback_amount
    },
    collabCategoryDisplayText() {
      return this.collab.external
        ? COLLAB_AGREEMENT_CATEGORY.EXTERNAL.display
        : COLLAB_AGREEMENT_CATEGORY.INTERNAL.display
    }
  },
  created() {
    this.loadCollab()
  },
  methods: {
    async updateData() {
      if (this.updatedKeys.length !== 0) {
        try {
          const { data } = deserialize(
            await this.$collabAgreements.update(this.collabId, this.updatedData)
          )
          this.reloadEvents = true

          this.collab = data
          this.beforeFormEditData = { ...this.collab }
        } catch (e) {
          let message = 'Could not update collab agreement. '
          if (e.response) {
            message += e.response.data.messages[0]
          } else {
            message += e.message
          }
          this.$store.commit('SET_SNACKBAR', {
            message,
            show: true
          })
        }
      }

      this.updatedKeys = []
    },
    async onSubmit({ start_date: formDate, ...properties }) {
      try {
        const start_date = formDate ? formatDate(formDate, 'YYYY-MM-DD') : null
        const updatedProperties = {
          ...properties,
          start_date,
          max_payback_amount_override: this.collab.max_payback_amount_override
        }

        if (
          this.beforeFormEditData.start_date !== null &&
          this.updatedKeys.includes('start_date') &&
          updatedProperties.start_date === null
        ) {
          delete updatedProperties.start_date

          this.$store.commit('SET_SNACKBAR', {
            message:
              "Cannot set the start date to blank if it's already been set.",
            show: true
          })
        }

        this.updatedData = Object.keys(updatedProperties)
          .filter((key) => this.updatedKeys.includes(key))
          .reduce((obj, key) => {
            obj[key] = updatedProperties[key]
            if (
              key === 'status' &&
              obj[key] ===
                COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_STATUS.COLLAB_REMOVED
            ) {
              this.showRemovedCollabStatusModal = true
              this.isEditDisabled = true
            }
            return obj
          }, {})
        if (!this.showRemovedCollabStatusModal) this.updateData()
      } catch (e) {
        this.$dd.addError(e)
        this.$store.commit('SET_SNACKBAR', {
          message: `There was an issue updating this agreement, ${e}`,
          show: true
        })
      }
    },
    async loadCollab() {
      this.viewLoading = true

      try {
        this.collab = await this.$collabAgreements.show(this.collabId)
        const remainingHours = Math.abs(2 - moment.utc().hour())
        this.daysTillRemoved = moment(this.collab.review_status_updated_at)
          .add(4, 'days')
          .add(remainingHours, 'hours')
          .fromNow()

        this.stateRequirements = (
          await this.$collabAgreements.getStateRequirements(this.collab.state)
        ).data
        this.$collabAgreements
          .getCollabStatuses()
          .then((response) => {
            this.collabStatusOptions = response.map(([, text]) => ({
              text,
              value: text
            }))

            this.collabStatusError = null
          })
          .catch((e) => {
            let message = 'There was an error getting options. '
            if (e.response) {
              message += e.response.data.messages[0]
            } else {
              message += e.message
            }
            this.collabStatusError = message
            this.$dd.addError(e)
          })
          .finally(() => {
            if (
              this.collab.review_status ===
              COLLAB_AGREEMENT_TYPES.REVIEW_STATUS.NEEDS_REVIEW
            ) {
              this.isReadOnly = true
              this.collabStatusOptions = this.collabStatusOptions.filter(
                (x) => {
                  return (
                    x.text ===
                    COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_STATUS
                      .COLLAB_REMOVED
                  )
                }
              )

              if (
                this.collab.status !==
                COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_STATUS.COLLAB_REMOVED
              )
                this.collab.status = null
            }

            if (!this.collab.supervising_clinician?.external)
              this.isReadOnly = true

            this.collabStatusLoading = false
          })
        this.beforeFormEditData = { ...this.collab }

        this.isEditDisabled =
          this.collab.status ===
          COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_STATUS.COLLAB_REMOVED
      } catch (e) {
        let message = 'Unable to load Agreement Details. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
        this.$dd.addError(e)
      } finally {
        this.viewLoading = false
      }
    },
    navigateToProfile(uuid) {
      this.$router.push(`/clinicians/${uuid}`)
    },
    onFieldChange(event, key) {
      this.updatedKeys.push(key)
    },
    handleClose() {
      this.clearForm()
      this.showRemovedCollabStatusModal = false
    },
    clearForm() {
      this.collab = { ...this.beforeFormEditData }
    },
    maxPaybackAmountValueChanged(value) {
      this.collab.max_payback_amount_override = Number(value) || null
    }
  }
}
</script>

<style lang="scss" scoped>
.clinician-info-form {
  &:hover {
    & .state-chip {
      display: none;
    }
    & .state-text {
      display: initial;
    }
  }
  & .state-text {
    display: none;
  }
}

.double-screen-width {
  width: 200vw;
}

.state-chip {
  max-height: size(24);
}
.collab-details {
  &__clinician-title {
    @include text-body-2;
    color: #a2a2a7;
  }
}

.collab-details .data-row a {
  color: $wheel-green;
}
</style>
