export const validNpi = [
  (v) =>
    !v ||
    (v >= 1000000000 && v <= 9999999999) ||
    'NPI must be a 10 digit number',
  (v) => !v || isNpiNumber(v) || 'The NPI supplied is not a valid NPI number'
]

/**
 * Validation based off of https://npiprofile.com/validation
 */
function isNpiNumber(value) {
  const NPI_NUMBER_CONSTANT = 24
  if (value === undefined) {
    return false
  }

  if (value === null) {
    return true
  }

  if (isNaN(Number(value))) {
    return false
  }

  const valueString = `${value}`
  if (valueString.length !== 10) {
    return false
  }

  const sum =
    valueString.split('').reduce((acc, val, ind) => {
      if (ind !== 9) {
        if (ind % 2 === 0) {
          const double = Number(val) * 2
          if (double >= 10) {
            acc += Math.floor(double / 10) + (double % 10)
          } else {
            acc += double
          }
        } else {
          acc += Number(val)
        }
      }
      return acc
    }, 0) + NPI_NUMBER_CONSTANT

  const mod = sum % 10
  const result = mod > 0 ? 10 - mod : mod

  return result === Number(value) % 10
}
