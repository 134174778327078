<template>
  <profile-form
    v-slot="formAttrs"
    header="Background"
    :action-function="actionFunction"
    @submit="(data) => $emit('submit', data)"
  >
    <profile-autocomplete
      field-name="race"
      :items="getFieldValues('races')"
      :value="clinician.race"
      v-bind="formAttrs"
    />
    <profile-autocomplete
      field-name="sex"
      :items="getFieldValues('sexes')"
      :value="clinician.sex"
      v-bind="formAttrs"
    />
  </profile-form>
</template>

<script>
import ProfileAutocomplete from '@/components/clinicians/profile/ProfileAutocomplete'
import ProfileForm from '@/components/clinicians/profile/ProfileForm'

export default {
  name: 'ProfileBackground',
  components: { ProfileAutocomplete, ProfileForm },
  props: {
    clinician: {
      type: Object,
      required: true
    },
    actionFunction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    getFieldValues(fieldName) {
      return () => this.$clinicians.getFieldValues(fieldName)
    }
  }
}
</script>

<style lang="scss" scoped></style>
