<template>
  <div class="misc-transfers-table">
    <p class="misc-transfers-table__results">
      <span v-if="loading">Loading results&hellip;</span>
      <span v-else
        >{{ total }} results, totaling {{ totalAmount | currency }}</span
      >
    </p>
    <search v-model="search" @input="searchRecords" />
    <navigating-data-table
      :navigate="(item) => `/clinicians/${item.clinician.id}`"
      disable-sort
      :headers="headers"
      :options.sync="options"
      :footer-props="footer"
      :server-items-length="total"
      :items="miscTransfers"
      :loading="loading"
      :item-key="'id'"
      @update:page="pageChanged"
    >
      <template #item.scheduled_at="{ item }">
        <v-icon v-if="item.scheduled_at" color="primary">
          pending_actions
        </v-icon>
      </template>

      <template #item.earned_at="{ item }">
        {{ item.earned_at | format('LL', true) }}
      </template>

      <template #item.amount="{ item }">
        {{ item.amount | currency }}
      </template>

      <template #item.clinician="{ item }">
        <clinician-label :name="item.clinician.name" />
      </template>
    </navigating-data-table>
  </div>
</template>

<script>
import Vue from 'vue'
import Loading from '@/mixins/Loading'

import Search from '@/components/common/Search.vue'
import ClinicianLabel from '@/components/common/ClinicianLabel.vue'

import { Currency } from '@/filters/Currency'
import { Format } from '@/filters/Moment'
import NavigatingDataTable from '@/components/tables/NavigatingDataTable'

export default {
  name: 'MiscTransfersTable',
  components: {
    NavigatingDataTable,
    Search,
    ClinicianLabel
  },
  filters: {
    format: Format,
    currency: Currency
  },
  mixins: [Loading],
  data() {
    return {
      loading: true,
      headers: [
        { text: 'Clinician', value: 'clinician' },
        { text: 'Scheduled', value: 'scheduled_at' },
        { text: 'Earned', value: 'earned_at' },
        { text: 'Amount', value: 'amount' },
        { text: 'Expense Category', value: 'category' },
        { text: 'Description', value: 'description' }
      ],
      options: {
        page: 1,
        itemsPerPage: 20
      },
      footer: {
        itemsPerPageOptions: [],
        itemsPerPageText: null,
        disableItemsPerPage: true
      },
      search: undefined,
      miscTransfers: [],
      totalAmount: 0,
      total: 0
    }
  },
  watch: {
    $route: 'fetchData'
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.parseQueryString()
      this.loadPendingTransfers()
    },
    loadPendingTransfers() {
      this.withLoading(this, this.fetchPendingTransfers)
    },
    async fetchPendingTransfers() {
      const { data, meta } = await this.$miscTransfers.pending({
        ...this.$route.query
      })

      Vue.set(this, 'miscTransfers', data)

      this.total = meta?.total || 0
      this.totalAmount = meta?.total_amount || 0
    },
    pageChanged(page) {
      const query = this.$route.query

      if (query.page !== page) {
        this.update({
          ...query,
          page
        })
      }
    },
    parseQueryString() {
      const { page, search } = this.$route.query

      this.options.page = page ? Number(page) : 1
      this.search = search
    },
    searchRecords(search) {
      this.update({
        ...this.$route.query,
        page: 1,
        search: search || undefined
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.misc-transfers-table {
  height: 100%;

  .misc-transfers-table__results {
    color: rgba(24, 24, 24, 0.6);
    margin-top: 16px;
  }
}
</style>
