<template>
  <v-card>
    <v-card-title class="headline">
      Select the clients to remove
      {{ deletedClients.shiftType | coverageDisplay }} shift for
    </v-card-title>
    <div class="px-6">
      <v-checkbox
        v-for="client in deletedClients.clients"
        :key="client.id"
        v-model="deletedShifts"
        :value="client.id"
      >
        <template #label>
          {{ client.client_name }}
        </template>
      </v-checkbox>
      <v-btn rounded @click="selectAll"> Select All </v-btn>
    </div>
    <v-card-actions>
      <span v-if="modalError">{{ modalError }}</span>
      <v-spacer />
      <v-btn color="primary" text @click="close"> Cancel </v-btn>
      <v-btn
        color="primary"
        rounded
        :disabled="deletedShifts.length === 0"
        :loading="saving"
        @click="handleConfirm"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ShiftModal from '@/components/clinicians/schedule/ShiftModal'

export default {
  name: 'ShiftDeleteModal',
  mixins: [ShiftModal],
  props: {
    shift: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      deletedShifts: [],
      error: 'Unable to delete shifts, please try again.'
    }
  },
  computed: {
    deletedClients() {
      const {
        extendedProps: { clients = [], shiftType = '' }
      } = this.shift || { extendedProps: {} }
      return {
        clients: clients.filter((shift) => shift?.source_system === 'System'),
        shiftType
      }
    }
  },
  methods: {
    async action() {
      return await Promise.all(
        this.deletedShifts.map(async (shift) => {
          await this.$clinicians.deleteShift(shift)
        })
      )
    },
    selectAll() {
      this.deletedShifts = this.deletedClients.clients.map(
        (client) => client.id
      )
    }
  }
}
</script>

<style lang="scss" scoped></style>
