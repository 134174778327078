<template>
  <div class="client-view">
    <view-header :breadcrumbs="breadcrumbs" :loading="loadingClient">
      <div class="d-flex align-center">
        <client-icon :name="client.name" :uuid="client.uuid" tooltip />
        <h3 class="ml-4">{{ client.name }}</h3>
      </div>
      <template slot="actions">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" rounded v-on="on">
              Actions
            </v-btn>
          </template>
          <v-list>
            <div v-if="!clientDetailsFlag">
              <v-dialog
                v-model="uploadDialog"
                content-class="confirm-cancel"
                max-width="50%"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title class="text-capitalize">
                      <v-icon class="mr-2"> mdi-calendar </v-icon>
                      Upload Schedule
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <schedule-upload
                  v-if="uploadDialog"
                  v-model="uploadDialog"
                  :client-id="clientId"
                />
              </v-dialog>
            </div>
            <div>
              <v-dialog
                v-model="createModal"
                max-width="600px"
                @click:outside="handleClose"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title class="text-capitalize">
                      <v-icon class="mr-2"> mdi-plus </v-icon>
                      Create Consult Rate
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <consult-rate-create-modal
                  v-if="createModal"
                  v-model="createModal"
                  :client-id="clientId"
                  @close="handleClose"
                  @confirm="handleCreate"
                />
              </v-dialog>
            </div>
          </v-list>
        </v-menu>
      </template>
    </view-header>
    <tabs>
      <template v-if="clientDetailsFlag" #details>
        <client-details :client-id="clientId" />
      </template>
      <template #consult-rates>
        <consult-rates-table
          :loading="loadingConsultRates"
          :consult-rates="consultRates"
        />
      </template>
      <template #forecast>
        <forecast-calendar
          :facet-id="clientId"
          :facet-type="forecastCalendarType"
        />
      </template>
      <template #coverage-calendar>
        <shifts-calendar :client-id="clientId" />
      </template>
    </tabs>
  </div>
</template>

<script>
import ClientIcon from '@/components/common/ClientIcon.vue'
import { Duration } from '@/filters/Moment'
import ConsultRatesTable from '@/components/consult-rates/ConsultRatesTable.vue'
import ViewHeader from '@/components/common/ViewHeader.vue'
import Tabs from '@/components/common/Tabs'
import ForecastCalendar, {
  CLIENT
} from '@/components/forecasts/ForecastCalendar'
import ShiftsCalendar from '@/components/clients/ShiftsCalendar'
import ConsultRateCreateModal from '@/components/consult-rates/ConsultRateCreateModal.vue'
import ClientDetails from '@/components/clients/ClientDetails.vue'
import ScheduleUpload from '@/components/coverages/ScheduleUpload.vue'

export default {
  name: 'ClientView',
  components: {
    ShiftsCalendar,
    ForecastCalendar,
    ClientIcon,
    ConsultRatesTable,
    ViewHeader,
    Tabs,
    ConsultRateCreateModal,
    ClientDetails,
    ScheduleUpload
  },
  filters: {
    Duration
  },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingClient: true,
      loadingConsultRates: true,
      client: {
        name: ''
      },
      consultRates: [],
      uploadDialog: false,
      createModal: false,
      forecastCalendarType: CLIENT,
      clientDetailsFlag: process.env.VUE_APP_OPERATING_HOURS_FLAG == 'true'
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { to: '/clients', display: 'Clients' },
        { to: `/clients/${this.client.id}`, display: this.client.name }
      ]
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: async function () {
      this.$clients.show(this.clientId).then((client) => {
        this.client = client
        this.loadingClient = false
      })

      const response = await this.$clients.getConsultRates(this.clientId)
      this.consultRates = response.data
      this.loadingConsultRates = false
    },
    handleClose() {
      this.createModal = false
    },
    handleCreate() {
      this.createModal = false
    }
  }
}
</script>

<style lang="scss">
.client-view {
  height: 100%;

  &__clinician-label:hover {
    cursor: pointer;
  }

  &__select {
    margin-bottom: 1rem;
    border-radius: 1rem;
    &__wrapper {
      width: fit-content;
    }

    & .v-select__selections {
      & input {
        display: none;
      }
    }
  }
}

.edit-button {
  margin-left: 10px;
}
</style>
