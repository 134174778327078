<template>
  <v-dialog
    v-model="uploadDialog"
    content-class="confirm-cancel"
    max-width="75%"
  >
    <template #activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title class="flex align-center mr-2">
          <v-icon class="mr-2">mdi-upload</v-icon>
          Upload Misc Transfers
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-stepper v-if="uploadDialog" v-model="step" @uploaded="uploaded">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Upload File
        </v-stepper-step>
        <v-divider />
        <v-stepper-step :complete="step > 2" step="2">
          Check Errors
        </v-stepper-step>
      </v-stepper-header>
      <upload-step
        step="1"
        template-file-name="misc_transfers"
        :get-template="getTemplate"
        :handle-upload="handleUpload"
        @upload-errors="handleUploadErrors"
        @upload-success-count="successCount = $event"
      />
      <check-errors-step
        step="2"
        error-file-name="misc_transfers"
        :errors="errors"
        :success-count="successCount"
        @action-back="handleBackAction"
        @action-close="handleCloseAction"
      />
    </v-stepper>
  </v-dialog>
</template>

<script>
import UploadStep from '@/components/csv-upload/UploadStep.vue'
import CheckErrorsStep from '@/components/csv-upload/CheckErrorsStep.vue'

export default {
  name: 'MiscTransferUpload',
  components: {
    UploadStep,
    CheckErrorsStep
  },
  data() {
    return {
      step: 1,
      errors: '',
      successCount: 0,
      uploadDialog: false
    }
  },
  methods: {
    getTemplate() {
      return this.$miscTransfers.getTemplateText()
    },
    handleUpload(payload) {
      return this.$miscTransfers.batchCreate(payload)
    },
    handleUploadErrors(errors) {
      this.errors = errors
      this.nextStep()
    },
    handleBackAction() {
      this.lastStep()
    },
    handleCloseAction() {
      this.uploadDialog = false
    },
    nextStep() {
      this.step += 1
    },
    lastStep() {
      this.step -= 1
    },
    uploaded() {
      this.$emit('uploaded')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .misc-transfer-upload {
  &__table > .v-data-table__wrapper > table > tbody > tr > td {
    white-space: nowrap;
  }
}

.headers {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}
</style>
