<template>
  <v-container class="fill-height" fluid>
    <v-layout justify-center>
      <div id="okta-signin-container" />
    </v-layout>
  </v-container>
</template>

<script>
import OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

import config from '../../config/auth'

export default {
  name: 'Login',
  mounted() {
    this.$nextTick(function () {
      if (this.$route.query.success) {
        this.$snackbar.show("You've been successfully logged out!")
      }
      this.widget = new OktaSignIn({
        baseUrl: config.oidc.issuer.split('/oauth2')[0],
        clientId: config.oidc.clientId,
        redirectUri: window.location.origin + '/implicit/callback',
        logo: require('@/assets/logo.png'),
        i18n: {
          en: {
            'primaryauth.title': 'Sign in',
            'primaryauth.username.placeholder': 'Email Address'
          }
        },
        authParams: {
          issuer: config.oidc.issuer,
          display: 'page',
          scopes: config.oidc.scope
        },
        idps: [{ type: 'GOOGLE', id: config.oidc.googleIdp }],
        idpDisplay: 'PRIMARY'
      })

      this.widget
        .showSignInAndRedirect({ el: '#okta-signin-container' })
        .catch((err) => {
          throw err
        })
    })
  },
  destroyed() {
    // Remove the widget from the DOM on path change
    this.widget.remove()
  }
}
</script>

<style lang="scss">
#okta-signin-container {
  .okta-sign-in-header,
  .auth-divider,
  .primary-auth-form {
    display: none;
  }

  .okta-form-title {
    @include text-h3;
    text-align: left;
    color: black !important;
  }
}
</style>
