<template>
  <v-dialog v-model="dialog" max-width="600" content-class="suspend-dialog">
    <template #activator="{ on, attrs }">
      <v-list-item :disabled="disabled" v-bind="attrs" v-on="on">
        <v-list-item-title class="add-to-suspend flex align-center mr-2">
          <v-icon class="mr-2">mdi-account-minus-outline</v-icon>
          Suspend Clinician
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between px-4">
        <span class="header"> Suspend Clinician </span>
        <v-btn icon class="" @click="close">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="warning-text px-4">
        Suspended clinicians are not routed consults.
      </v-card-text>
      <v-card-title class="d-flex align-center justify-space-between px-4">
        <span class="subtitle">Clinician </span>
      </v-card-title>
      <v-card-text class="px-4">
        <v-chip
          v-for="clinician in clinicians"
          :key="clinician.id"
          class="mr-2"
        >
          {{ clinician.name }}
        </v-chip>
      </v-card-text>
      <v-card-actions class="flex justify-end px-4">
        <v-btn
          color="white"
          depressed
          rounded
          v-bind="attrs"
          v-on="on"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="loading"
          depressed
          rounded
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="suspendClinician"
        >
          Suspend
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SuspendDialog',
  props: {
    clinicians: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      error: null,
      dialog: false
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.error = null
      this.loading = false
    },

    async suspendClinician() {
      try {
        this.error = null
        this.loading = true
        await this.$clinicians.batchUpdate(
          this.clinicians.map((clinician) => ({
            uuid: clinician.id,
            status: 'suspended'
          }))
        )
      } catch (e) {
        this.error = e
      } finally {
        this.loading = false
        this.close()
        this.$emit('reloadClinicians')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-to-suspend {
  font-size: size(16) !important;
}

.suspend-dialog.v-dialog .v-card {
  padding: 24px;
}
.suspend-dialog {
  &__error-text {
    color: #bf211e;
  }
}

.header {
  @include text-h5;
}

.subtitle {
  @include text-h6;
}

.warning-text {
  color: black !important;
  font-size: size(16) !important;
}
</style>
