<template>
  <div>
    <v-tabs v-model="tab" class="tabs">
      <v-tab
        v-for="tabName in tabs"
        :key="tabName"
        :href="`#${tabName}`"
        class="text-capitalize"
      >
        {{ tabName.replace(/-/g, ' ') }}
      </v-tab>
    </v-tabs>
    <hr class="divider mb-0" />
    <v-tabs-items :value="tab" class="tab-items">
      <v-tab-item v-for="tabName in tabs" :key="tabName" :value="tabName">
        <slot :name="tabName" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  computed: {
    tabs() {
      return Object.keys(this.$scopedSlots)
    },
    tab: {
      set(tab) {
        this.$router.replace({
          name: this.$route.name,
          params: { ...this.$route.params, tab: tab }
        })
      },
      get() {
        return this.$route.params.tab
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs ::v-deep {
  & .v-tabs-bar {
    background-color: transparent !important;
  }
  & .v-tabs-slider {
    height: 1px;
  }
}
.tab-items {
  overflow: visible;
}
.divider {
  border-bottom: 1px solid rgba(24, 24, 24, 0.12);
  margin-top: -2px !important;
}
</style>
