import axios from 'axios'

const ConsultImports = {
  install(Vue) {
    Vue.prototype.$consultImports = new Vue({
      methods: {
        async getTemplateText() {
          const { data } = await axios.get('/admin/consult_imports/template', {
            responseType: 'text'
          })
          return data
        },
        async batchCreate(transfers) {
          const { data } = await axios.post('/admin/consult_imports/batch', {
            consult_imports: transfers
          })
          return data
        }
      }
    })
  }
}

export default ConsultImports
