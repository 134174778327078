<template>
  <v-form
    ref="form"
    v-model="valid"
    class="px-4 py-4 profile-form"
    :class="{ readonly: disableEdit, editing: internalEditMode }"
    @submit.prevent="onSubmit"
  >
    <div @dblclick="onDoubleClick">
      <header class="d-flex align-center justify-space-between">
        <slot v-if="showHeader" name="header">
          <h6>{{ header }}</h6>
        </slot>
        <v-spacer />
        <div class="edit-buttons">
          <v-dialog v-if="!hideClear" v-model="dialog" max-width="500">
            <template #activator="{ on, attrs }">
              <v-btn icon small class="mr-4" v-bind="attrs" v-on="on">
                <v-icon>$delete_forever_outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline"> Confirm </v-card-title>
              <v-card-text>
                <slot name="clearBody">
                  Are you sure you want to delete the
                  <strong>{{ header }}</strong> section? This action cannot be
                  undone.
                </slot>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn rounded text @click="dialog = false">Cancel</v-btn>
                <v-btn
                  color="primary"
                  rounded
                  :loading="loading"
                  @click="clear"
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn icon small aria-label="edit" @click="setEdit"
            ><v-icon>$edit_outline</v-icon></v-btn
          >
        </div>
      </header>
      <v-row>
        <v-col :cols="12" :role="internalEditMode ? undefined : 'grid'">
          <slot
            :edit-mode="internalEditMode"
            :handle-validations="handleValidations"
            :loading="loading"
          />
        </v-col>
      </v-row>
      <footer v-if="internalEditMode" class="d-flex justify-end">
        <v-btn plain rounded @click="onCancel">Cancel</v-btn>
        <v-btn
          depressed
          rounded
          color="primary"
          type="submit"
          :disabled="!valid"
        >
          Save
        </v-btn>
      </footer>
      <footer v-if="internalEditMode" class="d-flex justify-end">
        <div
          v-for="(error, idx) in [...inputValidations]"
          :key="idx"
          class="errors"
        >
          {{ error }}
        </div>
      </footer>
    </div>
  </v-form>
</template>
<script>
export default {
  name: 'ProfileForm',
  props: {
    /**
     * The header to the form.
     */
    header: {
      type: String,
      default: '',
      required: false
    },
    hasErrors: {
      type: Boolean,
      default: false,
      required: false
    },
    hideClear: {
      type: Boolean,
      default: false,
      required: false
    },
    hideHeaderWhenEditing: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Function to call on submit or clear.
     * After the promise resolves, we'll reset the form back to editMode = false
     * @param data - The FormData from this submitted form, as a JS object.
     * @returns Promise - some promise to await on
     */
    actionFunction: {
      type: Function,
      required: true
    },
    /**
     * Optional function to call when the delete/clear button is pressed.
     * If this prop is not set, then this.actionFunction will be called
     */
    clearFunction: {
      type: Function,
      default: null
    },
    /**
     * Prop to default the state of the form into edit mode. Useful for "adding" things.
     */
    editMode: {
      type: Boolean
    },
    /**
     * Prop to disable (hide) the edit/delete buttons. Useful for "adding" things,
     * but also for rendering the form in "readonly" mode.
     */
    disableEdit: {
      type: Boolean
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      loading: false,
      internalEditMode: false,
      inputValidations: []
    }
  },
  computed: {
    showHeader: function () {
      if (!this.hideHeaderWhenEditing) {
        return true
      }
      return !this.internalEditMode
    }
  },
  watch: {
    editMode: {
      handler(value) {
        this.internalEditMode = value
      },
      immediate: true
    }
  },
  methods: {
    getFormRef() {
      return this.$refs.form
    },
    async onDoubleClick() {
      if (!this.disableEdit) {
        this.internalEditMode = true
        this.$emit('edit', this.internalEditMode)
      }
    },
    async clear() {
      try {
        this.loading = true
        const formData = new FormData(this.$refs.form.$el)
        const clearData = {}
        for (const key of formData.keys()) {
          clearData[key] = null
        }
        const response = this.clearFunction
          ? await this.clearFunction(clearData)
          : await this.actionFunction(clearData)
        this.$emit('submit', response)
        this.dialog = false
      } finally {
        this.loading = false
      }
    },
    async setEdit() {
      this.internalEditMode = true
      this.$emit('edit', this.internalEditMode)
    },
    async onCancel() {
      this.internalEditMode = false
      this.$emit('cancel')
      this.$emit('edit', this.internalEditMode)
    },
    /**
     * @param {object} data Current data that will be submitted
     * @param {string} origKey Key to parse
     * @param {any} value Submitted value
     */
    setProperty(data, origKey, value) {
      const keys = origKey.split('.')
      if (keys.length <= 1) {
        data[keys] = value
        return
      }

      let cur = data

      keys.forEach((key, index) => {
        const match = key.match(/\[([0-9].*)\]/)

        if (match) {
          const derivedKey = key.split(match[0])[0]
          if (keys.length - 1 <= index) {
            cur[derivedKey] = value
          }
          if (!cur[derivedKey]) {
            cur[derivedKey] = []
            cur[derivedKey][Number(match[1])] = {}
            cur = cur[derivedKey][Number(match[1])]
          } else {
            cur = cur[derivedKey][Number(match[1])]
              ? cur[derivedKey][Number(match[1])]
              : (cur[derivedKey][Number(match[1])] = {})
          }
        } else {
          if (keys.length - 1 <= index) {
            cur[key] = value
          }

          if (!cur[key]) {
            cur = cur[key] = {}
          } else {
            cur = cur[key]
          }
        }
      })
    },
    async onSubmit(event) {
      try {
        this.loading = true
        const formData = new FormData(event.target)
        const updateData = {}
        for (const [key, value] of formData) {
          // This seems to be the best combination of black magic to get vuetify and FormData to work together
          let updateValue = value
          // if the updateData[key] is null, that means this is a field with multiple <input>s (because the first one will be an empty string)
          if (updateData[key] === null) {
            // split by comma for multi-select fields (cross fingers that single selects have no commas)
            // also filter out empty strings
            updateValue = value.split(',').filter((v) => v.length)
          }
          if (updateValue && updateValue.length) {
            this.setProperty(updateData, key, updateValue)
          } else {
            // we accept null for all fields, so if the field itself is an empty string, then send null
            updateData[key] = null
          }
        }
        const response = await this.actionFunction(updateData)
        this.$emit('submit', response)
        if (!this.hasErrors) {
          this.internalEditMode = false
        }
      } finally {
        this.loading = false
        this.$emit('edit', this.internalEditMode)
      }
    },
    handleValidations(validations) {
      this.inputValidations = validations
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-form {
  width: 100%;
}
form {
  position: relative;
  ::v-deep {
    /* Don't break the fieldset that v-text-fields use */
    & fieldset:not([aria-hidden='true']) {
      border: 0;
      padding: 0;
      margin: 0;
      & > legend {
        @include text-h6;
        padding-top: 0.5rem;
      }
    }
  }
}

.full-width {
  width: 100%;
}

::v-deep .placeholder {
  color: #949494;
}

form .edit-buttons {
  display: none;
  position: absolute;
  right: 16px;
  top: 12px;
}

form:not(.readonly):not(.editing):hover {
  & .edit-buttons {
    display: block;
  }
  background: rgba(22, 22, 44, 0.04);
}

.errors {
  font-size: size(14);
  color: #ef6461;
  font-family: 'Apercu Regular', Apercu, sans-serif;
}
</style>
