<template>
  <div>
    <v-alert
      v-if="error"
      color="red"
      dense
      outlined
      text
      icon="mdi-alert-circle-outline"
    >
      Error retrieving data. Please try refreshing or getting help if the
      problem continues.
    </v-alert>
    <div v-else>
      <slot :show-error="showError" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorDisplay',
  data() {
    return {
      error: false
    }
  },
  methods: {
    showError() {
      this.error = true
    }
  }
}
</script>
