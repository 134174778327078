<template>
  <div class="consult-rates-table">
    <navigating-data-table
      class="mt-4"
      :navigate="(item) => `/consult-rates/${item.id}`"
      :headers="headers"
      :items="consultRates"
      :items-per-page="10"
      :loading="loading"
      multi-sort
      fixed-header
      :mobile-breakpoint="0"
    >
      <template #item.sla_in_seconds="{ item }">
        {{ item.sla_in_seconds | duration }}
      </template>
    </navigating-data-table>
  </div>
</template>

<script>
import NavigatingDataTable from '@/components/tables/NavigatingDataTable'
import { Duration } from '@/filters/Moment'

export default {
  name: 'ConsultRatesTable',
  components: {
    NavigatingDataTable
  },
  filters: {
    Duration
  },
  props: {
    loading: Boolean,
    consultRates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
          class: 'consult-rates-table-row__id horizontal-fixed'
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          class: 'consult-rates-table-row__name horizontal-fixed'
        },
        {
          text: 'Modality',
          value: 'modality'
        },
        {
          text: 'Consult Type',
          value: 'consult_type'
        },
        {
          text: 'Practitioner Types',
          value: 'practitioner_types'
        },
        {
          text: 'Cost to Client',
          value: 'cost_to_client'
        },
        {
          text: 'Paid to Provider',
          value: 'paid_to_provider'
        },
        {
          text: 'SLA',
          value: 'sla_in_seconds'
        },
        {
          text: 'Compensation Unit',
          value: 'compensation_unit'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.consult-rates-table {
  ::v-deep {
    & .available-icon {
      background-color: $clinician-available;
      color: $clinician-available;
    }
    & .unavailable-icon {
      background-color: $clinician-unavailable;
      color: $clinician-unavailable;
    }

    .horizontal-fixed {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      z-index: 5 !important;
    }

    thead th:first-child,
    tbody th:first-child {
      left: 0;
      min-width: 160px;
      max-width: 160px;
    }

    thead th:nth-child(2),
    tbody th:nth-child(2) {
      left: 160px;
      border-right: 1px solid #ccc;
    }

    thead th {
      white-space: nowrap;
      min-width: 57px;
    }
  }
}
</style>
