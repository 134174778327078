import axios from 'axios'

const DEFAULT_ERROR = 'There was an unknown error'

const Stripe = {
  install(Vue) {
    Vue.prototype.$stripe = new Vue({
      methods: {
        async getAccount(accountId) {
          if (!accountId) {
            throw new Error('Could not authorize stripe.')
          }

          try {
            await axios({
              baseURL: window.origin,
              method: 'post',
              url: '/.netlify/functions/stripe_verify',
              data: JSON.stringify({ accountId })
            })
          } catch (e) {
            const error = e.response || e.request || {}

            const { data = DEFAULT_ERROR } = error

            throw new Error(data)
          }
        }
      }
    })
  }
}

export default Stripe
