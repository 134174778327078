<template>
  <div>
    <data-row
      :loading="loading"
      label="Last Updated"
      :value="last_updated"
      class="text-capitalize"
    />
    <v-dialog
      v-model="uploadScheduleDialog"
      content-class="confirm-cancel"
      max-width="50%"
    >
      <schedule-upload
        v-if="uploadScheduleDialog"
        v-model="uploadScheduleDialog"
        :client-id="clientId"
      />
    </v-dialog>

    <CoverageBlocksUploadMessage :client-name="clientName" />
    <v-btn
      class="text-caption d-flex"
      outlined
      color="primary"
      rounded
      @click="openScheduleDialog"
    >
      <v-icon class="mr-2"> mdi-upload </v-icon> Upload Coverage Blocks
    </v-btn>
  </div>
</template>

<script>
import CoverageBlocksUploadMessage from './CoverageBlocksUploadMessage.vue'
import ScheduleUpload from '../coverages/ScheduleUpload.vue'
import DataRow from '@/components/common/DataRow'
import { formatDate } from '@/utils/Date'

export default {
  name: 'CoverageBlocks',
  components: {
    DataRow,
    CoverageBlocksUploadMessage,
    ScheduleUpload
  },
  props: {
    clientId: {
      type: String,
      required: true
    },
    clientName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      uploadScheduleDialog: false,
      loading: false,
      last_updated: null
    }
  },
  created() {
    this.getCoverageBlocksUpdatedAt()
  },
  methods: {
    openScheduleDialog() {
      this.uploadScheduleDialog = true
    },
    async getCoverageBlocksUpdatedAt() {
      try {
        this.loading = true
        const response = await this.$clients.getCoverageBlocksUpdatedAt(
          this.clientId
        )

        this.last_updated = formatDate(response.last_uploaded_at, 'MM/DD/YY')
      } catch (e) {
        let message = 'Unable to load last uploaded date. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
