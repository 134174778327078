<template>
  <div>
    <div class="d-flex">
      <h6 class="pa-4">Payer Enrollments</h6>
      <v-spacer />
    </div>
    <template v-if="payerEnrollments.length > 0">
      <v-row class="px-4 pt-4 mb-0 headers">
        <v-col> Payer </v-col>
        <v-col> Client</v-col>
        <v-col> PAR Status </v-col>
      </v-row>
      <div
        v-for="payerEnrollment in payerEnrollments"
        :key="payerEnrollment.payer_name"
      >
        <v-row class="d-flex px-4 pt-2 mb-0 data-rows">
          <v-col>
            {{ payerEnrollment.payer_name }}
          </v-col>
          <v-col>
            {{ payerEnrollment.client_name }}
          </v-col>
          <v-col>
            {{ formatParStatus(payerEnrollment.par_status) }}
          </v-col>
        </v-row>
      </div>
      <v-btn
        class="text-caption py-4 px-5 mt-4"
        outlined
        color="primary"
        rounded
        small
        :href="medallionProviderUrl"
        target="_blank"
      >
        Clinician Medallion Profile
        <v-icon class="ml-2" small> mdi-open-in-new </v-icon>
      </v-btn>
    </template>
    <div v-else class="pl-4">
      There are no payor enrollments for this clinician.
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfilePayerEnrollments',
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      payerEnrollments: []
    }
  },
  computed: {
    medallionProviderUrl() {
      const medallionBaseUrl = process.env.VUE_APP_MEDALLION_BASE_PROVIDER_URL
      return `${medallionBaseUrl}/${this.clinician?.medallion_id}/profile/your-info`
    }
  },
  created() {
    this.getPayerEnrollments()
  },
  methods: {
    async getPayerEnrollments() {
      this.payerEnrollments = (
        await this.$clinicians.getPayerEnrollments(this.clinician.id)
      ).data
    },
    formatParStatus(parStatus) {
      if (parStatus === 'par_linked_to_group') {
        return 'PAR Linked to Group'
      } else if (parStatus === 'non-par') {
        return 'non-PAR'
      } else if (parStatus === 'par_not_linked_to_group') {
        return 'PAR and NOT Linked to Group'
      } else if (parStatus === 'par') {
        return 'PAR'
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.headers {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

::v-deep .data-rows {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
