<template>
  <section class="overflow-auto">
    <h6 class="mb-6">{{ title }}</h6>
    <history-data
      v-if="data.length > 0"
      :data="data"
      :error="error"
      :entity-type="entityType"
    />
    <history-pagination
      :id="id"
      :data="data"
      :entity-name="entityName"
      :page.sync="page"
      :limit="limit"
      :count="count"
      :offset="offset"
    />
  </section>
</template>

<script>
import { Format } from '@/filters/Moment'
import HistoryData from './HistoryData.vue'
import HistoryPagination from './HistoryPagination.vue'

export default {
  name: 'PaginatedHistory',
  components: {
    HistoryData,
    HistoryPagination
  },
  filters: {
    Format
  },
  props: {
    id: {
      type: String,
      required: true
    },
    forceReload: {
      type: Boolean
    },
    entityType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: [],
      links: {},
      meta: {},
      error: '',
      page: 1,
      limit: 20,
      count: 0,
      offset: 0
    }
  },
  computed: {
    entityName() {
      return this.entityType === 'stateRequirement' ? 'state' : 'collab'
    }
  },
  watch: {
    forceReload: {
      handler: 'reload'
    },
    page(val) {
      this.next(val)
    }
  },
  created() {
    this.next(this.page)
  },
  methods: {
    reload() {
      if (!this.forceReload) {
        return
      }

      this.data = []
      this.links = {}

      setTimeout(async () => {
        this.next(this.page)
      }, 1000)
    },
    async next(page) {
      this.offset = Number(page) === 1 ? undefined : this.limit * (page - 1)

      if (this.entityType === 'stateRequirement') {
        const result =
          await this.$stateCollabRequirements.getStateCollabRequirementHistory({
            state: this.id,
            limit: this.limit,
            offset: this.offset
          })
        this.data = result.dataWithValues
        this.count = result.count
      } else if (this.entityType === 'collabAgreement') {
        const result = await this.$collabAgreements.getCollabAgreementsHistory({
          collabId: this.id,
          limit: this.limit,
          offset: this.offset
        })
        this.data = result.dataWithValues
        this.count = result.count
      }
    }
  }
}
</script>
