<template>
  <!--  The width property here is set to empty string intentionally,
        so that the width of the content will set the width of the drawer -->
  <v-navigation-drawer
    class="right-drawer"
    fixed
    :width="''"
    :value="value"
    right
    temporary
    @input="(param) => $emit('input', param)"
  >
    <template #prepend>
      <slot name="prepend" />
    </template>
    <template>
      <slot />
    </template>
    <template #append>
      <slot name="append" />
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'RightDrawer',
  props: {
    value: Boolean
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables.scss';
.right-drawer {
  //The transition for the drawer was causing blurriness in Chrome.
  transition: none !important;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: 20%;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100%;
  }
}
</style>
