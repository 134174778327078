import axios from 'axios'
import { deserialize } from 'deserialize-json-api'

const CollabAgreements = {
  install(Vue) {
    Vue.prototype.$collabAgreements = new Vue({
      methods: {
        async getAgreements(params) {
          const { data } = await axios.get('/admin/collabs/agreements', {
            params: params
          })
          return deserialize(data)
        },
        async getSupervisedClinicians(name) {
          const { data } = await axios.get(
            `/admin/clinicians?practitioner_type[]=np&practitioner_type[]=pa&practitioner_type[]=bhnp${
              name ? `&search=${name}` : ''
            }`
          )
          return data
        },
        async getSupervisingClinicians(name) {
          const { data } = await axios.get(
            `/admin/clinicians?practitioner_type[]=md&practitioner_type[]=do&practitioner_type[]=bhmd&practitioner_type[]=bhdo${
              name ? `&search=${name}` : ''
            }`
          )
          return data
        },
        async supervisingCliniciansWithAgreements(state) {
          const { data } = await axios.get(
            `/admin/collabs/agreements/${state}/clinicians`
          )
          return data
        },
        async getCollabStatuses() {
          const { data } = await axios.get(
            '/admin/collabs/agreements/collab_status'
          )
          return data
        },
        async getCollabTypes() {
          const { data } = await axios.get(
            '/admin/collabs/agreements/collab_types'
          )
          return data
        },
        async show(id) {
          const response = await axios.get(`/admin/collabs/agreements/${id}`)
          return deserialize(response.data).data
        },
        async getStateRequirements(state) {
          const response = await axios.get(
            `/admin/collabs/state-collab-requirements/${state}`
          )

          return deserialize(response.data)
        },
        async update(id, payload) {
          const { data } = await axios
            .patch(`/admin/collabs/agreements/${id}`, payload)
            .catch((error) => {
              if (error.response) {
                throw new Error(JSON.stringify(error.response.data))
              }
            })
          return data
        },
        async createConsultAgreement(params) {
          await axios
            .post(`/admin/collabs/agreements`, params)
            .catch((error) => {
              if (error.response) {
                throw new Error(JSON.stringify(error.response.data))
              }
            })
        },
        async getMaxSupervisionData(params) {
          const { data } = await axios.get(
            '/admin/collabs/agreements/max_supervisions',
            {
              params: params
            }
          )
          return data
        },
        async getCollabAgreementsHistory(params) {
          const { data } = await axios.get('admin/collabs/agreements/history', {
            params: params
          })
          return data
        },
        async verifyExternalCollabAgreement(id) {
          const { data } = await axios
            .patch(`/admin/collabs/agreements/${id}/verify_external`)
            .catch((error) => {
              if (error.response) {
                throw new Error(JSON.stringify(error.response.data))
              }
            })
          return data
        }
      }
    })
  }
}

export default CollabAgreements
