<template>
  <v-card>
    <v-card-title class="headline">
      Confirm booking cancellations
    </v-card-title>
    <div class="px-6">
      Editing this shift will result in {{ affectedBookings.length }} booking
      cancellation{{ affectedBookings.length > 1 ? 's' : '' }}. Please confirm
      to continue.
    </div>
    <v-card-actions>
      <span v-if="modalError">{{ modalError }}</span>
      <v-spacer />
      <v-btn color="primary" text @click="close"> Cancel </v-btn>
      <v-btn color="primary" rounded :loading="saving" @click="handleConfirm">
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ShiftModal from '@/components/clinicians/schedule/ShiftModal'

export default {
  name: 'ShiftEditModal',
  mixins: [ShiftModal],
  props: {
    shift: {
      type: Object,
      required: true
    },
    affectedBookings: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      error: 'Unable to edit shift, please try again.'
    }
  },
  computed: {
    editableClients() {
      const {
        extendedProps: { clients = [], shiftType = '' }
      } = this.shift || { extendedProps: {} }
      return {
        clients: clients.filter((shift) => shift?.source_system === 'System'),
        shiftType
      }
    }
  },
  methods: {
    async action() {
      return await Promise.all(
        this.editableClients.clients.map(async (shift) => {
          await this.$clinicians.updateShift(shift.id, {
            start_at: this.shift.start,
            end_at: this.shift.end
          })
        })
      )
    }
  }
}
</script>

<style lang="scss" scoped></style>
