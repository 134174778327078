<template>
  <div class="auto-avatar" :class="size">
    <v-avatar :size="size" v-bind="$attrs">
      <v-img v-if="isImage" alt="Clinician's Avatar" :src="name" />
      <span v-else>
        {{ initials }}
      </span>
    </v-avatar>
  </div>
</template>

<script>
const IMAGE_FORMAT_REGEX = /^(?:http|data:image).*$/i

export default {
  name: 'AutoAvatar',
  props: {
    name: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  },
  computed: {
    initials() {
      const names = this.name.split(' ')
      const letters = names.map((n) => n.slice(0, 1))
      return letters.join('')
    },
    size() {
      if (this.$attrs.large !== undefined) {
        return 'large'
      }
      return ''
    },
    isImage() {
      return this.name.match(IMAGE_FORMAT_REGEX)
    }
  }
}
</script>

<style lang="scss">
.auto-avatar {
  align-items: center;
  background: rgba(24, 24, 24, 0.08);
  border-radius: 50%;
  color: rgba(24, 24, 24, 0.6);
  display: inline-flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  margin-right: 0.5rem;
  width: 40px;

  &.large {
    height: 80px;
    width: 80px;
    font-size: 1.5rem;
    font-family: 'Ogg Bold';
  }
}
</style>
