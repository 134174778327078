<template>
  <section class="overflow-auto">
    <h6 class="mb-6">{{ title }}</h6>
    <history-data
      :data="data"
      :error="error"
      :entity-type="entityType"
      @routing-report:show="showRoutingReport"
    />
    <load-more
      :count="data.length"
      :total="meta.total"
      :show-button="!!links.next"
      type="history"
      @click="loadNextPage(links.next)"
    />
    <right-drawer v-model="showReport" class="routing-report">
      <header slot="prepend" class="d-flex align-center">
        <h3 class="h5">Routing Report</h3>
        <v-btn
          fab
          text
          class="routing-report__close"
          @click="showReport = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </header>
      <slot v-if="reportId && loadingType !== 'paginate'">
        <routing-report :id="reportId" :entity-type="entityType" />
      </slot>
    </right-drawer>
  </section>
</template>

<script>
import { Format } from '@/filters/Moment'
import LoadMore from './LoadMore.vue'
import HistoryData from './HistoryData.vue'
import RightDrawer from './RightDrawer.vue'
import RoutingReport from '@/components/routing-reports/RoutingReport.vue'

export default {
  name: 'History',
  components: {
    HistoryData,
    LoadMore,
    RightDrawer,
    RoutingReport
  },
  filters: {
    Format
  },
  props: {
    id: {
      type: String,
      required: true
    },
    forceReload: {
      type: Boolean
    },
    entityType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      data: [],
      totalData: 0,
      links: {},
      meta: {},
      error: '',
      reportId: null,
      showReport: false,
      page: 1,
      totalPages: 0,
      limit: 20,
      offset: 0
    }
  },
  watch: {
    forceReload: {
      handler: 'reload'
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    loadNextPage(url) {
      url = new URL(url)
      const params = new URLSearchParams(url.search)
      this.fetch(params.get('page'))
    },
    reload() {
      if (!this.forceReload) {
        return
      }

      this.data = []
      this.links = {}

      setTimeout(async () => {
        this.fetch()
        this.$emit('event-reload')
      }, 1000)
    },
    async fetch(page) {
      try {
        this.error = ''

        let response = ''

        if (this.entityType === 'consult') {
          response = await this.$consults.history(this.id, page)
        } else if (this.entityType === 'clinician') {
          response = await this.$clinicians.history(this.id, page)
        }

        if (!response.data.length) {
          throw new Error('No history found for this consult')
        }

        if (page) {
          this.data = this.data.concat(response.data)
          this.links = response.links
        } else {
          const attrs = { ...response, error: '' }
          Object.assign(this, attrs)
        }
      } catch (error) {
        this.error = error.message || error.toString()
      }
    },
    showRoutingReport(reportId) {
      this.reportId = reportId
      this.showReport = true
    }
  }
}
</script>

<style lang="scss" scoped>
.routing-report {
  padding: size(48);
  min-width: size(532);

  &__close {
    margin-left: auto;
    align-self: flex-end;
  }
}
</style>
