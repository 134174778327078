<template>
  <div class="consult-rate-tiers-table">
    <navigating-data-table
      class="mt-4"
      :navigate="(item) => `/consult-rate-tiers/${item.id}`"
      :headers="headers"
      :items="consultRateTiers"
      :items-per-page="10"
      :loading="loading"
      multi-sort
      fixed-header
      :mobile-breakpoint="0"
    >
      <template #item.tier="{ item }">
        {{ findTier(item.states[0]) }}
      </template>
      <template #item.states="{ item }">
        <state-chips :limit="12" :states="item.states" />
      </template>
      <template #item.sla_in_seconds="{ item }">
        {{ item.sla_in_seconds | duration }}
      </template>
      <template #item.assignment_lifetime="{ item }">
        {{ item.assignment_lifetime | duration }}
      </template>
    </navigating-data-table>
  </div>
</template>

<script>
import NavigatingDataTable from '@/components/tables/NavigatingDataTable'
import StateChips from '../common/StateChips'
import ConsultRateTiers from '@/mixins/ConsultRateTiers'
import { Duration } from '@/filters/Moment'

export default {
  name: 'ConsultRateTiersTable',
  components: {
    NavigatingDataTable,
    StateChips
  },
  filters: {
    Duration
  },
  mixins: [ConsultRateTiers],
  props: {
    loading: Boolean,
    consultRateTiers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
          class: 'consult-rate-tiers-table-row__id horizontal-fixed'
        },
        {
          text: 'Tier',
          value: 'tier'
        },
        {
          text: 'State(s)',
          value: 'states'
        },
        {
          text: 'SLA',
          value: 'sla_in_seconds'
        },
        {
          text: 'Assignment Lifetime',
          value: 'assignment_lifetime'
        }
      ],
      modifiedConsultRateTiers: []
    }
  }
}
</script>

<style lang="scss" scoped>
.consult-rate-tiers-table {
  ::v-deep {
    & .available-icon {
      background-color: $clinician-available;
      color: $clinician-available;
    }
    & .unavailable-icon {
      background-color: $clinician-unavailable;
      color: $clinician-unavailable;
    }

    .horizontal-fixed {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      z-index: 5 !important;
    }

    thead th:nth-child(3),
    tbody th:nth-child(3) {
      min-width: 460px;
      max-width: 460px;
    }

    thead th {
      white-space: nowrap;
      min-width: 57px;
    }
  }
}
</style>
