<template>
  <span>
    <span v-if="data.length" class="pr-4">
      <span v-if="offset"
        >{{ 1 + offset }} -
        {{ totalData >= limit ? offset + limit : totalData }} of
        {{ totalData }}</span
      >
      <span v-else
        >1 - {{ totalData >= limit ? limit : totalData }} of
        {{ totalData }}</span
      >
    </span>
    <span v-if="data.length" class="pagination-section">
      <v-pagination
        v-model="pageLocal"
        :length="totalPages"
        circle
        @input="next"
    /></span>
    <p v-else>
      No history for this {{ entityName }} <br />Showing 0 of 0 history
    </p>
  </span>
</template>

<script>
export default {
  name: 'HistoryPagination',
  props: {
    id: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    entityName: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    offset: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {}
  },
  computed: {
    totalData() {
      return this.count >= 0 ? this.count : 0
    },
    totalPages() {
      return this.count <= this.limit ? 1 : Math.ceil(this.count / this.limit)
    },
    pageLocal: {
      get: function () {
        return this.page
      },
      set: function (value) {
        return this.$emit('update:page', value)
      }
    }
  },
  methods: {
    async next(page) {
      this.$emit('update:page', page)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-pagination__item,
  .v-pagination__more {
    display: none;
  }

  .v-pagination__navigation {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .pagination-section {
    display: inline-block;

    button {
      &:hover {
        background: rgba(46, 79, 37, 0.08);
      }
    }
  }
}
</style>
