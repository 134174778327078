<template>
  <div>
    <profile-form
      v-slot="{ editMode, loading }"
      hide-clear
      :action-function="onSubmit"
      class="mt-6"
      @cancel="clearForm"
    >
      <fieldset>
        <legend>Marketplace Settings</legend>
        <profile-field
          :edit-mode="editMode"
          class="mt-4 pl-4"
          label="Auto-Reassignment in Seconds"
          :loading="loading"
        >
          <template #default="attrs">
            <v-text-field
              v-bind="attrs"
              v-model="reassignmentInSeconds"
              name="reassignment_in_seconds"
              :rules="[rules.reassignmentInSeconds]"
              placeholder="Auto-Reassignment In Seconds"
              item-text="display"
              item-value="value"
            />
          </template>
          <template #view>
            <data-row
              label="Auto-Reassignment in Seconds"
              :value="reassignmentInSeconds"
            />
          </template>
        </profile-field>
      </fieldset>
    </profile-form>
  </div>
</template>

<script>
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import DataRow from '@/components/common/DataRow.vue'
import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE as clientNamespace } from '@/store/clients/state'

const { mapActions: mappedClientActions } =
  createNamespacedHelpers(clientNamespace)

export default {
  name: 'MarketplaceSettings',
  components: {
    ProfileField,
    ProfileForm,
    DataRow
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reassignmentInSeconds: null,
      rules: {
        reassignmentInSeconds: (v) =>
          !v ||
          v >= 600 ||
          'Reassignment in seconds must either be empty or greater than 10 minutes'
      }
    }
  },
  async created() {
    this.reassignmentInSeconds = this.client.reassignment_in_seconds
  },
  methods: {
    ...mappedClientActions(['editClient']),
    clearForm() {
      this.reassignmentInSeconds = this.client.reassignment_in_seconds
    },
    async onSubmit() {
      try {
        await this.editClient({
          clientId: this.client.id,
          payload: {
            reassignment_in_seconds: parseInt(this.reassignmentInSeconds)
          }
        })
      } catch (e) {
        let message = 'Unable to save settings. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
        this.clearForm()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
