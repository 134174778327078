import axios from 'axios'

const RoutingReports = {
  install(Vue) {
    Vue.prototype.$routingReports = new Vue({
      methods: {
        async getById(id) {
          const { data } = await axios.get(`/admin/routing_reports/${id}`)
          return data
        }
      }
    })
  }
}

export default RoutingReports
