import axios from 'axios'

const ConsultRateTiers = {
  install(Vue) {
    Vue.prototype.$consultRateTiers = new Vue({
      methods: {
        async getById(id) {
          const { data } = await axios.get(`/admin/consult_rate_tiers/${id}`)
          return data
        },
        async edit(id, consult_rate_tier) {
          const response = await axios.patch(
            `/admin/consult_rate_tiers/${id}`,
            { consult_rate_tier }
          )
          return response.data
        }
      }
    })
  }
}

export default ConsultRateTiers
