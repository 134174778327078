<template>
  <div class="pt-3">
    <v-card v-if="consults.length === 0" class="consults-empty" elevation="0">
      <v-card-subtitle> No current consults </v-card-subtitle>
    </v-card>
    <v-card
      v-for="consult in consults"
      :key="consult.id"
      class="consults mb-2 px-2"
      :to="`/consults/${consult.id}`"
      elevation="1"
    >
      <div class="d-flex flex-no-wrap align-center justify-space-between">
        <client-icon
          :name="consult.client.name"
          :uuid="consult.client.uuid"
          :tooltip="true"
        />
        <div>
          <v-card-title>
            {{ consult.consult_rate.display_name }}
          </v-card-title>
          <v-card-subtitle>
            <span class="text-uppercase">{{ consult.state }}</span> &bull;
            <span class="text-capitalize">{{ consult.status }}</span> &bull;
            {{ consult.updated_at | elapsed }}
          </v-card-subtitle>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import ClientIcon from '@/components/common/ClientIcon'
import { Elapsed } from '@/filters/Moment'

export default {
  name: 'ClinicianConsults',
  components: {
    ClientIcon
  },
  filters: {
    Elapsed
  },
  props: {
    consults: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.consults-empty .v-card__subtitle {
  padding-left: 0;
}

.consults {
  .v-card__title {
    font-size: size(16);
  }
}
</style>
