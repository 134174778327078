import { datadogRum } from '@datadog/browser-rum'

const DataDog = {
  install(Vue) {
    const ddEnv = process.env.VUE_APP_DD_ENV
    const tracingOrigin = process.env.VUE_APP_ON_DEMAND_API.split('/v1')[0]
    const applicationId = process.env.VUE_APP_DD_APPLICATION_ID
    const clientToken = process.env.VUE_APP_DD_CLIENT_TOKEN
    const version = process.env.VUE_APP_VERSION

    if (ddEnv) {
      datadogRum.init({
        applicationId,
        allowedTracingOrigins: [tracingOrigin],
        clientToken,
        site: 'datadoghq.com',
        service: 'on-demand-admin',
        env: ddEnv,
        sampleRate: 100,
        version,
        trackViewsManually: true,
        trackInteractions: false
      })
    }

    const startView = (to) => {
      if (ddEnv && to) {
        const [firstMatch] = to.matched
        let pathPattern = firstMatch.path
        if (to.meta.queryParamNavigation) {
          const params = new URLSearchParams(to.query).toString()
          pathPattern += params ? `?${params}` : ''
        }
        datadogRum.startView(pathPattern)
      }
    }

    const setUser = (email) => {
      if (ddEnv) {
        datadogRum.setUser({ email })
      }
    }

    const addError = (error, context) => {
      if (ddEnv) {
        datadogRum.addError(error, context)
      }
    }

    Vue.prototype.$dd = {
      startView,
      setUser,
      addError
    }
  }
}

export default DataDog
