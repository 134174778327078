<template>
  <div class="d-flex wrapper">
    <div class="clinician-header__avatar">
      <div v-if="status">
        <img
          v-if="clinician.available"
          src="~@/assets/images/indicator-filled.svg"
          alt="online"
        />
        <img v-else src="~@/assets/images/indicator-empty.svg" alt="offline" />
      </div>
      <profile-image-cropper
        v-if="showModal"
        :image="img"
        :show-modal.sync="showModal"
        @image-cropped="updatePreviewImage"
        @close-modal="closeModal"
      />
      <section class="mx-4">
        <div class="d-flex align-center">
          <profile-form
            hide-clear
            class="edit-image mr-4"
            :action-function="uploadPhoto"
            :clear-function="updateClinician"
            :error-function="photoError"
            @cancel="onCancel"
          >
            <template #default="{ editMode, handleValidations }">
              <profile-image
                v-model="profileImage"
                field-name="photo"
                :validations="profileImageValidations"
                :preview-image.sync="img"
                :edit="editMode"
                :clear-image="clearImageFromInput"
                @validate="handleValidations"
                @showModal="enableModal"
              />
            </template>
          </profile-form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ProfileImageCropper from './profile/ProfileImageCropper.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm'
import ProfileImage from './profile/ProfileImage.vue'

const MAX_ALLOWED_SIZE_IN_MB = 1
const MAX_ALLOWED_SIZE_IN_BYTES = 1024 * 1024 * MAX_ALLOWED_SIZE_IN_MB

export default {
  name: 'ClinicianAvailability',
  components: {
    ProfileForm,
    ProfileImageCropper,
    ProfileImage
  },
  props: {
    clinician: {
      type: Object,
      required: true
    },
    status: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      profileImageValidations: [this.sizeValidation],
      img: null,
      showModal: false,
      canvas: null,
      fileName: null,
      fileType: null,
      clearImageFromInput: false
    }
  },
  computed: {
    profileImage() {
      return this.clinician.photo || this.clinician.name
    },
    clinicianLocal: {
      get: function () {
        return this.clinician
      },
      set: function (value) {
        return this.$emit('update:clinician', value)
      }
    }
  },
  methods: {
    onCancel() {
      this.img = null
    },
    async uploadPhoto() {
      this.canvas.toBlob(async (blob) => {
        const file = new File([blob], this.fileName, { type: this.fileType })
        const newPhotoUrl = await this.$clinicians.uploadPhoto(
          file,
          this.clinician.uuid
        )
        if (newPhotoUrl) {
          this.clinicianLocal.photo = newPhotoUrl
        }
      }, this.fileType)
    },

    async updateClinician(data) {
      await this.$clinicians.update(data, this.clinician.uuid)
    },

    photoError(errors = []) {
      if (Array.isArray(errors)) return errors
      else return [errors]
    },
    sizeValidation(file) {
      if (file && file[0]) {
        if (file[0].size > MAX_ALLOWED_SIZE_IN_BYTES) {
          return `Image must be smaller than ${MAX_ALLOWED_SIZE_IN_MB}MB`
        }
      }
      return true
    },
    updatePreviewImage(image, canvas) {
      this.showModal = false
      this.img = image
      this.canvas = canvas
    },
    enableModal(imageData) {
      this.showModal = true
      this.img = imageData.image
      this.fileName = imageData.fileName
      this.fileType = imageData.fileType
    },
    closeModal() {
      this.img = null
      this.clearImageFromInput = true
      this.showModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  align-self: baseline;
}
.clinician-header__avatar {
  position: relative;

  img {
    position: absolute;
    right: 0.2rem;
    bottom: 0.55rem;
  }
}
</style>
