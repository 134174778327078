import Vue from 'vue'
import VueRouter from 'vue-router'

import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import AuthConfig from '@/config/auth'
import { determineAuth0Override } from '@/router/utils'
import { updateStatsigUserId, getExperimentFlag } from '@/services/Statsig'

import routes from './routes'

Vue.use(VueRouter)

const oktaAuth = new OktaAuth({
  issuer: AuthConfig.oidc.issuer,
  clientId: AuthConfig.oidc.clientId,
  redirectUri: window.location.origin + '/implicit/callback',
  scopes: AuthConfig.oidc.scope,
  transformAuthState: determineAuth0Override
})

oktaAuth.authStateManager.subscribe(async ({ isAuthenticated }) => {
  try {
    if (isAuthenticated) {
      const { idToken: { claims } = {} } =
        oktaAuth.authStateManager.getAuthState()

      await updateStatsigUserId(claims?.email)

      const isAuth0Tester = await getExperimentFlag(
        'auth0progressiverollout',
        'auth0Enabled',
        false
      )

      if (isAuth0Tester) {
        sessionStorage.setItem('auth0Tester', true)
        await oktaAuth.signOut()
        router.push({ path: '/auth0Login' })
      }
    }
  } catch (e) {
    sessionStorage.setItem('auth0Tester', false)
  }
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(OktaVue, {
  oktaAuth,
  onAuthRequired: (_) => {
    if (router.currentRoute.path !== '/login') {
      router.push({ path: '/login' })
    }
  }
})

router.afterEach((to) => {
  Vue.prototype.$dd.startView(to)
})

export default router
