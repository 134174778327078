import axios from 'axios'

const Opportunities = {
  install(Vue) {
    Vue.prototype.$opportunities = new Vue({
      methods: {
        async index(params) {
          const { data } = await axios.get('/admin/opportunities', { params })
          return data
        },
        async create(opportunity) {
          const { data } = await axios.post('/admin/opportunities', opportunity)
          return data
        },
        async update(id, props) {
          const { data } = await axios.patch(`admin/opportunities/${id}`, props)
          return data
        },
        async recipients(params) {
          const { data } = await axios.get('/admin/opportunities/clinicians', {
            params
          })
          return data
        },
        async show(id) {
          const { data } = await axios.get(`/admin/opportunities/${id}`)
          return data
        }
      }
    })
  }
}

export default Opportunities
