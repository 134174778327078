<template>
  <div>
    <div v-if="error" class="red--text">{{ error }}</div>
    <v-sheet tile height="72" class="d-flex sticky">
      <v-btn-toggle
        v-model="toggleExclusive"
        color="primary"
        mandatory
        class="mt-auto mb-auto"
      >
        <v-btn> Demand </v-btn>
        <v-btn> Supply </v-btn>
        <v-btn> Both </v-btn>
      </v-btn-toggle>
      <v-spacer />
      <router-query-controller
        v-slot="{ updateQuery, queryValue }"
        query-name="state"
      >
        <state-selector
          :disabled="loading"
          :states="clinicianLicenses"
          :default-value="queryValue"
          @change="filters.state = updateQuery($event)"
        />
      </router-query-controller>
      <v-spacer v-if="!isClinician" />
      <clinician-selector
        v-if="!isClinician"
        :client-id="clientId"
        :consult-rate-id="consultRateId"
        :licenses="filters.state"
        :disabled="loading"
        @change="filters.clinician = $event"
      />
      <v-spacer v-if="!isRate" />
      <consult-rate-selector
        v-if="!isRate"
        :multi-client="!facetType"
        :disabled="loading"
        :client-id="isClient ? facetId : undefined"
        :clinician-id="clinicianId"
        @change="filters[$event.type] = $event.id"
      />
    </v-sheet>
    <v-calendar
      ref="calendar"
      type="category"
      :categories="daysOfWeek"
      :events="forecast"
      :weekday-format="() => ''"
      :event-color="eventColor"
      @change="fetchEvents"
    >
      <template #day-label-header>
        <div />
      </template>
      <template #event="{ event }">
        <h6 class="pl-1 font-weight-bold">{{ displayValue(event) }}</h6>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import StateSelector from '@/components/common/StateSelector.vue'
import ConsultRateSelector from '@/components/common/ConsultRateSelector.vue'
import ClinicianSelector from '@/components/common/ClinicianSelector.vue'
import RouterQueryController from '@/components/common/RouterQueryController.vue'
import { entireHour } from '@/utils/Date'

const DEMAND = 'Demand'
const SUPPLY = 'Supply'
const BOTH = 'Both'

export const CLIENT = 'client'
export const RATE = 'rate'
export const CLINICIAN = 'clinician'

export const ALLOWED_TYPES = [CLIENT, RATE, CLINICIAN]

export default {
  name: 'ForecastCalendar',
  components: {
    RouterQueryController,
    ClinicianSelector,
    ConsultRateSelector,
    StateSelector
  },
  props: {
    facetType: {
      type: String,
      default: null,
      validator: function (value) {
        return ALLOWED_TYPES.indexOf(value) !== -1
      }
    },
    facetId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      forecast: [],
      loading: false,
      error: null,
      filters: {
        state: undefined,
        [CLINICIAN]: undefined,
        [CLIENT]: undefined,
        [RATE]: undefined
      },
      daysOfWeek: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      gradient: [
        'lighten-5',
        'lighten-4',
        'lighten-3',
        'lighten-2',
        'lighten-1',
        '',
        'darken-1',
        'darken-2',
        'darken-3',
        'darken-4'
      ],
      toggleExclusive: 0,
      toggleOptions: [DEMAND, SUPPLY, BOTH]
    }
  },
  computed: {
    clinicianLicenses() {
      return this.filters.clinician?.licenses
    },
    isClient() {
      return this.facetType === CLIENT
    },
    clientId() {
      return this.isClient ? this.facetId : this.filters[CLIENT]
    },
    isRate() {
      return this.facetType === RATE
    },
    consultRateId() {
      return this.isRate ? this.facetId : this.filters[RATE]
    },
    isClinician() {
      return this.facetType === CLINICIAN
    },
    totalDemand() {
      return this.forecast.reduce((acc, forecast) => acc + forecast.demand, 0)
    },
    clinicianId() {
      return this.isClinician ? this.facetId : this.filters[CLINICIAN]?.id
    },
    maxDemand() {
      return this.forecast.reduce(
        (acc, forecast) => Math.max(acc, forecast.demand),
        0
      )
    },
    totalSupply() {
      return this.forecast.reduce((acc, forecast) => acc + forecast.supply, 0)
    },
    maxSupply() {
      return this.forecast.reduce(
        (acc, forecast) => Math.max(acc, forecast.supply),
        0
      )
    },
    start() {
      return this.$refs.calendar.lastStart
    },
    primaryFacet() {
      if (this.filters[RATE]) {
        return RATE
      } else if (this.filters[CLIENT]) {
        return CLIENT
      } else if (this.facetType) {
        return this.facetType
      } else {
        return null
      }
    }
  },
  watch: {
    facetId: function () {
      this.fetchEvents({ start: this.start })
    },
    facetType: function () {
      this.fetchEvents({ start: this.start })
    },
    filters: {
      handler: function () {
        this.fetchEvents({ start: this.start })
      },
      deep: true
    }
  },
  mounted() {
    this.$refs.calendar?.checkChange()
  },
  methods: {
    eventColor(event) {
      if (this.toggleOptions[this.toggleExclusive] === BOTH)
        return 'primary lighten-2'
      const percentile =
        this.toggleOptions[this.toggleExclusive] === DEMAND
          ? (event.demand / this.maxDemand) * 9
          : (event.supply / this.maxSupply) * 9
      return `primary ${this.gradient[Math.floor(percentile)]}`
    },
    displayValue(event) {
      if (this.toggleOptions[this.toggleExclusive] === BOTH)
        return `${event.demand} Consults, ${event.supply} Clinicians`
      return this.toggleOptions[this.toggleExclusive] === DEMAND
        ? `${event.demand} Consults`
        : `${event.supply} Clinicians`
    },
    async fetchEvents({ start }) {
      try {
        this.loading = true
        this.demand = []
        const response = await this.getForecast()
        this.forecast = response.data.map((forecast) => ({
          demand: Math.ceil(forecast.demand_forecast),
          supply: Math.ceil(forecast.supply_forecast),
          timed: true,
          category: this.daysOfWeek[forecast.day_of_week],
          ...entireHour(start.date, forecast.hour_of_day)
        }))
      } catch (e) {
        this.error = e
      } finally {
        this.loading = false
      }
    },
    getForecast() {
      switch (this.primaryFacet) {
        case CLIENT:
          return this.$clients.getForecast(
            this.filters[CLIENT] || this.facetId,
            {
              state: this.filters.state,
              clinician_id: this.filters.clinician?.id
            }
          )
        case RATE:
          return this.$consultRates.getForecast(
            this.filters[RATE] || this.facetId,
            {
              state: this.filters.state,
              clinician_id: this.filters.clinician?.id
            }
          )
        default:
          return this.$forecast.getForecast({
            state: this.filters.state,
            clinician_id: this.filters.clinician?.id
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 0;
  z-index: 5;
}

hr.v-divider {
  width: 100%;
  margin: 0;
}
</style>
