<template>
  <div class="manually-rebook">
    <v-dialog v-model="dialog" max-width="900">
      <template #activator="{ on, attrs }">
        <slot name="activator" :on="on" :attrs="attrs">
          <v-btn depressed fab small v-bind="attrs" color="primary" v-on="on">
            <v-icon color="white">compare_arrows</v-icon>
          </v-btn>
        </slot>
      </template>
      <v-card>
        <v-card-title class="headline">Manually Rebook</v-card-title>
        <v-container class="manually-reassign__modal-container">
          <v-alert type="error">
            Dangerous action! Proceed with care, the patient may have booked
            with a specific clinician.
          </v-alert>
          <v-alert
            v-for="(message, index) in alert.messages"
            :key="index"
            type="error"
          >
            {{ message }}
          </v-alert>
          <clinicians-mini-search v-model="clinician" :consult-id="consultId" />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn text rounded depressed @click="dialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            rounded
            depressed
            :disabled="!clinician"
            :loading="loading"
            @click="submit"
          >
            Rebook
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CliniciansMiniSearch from '@/components/common/CliniciansMiniSearch'

export default {
  name: 'ManuallyRebook',
  components: {
    CliniciansMiniSearch
  },
  props: {
    consultId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      clinician: null,
      loading: false,
      alert: {}
    }
  },
  mounted() {},
  methods: {
    async submit() {
      this.loading = true
      try {
        await this.$consults.rebook(this.consultId, this.clinician.id)
        this.$emit('rebooked', this.clinician)
      } catch (e) {
        if (e.response) {
          this.alert = e.response.data
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.theme--light .manually-reassign__modal-container {
  .v-icon {
    color: inherit;
  }
}
</style>
