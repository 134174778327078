<template>
  <div>
    <profile-field v-model="fieldValue" :edit-mode="edit">
      <template #default="attrs">
        <v-text-field
          class="profile-text"
          :label="labelText"
          v-bind="attrs"
          :counter="limit"
          :rules="baseRules"
          :placeholder="placeholder"
          :name="fieldName"
          autofocus
        />
      </template>
      <template #view>
        <data-row
          :label="labelText"
          :value="displayValue"
          :value-class="isPlaceholder ? 'placeholder' : ''"
        />
      </template>
    </profile-field>
  </div>
</template>

<script>
import ProfileField from './ProfileField.vue'
import DataRow from '@/components/common/DataRow.vue'

export default {
  name: 'ProfileTextField',
  components: {
    ProfileField,
    DataRow
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    edit: {
      type: Boolean
    },
    limit: {
      type: Number,
      default: 50
    },
    fieldName: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: function () {
        return []
      }
    },
    placeholder: {
      type: String,
      default: 'Enter text'
    },
    notEmpty: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      baseRules: [
        (v) => {
          const value = String(v)
          return (
            !value ||
            value.length <= this.limit ||
            `Max ${this.limit} characters`
          )
        },
        ...(this.notEmpty
          ? [(v) => !!String(v)?.length || 'Must not be empty']
          : []),
        ...this.rules
      ]
    }
  },
  computed: {
    fieldValue() {
      return this.value
    },
    displayValue() {
      return this.value || this.placeholder
    },
    isPlaceholder() {
      return !(this.value && this.value.length)
    },
    labelText() {
      return this.label ? this.label : this.fieldName.replaceAll('_', ' ')
    }
  }
}
</script>
