<template>
  <v-dialog
    :value="value"
    :width="width"
    :max-width="maxWidth"
    :fullscreen="$vuetify.breakpoint.xs"
    @keydown.esc="close"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="h4 pt-sm-10 px-sm-10 px-4 d-block">
        <slot name="title" />
      </v-card-title>
      <v-card-text class="px-sm-10 px-4">
        <slot name="content" />
      </v-card-text>
      <v-card-actions
        class="flex-column-reverse flex-sm-row justify-end px-sm-10 pb-sm-10 px-4"
      >
        <div
          v-if="error"
          class="error-status justify-self-start mt-4 mt-sm-0 mr-sm-8"
        >
          {{ error }}
        </div>
        <v-btn
          color="primary"
          class="px-4"
          text
          rounded
          :loading="declining"
          :disabled="working || disableSecondary"
          @click="onDecline"
        >
          <slot name="secondary"> Cancel </slot>
        </v-btn>
        <v-btn
          color="primary"
          class="mb-4 ml-0 mb-sm-0 ml-sm-4 px-4"
          rounded
          large
          :loading="confirming"
          :disabled="working || disablePrimary"
          @click="onConfirm"
        >
          <slot name="primary"> Confirm </slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    value: Boolean,
    disablePrimary: Boolean,
    disableSecondary: Boolean,
    confirm: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    width: {
      type: [String, Number],
      default: 550
    },
    maxWidth: {
      type: [String, Number],
      default: 800
    }
  },
  data() {
    return {
      error: '',
      working: false,
      confirming: false,
      declining: false
    }
  },
  methods: {
    async onDecline() {
      try {
        this.error = ''
        this.working = true
        this.declining = true
        await this.close()
      } catch (error) {
        this.$dd.addError(error)
        this.error = error.message
      } finally {
        this.declining = false
        this.working = false
      }
    },
    async onConfirm() {
      try {
        this.error = ''
        this.working = true
        this.confirming = true
        await this.confirm()
      } catch (error) {
        this.$dd.addError(error)
        this.error = error.message
      } finally {
        this.confirming = false
        this.working = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error-status {
  color: #ef6461;
}
</style>
