<template>
  <v-card>
    <v-card-title class="headline"> Create new activity rate </v-card-title>
    <v-card-text class="alert">
      <v-alert color="red" dense outlined text icon="mdi-alert-circle-outline">
        Confirmation with Engineering, Payments, and Legal is required before
        creating a new activity rate
      </v-alert>
    </v-card-text>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-autocomplete
          v-model="createInputs.activity_type"
          label="Select a Client Activity Type"
          :items="filteredActivityRates"
          item-text="display"
          item-value="value"
          :rules="[rules.required]"
          clearable
          hint="Select which activity type to create this activity rate for.  No duplicate activities can be created on the same consult rate."
          persistent-hint
        />
        <v-text-field
          v-model="createInputs.cost_to_client"
          label="Cost to Client"
          :rules="[rules.required]"
          clearable
          prefix="$"
          hint="Additive modifcation to the base consult rate Cost to Client value, can be positive or negative"
          persistent-hint
        />
        <v-text-field
          v-model="createInputs.paid_to_provider"
          label="Paid to Provider"
          :rules="[rules.required]"
          clearable
          prefix="$"
          hint="Additive modification to the base consult rate Paid to Provider value, can be positive or negative"
          persistent-hint
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <span v-if="modalError" class="modal-error">{{ modalError }}</span>
      <v-spacer />
      <v-btn text @click="close"> Cancel </v-btn>
      <v-btn
        color="primary"
        rounded
        :loading="saving"
        :disabled="!valid"
        @click="handleConfirm"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Modal from '@/mixins/Modal'

export default {
  name: 'ActivityRateCreateModal',
  mixins: [Modal],
  props: {
    consultRateId: {
      type: String,
      required: true
    },
    activityRates: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      error: 'Unable to create activity rate. Please try again.',
      rules: {
        required: (v) => !!v || 'This field is required!'
      },
      activityRateOptions: [
        { value: 'chat_sent', display: 'Chat Sent' },
        { value: 'chat_received', display: 'Chat Received' },
        { value: 'sync_attempt_failed', display: 'Sync Attempt Failed' },
        { value: 'sync_attempt_success', display: 'Sync Attempt Success' },
        { value: 'provider_snooze', display: 'Provider Snooze' },
        {
          value: 'identification_resubmitted',
          display: 'Identification Resubmitted'
        },
        { value: 'patient_no_show', display: 'Patient No Show' },
        { value: 'ready_for_sync_attempt', display: 'Ready for Sync Attempt' },
        { value: 'sync_attempt', display: 'Sync Attempt' },
        {
          value: 'clinician_flagged_issue',
          display: 'Clinician Flagged Issue'
        },
        {
          value: 'clinician_flagged_technical_issue',
          display: 'Clinician Flagged Technical Issue'
        },
        { value: 'billable_work_complete', display: 'Billable Work Complete' }
      ],
      createInputs: {},
      results: {},
      valid: false,
      activityNames: []
    }
  },
  computed: {
    existingActivityTypes() {
      return this.activityRates.map((activity) => activity.activity_type)
    },
    filteredActivityRates() {
      return this.activityRateOptions.filter(
        (opt) => !this.existingActivityTypes.includes(opt.value)
      )
    }
  },
  methods: {
    async action() {
      this.results = await this.$consultRates.createActivityRate(
        this.consultRateId,
        this.createInputs
      )
      this.$router.push({ path: `/activity-rates/${this.results.id}/details` })
      this.$store.commit('SET_SNACKBAR', {
        message: 'Success! Activity rate was created.',
        show: true
      })
      return this.results
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  margin-top: 32px;
}

.modal-error {
  color: #bf211e;
}
</style>
