import axios from 'axios'
import { deserialize } from 'deserialize-json-api'

const StateCollabRequirements = {
  install(Vue) {
    Vue.prototype.$stateCollabRequirements = new Vue({
      methods: {
        async getStateCollabRequirements(state) {
          const { data } = await axios.get(
            `admin/collabs/state-collab-requirements/${state}`
          )
          return deserialize(data)
        },
        async getAllStateCollabRequirements() {
          const { data } = await axios.get(
            `admin/collabs/state-collab-requirements/`
          )
          return deserialize(data)
        },
        async updateStateCollabRequirement(uuid, payload) {
          const { data } = await axios.patch(
            `admin/collabs/state-collab-requirements/${uuid}`,
            payload
          )
          return deserialize(data)
        },
        async validateStateCollabRequirement(uuid, payload) {
          const { data } = await axios.patch(
            `admin/collabs/state-collab-requirements/${uuid}/validate`,
            payload
          )
          return data
        },
        async getStateCollabRequirementHistory(params) {
          const { data } = await axios.get(
            'admin/collabs/state-collab-requirements/history',
            {
              params: params
            }
          )
          return data
        },
        async validateCollabsForStateRequirement(uuid) {
          const { data } = await axios.get(
            `admin/collabs/state-collab-requirements/${uuid}/validate-collabs`
          )
          return data
        }
      }
    })
  }
}

export default StateCollabRequirements
