export const METRIC_TYPES = {
  SCHEDULED_SYNC_ATTENDANCE_RATE: 'scheduled_sync_attendance_rate',
  SCHEDULED_SYNC_PUNCTUALITY_RATE: 'scheduled_sync_punctuality_rate',
  ACCEPTANCE_RATE: 'acceptance_rate',
  SCHEDULED_SHIFT_HOURS: 'scheduled_shift_hours'
}

export const METRIC_COUNT_UNIT_TYPES = {
  WEEK: 'week',
  CONSULT: 'consult'
}

export const METRIC_VALUE_UNIT_TYPES = {
  HOURS: 'hours',
  PERCENT: 'percent'
}

export function getPerformanceType(type, longTermValue) {
  if (type === METRIC_TYPES.SCHEDULED_SYNC_ATTENDANCE_RATE)
    return scheduleSyncAttendanceRatePerformance(longTermValue)
  if (type === METRIC_TYPES.SCHEDULED_SYNC_PUNCTUALITY_RATE)
    return scheduledSyncOnTimeStartRatePerformance(longTermValue)
  if (type === METRIC_TYPES.ACCEPTANCE_RATE)
    return acceptanceRatePerformance(longTermValue)
  return scheduledShiftHourPerformance(longTermValue)
}

function acceptanceRatePerformance(value) {
  if (value > 0.98) {
    return 'top-performer'
  } else if (value > 0.9) {
    return 'great'
  } else if (value > 0.8) {
    return 'needs-improvement'
  } else {
    return 'poor'
  }
}

function scheduleSyncAttendanceRatePerformance(value) {
  if (value > 0.98) {
    return 'top-performer'
  } else if (value > 0.95) {
    return 'great'
  } else if (value > 0.8) {
    return 'needs-improvement'
  } else {
    return 'poor'
  }
}

function scheduledSyncOnTimeStartRatePerformance(value) {
  if (value > 0.98) {
    return 'top-performer'
  } else if (value > 0.95) {
    return 'great'
  } else if (value > 0.8) {
    return 'needs-improvement'
  } else {
    return 'poor'
  }
}

function scheduledShiftHourPerformance(value) {
  if (value > 30) {
    return 'top-performer'
  } else if (value > 15) {
    return 'great'
  } else {
    return 'poor'
  }
}

export function formatValue(value, unit) {
  if (unit === METRIC_COUNT_UNIT_TYPES.WEEK) {
    if (value <= 1) return `${value} hour`
    return `${value} hours`
  }
  return `${Math.round(value * 100)}%`
}

export function formatDurationText(unit, count) {
  if (unit === METRIC_COUNT_UNIT_TYPES.CONSULT) {
    return `Last ${count} consults`
  }
  return formatWeeks(count)
}

export function formatWeeks(count) {
  switch (count) {
    case 0:
    case 1:
      return 'this week'
    case -1:
      return 'last week'
    default:
      return count > 1
        ? `the next ${count} weeks`
        : `the last ${Math.abs(count)} weeks`
  }
}

export function formatCumulativeRate(metricData) {
  return metricData.value_unit === METRIC_VALUE_UNIT_TYPES.PERCENT
    ? `${Number((metricData.long_term_value * 100).toFixed(2))}%`
    : `${metricData.long_term_value} ${metricData.value_unit}`
}

export function formatCumulativeText(metricData) {
  return `last ${metricData.long_term_count} ${metricData.count_unit}s`
}
