<template>
  <v-card>
    <v-card-title class="headline ml-n5 pb-8"> {{ title }} </v-card-title>
    <v-card-text>
      <v-alert
        dense
        outlined
        icon="mdi-alert-circle-outline"
        class="text-color--orange-900 warning-alert ml-n5"
      >
        Confirmation with Ops, Legal, and Payments is required before editing a
        state’s requirements!
      </v-alert>
    </v-card-text>

    <v-card-text class="pa-0 subtitle-1">
      <p class="text-lg-body-1 mb-0">
        Are you sure you want to
        <span class="font-weight-bold">remove</span> the collab requirement for
        <span class="font-weight-bold">{{ stateName }}</span
        >?
      </p>
      <p class="pt-6 mb-0">The following active collabs will be removed:</p>
      <div v-for="id in collabIds" :key="id" class="pa-0 ma-0">
        <span>
          <router-link
            :to="`/collabs/agreements/${id}`"
            class="meta flex-shrink-0 text-color--wheel-green"
            target="_blank"
          >
            <label>Collab {{ id }}</label>
          </router-link>
          <v-icon class="text-color--wheel-green remove-text-decoration pl-1">
            mdi-arrow-top-right
          </v-icon>
        </span>
      </div>
    </v-card-text>
    <v-card-actions>
      <span v-if="modalError" class="modal-error">{{ modalError }}</span>
      <v-spacer />
      <v-btn text @click="close"> Cancel </v-btn>
      <v-btn color="primary" rounded :loading="saving" @click="handleConfirm">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Modal from '@/mixins/Modal'

export default {
  name: 'StateCollabNotRequiredWarningModal',
  mixins: [Modal],
  props: {
    stateName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    collabIds: {
      type: Array,
      required: true
    }
  },
  methods: {
    async action() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.warning-alert {
  background-color: $orange-50 !important;
  border-color: $orange-600 !important;
}
</style>
