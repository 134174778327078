var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasCollabs)?_c('div',{staticClass:"clinician-collab-agreements mt-8",attrs:{"loading":_vm.loadingData}},[_c('profile-form',{staticClass:"clinician-info-form pl-4 mr-n4",attrs:{"action-function":_vm.updateClinician,"hide-clear":""},scopedSlots:_vm._u([{key:"default",fn:function({ editMode, loading }){return [_c('h6',[_vm._v("Hour Agreement")]),_c('profile-field',_vm._b({key:'hour_agreement',attrs:{"edit-mode":editMode},scopedSlots:_vm._u([{key:"default",fn:function(attrs){return [_c('v-text-field',_vm._b({attrs:{"label":'Minimum weekly hours',"rules":[_vm.rules.hourAgreement],"outlined":"","name":'hour_agreement',"loading":loading,"type":'hour_agreement'}},'v-text-field',attrs,false))]}},{key:"view",fn:function(){return [_c('div',{staticClass:"my-4 static-field-view"},[_c('data-row',{attrs:{"label":"Minimum weekly hours","value":_vm.clinicianLocal.hour_agreement}})],1)]},proxy:true}],null,true),model:{value:(_vm.clinicianLocal.hour_agreement),callback:function ($$v) {_vm.$set(_vm.clinicianLocal, "hour_agreement", $$v)},expression:"clinicianLocal.hour_agreement"}},'profile-field',{ editMode, loading },false))]}}],null,false,4291731816)}),_c('div',{staticClass:"d-flex flex-no-wrap align-center justify-space-between"},[_c('v-expansion-panels',{attrs:{"accordion":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.collabAgreements),function(agreement,state){return _c('v-expansion-panel',{key:state},[_c('v-expansion-panel-header',{staticClass:"h6 mt-4 mb-4"},[_vm._v(" "+_vm._s(_vm.states[state.toUpperCase()].name)+" ("+_vm._s(state.toUpperCase())+") "),(
              agreement.maxSupervisionValues.reachedMaxSupervisions &&
              _vm.clinician.practitioner_type !== 'np'
            )?_c('v-chip',{staticClass:"ml-6 error-view"},[_vm._v(" Maximum Supervisions ")]):_vm._e(),(
              !agreement.maxSupervisionValues.reachedMaxSupervisions &&
              agreement.maxSupervisionValues
                .reachedMaxPrescribingSupervisions &&
              _vm.clinician.practitioner_type !== 'np'
            )?_c('v-chip',{staticClass:"ml-6 error-view"},[_vm._v(" Maximum Prescribing Supervisions ")]):_vm._e(),(
              !agreement.maxSupervisionValues.reachedMaxSupervisions &&
              agreement.maxSupervisionValues.reachedMaxNonSupervisions &&
              _vm.clinician.practitioner_type !== 'np'
            )?_c('v-chip',{staticClass:"ml-6 error-view"},[_vm._v(" Maximum Non-Prescribing Supervisions ")]):_vm._e()],1),_c('v-expansion-panel-content',[_c('navigating-data-table',{staticClass:"mt-4",attrs:{"navigate":"","headers":_vm.headers,"items":agreement.collabs,"footer-props":_vm.footer,"disable-sort":""},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [_c('router-link',{staticClass:"mr-2 meta flex-shrink-0 text-color--wheel-green",attrs:{"to":`/collabs/agreements/${item.id}`}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.status",fn:function({ item }){return [(
                  item.review_status === 'Needs Review' &&
                  item.status !== 'Collab Removed'
                )?_c('div',{staticClass:"status-container"},[_c('v-tooltip',{attrs:{"color":"#141419","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#BF211E","dark":""}},'v-icon',attrs,false),on),[_vm._v(" warning ")])],1)]}}],null,true)},[_c('span',[_vm._v("Collab will be set to")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.getToolTipText(item)))])]),_c('span',{staticClass:"ml-2"},[_vm._v("Needs Review")])],1):_c('span',[_vm._v(_vm._s(item.status))])]}},{key:"item.external",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.collabCategoryDisplayText(item.external)))])]}},{key:"item.supervising_clinician",fn:function({ item }){return [(item.supervising_clinician)?_c('td',{class:{
                  hideHeader: _vm.clinician.practitioner_type !== 'np'
                }},[(!item.external)?_c('router-link',{staticClass:"mr-2 meta flex-shrink-0 text-color--wheel-green",attrs:{"to":`/clinicians/${item.supervising_clinician.uuid}/collab-agreements`}},[_vm._v(" "+_vm._s(item.supervising_clinician.name)+" ")]):_c('span',[_vm._v(_vm._s(item.supervising_clinician.name))])],1):_c('td',{class:{
                  hideHeader: _vm.clinician.practitioner_type !== 'np'
                }},[_c('span',[_vm._v("-")])])]}},{key:"item.supervised_clinician",fn:function({ item }){return [_c('td',{class:{
                  hideHeader: _vm.clinician.practitioner_type === 'np'
                }},[_c('router-link',{staticClass:"mr-2 meta flex-shrink-0 text-color--wheel-green",attrs:{"to":`/clinicians/${item.supervised_clinician.uuid}/collab-agreements`}},[_vm._v(" "+_vm._s(item.supervised_clinician.name)+" ")])],1)]}},{key:"item.supervising_clinician_payment",fn:function({ item }){return [_c('td',{class:{
                  hideHeader: _vm.clinician.practitioner_type === 'np'
                }},[(!item.external)?_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.supervising_clinician_payment)))]):_c('span',[_vm._v(" - ")])])]}},{key:"item.max_payback_amount",fn:function({ item }){return [_c('td',{class:{
                  hideHeader: _vm.clinician.practitioner_type !== 'np'
                }},[(!item.external)?_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.state_requirement.max_payback_amount)))]):_c('span',[_vm._v(" - ")])])]}},{key:"item.payback_model",fn:function({ item }){return [(!item.external)?_c('span',[_vm._v(" "+_vm._s(item.payback_model)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.collab_type",fn:function({ item }){return [(!item.external)?_c('span',[_vm._v(" "+_vm._s(item.collab_type)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.start_date",fn:function({ item }){return [(item.start_date)?_c('span',[_vm._v(" "+_vm._s(_vm.collabStartDate(item.start_date))+" ")]):_vm._e()]}}],null,true)})],1)],1)}),1)],1)],1):_c('div',[_vm._v("No collab agreements yet.")])
}
var staticRenderFns = []

export { render, staticRenderFns }