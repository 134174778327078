const COVERAGE_TYPE = {
  OPERATING_HOURS: {
    value: false,
    display: 'Operating Hours'
  },
  COVERAGE_BLOCKS: {
    value: true,
    display: 'Coverage Blocks'
  }
}

const HAS_OPERATING_HOURS = {
  CLOSED: 'None'
}

const OPERATING_HOUR_TYPE = {
  DAYTIME: 'Daytime Hours',
  FULL_COVERAGE: '24/7 Coverage',
  CUSTOM: 'Custom'
}

export default {
  COVERAGE_TYPE,
  OPERATING_HOUR_TYPE,
  HAS_OPERATING_HOURS
}
