<template>
  <v-dialog v-model="dialog" max-width="900" content-class="client-dialog">
    <template #activator="{ on, attrs }">
      <v-btn depressed v-bind="attrs" rounded color="primary" v-on="on">
        Stack Shifts on Client
      </v-btn>
    </template>
    <v-card>
      <h4 class="dialog-title">Stack Shifts on Client</h4>
      <p class="note">
        Note: Shifts not needed to meet a client's coverage needs will not be
        created.
      </p>
      <h6>Copy all future shifts from</h6>
      <v-autocomplete
        v-model="copyClientName"
        label="Client"
        :items="clinicianClients.map((client) => client.name)"
        item-text="display"
        item-value="value"
        clearable
      />
      <v-autocomplete
        v-model="copyShiftType"
        label="Type"
        :items="shiftTypeDisplayNames"
        item-text="display"
        item-value="value"
        clearable
      />
      <h6>Create shifts for</h6>
      <v-autocomplete
        v-model="createClientName"
        label="Client"
        :items="clinicianClients.map((client) => client.name)"
        item-text="display"
        item-value="value"
        clearable
      />
      <v-autocomplete
        v-model="createShiftType"
        label="Type"
        :items="shiftTypeDisplayNames"
        item-text="display"
        item-value="value"
        clearable
      />
      <v-alert v-if="error" text type="error">
        {{ error }}
      </v-alert>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="close"> Cancel </v-btn>
        <v-btn
          color="primary"
          rounded
          :disabled="
            !(
              copyClientName &&
              copyShiftType &&
              createClientName &&
              createShiftType
            )
          "
          @click="sendStackReqAndClose"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ClinicianScheduleStackShifts',
  props: {
    clinicianClients: {
      type: Array,
      required: true
    },
    clinicianOnDemandId: {
      type: Number,
      required: true
    }
  },
  data: () => {
    return {
      dialog: false,
      copyClientName: null,
      copyShiftType: null,
      createClientName: null,
      createShiftType: null,
      shiftTypeDisplayNames: ['Standard', 'On-Call'],
      shiftTypeToOnDemandEnum: {
        Standard: 'standard',
        'On-Call': 'on_call'
      },
      error: null
    }
  },
  methods: {
    async sendStackReqAndClose() {
      try {
        await this.$clinicians.copyClinicianClientShifts(
          this.clinicianOnDemandId,
          {
            create_client_id: this.clientNameToId(this.createClientName),
            create_shift_type:
              this.shiftTypeToOnDemandEnum[this.createShiftType],
            copy_client_id: this.clientNameToId(this.copyClientName),
            copy_shift_type: this.shiftTypeToOnDemandEnum[this.copyShiftType]
          }
        )
        this.close()
      } catch (e) {
        this.error = e.message
      }
    },
    close() {
      this.dialog = false
      this.copyClientName = null
      this.copyShiftType = null
      this.createClientName = null
      this.createShiftType = null
      this.error = null
    },
    clientNameToId(clientName) {
      return this.clinicianClients.find((client) => client.name === clientName)
        .id
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-title {
  margin-bottom: 1rem;
}

.note {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
