<template>
  <profile-form
    hide-clear
    :action-function="actionFunction"
    class="clinician-cohorts__wrapper ml-n4 pl-4 mr-n4"
    @submit="onSubmit"
  >
    <template #default="{ editMode }">
      <h6>Admin Information</h6>
      <data-row
        label="On Demand Sync Availability per Hour"
        :value="getDuration(clinicianLocal.on_demand_availability_in_seconds)"
      />
      <profile-autocomplete
        v-model="clinicianLocal.cohorts"
        field-name="cohorts"
        :items="getCohortOptions"
        :edit-mode="editMode"
      >
        <template #view>
          <data-row label="Cohorts">
            <div v-if="clinician.cohorts && clinician.cohorts.length">
              <ul class="cohort-list">
                <li v-for="cohort in clinician.cohorts" :key="cohort">
                  {{ cohort }}
                </li>
              </ul>
            </div>
            <div v-else class="ml-3 grey--text">Add tags to clinician</div>
          </data-row>
        </template>
      </profile-autocomplete>
    </template>
  </profile-form>
</template>

<script>
import ProfileAutocomplete from '@/components/clinicians/profile/ProfileAutocomplete.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import DataRow from '@/components/common/DataRow.vue'
import { Duration } from '@/filters/Moment'

export default {
  name: 'ClinicianCohorts',
  components: {
    DataRow,
    ProfileForm,
    ProfileAutocomplete
  },
  props: {
    clinician: {
      type: Object,
      required: true
    },
    actionFunction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    clinicianLocal: {
      get: function () {
        return this.clinician
      },
      set: function (value) {
        return this.$emit('update:clinician', value)
      }
    }
  },
  methods: {
    Duration,
    getDuration() {
      return this.clinicianLocal.on_demand_availability_in_seconds === 0
        ? 0
        : Duration(this.clinicianLocal.on_demand_availability_in_seconds)
    },
    getCohortOptions() {
      return this.$clinicians.getFieldValues('cohorts')
    },
    onSubmit(event) {
      if (!event.cohorts) {
        event.cohorts = []
      }
      return this.$emit('submit', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.cohort-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;

  & > li:not(:last-child)::after {
    content: ', ';
    margin-right: 4px;
  }
}
.clinician-cohorts {
  &__wrapper {
    width: calc(100% + size(32));
  }
  &__header {
    display: inline-flex;
  }
  &__list {
    margin-left: 12px;
    padding-right: 16px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
    text-overflow: ellipsis;
    & span:not(:last-child)::after {
      content: ', ';
    }
  }
}
</style>
