const GridSearch = {
  data() {
    return {
      error: '',
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 20
      },
      search: undefined,
      total: 0
    }
  },
  watch: {
    $route: 'fetchData'
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      this.error = ''
      try {
        this.parseQueryString()
        const { meta } = await this.loadData()
        this.total = meta?.total || 0
      } catch (e) {
        this.error = e.message
      } finally {
        this.loading = false
      }
    },
    pageChanged(page) {
      const query = this.$route.query

      // Avoids redundant page change for datagrid
      if (Number(query.page) !== page) {
        this.update({
          ...query,
          page
        })
      }
    },
    parseQueryString() {
      const { page = 1, search } = this.$route.query

      this.options.page = page ? Number(page) : 1
      this.search = search
    },
    searchRecords(search) {
      this.update({
        ...this.$route.query,
        page: 1,
        search: search || undefined
      })
    },
    update(query) {
      this.$router.push({
        name: this.name,
        query
      })
    }
  }
}

export default GridSearch
