<template>
  <div class="routing-report__content">
    <v-skeleton-loader v-if="!loaded" type="article" />
    <div v-else-if="error">
      {{ error }}
    </div>
    <div v-else>
      <data-row label="Time" :value="data.created_at | format('LTS')" />
      <data-row v-if="data.clinician" label="Clinician">
        <router-link :to="`/clinicians/${data.clinician.id}`">
          {{ data.clinician.name }}
        </router-link>
      </data-row>
      <data-row v-if="data.consult" label="Consult">
        <router-link :to="`/consults/${data.consult.id}`">
          #{{ data.consult.id }}
        </router-link>
      </data-row>
      <data-row v-if="data.client" label="Client">
        <router-link :to="`/clients/${data.client.id}`">
          {{ data.client.name }}
        </router-link>
      </data-row>
      <data-row v-if="data.consult_rate" label="Consult Rate">
        <router-link :to="`/consult-rates/${data.consult_rate.id}`">
          {{ data.consult_rate.display_name }}
        </router-link>
      </data-row>
      <div class="routing-report__metadata mt-10">
        <routing-report-segment
          v-if="metadata.selected"
          :segment="metadata.selected"
          :subject-type="subjectType"
          :name="`Selected - ${data.version}`"
        />
        <routing-report-segment
          v-if="metadata.eligible"
          :segment="metadata.eligible"
          :subject-type="subjectType"
          name="Eligible"
        />
        <routing-report-segment
          v-for="(segment, name) in metadata.ineligible"
          :key="name"
          :segment="segment"
          :subject-type="subjectType"
          :name="`Ineligible - ${name}`"
        />
        <routing-report-strategies
          v-if="strategies"
          :strategies="strategies"
          :subject-type="subjectType"
        />
        <p v-if="Object.keys(metadata).length === 0">
          No metadata exists because no assignable {{ subjectType }} were found
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow'
import RoutingReportSegment from '@/components/routing-reports/RoutingReportSegment.vue'
import RoutingReportStrategies from '@/components/routing-reports/RoutingReportStrategies.vue'

import { Format } from '@/filters/Moment'

export default {
  name: 'RoutingReport',
  components: {
    DataRow,
    RoutingReportSegment,
    RoutingReportStrategies
  },
  filters: {
    Format
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      metadata: {},
      strategies: {},
      error: ''
    }
  },
  computed: {
    loaded() {
      return this.data?.id || this.error.length
    },
    subjectType() {
      if (this.data.metadata.consults) {
        return 'consult'
      } else if (this.data.metadata.clinicians) {
        return 'clinician'
      }
      return null
    }
  },
  watch: {
    id() {
      this.data = {}
      this.fetch()
    }
  },
  created() {
    this.data = {}
    this.fetch()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async fetch() {
      try {
        this.error = ''

        this.data = await this.$routingReports.getById(this.id)
        this.metadata =
          this.data.metadata.clinicians || this.data.metadata.consults
        this.strategies = this.data.metadata.strategies
      } catch (error) {
        if (String(error.response.status) === '404') {
          this.error = 'Report unable to render. Format is obsolete.'
          return
        }
        this.error = error.message || error.toString()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.routing-report {
  &__content {
    padding: size(48) 0;
  }

  &__metadata {
    background-color: #f6f6f6;
    border-radius: size(8);
    padding: size(40);
    width: 100%;
  }
}
</style>
