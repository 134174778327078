<template>
  <filters
    :current-filters="filters"
    :filter-options="filterOptions"
    @apply="applyFilters"
    @reset="resetFilters"
  />
</template>

<script>
import Filters from '@/components/common/Filters.vue'
import { UNITED_STATES_ARRAY } from '@/utils/UnitedStates'
import { clientHelpers as clients } from '@/store/clients/index'

export default {
  name: 'ConsultFilters',
  components: {
    Filters
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      filterOptions: {
        status: {
          options: [],
          name: 'consult_status'
        },
        client: {
          options: [],
          name: 'clients'
        },
        modality: {
          options: [],
          name: 'modality'
        },
        state: {
          options: UNITED_STATES_ARRAY,
          name: 'states'
        }
      }
    }
  },
  computed: {
    clients: clients.mappedGetters.clients
  },
  created() {
    this.getClients().then(() => {
      this.formatClientsFilter()
    })
    this.getModalities()
    this.getConsultStatuses()
  },
  methods: {
    applyFilters(filters) {
      this.$emit('apply', filters)
    },
    resetFilters(filters) {
      this.$emit('reset', filters)
    },
    getClients: clients.mappedActions.index,
    async getModalities() {
      try {
        const response = await this.$consultRates.getConsultRateModalities()
        const modalities = response.data
        this.filterOptions.modality.options = modalities.map(
          ({ id, name }) => ({ value: id, display: name })
        )
      } catch (e) {
        this.$dd.addError(e)
      }
    },
    async getConsultStatuses() {
      try {
        const response = await this.$consults.fetchConsultStatuses()
        this.filterOptions.status.options = response.statuses.map((value) => ({
          value,
          display: value
        }))
      } catch (e) {
        this.$dd.addError(e)
      }
    },
    formatClientsFilter() {
      this.filterOptions.client.options = this.clients.data.map(
        ({ id, name }) => ({ value: id, display: name })
      )
    }
  }
}
</script>
