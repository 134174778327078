<template>
  <v-icon v-if="icon && value" @click="copy">mdi-content-copy</v-icon>
  <span v-else class="text-lowercase" @click="copy">{{ value }}</span>
</template>

<script>
export default {
  name: 'Copy',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    icon: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    async copy(e) {
      try {
        await navigator.clipboard.writeText(this.value)
        this.$store.commit('SET_SNACKBAR', {
          message: `'${this.value}' successfully copied to clipboard`,
          show: true
        })
      } catch (error) {
        this.$store.commit('SET_SNACKBAR', {
          message: error.message,
          show: true
        })
      }

      // The icon was styaing focused so manually blur
      e.target.blur()
    }
  }
}
</script>
