<template>
  <div v-if="!editMode">
    <data-row
      v-for="property in consultRateDetails"
      :key="property.label"
      :label="property.label"
      :value="property.value"
    />
  </div>
  <div v-else>
    <data-row label="Id" :value="consultRate.id" />
    <data-row label="Uuid" :value="consultRate.uuid" />
    <data-row label="Client" :value="consultRate.client.name" />
    <data-row label="Modality" :value="consultRate.modality" />
    <data-row
      v-if="!notInstant"
      label="Consult Type"
      :value="consultRate.consult_type"
    />
    <v-form ref="form" v-model="valid">
      <div>
        <v-text-field
          label="Consult Rate Name"
          :value="displayValue('name')"
          outlined
          :rules="[rules.name]"
          clearable
          @input="updateInput('name', $event)"
        />
        <v-select
          label="Practitioner Types"
          :value="displayValue('practitioner_types')"
          outlined
          chips
          multiple
          :items="practitionerTypesOptions"
          hint="Determines what practitioner types can enroll in this consult rate."
          persistent-hint
          @input="updateInput('practitioner_types', $event)"
        />
        <v-text-field
          label="Cost to Client"
          :value="displayValue('cost_to_client')"
          outlined
          :rules="[
            requiredField,
            twoDecimalsAllowed,
            positiveValue(displayValue('cost_to_client'), 'Cost to client')
          ]"
          clearable
          prefix="$"
          hint="Determines the base cost to clients per consult once completed."
          persistent-hint
          @input="updateInput('cost_to_client', $event)"
        />
        <v-text-field
          label="Paid to Provider"
          :value="displayValue('paid_to_provider')"
          outlined
          :rules="[
            positiveValue(displayValue('paid_to_provider'), 'Paid to provider')
          ]"
          clearable
          prefix="$"
          hint="Determines the base pay per clinician per consult once completed."
          persistent-hint
          @input="updateInput('paid_to_provider', $event)"
        />
        <v-text-field
          label="SLA (seconds)"
          :value="displayValue('sla_in_seconds')"
          outlined
          :rules="[
            slaLength(displayValue('sla_in_seconds'), consultRate.modality)
          ]"
          clearable
          hint="Determines the SLA given per consult."
          persistent-hint
          @input="updateInput('sla_in_seconds', $event)"
        />
        <v-text-field
          label="Assignment Lifetime (seconds)"
          :value="displayValue('assignment_lifetime')"
          outlined
          :rules="[rules.assignment_lifetime]"
          clearable
          hint="The time allowed for assignment."
          persistent-hint
          @input="updateInput('assignment_lifetime', $event)"
        />
        <v-text-field
          v-if="notInstant"
          label="Estimated Duration (seconds)"
          :value="displayValue('estimated_duration_in_seconds')"
          outlined
          :rules="[rules.estimated_duration]"
          clearable
          hint="Determines the length of a consult. Will impact current and future bookings if changed"
          persistent-hint
          @input="updateInput('estimated_duration_in_seconds', $event)"
        />
        <v-text-field
          v-if="notAsyncInstant"
          label="Advance Notice (seconds)"
          :value="displayValue('advance_notice_in_seconds')"
          outlined
          :rules="[
            positiveValue(
              displayValue('advance_notice_in_seconds'),
              'Advance notice'
            ),
            noDecimalsAllowed
          ]"
          clearable
          hint="Determines the advance notice for a consult"
          persistent-hint
          @input="updateInput('advance_notice_in_seconds', $event)"
        />
        <v-text-field
          v-if="notAsyncInstant"
          label="Buffer Before Duration (seconds)"
          :value="displayValue('buffer_before_duration_in_seconds')"
          outlined
          :rules="[
            positiveValue(
              displayValue('buffer_before_duration_in_seconds'),
              'Buffer before duration'
            )
          ]"
          clearable
          hint="Affects the amount of time before a scheduled sync. Can also impact clinician shifts"
          persistent-hint
          @input="updateInput('buffer_before_duration_in_seconds', $event)"
        />
        <v-text-field
          v-if="notAsyncInstant"
          label="Buffer After Duration (seconds)"
          :value="displayValue('buffer_after_duration_in_seconds')"
          outlined
          :rules="[
            positiveValue(
              displayValue('buffer_after_duration_in_seconds'),
              'Buffer after duration'
            )
          ]"
          clearable
          hint="Affects the amount of time before a scheduled sync. Can also impact clinician shifts"
          persistent-hint
          @input="updateInput('buffer_after_duration_in_seconds', $event)"
        />
        <v-text-field
          v-if="notAsyncInstant"
          label="Patient No Show Wait Time (seconds)"
          :value="displayValue('patient_no_show_wait_in_seconds')"
          outlined
          :rules="[rules.patient_no_show]"
          clearable
          hint="Used to calculate at what time the patient no show option is shown"
          persistent-hint
          @input="updateInput('patient_no_show_wait_in_seconds', $event)"
        />
        <v-autocomplete
          v-if="notInstant"
          label="Consult Type"
          :value="displayValue('consult_type')"
          :items="typeOptions"
          item-text="display"
          item-value="value"
          outlined
          clearable
          hint="Consult Type: Determines the workflow of the consult."
          persistent-hint
          @input="updateInput('consult_type', $event)"
        />
        <v-autocomplete
          label="Compensation Unit"
          :value="displayValue('compensation_unit')"
          :items="compensationOptions"
          item-text="display"
          item-value="value"
          outlined
          clearable
          hint="Hourly compensation requires the Payments team to manually process each consult with this type."
          persistent-hint
          @input="updateInput('compensation_unit', $event)"
        />
        <v-text-field
          label="Autocomplete"
          :value="displayValue('autocomplete_in_days')"
          outlined
          :rules="[
            positiveValue(displayValue('autocomplete_in_days'), 'autocomplete')
          ]"
          clearable
          hint="Number of days before a consult will be autocompleted after the last chat is sent."
          persistent-hint
          @input="updateInput('autocomplete_in_days', $event)"
        />
        <footer class="d-flex justify-end">
          <v-btn plain rounded @click="onCancel">Cancel</v-btn>
          <v-btn
            depressed
            rounded
            color="primary"
            :loading="saving"
            :disabled="shouldDisable"
            @click="handleConfirm"
          >
            Save Changes
          </v-btn>
        </footer>
      </div>
    </v-form>
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow'
import PractitionerTypes from '@/utils/PractitionerTypes'
import ValidationMixin from '@/mixins/Validation'

export default {
  name: 'ConsultRateDetails',
  components: { DataRow },
  mixins: [ValidationMixin],
  props: {
    consultRate: {
      type: Object,
      required: true
    },
    consultRateDetails: {
      type: Array,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      valid: false,
      rules: {
        name: (v) => v?.length > 0 || 'Name of consult rate cannot be blank!',
        assignment_lifetime: (v) =>
          v >= 60 || 'Assignment lifetime should be at least 60 seconds!',
        estimated_duration: (v) =>
          v >= 300 || 'Estimated duration should be at least 5 minutes!',
        patient_no_show: (v) =>
          v >= 300 ||
          'Patient no show wait duration should be at least 5 minutes!'
      },
      editInputs: {},
      saving: false,
      editError: 'Unable to edit consult rate at this time. ',
      compensationOptions: [
        { value: 'per consult', display: 'Per Consult' },
        { value: 'hourly', display: 'Hourly' }
      ],
      typeOptions: [{ value: 'task', display: 'Task' }],
      practitionerTypesOptions: PractitionerTypes
    }
  },
  computed: {
    shouldDisable() {
      return !this.valid || !Object.keys(this.editInputs).length
    },
    notAsyncInstant() {
      return (
        this.consultRate.modality !== 'async' &&
        this.consultRate.modality !== 'instant'
      )
    },
    notInstant() {
      return this.consultRate.modality !== 'instant'
    },
    isAsync() {
      return this.consultRate.modality === 'async'
    }
  },
  methods: {
    async onCancel() {
      this.editInputs = {}
      this.$emit('cancel')
    },
    isDirty(key) {
      return Object.keys(this.editInputs).includes(key)
    },
    displayValue(key) {
      return this.isDirty(key) ? this.editInputs[key] : this.consultRate[key]
    },
    updateInput(key, val) {
      this.$set(this.editInputs, key, val)
    },
    async handleConfirm() {
      try {
        this.saving = true
        this.results = await this.$consultRates.edit(
          this.consultRate.id,
          this.editInputs
        )
        this.$emit('confirm', this.results)
        this.$store.commit('SET_SNACKBAR', {
          message: 'Success! Consult rate was edited.',
          show: true
        })
      } catch (e) {
        let message = this.editError
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  ::v-deep {
    .v-messages__message {
      margin-bottom: 10px;
    }
  }
}
</style>
