<template>
  <filters
    :current-filters="filters"
    :filter-options="filterOptions"
    @apply="applyFilters"
    @reset="resetFilters"
    @searchSupervising="searchSupervisingClinicians"
    @searchSupervised="searchSupervisedClinicians"
  />
</template>

<script>
import Filters from '@/components/common/Filters.vue'
import { UNITED_STATES_ARRAY } from '@/utils/UnitedStates'
import { PAYMENT_MODELS_ARRAY } from '@/utils/PaymentModels'
import { COLLAB_AGREEMENT_CATEGORY_ARRAY } from '@/utils/CollabCategory'

export default {
  name: 'CollabAgreementFilters',
  components: {
    Filters
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      filterOptions: {
        status: {
          options: [],
          name: 'collab_status'
        },
        supervising_clinician: {
          options: [],
          name: 'supervising_physician'
        },
        supervised_clinician: {
          options: [],
          name: 'nurse_practioners'
        },
        payback_model: {
          options: PAYMENT_MODELS_ARRAY,
          name: 'payback_model'
        },
        state: {
          options: UNITED_STATES_ARRAY,
          name: 'states'
        },
        external: {
          options: COLLAB_AGREEMENT_CATEGORY_ARRAY,
          name: 'category'
        }
      }
    }
  },
  computed: {
    filtersLocal: {
      get: function () {
        return this.filters
      },
      set: function (value) {
        return this.$emit('update:filters', value)
      }
    }
  },
  created() {
    this.getCollabStatuses()
    this.mergeSelectedClinicians()
  },
  methods: {
    applyFilters(filters) {
      this.$emit('apply', filters)
      this.mergeSelectedClinicians()
    },
    resetFilters(filters) {
      this.$emit('reset', filters)
      this.mergeSelectedClinicians()
    },
    async searchSupervisingClinicians(search, selectedFilters) {
      try {
        const response = await this.$collabAgreements.getSupervisingClinicians(
          search
        )
        const supervisingClinician = response.data
        const supervisingIds = supervisingClinician.map(({ id }) => id)
        this.filterOptions.supervising_clinician.options =
          supervisingClinician.map(({ id, name }) => ({ id, name }))
        const filtered = selectedFilters.filter(
          (filter) => !supervisingIds.includes(filter.id)
        )
        this.filterOptions.supervising_clinician.options.push(...filtered)
      } catch (e) {
        this.$dd.addError(e)
      }
    },
    async searchSupervisedClinicians(search, selectedFilters) {
      try {
        const response = await this.$collabAgreements.getSupervisedClinicians(
          search
        )
        const supervisedClinician = response.data
        const supervisingIds = supervisedClinician.map(({ id }) => id)
        this.filterOptions.supervised_clinician.options =
          supervisedClinician.map(({ id, name }) => ({ id, name }))
        const filtered = selectedFilters.filter(
          (filter) => !supervisingIds.includes(filter.id)
        )
        this.filterOptions.supervised_clinician.options.push(...filtered)
      } catch (e) {
        this.$dd.addError(e)
      }
    },
    async getCollabStatuses() {
      try {
        const response = await this.$collabAgreements.getCollabStatuses()
        this.filterOptions.status.options = response.map(([id, name]) => ({
          value: name,
          display: name
        }))
      } catch (e) {
        this.$dd.addError(e)
      }
    },
    async mergeSelectedClinicians() {
      this.filterOptions.supervising_clinician.options = await Promise.all(
        this.filters.supervising_clinician.map(async (id) => {
          const response = await this.$clinicians.show(id)
          return { id: response.id, name: response.name }
        })
      )
      this.filterOptions.supervised_clinician.options = await Promise.all(
        this.filters.supervised_clinician.map(async (id) => {
          const response = await this.$clinicians.show(id)
          return { id: response.id, name: response.name }
        })
      )
      this.filtersLocal.supervising_clinician =
        this.filterOptions.supervising_clinician.options
      this.filtersLocal.supervised_clinician =
        this.filterOptions.supervised_clinician.options
    }
  }
}
</script>
