<template>
  <v-autocomplete
    v-model="selected"
    :items="selectorStates"
    multiple
    clearable
    item-text="name"
    item-value="abbreviationLower"
    prepend-icon="mdi-map"
    :disabled="disabled"
    v-on="$listeners"
  >
    <template #label>
      <h4 class="state-selector__label">State</h4>
    </template>
  </v-autocomplete>
</template>

<script>
import UnitedStates, { UNITED_STATES_ARRAY } from '@/utils/UnitedStates'

export default {
  name: 'StateSelector',
  props: {
    disabled: Boolean,
    // TODO: make a logical default and remove the ESLint exception
    // eslint-disable-next-line vue/require-default-prop
    defaultValue: {
      type: Array
    },
    states: {
      type: Array,
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      selectedStates: []
    }
  },
  computed: {
    selectorStates() {
      if (!this.states?.length) {
        return UNITED_STATES_ARRAY
      }
      return this.states.reduce((acc, state) => {
        const found = UnitedStates[state.toUpperCase()]
        if (found) {
          acc.push(found)
        }
        return acc
      }, [])
    },
    selected: {
      set(states) {
        this.selectedStates = states.map(
          (state) => UnitedStates[state.toUpperCase()]?.abbreviationLower
        )
      },
      get() {
        return this.selectedStates
      }
    }
  },
  watch: {
    selected: {
      handler: function (states) {
        this.$emit(
          'change',
          states?.map(
            (state) => UnitedStates[state?.toUpperCase()]?.abbreviationLower
          )
        )
      }
    }
  },
  mounted() {
    if (this.defaultValue) {
      this.selectedStates = this.defaultValue
    }
  }
}
</script>

<style lang="scss" scoped>
.state-selector {
  &__label {
    font-size: size(12);
    line-height: size(16);
    color: #666;
    text-transform: uppercase;
    @include font-monospaced;
  }
}
</style>
