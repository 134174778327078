<template>
  <div class="login-view">
    <login />
  </div>
</template>

<script>
import Login from '@/components/auth/Login'

export default {
  name: 'LoginView',
  components: {
    Login
  }
}
</script>
