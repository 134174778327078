import { keys as mutations } from './mutations'

import { createNamespacedHelpers } from 'vuex'
import { mapKeys } from '@/store/helpers'
import { NAMESPACE } from './state'
import Pagination from '@/utils/Pagination'
import axios from 'axios'

const { mapActions } = createNamespacedHelpers(NAMESPACE)

const actions = {
  async index({ getters, commit }) {
    try {
      let response
      if (getters.clients?.length) {
        Pagination.getAll('/admin/clients').then((data) => {
          response = data
          commit(mutations.set, response)
        })
      } else {
        response = await Pagination.getAll('/admin/clients')
      }

      commit(mutations.set, response)

      return response
    } catch (error) {
      commit(mutations.error, error.message)
      this._vm.$dd?.addError(error)
    }
  },
  async get({ getters, commit }, id) {
    try {
      let response = getters.clients(id)

      if (!response) {
        response = await Pagination.getAll('/admin/clients')
        commit(mutations.add, response.data)
      }

      return response
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  },

  async editClient({ commit }, { clientId, payload }) {
    try {
      const response = await axios.patch(`/admin/clients/${clientId}`, payload)
      commit(mutations.update, response.data)
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  }
}

export const mappedActions = mapActions(Object.keys(actions))
export const actionKeys = mapKeys(actions, NAMESPACE)
export default actions
