<template>
  <v-autocomplete
    v-model="selected"
    :error-messages="errorMessage"
    :loading="loadingClinicians"
    :items="clinicians"
    :search-input.sync="search"
    clearable
    label="Clinician"
    prepend-icon="mdi-account"
    item-value="id"
    item-text="name"
    :hide-no-data="loadingClinicians && !!search"
    return-object
    :disabled="disabled"
    v-on="$listeners"
  >
    <template #label>
      <h4 class="clinician-selector__label">Clinician</h4>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'debounce'

export default {
  name: 'ClinicianSelector',
  props: {
    disabled: Boolean,
    clientId: {
      type: [String, Number],
      default: undefined
    },
    consultRateId: {
      type: [String, Number],
      default: undefined
    },
    licenses: {
      type: [String, Array],
      default: undefined
    }
  },
  data() {
    return {
      loadingClinicians: false,
      clinicians: [],
      search: null,
      error: null,
      debounced: debounce(this.loadClinicians, 500)
    }
  },
  computed: {
    errorMessage() {
      return this.error?.message
    },
    selected: {
      set(selected) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            clinician: selected?.id
          }
        })
      },
      get() {
        return this.$route.query.clinician
      }
    },
    loader() {
      if (this.consultRateId) {
        return (search) =>
          this.$consultRates.getClinicians(this.consultRateId, {
            search,
            status: 'active',
            licenses: this.licenses
          })
      } else if (this.clientId) {
        return (search) =>
          this.$clients.getClinicians(this.clientId, {
            search,
            status: 'active',
            licenses: this.licenses
          })
      } else {
        return (search) =>
          this.$clinicians.index({
            search,
            status: 'active',
            licenses: this.licenses
          })
      }
    }
  },
  watch: {
    licenses() {
      this.loadClinicians(this.search)
    },
    clientId() {
      this.loadClinicians(this.search)
    },
    consultRateId() {
      this.loadClinicians(this.search)
    },
    search: {
      handler(val) {
        this.loadingClinicians = true
        this.debounced(val)
      },
      immediate: true
    }
  },
  methods: {
    async loadClinicians(search) {
      try {
        this.error = null
        this.loadingClinicians = true
        const response = await this.loader(search)
        this.clinicians = response.data
      } catch (e) {
        this.error = e
      } finally {
        this.loadingClinicians = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.clinician-selector {
  &__label {
    font-size: size(12);
    line-height: size(16);
    color: #666;
    text-transform: uppercase;
    @include font-monospaced;
  }
}
</style>
