<template>
  <div
    v-if="hasCollabs"
    :loading="loadingData"
    class="clinician-collab-agreements mt-8"
  >
    <profile-form
      v-slot="{ editMode, loading }"
      :action-function="updateClinician"
      hide-clear
      class="clinician-info-form pl-4 mr-n4"
    >
      <h6>Hour Agreement</h6>
      <profile-field
        v-bind="{ editMode, loading }"
        :key="'hour_agreement'"
        v-model="clinicianLocal.hour_agreement"
        :edit-mode="editMode"
      >
        <template #default="attrs">
          <v-text-field
            v-bind="attrs"
            :label="'Minimum weekly hours'"
            :rules="[rules.hourAgreement]"
            outlined
            :name="'hour_agreement'"
            :loading="loading"
            :type="'hour_agreement'"
          />
        </template>
        <template #view>
          <div class="my-4 static-field-view">
            <data-row
              label="Minimum weekly hours"
              :value="clinicianLocal.hour_agreement"
            />
          </div>
        </template>
      </profile-field>
    </profile-form>
    <div class="d-flex flex-no-wrap align-center justify-space-between">
      <v-expansion-panels v-model="panel" accordion multiple>
        <v-expansion-panel
          v-for="(agreement, state) in collabAgreements"
          :key="state"
        >
          <v-expansion-panel-header class="h6 mt-4 mb-4">
            {{ states[state.toUpperCase()].name }} ({{ state.toUpperCase() }})
            <v-chip
              v-if="
                agreement.maxSupervisionValues.reachedMaxSupervisions &&
                clinician.practitioner_type !== 'np'
              "
              class="ml-6 error-view"
            >
              Maximum Supervisions
            </v-chip>
            <v-chip
              v-if="
                !agreement.maxSupervisionValues.reachedMaxSupervisions &&
                agreement.maxSupervisionValues
                  .reachedMaxPrescribingSupervisions &&
                clinician.practitioner_type !== 'np'
              "
              class="ml-6 error-view"
            >
              Maximum Prescribing Supervisions
            </v-chip>
            <v-chip
              v-if="
                !agreement.maxSupervisionValues.reachedMaxSupervisions &&
                agreement.maxSupervisionValues.reachedMaxNonSupervisions &&
                clinician.practitioner_type !== 'np'
              "
              class="ml-6 error-view"
            >
              Maximum Non-Prescribing Supervisions
            </v-chip>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <navigating-data-table
              class="mt-4"
              navigate
              :headers="headers"
              :items="agreement.collabs"
              :footer-props="footer"
              disable-sort
            >
              <template #item.id="{ item }">
                <router-link
                  :to="`/collabs/agreements/${item.id}`"
                  class="mr-2 meta flex-shrink-0 text-color--wheel-green"
                >
                  {{ item.id }}
                </router-link>
              </template>
              <template #item.status="{ item }">
                <div
                  v-if="
                    item.review_status === 'Needs Review' &&
                    item.status !== 'Collab Removed'
                  "
                  class="status-container"
                >
                  <v-tooltip color="#141419" bottom>
                    <template #activator="{ on, attrs }">
                      <span>
                        <v-icon color="#BF211E" dark v-bind="attrs" v-on="on">
                          warning
                        </v-icon>
                      </span>
                    </template>
                    <span>Collab will be set to</span>
                    <br />
                    <span>{{ getToolTipText(item) }}</span>
                  </v-tooltip>
                  <span class="ml-2">Needs Review</span>
                </div>
                <span v-else>{{ item.status }}</span>
              </template>
              <template #item.external="{ item }">
                <span>{{ collabCategoryDisplayText(item.external) }}</span>
              </template>
              <template #item.supervising_clinician="{ item }">
                <td
                  v-if="item.supervising_clinician"
                  :class="{
                    hideHeader: clinician.practitioner_type !== 'np'
                  }"
                >
                  <router-link
                    v-if="!item.external"
                    :to="`/clinicians/${item.supervising_clinician.uuid}/collab-agreements`"
                    class="mr-2 meta flex-shrink-0 text-color--wheel-green"
                  >
                    {{ item.supervising_clinician.name }}
                  </router-link>
                  <span v-else>{{ item.supervising_clinician.name }}</span>
                </td>
                <td
                  v-else
                  :class="{
                    hideHeader: clinician.practitioner_type !== 'np'
                  }"
                >
                  <span>-</span>
                </td>
              </template>
              <template #item.supervised_clinician="{ item }">
                <td
                  :class="{
                    hideHeader: clinician.practitioner_type === 'np'
                  }"
                >
                  <router-link
                    :to="`/clinicians/${item.supervised_clinician.uuid}/collab-agreements`"
                    class="mr-2 meta flex-shrink-0 text-color--wheel-green"
                  >
                    {{ item.supervised_clinician.name }}
                  </router-link>
                </td>
              </template>
              <template #item.supervising_clinician_payment="{ item }">
                <td
                  :class="{
                    hideHeader: clinician.practitioner_type === 'np'
                  }"
                >
                  <span v-if="!item.external">{{
                    item.supervising_clinician_payment | currency
                  }}</span>
                  <span v-else> - </span>
                </td>
              </template>
              <template #item.max_payback_amount="{ item }">
                <td
                  :class="{
                    hideHeader: clinician.practitioner_type !== 'np'
                  }"
                >
                  <span v-if="!item.external">{{
                    item.state_requirement.max_payback_amount | currency
                  }}</span>
                  <span v-else> - </span>
                </td>
              </template>

              <template #item.payback_model="{ item }">
                <span v-if="!item.external"> {{ item.payback_model }} </span
                ><span v-else> - </span>
              </template>
              <template #item.collab_type="{ item }">
                <span v-if="!item.external"> {{ item.collab_type }} </span
                ><span v-else> - </span>
              </template>

              <template #item.start_date="{ item }">
                <span v-if="item.start_date">
                  {{ collabStartDate(item.start_date) }}
                </span>
              </template>
            </navigating-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
  <div v-else>No collab agreements yet.</div>
</template>

<script>
import NavigatingDataTable from '@/components/tables/NavigatingDataTable'
import { formatDate, toUtc } from '@/utils/Date'
import UNITED_STATES from '@/utils/UnitedStates'
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import DataRow from '@/components/common/DataRow.vue'
import moment from 'moment'
import { COLLAB_AGREEMENT_CATEGORY } from '@/utils/CollabCategory'
import Regex from '@/utils/Regex'

export default {
  name: 'ClinicianCollabAgreements',
  components: {
    NavigatingDataTable,
    ProfileForm,
    DataRow,
    ProfileField
  },
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loadingData: true,
      panel: [0],
      clinicianLocal: {},
      collabAgreements: [],
      groupedCollabAgreements: [],
      states: UNITED_STATES,
      headers: [
        {
          text: 'Collab ID',
          value: 'id'
        },
        {
          text: 'Collab Status',
          value: 'status'
        },
        {
          text: 'Category',
          value: 'external'
        },
        {
          text: 'Supervising Physician',
          value: 'supervising_clinician',
          class: this.clinician.practitioner_type === 'np' ? '' : 'hideHeader'
        },
        {
          text: 'Supervised Physician',
          value: 'supervised_clinician',
          class: this.clinician.practitioner_type !== 'np' ? '' : 'hideHeader'
        },
        {
          text: 'Supervising Physician Payment',
          value: 'supervising_clinician_payment',
          class: this.clinician.practitioner_type !== 'np' ? '' : 'hideHeader'
        },
        {
          text: 'Maximum Payback Amount',
          value: 'max_payback_amount',
          class: this.clinician.practitioner_type === 'np' ? '' : 'hideHeader'
        },
        {
          text: 'Payback Model',
          value: 'payback_model'
        },
        {
          text: 'Collab Type',
          value: 'collab_type'
        },
        {
          text: 'Date Started',
          value: 'start_date'
        }
      ],
      footer: {
        itemsPerPageOptions: [],
        itemsPerPageText: null,
        disableItemsPerPage: true
      },
      rules: {
        required: (value) => !!value || 'Required.',
        hourAgreement: (value) =>
          (+value >= 0 &&
            +value <= 40 &&
            Regex.MAXIMUM_TWO_DECIMAL_PLACES.test(value)) ||
          'Value must be within 0-40 and have a maximum of 2 decimal places.'
      }
    }
  },
  computed: {
    hasCollabs() {
      return this.clinician.collab_agreements?.length > 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: async function () {
      this.clinicianLocal = this.clinician
      if (this.clinicianLocal.hour_agreement)
        this.clinicianLocal.hour_agreement = parseFloat(
          +this.clinicianLocal.hour_agreement
        )
      this.loadAgreementsData()
    },
    async loadAgreementsData() {
      try {
        this.loadingData = true
        this.collabAgreements =
          await this.$clinicians.getClinicianCollabAgreements(this.clinician.id)
      } catch (e) {
        let message = 'Unable to load Agreements Data. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.loadingData = false
      }
    },
    paybackModel(val) {
      val = parseInt(val)
      return PAYMENT_MODELS[val].display
    },
    collabStartDate(val) {
      return val ? formatDate(toUtc(val), 'MM/DD/YYYY') : ''
    },
    getToolTipText(item) {
      const remainingHours = Math.abs(2 - moment.utc().hour())
      return `Removed ${moment(item.review_status_updated_at)
        .add(4, 'days')
        .add(remainingHours, 'hours')
        .fromNow()}`
    },
    updateClinician: function (data) {
      data.hour_agreement = data.hour_agreement
        ? parseFloat(+data.hour_agreement)
        : null
      this.$clinicians.update(this.clinician.id, {
        ...data
      })
      this.$emit('update:clinician', { ...this.clinician, ...data })
      this.clinicianLocal.hour_agreement = data.hour_agreement
    },
    collabCategoryDisplayText(category) {
      return category
        ? COLLAB_AGREEMENT_CATEGORY.EXTERNAL.display
        : COLLAB_AGREEMENT_CATEGORY.INTERNAL.display
    }
  }
}
</script>

<style lang="scss" scoped>
.clinician-collab-agreements {
  ::v-deep .hideHeader {
    display: none;
  }
  .theme--light.v-chip:not(.v-chip--active) {
    flex: none;
  }

  .error-view {
    font-family: 'Apercu Pro', sans-serif;
    background: rgba(239, 100, 97, 0.12);
    color: #4f0000;
  }
}
</style>
