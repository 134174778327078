import Vue from 'vue'

export const PractitionerType = Vue.filter(
  'practitionerType',
  function (value) {
    switch (value) {
      case 'np':
        return 'Nurse Practitioner'
      default:
        return value?.toUpperCase() || ''
    }
  }
)
