import Vue from 'vue'
import moment from 'moment'

export const Duration = Vue.filter(
  'duration',
  function (value, unit = 'seconds') {
    if (value) {
      return moment.duration(value, unit).humanize({ m: 60, h: 24 })
    }
    return ''
  }
)

export const Elapsed = Vue.filter(
  'elapsed',
  function (value, unit = 'seconds') {
    if (value) {
      const base = moment(value)
      return moment
        .duration(base.diff(moment(), unit), unit)
        .humanize({ m: 60, h: 24 })
    }
    return ''
  }
)

export const Format = Vue.filter(
  'format',
  function (value, format = 'LLLL Z', utc = false) {
    if (value) {
      const datetime = utc ? moment.utc(value) : moment(value)
      return datetime.format(format)
    }
    return ''
  }
)
