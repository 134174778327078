<template>
  <v-file-input
    v-model="file"
    accept="text/csv"
    label="Upload"
    :loading="loading || parsing"
    :rules="[(v) => !!v || 'File is required']"
    @change="parseFile"
  />
</template>

<script>
import Papa from 'papaparse'

export default {
  name: 'CsvFileInput',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: null,
      parsing: false
    }
  },
  methods: {
    async parseFile() {
      if (this.file) {
        this.parsing = true
        this.$emit('parsing', true)
        Papa.parse(this.file, {
          header: true,
          complete: this.onComplete,
          skipEmptyLines: true
        })
      } else {
        this.$emit('onChange', null)
      }
    },
    onComplete(parsedResults) {
      this.parsing = false
      this.$emit('onChange', parsedResults)
    }
  }
}
</script>
