import { mapKeys } from '@/store/helpers'

const mutations = {
  set(state, { data }) {
    state.data.consultAttributes = data

    let categories = new Set()
    for (let attribute of data) {
      if (attribute.category) {
        categories.add(attribute.category)
      }
    }
    state.data.categories = Array.from(categories).sort()
  },
  error(state, data) {
    state.error = data
  }
}

export const keys = mapKeys(mutations)
export default mutations
