<template>
  <section v-if="activityRate">
    <view-header :breadcrumbs="breadcrumbs" :loading="loading">
      <span class="text-capitalize">{{
        activityRate.activity_type | snakeToTitle
      }}</span>
      <template slot="actions">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" rounded v-on="on">
              Actions
            </v-btn>
          </template>
          <v-list>
            <div>
              <v-dialog
                v-model="editWarningModal"
                max-width="600px"
                @click:outside="handleClose"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title class="text-capitalize">
                      <v-icon class="mr-2"> mdi-pencil </v-icon>
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <edit-warning-modal
                  v-if="editWarningModal"
                  v-model="editWarningModal"
                  type-title="activity rate"
                  :name="activityRateName"
                  @close="handleClose"
                  @confirm="editDetails"
                />
              </v-dialog>
            </div>
          </v-list>
        </v-menu>
      </template>
    </view-header>
    <tabs>
      <template #details>
        <div class="details">
          <activity-rate-details
            :activity-rate="activityRate"
            :activity-rate-details="activityRateDetails"
            :edit-mode="editMode"
            @cancel="handleCancel"
            @confirm="handleConfirm"
          />
        </div>
      </template>
    </tabs>
  </section>
</template>

<script>
import ViewHeader from '@/components/common/ViewHeader.vue'
import Tabs from '@/components/common/Tabs'
import EditWarningModal from '@/components/common/editWarningModal'
import ActivityRateDetails from '@/components/activity-rates/ActivityRateDetails'
import { SnakeToTitle } from '@/filters/SnakeToTitle'
import { Currency } from '@/filters/Currency'

export default {
  name: 'ActivityRateView',
  components: {
    Tabs,
    ViewHeader,
    EditWarningModal,
    ActivityRateDetails
  },
  filters: {
    SnakeToTitle,
    Currency
  },
  props: {
    activityRateId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activityRate: null,
      loading: true,
      editWarningModal: false,
      editMode: false
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { to: '/clients', display: 'Clients' },
        {
          to: `/clients/${this.activityRate.consult_rate.client.id}`,
          display: `${this.activityRate.consult_rate.client.name}`
        },
        {
          to: `/consult-rates/${this.activityRate.consult_rate.id}?tab=activity-rates`,
          display: `${this.activityRate.consult_rate.name}`
        }
      ]
    },
    activityRateName() {
      return this.$options.filters.snakeToTitle(this.activityRate.activity_type)
    },
    activityRateDetails() {
      const {
        id,
        consult_rate,
        activity_type,
        cost_to_client,
        paid_to_provider
      } = this.activityRate
      return [
        {
          label: 'Id',
          value: id
        },
        {
          label: 'Consult Rate',
          value: consult_rate.name
        },
        {
          label: 'Rate',
          value: this.$options.filters.snakeToTitle(activity_type)
        },
        {
          label: 'Activity Type',
          value: activity_type
        },
        {
          label: 'Cost to Client',
          value: this.$options.filters.currency(cost_to_client)
        },
        {
          label: 'Paid to Provider',
          value: this.$options.filters.currency(paid_to_provider)
        }
      ]
    }
  },
  created() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    init: async function () {
      try {
        this.activityRate = await this.$activityRates.getById(
          this.activityRateId
        )
        this.activityRate.name = this.activityRateName
      } catch (e) {
        this.$router.replace('/404')
      }
    },
    handleClose() {
      this.editWarningModal = false
    },
    editDetails() {
      this.editMode = true
      if (
        this.$router.currentRoute.path !==
        `/activity-rates/${this.activityRateId}/details`
      ) {
        this.$router.push({
          path: `/activity-rates/${this.activityRateId}/details`
        })
      }
    },
    handleCancel() {
      this.editMode = false
    },
    handleConfirm(results) {
      this.activityRate = results
      this.editMode = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
