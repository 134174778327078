<template>
  <span>{{ timeRemaining }} {{ relative }}</span>
</template>

<script>
export default {
  name: 'ConsultExpiration',
  props: {
    start: {
      type: String,
      required: true
    },
    sla: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      milliseconds: 0,
      relative: 'from now'
    }
  },
  computed: {
    timeRemaining() {
      let duration = this.milliseconds / 1000

      const hours = Math.floor(duration / (60 * 60))
      duration = duration - hours * (60 * 60)

      const minutes = Math.floor(duration / 60)
      duration = duration - minutes * 60

      const seconds = Math.floor(duration)

      let output = this.milliseconds < 0 ? '-' : ''

      output += hours > 0 ? hours + 'h ' : ''
      output += minutes > 0 ? minutes + 'm ' : ''
      output += seconds > 0 ? seconds + 's' : ''

      return output
    }
  },
  mounted() {
    var expiration = new Date(this.start)
    const now = new Date()

    expiration.setSeconds(expiration.getSeconds() + parseInt(this.sla))

    if (expiration > now) {
      this.milliseconds = expiration - now
      this.relative = 'from now'
    } else {
      this.milliseconds = now - expiration
      this.relative = 'ago'
    }
  }
}
</script>
