<template>
  <div v-if="name" class="client-label">
    <client-icon :name="name" :uuid="uuid" :tooltip="!!$slots.default" />
    <span class="ml-2">
      <slot>{{ name }}</slot>
    </span>
  </div>
</template>

<script>
import ClientIcon from '@/components/common/ClientIcon.vue'

export default {
  name: 'ClientLabel',
  components: {
    ClientIcon
  },
  props: {
    uuid: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.client-label {
  display: flex;
  align-items: center;
}
</style>
