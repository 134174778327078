<template>
  <v-skeleton-loader v-if="!loaded" type="article" />
  <div v-else-if="error">
    {{ error }}
  </div>
  <div v-else>
    <ol v-for="(group, key) in grouped" :key="key" class="wrapper">
      <li>
        <div class="date header">{{ key }}</div>
        <ol>
          <li
            v-for="item in group"
            :key="item.history_type + item.id + item.created_at"
            dense
            class="d-flex"
          >
            <div>
              <v-tooltip bottom open-delay="300">
                <template #activator="{ on, attrs }">
                  <div class="date" v-bind="attrs" v-on="on">
                    {{ item.created_at | format('h:mm:ss a') }} ({{
                      formattedUtcTime(item.created_at, 'h:mm:ss a')
                    }}
                    UTC)
                  </div>
                </template>
                {{ item.created_at | format('HH:mm:ss:SSS') }} ({{
                  formattedUtcTime(item.created_at, 'HH:mm:ss:SSS')
                }}
                UTC)
              </v-tooltip>
            </div>
            <div>
              <span
                v-if="item.history_type === 'routing_report'"
                class="text-capitalize mr-2 flex-shrink-0"
              >
                <v-icon>mdi-file-document-outline</v-icon>
                <a
                  class="text-decoration-underline"
                  @click="viewRoutingReport(item)"
                  >View Routing Report</a
                >
              </span>
              <span
                v-else-if="item.description"
                class="text-capitalize mr-2 flex-shrink-0"
                :class="{ alert: isWarning(item.description) }"
              >
                {{ item.description | historyTranslation }}
              </span>
              <template v-if="entityType === 'consult'">
                <span v-if="item.clinician">
                  <v-icon class="mr-1" color="#737380" small
                    >mdi-account-outline</v-icon
                  >
                  <router-link
                    :to="`/clinicians/${item.clinician.id}`"
                    class="mr-2 meta flex-shrink-0"
                    >{{ item.clinician.name }}</router-link
                  >
                </span>
                <div v-if="metadata(item.metadata)" class="meta">
                  {{ item.metadata }}
                </div>
                <div v-if="item.statusDescription" class="meta">
                  {{ item.statusDescription }}
                </div>
              </template>
              <template v-else-if="entityType === 'clinician'">
                <router-link
                  v-if="item.consult"
                  :to="`/consults/${item.consult.id}`"
                  class="mr-2 meta flex-shrink-0"
                >
                  #{{ item.consult.id }}
                </router-link>
                <router-link
                  v-if="item.client"
                  :to="`/clients/${item.client.id}`"
                  class="mr-2 meta flex-shrink-0"
                >
                  {{ item.client.name }}
                </router-link>
                <span v-if="item.consult" class="mr-2 meta flex-shrink-0">
                  {{ item.consult.state.toUpperCase() }}
                </span>
                <div v-if="metadata(item.metadata)" class="meta">
                  {{ item.metadata }}
                </div>
              </template>
              <template
                v-else-if="
                  entityType === 'stateRequirement' ||
                  entityType === 'collabAgreement'
                "
              >
                <span class="history-section">
                  <span v-for="(row, keyItem) in item" :key="keyItem">
                    <div
                      v-if="
                        keyItem !== 'created_by' && keyItem !== 'created_at'
                      "
                    >
                      <span
                        :class="{
                          'state-requirement-change-text':
                            row === 'State Requirements Updated'
                        }"
                        >{{ row }}</span
                      >
                    </div>
                    <span
                      v-if="keyItem === 'created_by'"
                      class="text-decoration-underline created-by"
                    >
                      {{ row }}</span
                    >
                  </span>
                </span>
              </template>
            </div>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>

<script>
import moment from 'moment'
import { formatDate, toUtc } from '@/utils/Date'

const ALERT_EVENTS = [
  'declined',
  'ignored',
  'canceled',
  'incomplete',
  'voided',
  'no_show',
  'patient_no_show'
]

export default {
  name: 'HistoryData',
  filters: {
    historyTranslation: function (name) {
      if (name === 'sync_consult_assignment_failed') {
        return 'Attempted assignment; no clinician available'
      } else {
        return name.split('_').join(' ')
      }
    }
  },
  props: {
    data: {
      type: [Array, String],
      required: true
    },
    error: {
      type: String,
      default: ''
    },
    entityType: {
      type: String,
      required: true
    }
  },
  computed: {
    loaded() {
      return this.data.length || this.error.length
    },
    grouped() {
      const groups = {}

      this.data.forEach((item) => {
        const date = moment(item.created_at).format('MMMM DD, YYYY')
        if (!groups[date]) {
          groups[date] = []
        }
        if (item.metadata && 'status_reason' in item.metadata) {
          const {
            status_reason: statusReason,
            void_code: statusCode,
            ...metadata
          } = item.metadata
          item.statusDescription = statusCode
            ? statusCode + ', ' + statusReason
            : statusReason
          item.metadata = metadata
        }
        groups[date].push(item)
      })

      return groups
    }
  },
  methods: {
    isWarning(type) {
      return (
        (type.includes('failed') &&
          type !== 'sync_consult_assignment_failed') ||
        ALERT_EVENTS.includes(type)
      )
    },
    metadata(data) {
      return data ? Object.keys(data).length : false
    },
    viewRoutingReport(item) {
      this.$emit('routing-report:show', item.id)
    },
    formattedUtcTime(time, timeFormat) {
      const date = toUtc(time)
      timeFormat =
        moment(time).format('DD') === date.format('DD')
          ? timeFormat
          : 'MMM DD, ' + timeFormat
      return formatDate(date, timeFormat)
    }
  }
}
</script>

<style lang="scss" scoped>
ol {
  list-style: none;
  margin: 0 0 size(24);
  padding: 0 0 size(24);

  ol li {
    margin-bottom: size(12);
  }

  :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.wrapper {
  border-bottom: 1px solid #e3e3e3;

  &:last-child {
    border-bottom: none;
  }
}

.date {
  color: #747474;
  min-width: size(300);
  display: block;
}

.created-by {
  font-size: 0.875rem;
  color: $grey-600;
  display: inline;
}

.state-requirement-change-text {
  color: $red-600;
}

.header {
  font-weight: bold;
  font-size: size(14);
  line-height: 1.4;
  margin-bottom: size(16);
}

.alert {
  color: #bf211e;
}

.meta {
  font-size: size(14);
  color: #737380;
  letter-spacing: size(0.25);
  margin: size(2) 0 size(8);
}
</style>
