const NavigateTableRow = {
  props: {
    navigate: {
      type: [String, Function],
      required: true
    }
  },
  methods: {
    link(item) {
      return typeof this.navigate === 'function'
        ? this.navigate(item)
        : this.navigate
    },
    go(event, { item }) {
      const computedRoute = this.link(item)
      // first parameter for click in Vue 2 is the item, instead of the mouse event
      if (event?.button === 3 || event?.metaKey) {
        window.open(computedRoute, '_blank')
      } else {
        this.$router.push({ path: computedRoute })
      }
    }
  }
}

export default NavigateTableRow
