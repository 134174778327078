<template>
  <div class="clinician-paused">
    <span>{{ value }}</span>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ClinicianPaused',
  props: {
    until: {
      type: String,
      default: ''
    }
  },
  computed: {
    value() {
      const diff = moment().diff(this.until)
      const duration = moment.duration(diff)

      return diff && diff < 0 ? duration.humanize() : '-'
    }
  }
}
</script>

<style lang="scss">
.clinician-paused {
  text-transform: lowercase;
}
</style>
