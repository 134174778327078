<template>
  <div class="data-row" role="row">
    <div class="data-row__label" role="rowheader">
      {{ label | snakeToTitle }}
    </div>
    <div class="data-row__fill" />
    <copy v-if="copy" :value="value" icon class="mr-2" />
    <v-skeleton-loader v-if="loading" class="data-row__value" type="chip" />
    <slot v-else role="cell">
      <div
        class="data-row__value"
        :class="{ [valueClass]: valueClass }"
        role="cell"
      >
        <slot name="prepend" />
        {{ value }}
      </div>
    </slot>
  </div>
</template>

<script>
import Copy from '@/components/common/Copy.vue'
import { SnakeToTitle } from '@/filters/SnakeToTitle'

export default {
  name: 'DataRow',
  components: {
    Copy
  },
  filters: {
    SnakeToTitle
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    valueClass: {
      type: String,
      default: ''
    },
    copy: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
.data-row {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;

  &.mini {
    font-size: size(14);
    margin: size(4) 0;
  }

  &__label {
    color: rgba(24, 24, 24, 0.6);
    text-transform: capitalize;
  }

  &__fill {
    flex-grow: 1;
    margin: 11px 1rem 0;

    border-top: 1px solid rgba(24, 24, 24, 0.12);
  }
}
</style>
