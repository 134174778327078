<template>
  <div>
    <v-dialog
      v-model="editWarningModal"
      max-width="734px"
      @click:outside="closeModal"
    >
      <client-coverage-change-modal
        v-if="editWarningModal"
        v-model="editWarningModal"
        title="Confirm client coverage change"
        :content="modalContent"
        @close="closeModal"
        @confirm="confirmModal"
      />
    </v-dialog>
    <div>
      <profile-form
        v-slot="{ editMode }"
        hide-clear
        :action-function="onSubmit"
        class="mt-12"
        @cancel="clearForm"
        @edit="isEditMode"
      >
        <fieldset>
          <legend>Client Hours</legend>
          <coverage-type
            v-if="!consultRateId"
            :client-id="clientId"
            :coverage-block-required-data="coverageBlocksRequired"
            :edit-mode="editMode"
            class="pl-4"
            @switchedCoverageTypes="hasSwitchedCoverageType"
          />
          <coverage-blocks
            v-if="coverageBlocksRequired"
            class="pl-4"
            :client-id="clientId"
            :client-name="client.name"
          />

          <profile-field
            v-if="editMode && !coverageBlocksRequired"
            label="Operating Hours"
            :edit-mode="editMode"
            class="pl-4 pb-5"
          >
            <template #default="attrs">
              <v-autocomplete
                v-model="selectedOperatingHourType"
                class="pt-4"
                v-bind="attrs"
                name="selectedOperatingHourType"
                default=""
                placeholder="Select Operating Hours"
                :items="operatingHourTypeOptions"
                item-text="display"
                item-value="value"
                :rules="[rules.requireValueDropDown]"
              />
            </template>
            <template #view="attrs">
              <data-row v-bind="attrs">
                {{ attrs.value }}
              </data-row>
            </template>
          </profile-field>

          <div v-if="!coverageBlocksRequired && !hasSwitched">
            <div v-for="(value, key) in operatingHours" :key="key" class="pl-4">
              <profile-field
                v-model="operatingHours[key]"
                label="key"
                :edit-mode="editMode"
              >
                <template #view="attrs">
                  <data-row v-bind="value" :label="key">
                    {{ formatOperatingHourTimes(attrs.value.times) }}
                  </data-row>
                </template>
                <template #default="attrs">
                  <v-row
                    v-if="selectedOperatingHourType === 'Custom'"
                    class="pb-10"
                  >
                    <v-col md="2" class="pb-0 pt-0 mt-1">
                      <v-switch
                        v-model="attrs.value.switch"
                        class="mt-2"
                        :label="key"
                        inset
                        @click="hasOperatingHoursSwitch(key)"
                      />
                    </v-col>
                    <v-col md="10">
                      <v-row>
                        <v-col
                          v-if="attrs.value.times.length === 0"
                          cols="12"
                          class="pb-0 pt-0"
                        >
                          <v-row>
                            <v-col md="5">
                              <v-text-field
                                label="Start Time"
                                :disabled="!attrs.value.switch"
                                outlined
                                type="time"
                                suffix="CT"
                                class="hideClock"
                              />
                            </v-col>

                            <v-col md="5">
                              <v-text-field
                                label="End Time"
                                :disabled="!attrs.value.switch"
                                outlined
                                type="time"
                                suffix="CT"
                                class="hideClock"
                              />
                            </v-col>
                            <v-col md="2">
                              <v-icon
                                v-if="attrs.value.switch"
                                aria-label="add time"
                                @click="addTime(key)"
                              >
                                mdi-plus-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col
                          v-for="(times, index) in attrs.value.times"
                          :key="times[index]"
                          cols="12"
                          class="pb-2 pt-0"
                        >
                          <v-row>
                            <v-col md="5">
                              <v-text-field
                                v-model="
                                  operatingHours[key].times[index].start_at
                                "
                                label="Start Time"
                                outlined
                                type="time"
                                suffix="CT"
                                class="hideClock"
                                :rules="[
                                  rules.requireValue(
                                    times.start_at,
                                    index,
                                    key,
                                    'Add start time'
                                  ),
                                  rules.requireTimeToBeOrdered(
                                    times,
                                    key,
                                    index,
                                    'Start time must be earlier than end time'
                                  ),
                                  rules.requireStartAndEndTimes(
                                    times,
                                    key,
                                    index
                                  )
                                ]"
                              />
                            </v-col>

                            <v-col md="5">
                              <v-text-field
                                v-model="
                                  operatingHours[key].times[index].end_at
                                "
                                label="End Time"
                                outlined
                                type="time"
                                suffix="CT"
                                class="hideClock"
                                :rules="[
                                  rules.requireValue(
                                    times.end_at,
                                    index,
                                    key,
                                    'Add end time'
                                  ),
                                  rules.requireTimeToBeOrdered(
                                    times,
                                    key,
                                    index,
                                    'End time must begin after start time'
                                  ),
                                  rules.requireStartAndEndTimes(
                                    times,
                                    key,
                                    index
                                  )
                                ]"
                              />
                            </v-col>
                            <v-col md="2">
                              <v-icon
                                v-if="showSubtract(index, key)"
                                class="mt-4"
                                aria-label="remove time"
                                @click="removeTime(index, key)"
                              >
                                mdi-minus-circle-outline
                              </v-icon>
                              <v-icon
                                v-if="showAdd(index, key)"
                                aria-label="add time"
                                class="mt-4"
                                @click="addTime(key)"
                              >
                                mdi-plus-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <data-row
                    v-if="
                      selectedOperatingHourType !== 'Custom' &&
                      selectedOperatingHourType !== ''
                    "
                    v-bind="value"
                    :label="key"
                  >
                    {{ formatOperatingHourTimes(attrs.value.times) }}
                  </data-row>
                </template>
              </profile-field>
            </div>
          </div>
        </fieldset>
      </profile-form>
    </div>
  </div>
</template>

<script>
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import DataRow from '@/components/common/DataRow.vue'
import OPERATING_HOUR_TYPES from '@/utils/OperatingHourTypes'
import moment from 'moment'
import CoverageType from './CoverageType.vue'
import CoverageBlocks from './CoverageBlocks.vue'
import ClientCoverageChangeModal from './ClientCoverageChangeModal.vue'
import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE as operatingHoursNamespace } from '@/store/operatingHours/state'
import { NAMESPACE as clientNamespace } from '@/store/clients/state'

const { mapActions, mapGetters: mappedOperatingHoursGetter } =
  createNamespacedHelpers(operatingHoursNamespace)
const { mapGetters: mappedClientGetters, mapActions: mappedClientActions } =
  createNamespacedHelpers(clientNamespace)

export default {
  name: 'OperatingHours',
  components: {
    ProfileField,
    ProfileForm,
    DataRow,
    CoverageType,
    CoverageBlocks,
    ClientCoverageChangeModal
  },
  props: {
    clientId: {
      type: String,
      required: true
    },
    consultRateId: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      operatingHoursData: null,
      rawOperatingHours: null,
      currentOperatingHourChanges: null,
      coverageBlocksRequired: false,
      editWarningModal: false,
      hasSwitched: false,
      client: null,
      beforeEditOperatingHours: null,
      operatingHours: null,
      defaultTimezone: 'America/Chicago',
      operatingHourTypeOptions: Object.values(
        OPERATING_HOUR_TYPES.OPERATING_HOUR_TYPE
      ),
      selectedOperatingHourType: null,
      rules: {
        requireValueDropDown: (val) => {
          if (val) return true
          return false
        },
        requireValue: (val, index, key, message) => {
          if (val || index === this.operatingHours[key].times.length - 1)
            return true
          return message
        },
        requireTimeToBeOrdered: (val, key, index, message) => {
          if (
            this.operatingHours[key].times.length - 1 === index &&
            !val.end_at &&
            !val.start_at
          )
            return true

          if (
            val.start_at &&
            val.start_at.slice(0, 5) === '00:00' &&
            val.end_at &&
            val.end_at.slice(0, 5) === '00:00'
          )
            return true

          return this.timesAreOrdered(val) ? true : message
        },
        requireStartAndEndTimes: (val, key, index) => {
          if (
            this.operatingHours[key].times.length - 1 === index &&
            !val.end_at &&
            !val.start_at
          )
            return true

          if ((val.start_at && !val.end_at) || (val.end_at && !val.start_at))
            return 'Both start and end times are required'
          return true
        }
      }
    }
  },
  computed: {
    modalContent() {
      if (!this.newClient && this.coverageBlocksRequired && this.hasSwitched)
        return 'Existing Operating Hours will be replaced by uploaded coverage blocks'
      if (!this.newClient && !this.coverageBlocksRequired && this.hasSwitched)
        return 'Coverage blocks will be replaced by Operating Hours.'
      return ''
    },

    newClient() {
      return (
        Object.entries(this.rawOperatingHours).length === 0 &&
        !this.client.coverage_blocks_required
      )
    }
  },
  watch: {
    selectedOperatingHourType: {
      handler: function () {
        this.setOperatingHoursBasedOffType()
      },
      immediate: false
    }
  },
  async created() {
    this.rawOperatingHours = this.$store.state.operatingHours.data

    const operatingHourData = structuredClone(this.addSwitchToObjectValues())
    this.operatingHours = structuredClone(operatingHourData)

    this.client = structuredClone(this.clientById()(this.clientId))
    this.coverageBlocksRequired = this.client.coverage_blocks_required
    this.selectedOperatingHourType = this.getSelectedOperatingHourType()

    this.currentOperatingHourChanges = this.operatingHours
  },
  methods: {
    ...mapActions([
      'operatingHoursForClient',
      'saveOperatringHoursForClient',
      'createOperatingHourClient'
    ]),
    ...mappedClientGetters(['clientById']),
    ...mappedOperatingHoursGetter([
      'getSelectedOperatingHourType',
      'getDaylightOperatingHours',
      'getOperatingHoursToFullTime',
      'addSwitchToObjectValues'
    ]),
    ...mappedClientActions(['editClient']),

    isEditMode(value) {
      this.$emit('editModeForOperatingHours', value)
    },
    setOperatingHoursBasedOffType() {
      if (
        this.selectedOperatingHourType ===
        OPERATING_HOUR_TYPES.OPERATING_HOUR_TYPE.DAYTIME
      )
        this.operatingHours = this.getDaylightOperatingHours()
      if (
        this.selectedOperatingHourType ===
        OPERATING_HOUR_TYPES.OPERATING_HOUR_TYPE.FULL_COVERAGE
      )
        this.operatingHours = this.getOperatingHoursToFullTime()
      if (
        this.selectedOperatingHourType ===
        OPERATING_HOUR_TYPES.OPERATING_HOUR_TYPE.CUSTOM
      ) {
        this.operatingHours = this.currentOperatingHourChanges
      }
    },
    timesAreOrdered(times) {
      const startAt = new moment(times?.start_at, 'HH:mm')
      const endAt = new moment(times?.end_at, 'HH:mm')
      return startAt.isBefore(endAt)
    },
    timesAreBothMidnight(times) {
      return (
        times.start_at &&
        times.start_at.slice(0, 5) === '00:00' &&
        times.end_at &&
        times.end_at.slice(0, 5) === '00:00'
      )
    },
    showAdd(index, key) {
      return (
        ((index === this.operatingHours[key].times.length - 1 &&
          this.operatingHours[key].times.length !== 1) ||
          this.operatingHours[key].times.length === 1) &&
        (this.timesAreBothMidnight(this.operatingHours[key].times[index]) ||
          this.timesAreOrdered(this.operatingHours[key].times[index]))
      )
    },
    showSubtract(index, key) {
      return index !== this.operatingHours[key].times.length - 1
    },
    async confirmModal() {
      this.hasSwitched = false

      //TODO add bug for string issue
      await this.editClient({
        clientId: this.clientId,
        payload: {
          coverage_blocks_required: this.coverageBlocksRequired.toString()
        }
      })

      if (!this.coverageBlocksRequired) {
        await this.createOperatingHourClient({
          clientId: this.clientId,
          payload: {
            client_id: this.clientId,
            operating_hours: {
              sunday: [],
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: []
            },
            time_zone: this.defaultTimezone
          }
        })
      }

      this.clearForm()
      this.editWarningModal = false
    },
    closeModal() {
      this.clearForm()
      this.editWarningModal = false
    },
    clearForm() {
      this.rawOperatingHours = this.$store.state.operatingHours.data
      this.client = structuredClone(this.clientById()(this.clientId))
      this.hasSwitched = false

      this.selectedOperatingHourType = this.getSelectedOperatingHourType()
      this.coverageBlocksRequired = this.client.coverage_blocks_required
      this.operatingHours = structuredClone(this.addSwitchToObjectValues())

      this.currentOperatingHourChanges = this.operatingHours
    },
    hasSwitchedCoverageType(coverage) {
      this.hasSwitched = this.client.coverage_blocks_required !== coverage
      this.coverageBlocksRequired = coverage

      this.editWarningModal = !this.newClient && this.hasSwitched ? true : false
    },
    async onSubmit() {
      //TODO bug for string value
      if (this.newClient) {
        await this.editClient({
          clientId: this.clientId,
          payload: {
            coverage_blocks_required: this.coverageBlocksRequired.toString()
          }
        })
      }

      if (!this.coverageBlocksRequired) {
        if (this.newClient)
          await this.createOperatingHourClient({
            clientId: this.clientId,
            payload: {
              client_id: this.clientId,
              operating_hours: this.formatSaveTime(),
              time_zone: this.defaultTimezone
            }
          })
        else
          await this.saveOperatringHoursForClient({
            clientId: this.clientId,
            operatingHourId: this.rawOperatingHours.id,
            payload: {
              client_id: this.clientId,
              operatingHourId: this.rawOperatingHours.id,
              operating_hours: this.formatSaveTime(),
              time_zone: this.defaultTimezone
            }
          })
      }

      this.clearForm()
      this.operatingHours = structuredClone(this.addSwitchToObjectValues())
      this.currentOperatingHourChanges = this.operatingHours
    },
    formatOperatingHourTimes(times) {
      if (times.length === 0)
        return OPERATING_HOUR_TYPES.HAS_OPERATING_HOURS.CLOSED

      let formattedTime = ''

      times.forEach((time, index) => {
        if (times.length > 1 && index != times.length - 1)
          formattedTime += `${this.formatViewTime(
            time.start_at
          )} - ${this.formatViewTime(time.end_at)}, `
        else
          formattedTime += `${this.formatViewTime(
            time.start_at
          )} - ${this.formatViewTime(time.end_at)} CT`
      })

      return formattedTime
    },
    formatViewTime(time) {
      return moment(time, 'HH:mm').format('h:mm a')
    },
    formatSaveTime() {
      return Object.entries(this.operatingHours).reduce(
        (result, [day, entries]) => {
          const formattedTimes = entries.times.map((time) => {
            if (time.start_at && time.end_at)
              return {
                start_at: new moment(time.start_at, 'HH:mm').format('HH:mm:ss'),
                end_at: new moment(time.end_at, 'HH:mm').format('HH:mm:ss')
              }
          })
          result[day.toLowerCase()] = formattedTimes.filter((x) => x)
          return result
        },
        {}
      )
    },
    addTime(key) {
      this.operatingHours[key].times.push({ start_at: null, end_at: null })
    },
    removeTime(index, key) {
      this.operatingHours[key].times.splice(index, 1)
    },
    hasOperatingHoursSwitch(key) {
      if (!this.operatingHours[key].switch) this.operatingHours[key].times = []
      else this.operatingHours[key].times.push({ start_at: null, end_at: null })
    }
  }
}
</script>

<style lang="scss" scoped>
.hideClock {
  ::v-deep ::-webkit-calendar-picker-indicator {
    display: none;
  }
}
</style>
