<template>
  <div>
    <slot :update-query="updateQuery" :query-value="queryValue" />
  </div>
</template>

<script>
export default {
  name: 'RouterQueryController',
  props: {
    queryName: {
      type: String,
      required: true
    }
  },
  computed: {
    queryValue() {
      return this.$route.query[this.queryName]
    }
  },
  methods: {
    queryEquals(value) {
      const currentQuery = this.$route.query[this.queryName]
      return (
        Array.isArray(currentQuery) &&
        Array.isArray(value) &&
        currentQuery.length === value.length &&
        currentQuery.every((val, i) => val === value[i])
      )
    },
    updateQuery(value) {
      if (!this.queryEquals(value)) {
        this.$router.replace({
          query: { ...this.$route.query, [this.queryName]: value || undefined }
        })
      }
      return value
    }
  }
}
</script>
