<template>
  <div>
    <div class="d-flex">
      <h6 class="pa-4">Metrics</h6>
      <v-spacer />
    </div>
    <template v-if="metrics.length > 0">
      <v-row class="px-4 pt-4 mb-0 headers">
        <v-col class="col-3">Metrics</v-col>
        <v-col>Status</v-col>
        <v-col class="col-4">Trending</v-col>
        <v-col>Recent Rate</v-col>
        <v-col>Cumulative Rate</v-col>
      </v-row>
      <div v-for="(metric, index) in metrics" :key="index">
        <v-row class="d-flex px-4 pt-2 mb-0 data-rows">
          <v-col class="pt-5 col-3">
            {{ metric.title }}
          </v-col>
          <v-col class="pt-5">
            <div class="d-flex">
              <div class="pill px-2" :class="formatPerformance(metric).class">
                <v-icon size="small" fill="currentColor">{{
                  formatPerformance(metric).icon
                }}</v-icon>
                <span class="ml-1">{{ formatPerformance(metric).text }}</span>
              </div>
            </div>
          </v-col>
          <v-col class="pt-5 col-4">
            <div class="d-flex">
              <div class="pill-container d-flex">
                <div
                  class="d-flex px-2 pill"
                  :class="{
                    positive: isPositiveChange(metric),
                    negative: !isPositiveChange(metric),
                    neutral: metric.change === 0
                  }"
                >
                  <v-icon size="medium">{{ icon(metric) }}</v-icon>
                  <div>
                    <span class="change-text">
                      {{ changeText(metric) }}
                    </span>
                  </div>
                </div>
              </div>
              <p class="mb-0 ml-2">
                {{ formatText(metric) }}
              </p>
            </div>
          </v-col>
          <v-col class="pt-5">
            <b>{{ formatValue(metric) }}</b> {{ formatDurationText(metric) }}
          </v-col>
          <v-col class="pt-5">
            <b>{{ formatCumulativeRate(metric) }}</b>
            {{ formatCumulativeText(metric) }}
          </v-col>
        </v-row>
      </div>
    </template>
    <div v-else class="pl-4">There are no metrics for this clinician.</div>
  </div>
</template>

<script>
import {
  METRIC_COUNT_UNIT_TYPES,
  getPerformanceType,
  formatWeeks,
  formatCumulativeRate,
  formatValue,
  formatDurationText,
  formatCumulativeText
} from '@/utils/MetricTypes'
export default {
  name: 'ClinicianCollabAgreements',
  components: {},
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      metrics: [],
      loadingData: true
    }
  },
  computed: {},
  created() {
    this.init()
  },
  methods: {
    init: async function () {
      this.clinicianLocal = this.clinician
      if (this.clinicianLocal.hour_agreement)
        this.clinicianLocal.hour_agreement = parseFloat(
          +this.clinicianLocal.hour_agreement
        )
      this.loadMetricsData()
    },
    async loadMetricsData() {
      try {
        this.loadingData = true
        this.metrics = (
          await this.$clinicians.getMetrics(this.clinician.id)
        ).data
      } catch (e) {
        let message = 'Unable to load metric Data. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.loadingData = false
      }
    },
    formatPerformance(metric) {
      switch (getPerformanceType(metric.name, metric.long_term_value)) {
        case 'top-performer':
          return {
            class: 'good',
            text: 'Top Performer',
            icon: 'mdi-star-four-points'
          }
        case 'great':
          return {
            class: 'good',
            text: 'Great',
            icon: 'mdi-star-four-points'
          }
        case 'needs-improvement':
          return {
            class: 'ok',
            text: 'Needs improvement',
            icon: 'mdi-alert'
          }
        case 'poor':
          return {
            class: 'poor',
            text: 'Warning: Poor',
            icon: 'mdi-alert-circle'
          }
        default:
          return {}
      }
    },
    formatText(metric) {
      if (metric.count_unit === METRIC_COUNT_UNIT_TYPES.CONSULT)
        return `Compared to the last ${metric.long_term_count}`
      return `Compared to ${formatWeeks(metric.long_term_count)}`
    },
    changeText(metric) {
      const sign =
        !this.isPositiveChange(metric) || metric.change == 0 ? '' : '+'
      return sign + metric.change + '%'
    },
    isPositiveChange(metric) {
      return metric.change >= 0
    },
    icon(metric) {
      if (metric.change === 0) return 'mdi-arrow-right'
      return this.isPositiveChange(metric)
        ? 'mdi-arrow-top-right'
        : 'mdi-arrow-bottom-right'
    },
    formatCumulativeRate(metric) {
      return formatCumulativeRate(metric)
    },
    formatCumulativeText(metric) {
      return formatCumulativeText(metric)
    },
    formatValue(metric) {
      return formatValue(metric.short_term_value, metric.count_unit)
    },
    formatDurationText(metric) {
      return formatDurationText(metric.count_unit, metric.short_term_count)
    }
  }
}
</script>

<style lang="scss" scoped>
.headers {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

::v-deep .data-rows {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.pill-container {
  width: 82px;
}

.pill {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  border-radius: 40px;
  align-items: center;
}
.good {
  background-color: #e9ffe9;
  color: #0b5b0b;
}
.good > i.v-icon.v-icon {
  color: #0b5b0b;
}
.ok {
  background-color: #fff0e5;
  color: #b24b01;
}
.ok > i.v-icon.v-icon {
  color: #b24b01;
}
.poor {
  background-color: #fff0f5;
  color: #c0033e;
}
.poor > i.v-icon.v-icon {
  color: #c0033e;
}

.positive {
  background-color: #e9ffe9;
  color: #0b5b0b;
}

.positive > i {
  color: #34c759;
}

.neutral {
  background-color: #fff7ee;
  color: #b34700;
}

.neutral > i {
  color: #b34700;
}

.negative {
  background-color: #fff0f5;
  color: #c0033e;
}

.negative > i {
  color: #ff3b30;
}
</style>
