<template>
  <div class="search-filters ml-2">
    <div class="search-filters__trigger">
      <slot name="trigger" :toggle="toggle">
        <v-btn
          text
          large
          :class="selections > 0 ? 'search-filters__trigger-applied' : ''"
          @click="toggle"
        >
          <v-chip v-if="selections > 0" color="primary">
            <strong>{{ selections }}</strong>
          </v-chip>
          <v-icon v-else>mdi-filter-variant</v-icon>
          <span class="ml-2">Filters</span>
        </v-btn>
      </slot>
    </div>
    <right-drawer v-model="show" class="search-filters__content">
      <header slot="prepend" class="d-flex align-center search-filters__header">
        <h3 class="h5 ml-2 search-filters__content-title">Filters</h3>
        <v-btn fab text class="search-filters__content-close" @click="toggle">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </header>
      <footer slot="append" class="search-filters__footer">
        <v-btn text class="ml-auto" @click="reset"> Clear All </v-btn>
        <v-btn depressed rounded class="primary" @click="apply">
          Apply Filters
        </v-btn>
      </footer>
      <slot />
    </right-drawer>
  </div>
</template>

<script>
import RightDrawer from './RightDrawer.vue'

export default {
  name: 'SearchFilter',
  components: {
    RightDrawer
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    selections() {
      return Object.values(this.value).reduce((total, item) => {
        return total + item.length
      }, 0)
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
    apply() {
      this.toggle()
      this.$emit('apply')
    },
    reset() {
      this.toggle()
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss" scoped>
.search-filters {
  &__trigger {
    display: inline-block;
  }

  &__trigger-applied {
    background: #dee3dc;
  }

  &__content {
    padding: size(48);
    min-width: size(532);

    &-close {
      margin-left: auto;
      align-self: flex-end;
    }
  }

  &__footer {
    text-align: right;
  }
}
</style>
