<template>
  <div class="activity-rates-table">
    <navigating-data-table
      class="mt-4"
      :navigate="(item) => `/activity-rates/${item.id}`"
      :headers="headers"
      :items="activityRates"
      :items-per-page="10"
      :loading="loading"
      multi-sort
      fixed-header
      :mobile-breakpoint="0"
    >
      <template #item.rate="{ item }">
        <span class="text-capitalize">{{
          item.activity_type | snakeToTitle
        }}</span>
      </template>
      <template #item.activity_type="{ item }">
        <v-chip>{{ item.activity_type }}</v-chip>
      </template>
      <template #item.cost_to_client="{ item }">
        {{ item.cost_to_client | currency }}
      </template>
      <template #item.paid_to_provider="{ item }">
        {{ item.paid_to_provider | currency }}
      </template>
    </navigating-data-table>
  </div>
</template>

<script>
import NavigatingDataTable from '@/components/tables/NavigatingDataTable'
import { SnakeToTitle } from '@/filters/SnakeToTitle'

export default {
  name: 'ActivityRatesTable',
  components: {
    NavigatingDataTable
  },
  filters: {
    SnakeToTitle
  },
  props: {
    loading: Boolean,
    activityRates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Rate',
          sortable: true,
          value: 'rate',
          class: 'activity-rate-table-row__id horizontal-fixed'
        },
        {
          text: 'Activity Type',
          value: 'activity_type'
        },
        {
          text: 'Cost to Client',
          value: 'cost_to_client'
        },
        {
          text: 'Paid to Provider',
          value: 'paid_to_provider'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-rate-table {
  ::v-deep {
    & .available-icon {
      background-color: $clinician-available;
      color: $clinician-available;
    }
    & .unavailable-icon {
      background-color: $clinician-unavailable;
      color: $clinician-unavailable;
    }

    .horizontal-fixed {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      z-index: 5 !important;
    }

    thead th:nth-child(3),
    tbody th:nth-child(3) {
      min-width: 460px;
      max-width: 460px;
    }

    thead th {
      white-space: nowrap;
      min-width: 57px;
    }
  }
}

.theme--light.v-chip {
  background: rgba(46, 79, 37, 0.24);
  margin: 0 0.5rem 0.25rem 0;
}
</style>
