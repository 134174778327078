<template>
  <search-filter
    v-model="selectedFilters"
    @reset="resetFilters"
    @apply="applyFilters"
  >
    <section class="filter-group flex flex-wrap mt-8">
      <ol>
        <li v-for="(filter, index) in filters" :key="index">
          <h4 class="search-title">{{ filter.name | formatOptionName }}</h4>
          <template v-if="index === 'licenses' || index === 'state'">
            <v-autocomplete
              v-model="selectedFilters[index]"
              :items="filter.options"
              :filter="filterStates"
              item-text="name"
              item-value="abbreviationLower"
              multiple
              chips
              clearable
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  :ripple="false"
                  @click:close="
                    removeFilterValue(data.item.abbreviationLower, index)
                  "
                >
                  {{ data.item.abbreviationUpper }}
                </v-chip>
              </template>
              <template #item="data">
                {{ data.item.name }} ({{ data.item.abbreviationUpper }})
              </template>
            </v-autocomplete>
          </template>
          <template v-else-if="index === 'supervised_clinician'">
            <v-autocomplete
              v-model="selectedFilters['supervised_clinician']"
              :items="filter.options"
              :search-input.sync="searchSupervisedClinicians"
              hide-no-data
              multiple
              clearable
              chips
              placeholder="Start typing to Search"
              item-value="id"
              item-text="name"
              return-object
              @change="clearInput($event, 'supervised_clinician')"
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  :ripple="false"
                  @click:close="removeFilterValue(data.item.id, index, true)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template #item="data">
                {{ data.item.name }}
              </template>
            </v-autocomplete>
          </template>
          <template v-else-if="index === 'supervising_clinician'">
            <v-autocomplete
              v-model="selectedFilters['supervising_clinician']"
              :items="filter.options"
              :search-input.sync="searchSupervisingClinicians"
              hide-no-data
              multiple
              clearable
              chips
              placeholder="Start typing to Search"
              item-value="id"
              item-text="name"
              return-object
              @change="clearInput($event, 'supervising_clinician')"
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  :ripple="false"
                  @click:close="removeFilterValue(data.item.id, index, true)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template #item="data">
                {{ data.item.name }}
              </template>
            </v-autocomplete>
          </template>
          <template v-else>
            <v-autocomplete
              v-model="selectedFilters[index]"
              :items="filter.options"
              item-text="display"
              item-value="value"
              multiple
              chips
              clearable
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  :ripple="false"
                  @click:close="removeFilterValue(data.item.value, index)"
                >
                  {{ data.item.display }}
                </v-chip>
              </template>
              <template #item="data">
                {{ data.item.display }}
              </template>
            </v-autocomplete>
          </template>
        </li>
      </ol>
    </section>
  </search-filter>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import debounce from 'debounce'

export default {
  name: 'Filters',
  filters: {
    formatOptionName: function (name) {
      return name.split('_').join(' ')
    }
  },
  components: {
    SearchFilter
  },
  props: {
    currentFilters: {
      type: Object,
      default() {
        return {}
      }
    },
    filterOptions: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      selectedFilters: {},
      filters: {},
      searchSupervisingClinicians: null,
      searchSupervisedClinicians: null
    }
  },
  watch: {
    searchSupervisedClinicians: {
      handler(search) {
        const selected = this.selectedFilters.supervised_clinician
        debounce(this.$emit('searchSupervised', search, selected), 350)
      }
    },
    searchSupervisingClinicians: {
      handler(search) {
        const selected = this.selectedFilters.supervising_clinician
        debounce(this.$emit('searchSupervising', search, selected), 350)
      }
    }
  },
  async created() {
    this.mergeSelectedFilters(this.currentFilters)
    this.mergeFilterOptions(this.filterOptions)
  },
  methods: {
    applyFilters() {
      if (this.selectedFilters.supervising_clinician?.length > 0)
        this.selectedFilters.supervising_clinician =
          this.selectedFilters.supervising_clinician.map(({ id }) => id)
      if (this.selectedFilters.supervised_clinician?.length > 0)
        this.selectedFilters.supervised_clinician =
          this.selectedFilters.supervised_clinician.map(({ id }) => id)
      this.$emit('apply', this.selectedFilters)
    },
    filterStates(item, queryText, itemText) {
      const term = queryText.toLowerCase()
      const name = itemText.toLowerCase()
      const abbrev = item.abbreviationLower

      return name.includes(term) || abbrev.includes(term)
    },
    mergeSelectedFilters(props) {
      this.selectedFilters = props
    },
    mergeFilterOptions(props) {
      this.filters = {
        ...props
      }
    },
    removeFilterValue(item, filter, object = false) {
      let index
      if (object)
        index = this.selectedFilters[filter].findIndex(
          (option) => option.id == item
        )
      else index = this.selectedFilters[filter].indexOf(item)

      if (index >= 0) {
        this.selectedFilters[filter].splice(index, 1)
      }
    },
    resetFilters() {
      for (const filter of Object.keys(this.selectedFilters)) {
        this.selectedFilters[filter] = []
      }
      this.$emit('reset', this.selectedFilters)
    },
    clearInput(event, field) {
      this.$nextTick(() => {
        if (field === 'supervising_clinician')
          this.searchSupervisingClinicians = ''
        else if (field === 'supervised_clinician')
          this.searchSupervisedClinicians = ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>
ol {
  list-style: none;
  padding: 0 0 0 8px;
}

.search-title {
  font-size: size(12);
  line-height: size(16);
  color: #666;
  text-transform: uppercase;
  @include font-monospaced;
}

.v-input--selection-controls.v-input {
  flex: 1 0 50%;
}

.theme--light.v-chip {
  background: rgba(230, 234, 229, 1);
  text-transform: capitalize;
}

.v-list {
  text-transform: capitalize;
}
</style>
