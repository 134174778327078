<template>
  <v-dialog
    v-model="paymentsDialog"
    content-class="confirm-cancel"
    max-width="600"
  >
    <template #activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-title class="flex align-center mr-2">
          <v-icon class="mr-2">mdi-currency-usd</v-icon>
          Run Ledger Payments
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card v-if="paymentsDialog">
      <v-card-title class="headline"> Run Ledger Payments </v-card-title>
      <v-card-text class="warning-alert">
        <v-alert
          color="orange"
          dense
          outlined
          text
          icon="mdi-alert-circle-outline"
        >
          Confirmation with Engineering and Payments is required before running
          payments!
        </v-alert>
      </v-card-text>
      <v-card-text>
        <v-form>
          To load the amount preview please select a month below
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="date"
                label="Select billables that occurred on"
                prepend-icon="event"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              no-title
              scrollable
              @input="
                $refs.menu.save(date)
                handleSelect()
              "
            />
          </v-menu>
        </v-form>
        <div>
          <data-row
            label="Amount to be paid:"
            :value="'$' + paymentAmount"
            :loading="loading"
          />
          <data-row
            label="Stripe Balance Available:"
            :value="'$' + stripeAmount"
            :loading="loading"
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="paymentsDialog = false"> Cancel </v-btn>
        <v-btn
          color="primary"
          rounded
          :loading="saving"
          :disabled="invalid"
          @click="handleConfirm"
        >
          Confirm
        </v-btn>
      </v-card-actions>
      <v-card-text class="alert">
        <v-alert
          v-if="error"
          color="red"
          dense
          outlined
          text
          icon="mdi-alert-circle-outline"
        >
          ERROR: Payment process was unsuccessful. No payments were sent to
          stripe. ({{ error }})
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Loading from '@/mixins/Loading'
import DataRow from '@/components/common/DataRow'
import moment from 'moment'

export default {
  name: 'RunLedgerPayments',
  components: {
    DataRow
  },
  mixins: [Loading],
  data() {
    return {
      date: '',
      error: '',
      successCount: 0,
      paymentsDialog: false,
      menu: false,
      loading: false,
      saving: false,
      stripeAmount: null,
      paymentAmount: null,
      success: false,
      insufficientBalance: false
    }
  },
  computed: {
    invalid() {
      return (
        !this.date ||
        this.loading ||
        this.saving ||
        this.error.length > 0 ||
        this.insufficientBalance
      )
    },
    since() {
      return moment(this.date).format('YYYY-MM-DDTHH:mm:SS')
    },
    until() {
      return moment(this.date).add(1, 'M').format('YYYY-MM-DDTHH:mm:SS')
    }
  },
  methods: {
    async handleSelect() {
      this.loading = true
      const data = await this.$payments.previewAmount(this.since, this.until)
      this.stripeAmount = this.money(data.stripeBalance)
      this.paymentAmount = this.money(data.amountToBePaid)
      this.insufficientBalance = !!(data.stripeBalance < data.amountToBePaid)
      this.loading = false
    },
    async handleConfirm() {
      this.saving = true
      try {
        const data = await this.$payments.runPayments(this.since, this.until)
        if (data === '') {
          this.saving = false
          this.success = true
          this.paymentsDialog = false
          this.$store.commit('SET_SNACKBAR', {
            message:
              'Ledger Payment Triggered successfully. It can take a few minutes for them to show up on Stripe.',
            show: true
          })
        }
      } catch (e) {
        this.error = e.message
        this.saving = false
      }
    },
    money(cents) {
      return cents > 0 ? (cents / 100.0).toFixed(2) : (0).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__text {
  .data-row,
  form {
    font-size: 1rem;
  }
}
</style>
