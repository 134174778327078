<template>
  <v-form
    v-if="optOutClientsFlag"
    ref="form"
    :loading="loading"
    class="px-4 py-4 clinician-info-form ml-n4 pl-4 mr-4"
    @submit.prevent="onSubmit"
  >
    <section @dblclick="enableEditMode">
      <header class="d-flex mb-4">
        <v-row>
          <v-col>
            <h6>Opt Outs Preferences</h6>
          </v-col>
          <v-col class="d-flex justify-end align-center">
            <v-btn class="edit-buttons" icon small @click="enableEditMode">
              <v-icon>$edit_outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </header>
    </section>
    <v-container v-if="!editMode" class="pa-0">
      <v-col v-if="sortedOptOuts.length == 0" class="pa-0">
        <data-row label="Opted Out Of" value="None" />
      </v-col>
      <v-col
        v-for="optOut in sortedOptOuts"
        :key="optOut.id"
        :edit-mode="editMode"
        class="pa-0"
      >
        <data-row
          label="Opted Out Of"
          :value="attributesAsReadable(optOut.attributes)"
        />
      </v-col>
    </v-container>
    <v-row
      v-for="(optOut, optOutIdx) in sortedOptOuts"
      v-else
      :key="optOut.id"
      :edit-mode="editMode"
    >
      <v-col
        v-for="(value, category, idx) in optOut.attributes"
        :key="idx"
        :edit-mode="editMode"
        class="mb-n6"
      >
        <profile-field
          v-slot="attrs"
          v-model="optOut.attributes[category]"
          :edit-mode="editMode"
        >
          <v-select
            v-bind="attrs"
            :label="convertToReadable(category)"
            outlined
            :name="category"
            :loading="loading"
            :disabled="
              (creatingNewOptOut && optOut.id != null) ||
              (selected && selected != optOut.id)
            "
            :items="sortedAvailableAttributes[category]"
            @change="(newValue) => handleSelect(newValue, optOutIdx, category)"
          />
        </profile-field>
      </v-col>
      <v-col v-if="editMode" cols="1" class="d-flex pt-6">
        <v-btn
          small
          rounded
          icon
          aria-label="delete"
          :disabled="creatingNewOptOut || (selected && selected != optOut.id)"
          @click="deleteOptOut(optOut.id, optOut.attributes)"
        >
          <v-icon>mdi-minus-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="editMode" class="pl-4">
      <v-btn
        depressed
        small
        class="primary--text"
        color="white"
        outlined
        rounded
        :disabled="selected != null || creatingNewOptOut"
        @click="onAddOptOut"
      >
        Add Another Preference
      </v-btn>
    </v-row>
    <v-col v-if="creatingNewOptOut || selected">
      <div class="mt-3 text-subtitle-2">
        You can add or edit one row of preferences at a time. Save or Cancel to
        edit or add another preference.
      </div>
    </v-col>
    <footer v-if="editMode">
      <v-row no-gutters class="d-flex justify-end">
        <v-btn plain rounded :loading="loading" @click="onCancel">
          Cancel
        </v-btn>
        <v-btn
          depressed
          rounded
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="!valid"
        >
          Save
        </v-btn>
      </v-row>
    </footer>
    <v-dialog v-model="showConfirm" :retain-focus="false" width="600">
      <v-card>
        <v-card-title class="headline"> Confirm Opt Out Removal</v-card-title>
        <v-card-text>
          <v-alert
            dense
            outlined
            icon="mdi-alert-circle-outline"
            class="text-color--orange-900 warning-alert mt-4 mb-4"
          >
            {{ attributesAsReadable(selectedAttributes) }} Consults
          </v-alert>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="onDialogCancel"> Cancel </v-btn>
            <v-btn color="primary" rounded @click="onDialogConfirm">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import DataRow from '@/components/common/DataRow.vue'
import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE as consultAttributesNamespace } from '@/store/consultAttributes/state'
const { mapActions } = createNamespacedHelpers(consultAttributesNamespace)

export default {
  name: 'ClinicianOptOutPreferences',
  components: {
    ProfileField,
    DataRow
  },
  props: {
    optOutsInfo: {
      type: Object,
      required: true
    },
    clinicianId: {
      type: String,
      required: true
    },
    clients: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      consultAttributes: [],
      selected: null,
      selectedAttributes: [],
      editMode: false,
      valid: false,
      currentOptOuts: this.optOutsInfo.opt_outs
        ? [...this.optOutsInfo.opt_outs]
        : [],
      previousOptOuts: null,
      creatingNewOptOut: false,
      error: null,
      optOutClientsFlag: false,
      attributeCategories: [],
      showConfirm: false,
      sortedOptOuts: []
    }
  },

  computed: {
    sortedAvailableAttributes() {
      let sorted = {}
      for (let attribute of this.consultAttributes) {
        let category = attribute.category
        if (!(category in sorted)) {
          sorted[category] = [{ text: 'All', value: null }]
        }
        sorted[category].push({
          text: this.convertToReadable(attribute.name),
          value: attribute.id
        })
      }
      return sorted
    }
  },
  async created() {
    this.loading = true
    await this.checkOptOutsFlag()
    await this.loadConsultAttributes()
    this.setInitialOptOuts()
    this.loading = false
  },

  methods: {
    ...mapActions(['getConsultAttributes', 'getCategories']),
    setInitialOptOuts() {
      this.sortedOptOuts = this.sortOptOuts(this.currentOptOuts)
    },
    async loadConsultAttributes() {
      this.consultAttributes = await this.getConsultAttributes()
      this.attributeCategories = await this.getCategories()
    },
    async checkOptOutsFlag() {
      let configValues = await this.$configValues.getConfigValues()
      let optOutClientsValue = configValues?.data?.filter(
        (config) => config.name == 'consult_attributes_routing_clients'
      )?.[0]
      if (optOutClientsValue == null) return

      optOutClientsValue = optOutClientsValue.value.split(',').map(Number)
      for (let client of this.clients) {
        if (optOutClientsValue.includes(client.id)) {
          this.optOutClientsFlag = true
          return
        }
      }
    },
    convertToReadable(string) {
      const words = string.split('_')
      const capitalized = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      return capitalized.join(' ')
    },
    attributesAsReadable(attributes) {
      if (attributes == null) return

      let attributeNames = []
      for (let [category, value] of Object.entries(attributes)) {
        if (value == null) continue
        let attribute = this.sortedAvailableAttributes[category]?.find(
          (attr) => attr.value == value
        )
        attribute && attributeNames.push(attribute.text)
      }
      if (attributeNames.length == 1) return `All ${attributeNames[0]}`
      return `${attributeNames.join(' for ')}`
    },
    validateAttributeSelection() {
      if (this.selected == null && !this.creatingNewOptOut) {
        return false
      }
      let selectedOptOut = this.sortedOptOuts.find(
        (optOut) => optOut.id == this.selected
      )
      let attributes = Object.values(selectedOptOut.attributes).filter(
        (attribute) => attribute != null
      )
      return attributes.length != 0
    },
    generateBaseOptOut() {
      const base = this.attributeCategories.reduce((obj, category) => {
        obj[category] = null
        return obj
      }, {})
      return base
    },
    sortOptOuts(optOuts) {
      let sorted = []
      const base = this.generateBaseOptOut()

      for (let optOut of optOuts) {
        const sortedByCategory = structuredClone(base)
        for (let consultAttribute of optOut.consult_attributes) {
          sortedByCategory[consultAttribute.category] = consultAttribute.id
        }
        sorted.push({ id: optOut.id, attributes: sortedByCategory })
      }
      return sorted
    },
    handleSelect(value, idx, category) {
      this.selected = this.sortedOptOuts[idx].id
      this.sortedOptOuts[idx].attributes[category] = value
      this.valid = this.validateAttributeSelection()
    },
    async updateOptOut(attributes) {
      let response = await this.$optOuts.updateOptOut(this.selected, {
        clinician_id: this.clinicianId,
        consult_attributes: attributes
      })
      this.updateLocalOptOuts(
        this.selected,
        response.data.consult_attributes,
        response.data.id
      )
      return 'Successfully updated clinician opt out.'
    },
    async createOptOut(attributes) {
      let response = await this.$optOuts.createOptOut({
        id: this.selected,
        clinician_id: this.clinicianId,
        consult_attributes: attributes
      })
      this.updateLocalOptOuts(
        null,
        response.data.consult_attributes,
        response.data.id
      )
      return 'Successfully created clinician opt out.'
    },
    deleteOptOut(id, attributes) {
      this.selected = id
      this.selectedAttributes = attributes
      this.showConfirm = true
    },
    async onSubmit() {
      this.loading = true
      let selectedOptOut = this.sortedOptOuts.find(
        (optOut) => optOut.id == this.selected
      )
      let attributes = Object.values(selectedOptOut.attributes).filter(
        (attribute) => attribute != null
      )
      try {
        let successMessage
        if (this.creatingNewOptOut) {
          successMessage = await this.createOptOut(attributes)
        } else {
          successMessage = await this.updateOptOut(attributes)
        }
        this.triggerSnackBar(successMessage)
        this.clearForm()
      } catch (e) {
        this.handleResponseError(e, 'Opt out changes failed. ')
      }
      this.loading = false
    },
    onCancel() {
      if (this.previousOptOuts) {
        this.sortedOptOuts = structuredClone(this.previousOptOuts)
      }
      this.previousOptOuts = null

      this.clearForm()
    },
    clearForm() {
      this.error = null
      this.editMode = false
      this.previousOptOuts = null
      this.valid = false
      this.selected = null
      this.creatingNewOptOut = false
    },
    enableEditMode() {
      // this.previousOptOuts = structuredClone(this.currentOptOuts)
      this.previousOptOuts = structuredClone(this.sortedOptOuts)
      this.editMode = true
    },
    triggerSnackBar(message) {
      this.$store.commit('SET_SNACKBAR', {
        message,
        show: true
      })
    },
    handleResponseError(error, message) {
      if (error.response) {
        message += error.response.data.messages[0]
        this.error = error.response.data.messages[0]
      } else {
        message += error.message
      }
      this.triggerSnackBar(message)
    },
    updateLocalOptOuts(idx, attributes, id) {
      let createdOptOutIdx = this.sortedOptOuts.findIndex(
        (optOut) => optOut.id === idx
      )
      if (idx == null) {
        this.sortedOptOuts[createdOptOutIdx].id = id
      } // Set id for new opt outs
      const categorizedOptOut = this.generateBaseOptOut()
      for (let attribute of attributes) {
        categorizedOptOut[attribute.category] = attribute.id
      }
      this.sortedOptOuts[createdOptOutIdx].attributes =
        structuredClone(categorizedOptOut)
    },
    onAddOptOut() {
      this.creatingNewOptOut = true
      this.sortedOptOuts.push({
        id: null,
        attributes: structuredClone(this.generateBaseOptOut())
      })
      this.valid = false
    },
    onDialogCancel() {
      this.showConfirm = false
      this.selected = null
      this.selectedAttributes = []
    },
    async onDialogConfirm() {
      this.showConfirm = false
      this.selectedAttributes = []
      try {
        await this.$optOuts.deleteOptOut(this.selected)
        this.triggerSnackBar('Successfully deleted opt out.')
        this.sortedOptOuts = this.sortedOptOuts.filter(
          (optOut) => optOut.id != this.selected
        )
        this.clearForm()
      } catch (e) {
        this.handleResponseError(e, 'Unable to delete clinician opt out. ')
      }
    }
  }
}
</script>

<style lang="scss">
.static-field-view {
  & i {
    margin-right: 20px;
  }
}
form {
  position: relative;
}

form .edit-buttons {
  display: none;
  position: absolute;
  right: 16px;
  top: 12px;
}

form:not(.readonly):not(.editing):hover {
  & .edit-buttons {
    display: block;
  }
  background: rgba(22, 22, 44, 0.04);
}
</style>
