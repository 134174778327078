<template>
  <header>
    <v-skeleton-loader v-if="loading" type="heading" />
    <div class="d-flex">
      <span v-if="loading" class="loading">{{ loadingValue }}</span>
      <router-link
        v-for="(breadcrumb, i) in breadcrumbs"
        v-else
        :key="breadcrumb.display"
        class="breadcrumb d-flex align-center"
        :to="breadcrumb.to"
      >
        <v-icon v-if="i" small class="mx-1">chevron_right</v-icon>
        <span :class="{ 'primary--text': !i }" class="body-2">
          {{ breadcrumb.display }}
        </span>
      </router-link>
    </div>
    <div class="mt-4 d-flex justify-space-between">
      <div class="flex-grow-1">
        <v-skeleton-loader v-if="loading" type="heading" />
        <h1 v-else class="h3"><slot /></h1>
        <v-skeleton-loader
          v-if="loading && $slots['subheader']"
          type="heading"
          class="mt-2"
          width="200"
        />
        <slot v-else name="subheader" />
      </div>
      <div v-if="$slots['actions']" class="d-flex">
        <v-skeleton-loader v-if="loading" type="button" />
        <slot v-else name="actions" />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'ViewHeader',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => {
        return [{ to: '/', display: '' }]
      }
    },
    loading: Boolean
  },
  data() {
    return { loadingValue: '' }
  }
}
</script>

<style lang="scss">
.h3 {
  .v-btn,
  .v-input {
    font-family: 'Apercu Regular', sans-serif;
  }
}

.loading {
  min-width: 500px;
}
</style>
