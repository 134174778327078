<template>
  <div v-if="!editMode">
    <data-row
      v-for="property in activityRateDetails"
      :key="property.label"
      :label="property.label"
      :value="property.value"
    />
  </div>
  <div v-else>
    <data-row label="Id" :value="activityRate.id" />
    <data-row label="Consult Rate" :value="activityRate.consult_rate.name" />
    <data-row label="Rate" :value="activityRate.name" />
    <data-row label="Activity Type" :value="activityRate.activity_type" />
    <v-form ref="form" v-model="valid">
      <div>
        <v-text-field
          label="Cost to Client"
          :value="displayValue('cost_to_client')"
          outlined
          clearable
          prefix="$"
          hint="Adjustment (+/-) to the consult rate's Cost to Clients per consult once completed."
          persistent-hint
          @input="updateInput('cost_to_client', $event)"
        />
        <v-text-field
          label="Paid to Provider"
          :value="displayValue('paid_to_provider')"
          outlined
          clearable
          prefix="$"
          hint="Adjustment (+/-) to the consult rate's Pay per Clinician per consult once completed."
          persistent-hint
          @input="updateInput('paid_to_provider', $event)"
        />
        <footer class="d-flex justify-end">
          <v-btn plain rounded @click="onCancel">Cancel</v-btn>
          <v-btn
            depressed
            rounded
            color="primary"
            :loading="saving"
            :disabled="shouldDisable"
            @click="handleConfirm"
          >
            Save Changes
          </v-btn>
        </footer>
      </div>
    </v-form>
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow'

export default {
  name: 'ActivityRateDetails',
  components: { DataRow },
  props: {
    activityRate: {
      type: Object,
      required: true
    },
    activityRateDetails: {
      type: Array,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      valid: false,
      editInputs: {},
      saving: false,
      editError: 'Unable to edit activity rate at this time. '
    }
  },
  computed: {
    shouldDisable() {
      return !this.valid || !Object.keys(this.editInputs).length
    }
  },
  methods: {
    async onCancel() {
      this.editInputs = {}
      this.$emit('cancel')
    },
    isDirty(key) {
      return Object.keys(this.editInputs).includes(key)
    },
    displayValue(key) {
      return this.isDirty(key) ? this.editInputs[key] : this.activityRate[key]
    },
    updateInput(key, val) {
      this.$set(this.editInputs, key, val)
    },
    async handleConfirm() {
      try {
        this.saving = true
        this.results = await this.$activityRates.edit(
          this.activityRate.id,
          this.editInputs
        )
        this.$emit('confirm', this.results)
        this.$store.commit('SET_SNACKBAR', {
          message: 'Success! Activity rate was edited.',
          show: true
        })
      } catch (e) {
        let message = this.editError
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  ::v-deep {
    .v-messages__message {
      margin-bottom: 10px;
    }
  }
}
</style>
