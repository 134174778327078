<template>
  <profile-form
    v-slot="{ editMode, loading }"
    :action-function="actionFunction"
    class="clinician-summary-info-form ml-n4 pl-4 mr-n4"
    hide-clear
    @submit="(event) => $emit('submit', event)"
  >
    <h6>Clinician Information</h6>
    <profile-field
      v-model="clinicianLocal.practitioner_type"
      :edit-mode="editMode"
      :loading="loading || practitionerTypesLoading"
    >
      <v-select
        v-model="clinicianLocal.practitioner_type"
        label="Clinician Type"
        outlined
        :error-messages="errorMessage"
        :items="practitionerTypes"
        name="practitioner_type"
        :loading="loading"
        class="mt-4 mb-0"
      >
        <template #selection="{ item }">
          {{ item.value }}: {{ item.display }}
        </template>
        <template #item="{ item }">
          {{ item.value }}: {{ item.display }}
        </template>
      </v-select>
      <template #view>
        <data-row
          label="Clinician type"
          :value="clinician.practitioner_type | practitionerType"
        />
      </template>
    </profile-field>

    <v-text-field
      v-show="editMode"
      :value="clinician.id"
      type="text"
      outlined
      label="UUID"
      disabled
    />
    <data-row v-show="!editMode" label="UUID" :value="clinician.id" copy />

    <v-text-field
      v-show="editMode"
      :value="clinician.on_demand_id"
      type="text"
      outlined
      label="On Demand ID"
      disabled
    />
    <data-row
      v-show="!editMode"
      label="On Demand ID"
      :value="clinician.on_demand_id"
      copy
    />

    <profile-field
      v-model="clinicianLocal.status"
      :edit-mode="editMode"
      :loading="loading"
    >
      <v-select
        v-model="clinicianLocal.status"
        label="Account status"
        outlined
        :items="clinicianStatus"
        name="status"
      />
      <template #view>
        <data-row
          label="Account status"
          class="text-capitalize"
          :value="clinician.status"
        />
      </template>
    </profile-field>

    <v-text-field
      v-show="editMode"
      :value="clinician.updated_at | format"
      type="text"
      outlined
      label="Account modified"
      disabled
    />
    <data-row
      v-show="!editMode"
      label="Account modified"
      :value="clinician.updated_at | format"
    />
  </profile-form>
</template>

<script>
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import DataRow from '@/components/common/DataRow.vue'

export default {
  name: 'ClinicianSummaryInfo',
  components: {
    ProfileForm,
    DataRow,
    ProfileField
  },
  props: {
    clinician: {
      type: Object,
      required: true
    },
    actionFunction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      clinicianStatus: [
        { text: 'Active', value: 'active' },
        { text: 'Suspended', value: 'suspended' }
      ],
      practitionerTypes: [],
      practitionerTypesLoading: false,
      error: null
    }
  },
  computed: {
    clinicianLocal: {
      get: function () {
        return this.clinician
      },
      set: function (value) {
        return this.$emit('update:clinician', value)
      }
    },
    errorMessage() {
      return this.error?.message
    }
  },
  async created() {
    try {
      this.practitionerTypesLoading = true
      this.practitionerTypes = await this.$clinicians.getFieldValues(
        'practitioner_types'
      )
    } catch (e) {
      this.error = e
    } finally {
      this.practitionerTypesLoading = false
    }
  }
}
</script>

<style lang="scss">
.clinician-summary-info {
  &-form {
    width: calc(100% + size(32)) !important;
    margin-right: 36px;
    flex: 1;
  }
}
.static-field-view {
  & i {
    margin-right: 20px;
  }
}
</style>
