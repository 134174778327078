<template>
  <div class="collabs-view">
    <header>
      <div class="d-flex justify-space-between align-center">
        <div>
          <h2>Collab Agreements</h2>
        </div>
        <div class="mb-2">
          <add-collab-dialog />
        </div>
      </div>
    </header>
    <tabs>
      <template #collab-agreements>
        <collab-agreements-table />
      </template>
      <template #state-requirements>
        <error-display v-slot="{ showError }">
          <state-collab-requirements-table
            :loading="loadingStateRequirements"
            :state-collab-requirements="stateCollabRequirements"
            @error="showError"
          />
        </error-display>
      </template>
    </tabs>
  </div>
</template>

<script>
import Tabs from '@/components/common/Tabs'
import AddCollabDialog from '@/components/collab-agreements/AddCollabDialog'
import CollabAgreementsTable from '@/components/collab-agreements/CollabAgreementsTable'
import StateCollabRequirementsTable from '@/components/state-collab-requirements/StateCollabRequirementsTable'
import ErrorDisplay from '@/components/common/ErrorDisplay'

export default {
  name: 'CollabsView',
  components: {
    ErrorDisplay,
    Tabs,
    AddCollabDialog,
    StateCollabRequirementsTable,
    CollabAgreementsTable
  },
  data() {
    return {
      loadingAgreements: true,
      loadingStateRequirements: true,
      collabAgreements: [],
      stateCollabRequirements: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    async loadStateRequirementData() {
      try {
        this.loadingStateRequirements = true
        const stateCollabResponse =
          await this.$stateCollabRequirements.getAllStateCollabRequirements()
        this.stateCollabRequirements = stateCollabResponse.data
      } catch (e) {
        let message = 'Unable to load State Requirements Table. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.loadingStateRequirements = false
      }
    },
    init: async function () {
      this.loadStateRequirementData()
    }
  }
}
</script>

<style lang="scss">
.collabs-view {
  height: 100%;
  padding-top: 4rem;
}
</style>
