<template>
  <div>
    <v-row class="full-calendar" :class="{ loading: loading }">
      <div v-if="loading" class="loader">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        />
      </div>
      <v-col>
        <v-alert
          v-if="activeErrors.length"
          class="mt-4 mb-0 pb-0"
          type="error"
          border="top"
          colored-border
        >
          <div v-for="value in activeErrors" :key="value.key">
            There was an error loading {{ value.key }}. {{ value.error }}.
            Please
            <a class="retry" @click="refetchEvents"> try again. </a>
          </div>
        </v-alert>
        <full-calendar ref="fullCalendar" :options="calendarOptions">
          <template #eventContent="{ event }">
            <v-tooltip
              v-if="event.source.id === eventSources.SHIFTS.id"
              left
              color="white"
              content-class="event-container__tooltip elevation-2 pa-2"
            >
              <template #default>
                <ul class="ma-0 pa-0">
                  <li
                    v-for="client in event.extendedProps.clients"
                    :key="client.id"
                  >
                    <client-icon
                      class="mr-2"
                      :uuid="client.uuid"
                      :name="client.name"
                      :tooltip="false"
                      :size="24"
                    />
                    <span class="ml-2">{{ client.client_name }}</span>
                  </li>
                </ul>
              </template>
              <template #activator="{ attrs, on }">
                <div class="event-container" v-bind="attrs" v-on="on">
                  <div
                    v-for="shift in event.extendedProps.clients"
                    :key="shift.id"
                    class="d-none"
                  >
                    <div :data-client="shift.client_id" :data-id="shift.id">
                      {{ shift.client_name }}: {{ shift.id }}
                    </div>
                  </div>
                  <p>
                    {{ getTimeRange(event.start, event.end) }}
                  </p>
                  <v-icon
                    v-if="event.extendedProps.allowDelete"
                    color="white"
                    dense
                    aria-label="Remove from calendar"
                    @click="clickDeleteButton(event)"
                  >
                    mdi-delete
                  </v-icon>
                </div>
              </template>
            </v-tooltip>
            <v-tooltip
              v-else-if="event.source.id === eventSources.BOOKINGS.id"
              left
              nudge-top="10"
              color="white"
              content-class="event-container__tooltip elevation-2 pa-2"
            >
              <template #default>
                <p class="my-0"><strong>Scheduled Consult</strong></p>
                <p class="mt-0">
                  {{ getTimeRange(event.start, event.end) }}
                </p>
                <ul class="ma-0 pa-0">
                  <li>
                    <client-icon
                      :uuid="event.extendedProps.client.uuid"
                      :name="event.extendedProps.client.name"
                      :tooltip="false"
                      :size="24"
                    />
                    <span class="ml-2">{{
                      event.extendedProps.client.name
                    }}</span>
                  </li>
                </ul>
              </template>
              <template #activator="{ attrs, on }">
                <router-link
                  class="event-container booking-container"
                  :to="`/consults/${event.extendedProps.consultId}`"
                  target="_blank"
                >
                  <div
                    class="event-container booking-container"
                    v-bind="attrs"
                    :data-id="event.extendedProps.consultId"
                    :data-scheduled-shift-id="event.extendedProps.shiftId"
                    v-on="on"
                  >
                    <p class="booking-title">
                      {{ getTimeRange(event.start, event.end) }}
                    </p>
                    <v-icon dense color="white">mdi-account</v-icon>
                  </div>
                </router-link>
              </template>
            </v-tooltip>
            <div v-else class="event-container">
              <p>{{ event.title }}</p>
            </div>
          </template>
        </full-calendar>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showModal"
      max-width="800px"
      @click:outside="handleClose"
      @keydown="handleClose"
    >
      <shift-create-modal
        v-if="shiftToCreate"
        :clinician="clinician"
        :shift="shiftToCreate"
        @close="handleClose"
        @confirm="refetchEvents"
      />
      <shift-delete-modal
        v-else-if="shiftToDelete"
        :shift="shiftToDelete"
        @close="handleClose"
        @confirm="refetchEvents"
      />
      <shift-edit-modal
        v-else-if="shiftToEdit"
        :shift="shiftToEdit"
        :affected-bookings="shiftEditBookings"
        @close="handleClose"
        @confirm="refetchEvents"
      />
    </v-dialog>
    <clinician-schedule-stack-shifts
      :clinician-clients="clinician.clients"
      :clinician-on-demand-id="clinician.on_demand_id"
    />
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import TimeGridPlugin from '@fullcalendar/timegrid'
import InteractionPlugin from '@fullcalendar/interaction'
import moment from 'moment-timezone'
import ClientIcon from '@/components/common/ClientIcon.vue'
import ShiftCreateModal from '@/components/clinicians/schedule/ShiftCreateModal'
import ShiftDeleteModal from '@/components/clinicians/schedule/ShiftDeleteModal'
import ShiftEditModal from '@/components/clinicians/schedule/ShiftEditModal'
import { getCoverageClassName, getCoverageDisplay } from '@/filters/Coverage'
import ClinicianScheduleStackShifts from '@/components/clinicians/ClinicianScheduleStackShifts'

const EVENT_SOURCES = {
  BOOKINGS: {
    id: 'bookings',
    name: 'bookings'
  },
  COVERAGE: {
    id: 'coverage-blocks',
    constraint: 'coverageBlock',
    name: 'coverage blocks'
  },
  SHIFTS: {
    id: 'shifts',
    name: 'shifts'
  }
}

export default {
  name: 'ClinicianSchedule',
  components: {
    ClinicianScheduleStackShifts,
    ShiftEditModal,
    ShiftDeleteModal,
    ShiftCreateModal,
    FullCalendar,
    ClientIcon
  },
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      eventSources: EVENT_SOURCES,
      initialDate: null,
      showModal: false,
      shiftToDelete: null,
      shiftToEdit: null,
      shiftEditBookings: [],
      editRevert: null,
      shiftToCreate: null,
      loading: false,
      errors: {
        [EVENT_SOURCES.BOOKINGS.id]: null,
        [EVENT_SOURCES.SHIFTS.id]: null,
        [EVENT_SOURCES.COVERAGE.id]: null
      },
      calendar: {
        display: {
          plugins: [TimeGridPlugin, InteractionPlugin],
          initialView: 'timeGridWeek',
          slotDuration: '00:30:00',
          allDaySlot: false,
          nowIndicator: true,
          initialDate: this.$route.query.date,
          datesSet: this.setDate
        },
        toolbar: {
          headerToolbar: {
            left: '',
            center: 'title',
            right: 'prev,next today'
          },
          titleFormat: {
            year: 'numeric',
            month: 'long'
          }
        },
        sizing: {
          height: 'auto',
          expandRows: true
        },
        events: {
          eventChange: this.onEventChange,
          editable: true,
          eventDidMount: this.handleEventMount,
          eventResizableFromStart: true,
          loading: (loading) => (this.loading = loading),
          eventSources: [
            {
              id: EVENT_SOURCES.BOOKINGS.id,
              events: this.getBookingEvents,
              color: '#1E3418',
              editable: false,
              failure: (error) =>
                (this.errors[EVENT_SOURCES.BOOKINGS.id] = error.message)
            },
            {
              id: EVENT_SOURCES.SHIFTS.id,
              events: this.getShiftEvents,
              editable: true,
              color: '#2E4F25',
              failure: (error) =>
                (this.errors[EVENT_SOURCES.SHIFTS.id] = error.message)
            },
            {
              id: EVENT_SOURCES.COVERAGE.id,
              events: this.getCoverageBlockEvents,
              failure: (error) =>
                (this.errors[EVENT_SOURCES.COVERAGE.id] = error.message)
            }
          ]
        },
        selecting: {
          selectable: true,
          selectAllow: this.allowSelect,
          select: this.handleSelect
        }
      }
    }
  },
  computed: {
    activeErrors() {
      return Object.keys(this.errors)
        .filter((key) => this.errors[key])
        .map((key) => ({ key, error: this.errors[key] }))
    },
    calendarOptions() {
      return {
        ...this.calendar.display,
        ...this.calendar.events,
        ...this.calendar.selecting,
        ...this.calendar.sizing,
        ...this.calendar.toolbar
      }
    }
  },
  methods: {
    // FullCalendar Event Source Loading
    async getCoverageBlockEvents({ start, end }) {
      this.errors[EVENT_SOURCES.COVERAGE.id] = null
      const coverageBlocks = await this.$clinicians.getCoverageBlocks(
        this.clinician.id,
        start,
        end,
        moment.tz.guess()
      )

      return coverageBlocks.map((coverageBlock) => {
        const title = coverageBlock.shift_types
          .map((type) => getCoverageDisplay(type))
          .sort()
          .join(' / ')

        const className = coverageBlock.shift_types
          .map((type) => getCoverageClassName(type))
          .sort()
          .join(' ')

        return {
          title,
          start: coverageBlock.start_at,
          end: coverageBlock.end_at,
          display: 'background',
          className: `coverage-block ${className}`
        }
      })
    },
    async getShiftEvents({ start, end }) {
      const shifts = await this.$clinicians.getShifts(
        this.clinician.id,
        start,
        end
      )
      this.errors[EVENT_SOURCES.SHIFTS.id] = null
      return shifts.map((shift) => {
        const color = this.getShiftColor(shift.shift_type.type)
        return {
          start: shift.start_at,
          end: shift.end_at,
          extendedProps: {
            clients: shift.client_shifts,
            allowDelete: new Date() < new Date(shift.end_at),
            shiftType: shift.shift_type.type,
            error: null,
            loading: false
          },
          color
        }
      })
    },
    async getBookingEvents({ start, end }) {
      const bookings = await this.$clinicians.getBookings(
        this.clinician.id,
        start,
        end
      )
      this.errors[EVENT_SOURCES.BOOKINGS.id] = null
      return bookings.map((booking) => {
        const bookingStart = new Date(booking.buffer_start_at).toISOString()
        const bookingEnd = new Date(booking.buffer_end_at).toISOString()

        return {
          start: bookingStart,
          end: bookingEnd,
          extendedProps: {
            shiftId: booking.scheduled_shift_id,
            client: booking.client,
            consultId: booking.id
          }
        }
      })
    },
    handleEventMount({ el, event }) {
      if (
        [EVENT_SOURCES.BOOKINGS.id, EVENT_SOURCES.SHIFTS.id].includes(
          event.source.id
        )
      ) {
        el.parentElement.classList.add(`fc-${event.source.id}`)
      }
    },
    getTimeRange(start, end) {
      const format = (date) => moment(date).format('h:mma')
      return `${format(start)} - ${format(end)}`
    },
    // FullCalendar Interactivity
    allowSelect(event, oldEvent) {
      const duration = moment.duration(30, 'minutes').valueOf()
      const thirtyMinuteFloor = moment(
        Math.floor(moment().valueOf() / duration) * duration
      )

      if (!oldEvent) {
        return thirtyMinuteFloor.isSameOrBefore(event.start)
      }

      const withinStartThreshold =
        event.startStr === oldEvent.startStr ||
        thirtyMinuteFloor.isSameOrBefore(event.start)
      const withinEndThreshhold =
        event.endStr === oldEvent.endStr ||
        moment().startOf('minute').isSameOrBefore(event.end)

      return withinStartThreshold && withinEndThreshhold
    },
    handleSelect(event) {
      if (this.allowSelect(event)) {
        this.shiftToCreate = event
        this.showModal = true
      }
    },
    async onEventChange({ event, oldEvent, revert }) {
      if (
        event.source.id === EVENT_SOURCES.SHIFTS.id &&
        this.allowSelect(event, oldEvent)
      ) {
        const shiftIds = event.extendedProps.clients.map((client) => client.id)
        const bookings = this.$refs.fullCalendar
          .getApi()
          .getEvents()
          .filter((calendarEvent) => {
            return (
              calendarEvent.source.id === EVENT_SOURCES.BOOKINGS.id &&
              shiftIds.includes(calendarEvent.extendedProps.shiftId) &&
              (calendarEvent.start < event.start ||
                calendarEvent.end > event.end)
            )
          })
        if (bookings.length) {
          this.shiftToEdit = event
          this.shiftEditBookings = bookings
          this.editRevert = revert
          this.showModal = true
        } else {
          await this.editShift(event)
        }
      } else {
        revert()
      }
    },
    clickDeleteButton(event) {
      if (event.source.id === EVENT_SOURCES.SHIFTS.id) {
        this.shiftToDelete = event
        this.showModal = true
      }
    },
    handleClose() {
      if (this.editRevert) {
        this.editRevert()
      }
      this.shiftToCreate = null
      this.shiftToEdit = null
      this.shiftToDelete = null
      this.editRevert = null
      this.showModal = false
    },
    async editShift(event) {
      try {
        this.loading = true
        await Promise.all(
          event.extendedProps.clients
            .filter((client) => {
              return client.source_system === 'System'
            })
            .map((shift) =>
              this.$clinicians.updateShift(shift.id, {
                start_at: event.start,
                end_at: event.end
              })
            )
        )
      } catch (e) {
        let message = 'Unable to edit shift. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.loading = false
        this.refetchEvents()
      }
    },
    refetchEvents() {
      this.$refs.fullCalendar.getApi().refetchEvents()
    },
    getShiftColor(shiftType) {
      if (shiftType === 'on_call') {
        return '#F38D00'
      }

      return '#2E4F25'
    },
    setDate({ start }) {
      const date = moment(start).format('YYYY-MM-DD')
      if (this.$route.query.date !== date) {
        this.$router.replace({ query: { ...this.$route.query, date } })
      }
    }
  }
}
</script>
<style lang="scss">
.loading .fc-timegrid-slot-lane {
  background: rgba(0, 0, 0, 0.25);
  border: 0;
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.event-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  padding: 1px 4px;

  &__tooltip {
    border: 1px solid #d4d4d4;
    color: black;
    ul {
      list-style: none;
    }
    li {
      display: flex;
      align-items: center;
      margin-bottom: size(2);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.booking {
  &-container {
    text-decoration: none;
    transition: inset 0.3s ease-in-out;
  }
  &-title {
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 100%;
  }
}

.fc {
  &-timegrid-event-harness {
    margin-right: 5%;
  }
  &-shifts:hover {
    z-index: 9 !important;
  }
}

.coverage-block {
  border-radius: 4px;
  margin: 2px;
  opacity: 1 !important;
  font-size: 12px;
  &.--standard {
    background-color: #d5dfd278;
    color: #2e4f25;
  }
  &.--on-call {
    background-color: #ffd0896e;
    color: #f38d00;
  }
  &.--standard.--on-call {
    background-color: #c3f6ff73;
    color: #00adc1;
  }
}
</style>
