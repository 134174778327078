import Vue from 'vue'
import Vuex from 'vuex'
import deepmerge from 'deepmerge'
import state from '@/store/state'
import customMutations from '@/store/mutations'

// Modules
import clients from '@/store/clients'
import operatingHours from './operatingHours/index'
import consultAttributes from './consultAttributes/index'

Vue.use(Vuex)

const storeKeys = Object.keys(state)

/**
 * Generates mutations for base level domain store and outer most object properties. The convention it follows for
 * naming is based on Redux / Vuex style "enums" (all caps snake case consts). So a base store mutation becomes
 * "SET_[STORE_NAME]" and "SET_[STORE_NAME]_PROP_[PROPERTY_NAME]"
 *
 * @type {Object} - an object where all keys map to a function
 */
const mutations = storeKeys.reduce((acc, storeName) => {
  const toSnakeAndUpperCase = (s) =>
    s.replace(/[A-Z]/g, (subString) => `_${subString}`).toUpperCase()
  const setterLabel = `SET_${toSnakeAndUpperCase(storeName)}`
  const clearLabel = `CLEAR_${toSnakeAndUpperCase(storeName)}`

  // note: general setter for entire [domain specific] store state
  acc[setterLabel] = function (state, data) {
    Vue.set(state, storeName, data)
  }

  const initialState = state[storeName]

  acc[clearLabel] = function (state, data) {
    Vue.set(state, storeName, initialState)
  }

  // note: setters for individual properties within a [domain specific] store
  const storePropKeys = Object.keys(state[storeName])
  storePropKeys.forEach((propertyName) => {
    const propertySetterLabel = `SET_${toSnakeAndUpperCase(
      storeName
    )}_PROP_${toSnakeAndUpperCase(propertyName)}`

    acc[propertySetterLabel] = function (state, data) {
      Vue.set(state[storeName], propertyName, data)
    }
  })

  if (customMutations[storeName]) Object.assign(acc, customMutations[storeName])

  return acc
}, {})

const getters = storeKeys.reduce((acc, storeName) => {
  acc[storeName] = (state) => state[storeName]
  return acc
}, {})

export const storeConfig = {
  modules: {
    clients: deepmerge(clients, {}),
    operatingHours: deepmerge(operatingHours, {}),
    consultAttributes: deepmerge(consultAttributes, {})
  },
  state,
  getters,
  mutations
}

export default new Vuex.Store(deepmerge(storeConfig, {}))
