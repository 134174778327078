<template>
  <div v-if="!editMode">
    <data-row
      v-for="property in consultRateTierDetails"
      :key="property.label"
      :label="property.label"
      :value="property.value"
    />
  </div>
  <div v-else>
    <data-row label="Id" :value="consultRateTier.id" />
    <data-row label="Consult Rate" :value="consultRateTier.consult_rate.name" />
    <data-row label="Tier" :value="consultRateTier.tier" />
    <data-row label="States" :value="showStates" />
    <v-form ref="form" v-model="valid">
      <div>
        <v-text-field
          label="Cost to Client"
          :value="displayValue('cost_to_client')"
          outlined
          clearable
          prefix="$"
          hint="Determines the base cost to clients per consult once completed."
          persistent-hint
          @input="updateInput('cost_to_client', $event)"
        />
        <v-text-field
          label="Paid to Provider"
          :value="displayValue('paid_to_provider')"
          outlined
          clearable
          prefix="$"
          hint="Determines the base pay per clinician per consult once completed."
          persistent-hint
          @input="updateInput('paid_to_provider', $event)"
        />
        <v-text-field
          label="SLA (seconds)"
          :value="displayValue('sla_in_seconds')"
          outlined
          :rules="[rules.sla]"
          clearable
          hint="Determines the SLA given per consult."
          persistent-hint
          @input="updateInput('sla_in_seconds', $event)"
        />
        <v-text-field
          label="Assignment Lifetime (seconds)"
          :value="displayValue('assignment_lifetime')"
          outlined
          :rules="[rules.assignment_lifetime]"
          clearable
          hint="The time allowed for assignment."
          persistent-hint
          @input="updateInput('assignment_lifetime', $event)"
        />
        <footer class="d-flex justify-end">
          <v-btn plain rounded @click="onCancel">Cancel</v-btn>
          <v-btn
            depressed
            rounded
            color="primary"
            :loading="saving"
            :disabled="!valid"
            @click="handleConfirm"
          >
            Save Changes
          </v-btn>
        </footer>
      </div>
    </v-form>
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow'
import UnitedStates from '@/utils/UnitedStates'

export default {
  name: 'ConsultRateTierDetails',
  components: { DataRow },
  props: {
    consultRateTier: {
      type: Object,
      required: true
    },
    consultRateTierDetails: {
      type: Array,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      valid: false,
      rules: {
        sla: (v) => v >= 600 || 'SLA should be at least 10 minutes',
        assignment_lifetime: (v) =>
          v >= 60 || 'Assignment lifetime should be at least 60 seconds!'
      },
      editInputs: {},
      saving: false,
      editError: 'Unable to edit consult rate tier at this time. '
    }
  },
  computed: {
    showStates() {
      return this.consultRateTier.states
        .map((state) => UnitedStates[state.toUpperCase()].name)
        .join(', ')
    }
  },
  methods: {
    async onCancel() {
      this.editInputs = {}
      this.$emit('cancel')
    },
    isDirty(key) {
      return Object.keys(this.editInputs).includes(key)
    },
    displayValue(key) {
      return this.isDirty(key)
        ? this.editInputs[key]
        : this.consultRateTier[key]
    },
    updateInput(key, val) {
      this.editInputs[key] = val
    },
    async handleConfirm() {
      try {
        this.saving = true
        this.results = await this.$consultRateTiers.edit(
          this.consultRateTier.id,
          this.editInputs
        )
        this.$emit('confirm', this.results)
        this.$store.commit('SET_SNACKBAR', {
          message: 'Success! Consult rate tier was edited.',
          show: true
        })
      } catch (e) {
        let message = this.editError
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  ::v-deep {
    .v-messages__message {
      margin-bottom: 10px;
    }
  }
}
</style>
