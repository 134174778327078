<template>
  <v-snackbar v-model="show" :timeout="timeout" class="snackbar-container">
    {{ message }}
    <v-btn text color="accent" @click.native="show = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '',
      timeout: 7000
    }
  },
  created: function () {
    this.$store.watch(
      (state) => state.snackbar,
      () => {
        const message = this.$store.state.snackbar.message
        if (message !== '') {
          this.show = true
          this.message = message
          this.$store.commit('SET_SNACKBAR', { show: false, message: '' })
        }
      }
    )
  }
}
</script>

<style lang="scss">
.snackbar-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
