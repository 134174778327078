<template>
  <div>
    <h5>{{ year }}</h5>
    <v-data-table
      hide-default-footer
      :items-per-page="-1"
      :headers="headers"
      :items="earningsYear"
      @click:row="handleClick"
    >
      <template #item.year_month="{ item }">
        {{ item.year_month | monthName }}
      </template>
      <template #item.count="{ item }">
        {{ item.count }}
      </template>
      <template #item.paid_to_provider="{ item }">
        {{ item.paid_to_provider | currency }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { CLINICIAN_ROUTE } from '@/router/routes'
import { getMonthName } from '@/utils/Date'

export default {
  name: 'EarningsSummary',
  filters: {
    monthName: getMonthName
  },
  props: {
    clinician: {
      type: Object,
      required: true
    },
    year: {
      type: String,
      default: ''
    },
    earningsYear: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: function () {
    return {
      headers: [
        { text: 'Month', value: 'year_month', sortable: false },
        {
          text: 'Completed Consults',
          value: 'count',
          align: 'end',
          sortable: false
        },
        {
          text: 'Amount',
          value: 'paid_to_provider',
          align: 'end',
          sortable: false
        }
      ]
    }
  },
  methods: {
    handleClick: function ({ year_month: period }) {
      this.$router.push({
        name: CLINICIAN_ROUTE,
        params: { clinicianId: this.clinician.id, tab: 'earnings' },
        query: { period }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep td {
  cursor: pointer;
}
</style>
