<template>
  <v-data-table
    :loading="loading"
    hide-default-footer
    disable-pagination
    :headers="headers"
    :items="clientData"
    :mobile-breakpoint="0"
  >
    <!-- mobile-breakpoint=0 Seems counterintuitive, but makes sense for such a skinny table -->
    <template #item.client.name="{ item }">
      <client-label v-bind="item" />
    </template>
    <template #body.append>
      <tr>
        <td class="d-flex align-center">
          <v-icon x-large class="mr-2">mdi-subdirectory-arrow-right</v-icon>
          <span class="mt-2">{{ clientData.length }} Total</span>
        </td>
        <td class="text-end">
          <div class="pt-2">{{ totals.totalEnrollments }}</div>
        </td>
        <td class="text-end">
          <div class="pt-2">{{ totals.totalActiveStates }}</div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import ClientLabel from '@/components/common/ClientLabel'

export default {
  name: 'ClinicianEnrollmentSummary',
  components: {
    ClientLabel
  },
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      clientData: [],
      headers: [
        {
          text: 'Clients',
          value: 'client.name',
          class: 'text-h6 black--text',
          sortable: true
        },
        {
          text: 'Enrollments',
          value: 'enrollments_count',
          align: 'end',
          sortable: true,
          class: 'text-subtitle-1'
        },
        {
          text: 'Active States',
          value: 'active_states',
          align: 'end',
          sortable: true,
          class: 'text-subtitle-1'
        }
      ],
      loading: true
    }
  },
  computed: {
    totals() {
      return this.clientData.reduce(
        (acc, val) => {
          acc.totalEnrollments += val.enrollments_count
          acc.totalActiveStates += val.active_states
          return acc
        },
        {
          totalEnrollments: 0,
          totalActiveStates: 0
        }
      )
    }
  },
  watch: {
    clinician: {
      handler: 'loadEnrollments',
      immediate: true
    }
  },
  methods: {
    loadEnrollments: async function () {
      this.loading = true
      this.clientData = []
      const clientSummary = await this.$clinicians.getEnrollmentSummary(
        this.clinician.id
      )
      this.clientData = clientSummary.data
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
