<template>
  <div>
    <div v-if="period">
      <earnings-history :clinician="clinician" :summary="summary" />
    </div>
    <div v-else-if="hasEarnings">
      <router-view />
      <earnings-summary
        v-for="year in years"
        :key="year"
        :clinician="clinician"
        :year="year"
        :earnings-year="summary[year]"
      />
    </div>
    <div v-else>No earnings yet.</div>
  </div>
</template>

<script>
import EarningsSummary from '@/components/clinicians/earnings/EarningsSummary.vue'
import EarningsHistory from '@/components/clinicians/earnings/EarningsHistory.vue'

export default {
  name: 'ClinicianEarnings',
  components: {
    EarningsSummary,
    EarningsHistory
  },
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      summaryRes: [],
      period: this.$route.query.period
    }
  },
  computed: {
    summary() {
      const data = {}

      for (const month of this.summaryRes) {
        const [year] = month.year_month.split('-')

        if (data[year] === undefined) {
          data[year] = [month]
        } else {
          data[year].push(month)
        }
      }
      return data
    },
    hasEarnings() {
      return Object.keys(this.summary).length > 0
    },
    years() {
      return Object.keys(this.summary).sort(this.desc)
    }
  },
  watch: {
    $route(to, from) {
      this.period = this.$route.query.period
      if (!this.period) {
        this.loadEarnings(this.clinician.id)
      }
    }
  },
  created() {
    if (!this.period) {
      this.loadEarnings(this.clinician.id)
    }
  },
  methods: {
    async loadEarnings(id) {
      const { data } = await this.$clinicians.getEarningsSummary(id)
      this.summaryRes = data
    },
    desc: (a, b) => b - a
  }
}
</script>
