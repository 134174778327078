<template>
  <section v-if="consultRate">
    <view-header :breadcrumbs="breadcrumbs" :loading="loading">
      {{ consultRate.name }}
      <template slot="actions">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" rounded v-on="on">
              Actions
            </v-btn>
          </template>
          <v-list>
            <div>
              <v-dialog
                v-model="editWarningModal"
                max-width="600px"
                @click:outside="handleClose"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title class="text-capitalize">
                      <v-icon class="mr-2"> mdi-pencil </v-icon>
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <edit-warning-modal
                  v-if="editWarningModal"
                  v-model="editWarningModal"
                  type-title="consult rate"
                  :name="consultRate.name"
                  @close="handleClose"
                  @confirm="editDetails"
                />
              </v-dialog>
            </div>
            <div>
              <v-dialog
                v-model="createTierModal"
                max-width="600px"
                @click:outside="handleClose"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title class="text-capitalize">
                      <v-icon class="mr-2"> mdi-plus </v-icon>
                      Create Tier
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <consult-rate-tier-create-modal
                  v-if="createTierModal"
                  v-model="createTierModal"
                  :consult-rate-id="consultRateId"
                  :consult-rate-tiers="consultRateTiers"
                  :consult-rate-modality="consultRate.modality"
                  @close="handleClose"
                  @confirm="handleCreate"
                />
              </v-dialog>
            </div>
            <div>
              <v-dialog
                v-model="createActivityModal"
                max-width="600px"
                @click:outside="handleClose"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title class="text-capitalize">
                      <v-icon class="mr-2"> mdi-plus </v-icon>
                      Create Activity Rate
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <activity-rate-create-modal
                  v-if="createActivityModal"
                  v-model="createActivityModal"
                  :consult-rate-id="consultRateId"
                  :activity-rates="activityRates"
                  @close="handleClose"
                  @confirm="handleCreate"
                />
              </v-dialog>
            </div>
          </v-list>
        </v-menu>
      </template>
    </view-header>
    <div>
      <warning-banner
        v-if="activityRateMissingWarning"
        v-model="activityRateMissingWarning"
        :title="missingActivityRateTitle"
        :content="missingActivityRateMessage"
      />
    </div>
    <tabs>
      <template #enrollments>
        <enrollments-table
          v-slot="{ enrollment }"
          :loading="loadingEnrollments"
          :enrollments="enrollments"
          :first-column="firstTableHeader"
          :consult-rate="consultRate"
          :filter="enrollmentsFilter"
          @delete:enrollment="deleteEnrollment"
          @update:enrollment="updateEnrollment"
        >
          <clinician-label
            class="client-view__clinician-label"
            :name="enrollment.clinician.name"
            @click="openDrawer(enrollment.clinician)"
          />
        </enrollments-table>
        <right-drawer v-model="showDrawer" @input="onDrawerChange">
          <clinician-details
            v-if="selectedClinician"
            :clinician="selectedClinician"
            @close="closeDrawer"
          />
        </right-drawer>
      </template>
      <template #details>
        <div class="details">
          <consult-rate-details
            :consult-rate="consultRate"
            :consult-rate-details="consultRateDetails"
            :edit-mode="editMode"
            @cancel="handleCancel"
            @confirm="handleConfirm"
          />
        </div>
      </template>
      <template #forecast>
        <forecast-calendar
          :facet-id="consultRateId"
          :facet-type="forecastCalendarType"
        />
      </template>
      <template #consult-rate-tiers>
        <consult-rate-tiers-table
          :loading="loadingConsultRateTiers"
          :consult-rate-tiers="consultRateTiers"
        />
      </template>
      <template #activity-rates>
        <activity-rates-table
          :loading="loadingActivityRates"
          :activity-rates="activityRates"
        />
      </template>
    </tabs>
  </section>
</template>

<script>
import ClinicianDetails from '@/components/clinicians/ClinicianDetails.vue'
import EnrollmentsTable from '@/components/enrollments/EnrollmentsTable.vue'
import ClinicianLabel from '@/components/common/ClinicianLabel.vue'
import RightDrawer from '@/components/common/RightDrawer.vue'
import ViewHeader from '@/components/common/ViewHeader.vue'
import EnrollmentsMixin from '@/mixins/Enrollments'
import Tabs from '@/components/common/Tabs'
import ForecastCalendar, { RATE } from '@/components/forecasts/ForecastCalendar'
import ConsultRateTiersTable from '@/components/consult-rate-tiers/ConsultRateTiersTable'
import ActivityRatesTable from '@/components/activity-rates/ActivityRatesTable'
import ConsultRateTierCreateModal from '@/components/consult-rate-tiers/ConsultRateTierCreateModal'
import ActivityRateCreateModal from '@/components/activity-rates/ActivityRateCreateModal'
import EditWarningModal from '@/components/common/editWarningModal'
import ConsultRateDetails from '@/components/consult-rates/ConsultRateDetails'
import { Duration } from '@/filters/Moment'
import UNITED_STATES from '@/utils/UnitedStates'
import WarningBanner from '@/components/common/WarningBanner.vue'

const AUTOCOMPLETE_DAYS_DEFAULT = '30'

export default {
  name: 'ConsultRateView',
  components: {
    ForecastCalendar,
    Tabs,
    ClinicianDetails,
    RightDrawer,
    EnrollmentsTable,
    ViewHeader,
    ClinicianLabel,
    ConsultRateTiersTable,
    ActivityRatesTable,
    ConsultRateTierCreateModal,
    ActivityRateCreateModal,
    EditWarningModal,
    ConsultRateDetails,
    WarningBanner
  },
  filters: {
    Duration
  },
  mixins: [EnrollmentsMixin],
  props: {
    consultRateId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingConsultRateTiers: true,
      loadingEnrollments: true,
      loadingActivityRates: true,
      consultRate: null,
      consultRateTiers: [],
      activityRates: [],
      enrollments: [],
      loading: true,
      selectedClinician: null,
      showDrawer: false,
      forecastCalendarType: RATE,
      createTierModal: false,
      createActivityModal: false,
      editWarningModal: false,
      editMode: false,
      isSyncConsultRate: false,
      activityRateMissingWarning: false,
      missingActivityRateMessage:
        'This consult rate is missing an activity rate',
      missingActivityRateTitle: 'Missing Activity Rate'
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { to: '/clients', display: 'Clients' },
        {
          to: `/clients/${this.consultRate.client.id}`,
          display: `${this.consultRate.client.name}`
        }
      ]
    },
    firstTableHeader() {
      return {
        text: `${this.enrollments.length} Clinicians`,
        sortable: true,
        value: 'clinician.name',
        class: 'horizontal-fixed'
      }
    },
    consultRateDetails() {
      const {
        id,
        uuid,
        name,
        client,
        sla_in_seconds,
        estimated_duration_in_seconds,
        cost_to_client,
        paid_to_provider,
        assignment_lifetime,
        practitioner_types,
        autocomplete_in_days,
        advance_notice_in_seconds,
        ...rest
      } = this.consultRate

      return [
        {
          label: 'Id',
          value: id
        },
        {
          label: 'Uuid',
          value: uuid
        },
        {
          label: 'Consult Rate',
          value: name
        },
        {
          label: 'client',
          value: client.name
        },
        {
          label: 'SLA',
          value: this.$options.filters.Duration(sla_in_seconds)
        },
        {
          label: 'Estimated Duration',
          value: this.$options.filters.Duration(estimated_duration_in_seconds)
        },
        {
          label: 'Cost to Client',
          value: this.$options.filters.currency(cost_to_client)
        },
        {
          label: 'Paid to Provider',
          value: this.$options.filters.currency(paid_to_provider)
        },
        {
          label: 'Assignment Lifetime',
          value: this.$options.filters.Duration(assignment_lifetime)
        },
        {
          label: 'Practitioner Types',
          value: practitioner_types.join(', ')
        },
        {
          label: 'Autocomplete',
          value: (autocomplete_in_days ?? AUTOCOMPLETE_DAYS_DEFAULT) + ' day(s)'
        },
        {
          label: 'Advance Notice',
          value: this.$options.filters.Duration(advance_notice_in_seconds)
        },
        ...Object.entries(rest).map(([key, value]) => ({
          label: key,
          value
        }))
      ]
    }
  },
  created() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    enrollmentsFilter: function (item) {
      const stateNames = item.clinician.licenses.flatMap((license) => {
        const state = UNITED_STATES[license.toUpperCase()]
        if (state) {
          return [state.name, license]
        }
        return [license]
      })

      return [
        item.clinician.id,
        item.clinician.name,
        item.clinician.email,
        item.clinician.on_demand_id,
        ...stateNames
      ]
    },
    init: async function () {
      try {
        this.consultRate = await this.$consultRates.getById(this.consultRateId)
      } catch (e) {
        this.$router.replace('/404')
      }

      this.isSyncConsultRate = ['phone', 'chat', 'video'].includes(
        this.consultRate.modality
      )
      await this.getEnrollmentsFromConsultRate(this.consultRateId)
      await this.getConsultRateTiers(this.consultRateId)
      await this.getActivityRates(this.consultRateId)
    },
    getEnrollmentsFromConsultRate: async function (consultRateId) {
      if (consultRateId) {
        this.loadingEnrollments = true
        const response = await this.$consultRates.getEnrollments(consultRateId)
        this.enrollments = response.data
      }
      this.loadingEnrollments = false
    },
    getConsultRateTiers: async function (consultRateId) {
      if (consultRateId) {
        this.loadingConsultRateTiers = true
        const response = await this.$consultRates.getConsultRateTiers(
          consultRateId
        )
        this.consultRateTiers = response.data
      }
      this.loadingConsultRateTiers = false
    },
    getActivityRates: async function (consultRateId) {
      if (consultRateId) {
        this.loadingActivityRates = true
        const response = await this.$consultRates.getActivityRates(
          consultRateId
        )
        this.activityRates = response.data

        this.isActivityRateMissing()
      }
      this.loadingActivityRates = false
    },
    isActivityRateMissing() {
      if (this.isSyncConsultRate) {
        if (this.activityRates.length === 0) {
          this.activityRateMissingWarning = true
        } else this.activityRateMissingWarning = false
      }
    },
    openDrawer: function (clinician) {
      this.selectedClinician = clinician
      // do this last so all the data for the drawer is in place before showing it
      this.showDrawer = true
    },
    closeDrawer: function () {
      this.showDrawer = false
    },
    onDrawerChange(open) {
      if (!open) {
        this.getEnrollmentsFromConsultRate(this.selectedConsultRate)
      }
    },
    handleClose() {
      this.createTierModal = false
      this.createActivityModal = false
      this.editWarningModal = false
    },
    handleCreate() {
      this.createTierModal = false
      this.createActivityModal = false
      this.isActivityRateMissing()
    },
    editDetails() {
      this.editMode = true
      if (
        this.$router.currentRoute.path !==
        `/consult-rates/${this.consultRateId}/details`
      ) {
        this.$router.push({
          path: `/consult-rates/${this.consultRateId}/details`
        })
      }
    },
    handleCancel() {
      this.editMode = false
    },
    handleConfirm(results) {
      this.consultRate = results
      this.editMode = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
