<template>
  <selectable-data-table
    v-model="consultRates.selected"
    class="consult-rates-select-table"
    :headers="consultRates.headers"
    :items="consultRates.data"
    :loading="consultRates.loading"
    loading-text="Finding Consult Rates"
    item-key="id"
    disable-sort
    hide-default-footer
    :single-select="singleSelect"
    :hide-default-header="singleSelect || consultRates.loading"
    @input="$emit('input', $event)"
  >
    <template #header.name="{ header }">
      {{ consultRates.selected.length }} {{ header.text }}
    </template>
  </selectable-data-table>
</template>

<script>
import SelectableDataTable from '@/components/tables/SelectableDataTable'
const consultRatesDefaults = {
  loading: false,
  data: [],
  selected: [],
  headers: [{ text: 'Rates Selected', value: 'name' }]
}

const MODALITIES = {
  async: ['async'],
  sync: ['chat', 'phone', 'video']
}

export default {
  name: 'ConsultRatesSelectTable',
  components: {
    SelectableDataTable
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true
    },
    autoSelect: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      required: true
    },
    singleSelect: {
      type: Boolean,
      default: false
    },
    show: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      consultRates: { ...consultRatesDefaults }
    }
  },
  watch: {
    clientId: {
      handler: 'loadConsultRates',
      immediate: true
    }
  },
  methods: {
    async loadConsultRates() {
      this.consultRates.loading = true
      this.consultRates.data = []
      this.consultRates.selected = []
      const { data } = await this.$clients.getConsultRates(this.clientId)

      if (this.show) {
        this.consultRates.data = data.filter((rate) =>
          MODALITIES[this.show].includes(rate.modality)
        )
      } else {
        this.consultRates.data = data
      }

      if (this.autoSelect) {
        this.consultRates.selected = data
      }
      this.consultRates.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.consult-rates-select-table {
  ::v-deep td,
  ::v-deep th {
    border-bottom: none !important;
  }

  ::v-deep td {
    color: #16162c;
  }
}
</style>
