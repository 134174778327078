<template>
  <div>
    <div class="d-flex">
      <h6 class="pa-4">State Licenses</h6>
      <v-spacer />
    </div>
    <v-alert
      v-if="!clinician.verifiable_id"
      border="top"
      colored-border
      type="info"
      elevation="2"
      class="mx-2"
    >
      <p class="pb-2 alert-title">
        <strong>Licenses are now managed via Verifiable</strong>
      </p>
      <p>
        Please add a Verifiable ID to this clinician to get the most up-to-date
        license information.
      </p>
    </v-alert>
    <v-row class="px-4 pt-4 mb-0 headers">
      <v-col> State </v-col>
      <v-col> License # </v-col>
      <v-col> Expiration </v-col>
      <v-col> Verifiable Status </v-col>
      <v-col> Approved At </v-col>
      <v-col> Collab Status </v-col>
    </v-row>
    <div v-for="state in licensedStates" :key="state">
      <div v-for="(license, index) in clinicianLicenses[state]" :key="index">
        <v-row class="d-flex px-2 pt-2 mb-0 data-rows">
          <v-col>
            <state-chips v-if="setLicenseColor(state)" :states="[state]" />
            <state-chips v-else :states="[state]" error="true" />
          </v-col>
          <v-col class="pt-5">
            {{ license.license_number }}
          </v-col>
          <v-col class="pt-5">
            {{ formatExpiresDate(license.expires_at) }}
          </v-col>
          <v-col class="pt-5">
            {{ formatLicenseStatus(license.approved_status) }}
          </v-col>
          <v-col class="pt-5">
            {{ formatApprovedDate(license.approved_at) }}
          </v-col>
          <v-col class="pt-5">
            <span v-if="license.collabInfo.collabId">
              <router-link
                :to="`/collabs/agreements/${license.collabInfo.collabId}`"
                class="mr-2 meta flex-shrink-0 text-color--wheel-green"
              >
                {{ license.collabInfo.displayString }}
              </router-link>
            </span>
            <span v-else>
              {{ license.collabInfo.displayString }}
            </span>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { StateName } from '@/filters/States'
import { UNITED_STATES_ARRAY } from '@/utils/UnitedStates'
import { toUtc, formatDate } from '@/utils/Date'
import StateChips from '@/components/common/StateChips.vue'

export default {
  name: 'ProfileLicenses',
  components: { StateChips },
  filters: {
    StateName
  },
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      states: UNITED_STATES_ARRAY,
      acceptablePractitionerTypes: ['np'],
      clinicianLicenses: {}
    }
  },
  computed: {
    licensedStates() {
      return Object.keys(this.clinician.license_info).sort()
    }
  },
  created() {
    this.init()
  },
  methods: {
    init: function () {
      this.clinicianLicenses = this.clinician.license_info
      this.addCollabToLicenses()
    },
    formatApprovedDate(dateString) {
      if (!dateString) return ''
      return formatDate(toUtc(dateString), 'MM/DD/YY HH:MM z')
    },
    formatExpiresDate(dateString) {
      if (!dateString) return ''
      return formatDate(dateString, 'MM/DD/YY')
    },
    formatLicenseStatus(status) {
      if (!status) return ''
      return status === 'Yes' ? 'Approved' : status
    },
    setLicenseColor(state) {
      return this.clinician.licenses.includes(state)
    },
    addCollabToLicenses() {
      const validPractitionerType = this.checkPractitionerType()

      Object.keys(this.clinicianLicenses).forEach((license) => {
        const collabInfo = this.clinician.collab_status.find(
          (c) => c.state === license
        )

        if (collabInfo.collabRequired && validPractitionerType) {
          let collab

          if (collabInfo.collabs.length === 0) {
            collab = { displayString: 'Collab Missing', collabId: null }
          } else {
            collab = {
              displayString: collabInfo.collabs[0].status,
              collabId: collabInfo.collabs[0].collabId
            }
          }

          for (let i = 0; i < this.clinicianLicenses[license].length; i++) {
            this.clinicianLicenses[license][i].collabInfo = collab
          }
        } else {
          for (let i = 0; i < this.clinicianLicenses[license].length; i++) {
            this.clinicianLicenses[license][i].collabInfo = {
              displayString: 'Not Required',
              collabId: null
            }
          }
        }
      })
    },
    checkPractitionerType() {
      return this.acceptablePractitionerTypes.includes(
        this.clinician.practitioner_type
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.headers {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

.alert-title {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
::v-deep .data-rows {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
