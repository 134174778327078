<template>
  <v-navigation-drawer
    v-model="drawer"
    class="drawer"
    :mini-variant.sync="mini"
    app
  >
    <v-list-item class="mt-8 px-2">
      <v-list-item-avatar v-if="name">
        <v-img
          :src="
            'https://on-demand-public.s3.amazonaws.com/admin-icons/' +
            avatar +
            '.png'
          "
        />
      </v-list-item-avatar>

      <v-list-item-title
        ><h6 class="name-wrapper">{{ name }}</h6></v-list-item-title
      >
    </v-list-item>

    <v-list-item class="px-1" :link="!mini">
      <v-btn icon @click.stop="mini = !mini">
        <v-icon v-if="mini">chevron_right</v-icon>
        <v-icon v-else>chevron_left</v-icon>
      </v-btn>
      <v-list-item-title
        class="text-subtitle-2 pl-4"
        @click.stop="mini = !mini"
      >
        Collapse Menu
      </v-list-item-title>
    </v-list-item>

    <v-list class="mt-12">
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="{ path: item.path }"
        link
      >
        <v-list-item-icon>
          <v-tooltip right :disabled="!mini">
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                {{ item.icon }}
              </v-icon>
            </template>
            {{ item.title }}
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template #append>
      <v-list>
        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    toggle: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      showSyncSnackbar: false,
      drawer: null,
      items: [
        { title: 'Home', icon: '$house_outline', path: '/' },
        {
          title: 'Clinicians',
          icon: '$people_alt_outline',
          path: '/clinicians'
        },
        { title: 'Consults', icon: '$assignment_outline', path: '/consults' },
        { title: 'Clients', icon: 'business', path: '/clients' },
        {
          title: 'Opportunities',
          icon: 'mdi-alert-octagram-outline',
          path: '/opportunities'
        },
        {
          title: 'Performance',
          icon: 'mdi-chart-timeline-variant-shimmer',
          path: '/performance'
        },
        ...(process.env.VUE_APP_FEEDBACK_FEATURE_FLAG
          ? [
              {
                title: 'Feedback',
                icon: 'mdi-message-text-outline',
                path: '/feedback'
              }
            ]
          : []),
        ...(process.env.VUE_APP_COLLAB_AGREEMENTS_FEATURE_FLAG
          ? [
              {
                title: 'Collab Agreements',
                icon: 'handshake',
                path: '/collabs'
              }
            ]
          : []),
        {
          title: 'Payments & Transfers',
          icon: 'attach_money',
          path: '/payments-and-transfers'
        },
        { title: 'Forecast', icon: 'mdi-crystal-ball', path: '/forecast' },
        {
          title: 'Configuration Values',
          icon: 'mdi-cog',
          path: '/config-values'
        }
      ],
      mini: false,
      environments: ['Production', 'Sandbox'],
      SYNC_STATES: {
        LOADING: 'loading',
        ERROR: 'error',
        IDLE: 'idle',
        SUCCESS: 'success'
      }
    }
  },
  computed: {
    ...mapState({
      name: (state) => {
        return state.user.name
      }
    }),
    avatar() {
      return this.name.replace(' ', '-').toLowerCase()
    }
  },
  watch: {
    toggle() {
      this.drawer = this.toggle
    }
  },
  methods: {
    async logout() {
      const auth0enabled = await this.$auth0.useAuth0Async()
      if (auth0enabled) {
        this.$auth0.logout()
        return
      }
      await this.$auth.signOut({
        postLogoutRedirectUri: window.location.origin + '/login'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.name-wrapper {
  white-space: break-spaces;
}
.drawer__airtable-modal {
  & .v-btn {
    padding: 0 1rem;
    &--content {
      padding: 0;
    }
  }
  & .v-list-item.v-list-item--sync {
    padding-left: 0.5rem;
  }
  & .v-card__actions {
    padding: 0;
  }
}
</style>
