import Statsig from 'statsig-js'

import { getEnvMapping } from '@/constants/envs'

export const initializeStatsig = async () => {
  await Statsig.initialize(
    process.env.VUE_APP_STATSIG_CLIENT_ID,
    { userID: 'anonymous' },
    {
      environment: {
        tier: getEnvMapping(process.env.VUE_APP_APPLICATION_ENVIRONMENT)
      }
    }
  )
}

export const getExperimentFlag = async (experiment, flag, defaultValue) => {
  return await Statsig.getExperiment(experiment).get(flag, defaultValue)
}

export const checkGate = async (featureGate) => {
  return await Statsig.checkGate(featureGate)
}

export const updateStatsigUserId = async (userID) => {
  await Statsig.updateUser({ userID })
}
