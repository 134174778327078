<template>
  <ol class="routing-report-segment">
    <p class="routing-report-segment__header">
      {{ name | snakeToTitle }}: {{ segmentCount }}
    </p>
    <li
      v-for="(subject, id) in segment"
      :key="subject.id"
      :class="subject.scores ? 'mb-4' : ''"
    >
      <data-row class="mini" :label="subjectType">
        <router-link :to="`/${subjectType}s/${id}`">
          {{ subject.name || id }}
        </router-link>
      </data-row>
      <data-row
        v-for="(score, metric) in subject.scores"
        :key="id + metric"
        class="mini"
        :label="metric | snakeToTitle"
      >
        <routing-score :score="score" />
      </data-row>
    </li>
  </ol>
</template>

<script>
import RoutingScore from '@/components/routing-reports/RoutingScore.vue'
import DataRow from '@/components/common/DataRow.vue'
import { SnakeToTitle } from '@/filters/SnakeToTitle'

export default {
  name: 'RoutingReport',
  components: {
    RoutingScore,
    DataRow
  },
  filters: {
    SnakeToTitle
  },
  props: {
    segment: {
      type: Object,
      required: true
    },
    subjectType: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    segmentCount() {
      return Object.keys(this.segment).length
    }
  }
}
</script>

<style lang="scss" scoped>
.routing-report-segment {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: size(4);

    &:last-child {
      margin-bottom: size(16);
    }
  }

  &__header {
    font-weight: bold;
    text-transform: uppercase;
    font-size: size(14);
    margin-bottom: size(8);
  }
}
</style>
