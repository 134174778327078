var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Create new consult rate ")]),_c('v-card-text',{staticClass:"alert"},[_c('v-alert',{attrs:{"color":"red","dense":"","outlined":"","text":"","icon":"mdi-alert-circle-outline"}},[_vm._v(" Confirmation with Engineering, Payments, and Legal is required before creating a new consult rate! ")])],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"Consult Rate Name","rules":[_vm.requiredField],"clearable":""},model:{value:(_vm.createInputs.display_name),callback:function ($$v) {_vm.$set(_vm.createInputs, "display_name", $$v)},expression:"createInputs.display_name"}}),_c('v-text-field',{attrs:{"label":"Consult Rate UUID","rules":[_vm.isUuid],"clearable":""},model:{value:(_vm.createInputs.uuid),callback:function ($$v) {_vm.$set(_vm.createInputs, "uuid", $$v)},expression:"createInputs.uuid"}}),_c('v-autocomplete',{attrs:{"label":"Modality","items":_vm.modalityOptions,"item-text":"display","item-value":"value","rules":[_vm.requiredField],"clearable":"","hint":"Modality is not editable after creation. Selecting 'Instant (Labs) will set the consult as a lab type.","persistent-hint":""},model:{value:(_vm.createInputs.modality),callback:function ($$v) {_vm.$set(_vm.createInputs, "modality", $$v)},expression:"createInputs.modality"}}),_c('v-autocomplete',{attrs:{"label":"Type","items":_vm.typeOptions,"item-text":"display","item-value":"value","rules":[
          _vm.instantType(_vm.createInputs.consult_type, _vm.createInputs.modality)
        ],"clearable":"","hint":"Consult Type: Determines the workflow of the consult.","persistent-hint":""},model:{value:(_vm.createInputs.consult_type),callback:function ($$v) {_vm.$set(_vm.createInputs, "consult_type", $$v)},expression:"createInputs.consult_type"}}),_c('v-select',{attrs:{"label":"Practitioner Types","items":_vm.practitionerTypesOptions,"rules":[_vm.requiredField],"chips":"","multiple":"","hint":"Determines what practitioner types can enroll in this consult rate.","persistent-hint":""},model:{value:(_vm.createInputs.practitioner_types),callback:function ($$v) {_vm.$set(_vm.createInputs, "practitioner_types", $$v)},expression:"createInputs.practitioner_types"}}),_c('v-text-field',{attrs:{"label":"Cost to Client","rules":[
          _vm.requiredField,
          _vm.positiveValue(_vm.createInputs.cost_to_client, 'Cost to client'),
          _vm.twoDecimalsAllowed
        ],"clearable":"","prefix":"$","hint":"Determines the base cost to clients per consult once completed.","persistent-hint":""},model:{value:(_vm.createInputs.cost_to_client),callback:function ($$v) {_vm.$set(_vm.createInputs, "cost_to_client", $$v)},expression:"createInputs.cost_to_client"}}),_c('v-text-field',{attrs:{"label":"Paid to Provider","rules":[
          _vm.requiredField,
          _vm.positiveValue(_vm.createInputs.paid_to_provider, 'Paid to provider'),
          _vm.twoDecimalsAllowed
        ],"clearable":"","prefix":"$","hint":"Determines the base pay per clinician per consult once completed.","persistent-hint":""},model:{value:(_vm.createInputs.paid_to_provider),callback:function ($$v) {_vm.$set(_vm.createInputs, "paid_to_provider", $$v)},expression:"createInputs.paid_to_provider"}}),_c('v-text-field',{attrs:{"label":"SLA (seconds)","type":"number","rules":[
          _vm.requiredField,
          _vm.slaLength(_vm.createInputs.sla_in_seconds, _vm.createInputs.modality),
          _vm.noDecimalsAllowed
        ],"clearable":"","hint":"Determines the SLA given per consult.","persistent-hint":""},model:{value:(_vm.createInputs.sla_in_seconds),callback:function ($$v) {_vm.$set(_vm.createInputs, "sla_in_seconds", $$v)},expression:"createInputs.sla_in_seconds"}}),_c('v-text-field',{attrs:{"label":"Advance Notice (seconds)","type":"number","rules":[
          _vm.positiveValue(
            _vm.createInputs.advance_notice_in_seconds,
            'Advance notice'
          ),
          _vm.noDecimalsAllowed
        ],"clearable":"","hint":"Determines the advance notice for a consult","persistent-hint":""},model:{value:(_vm.createInputs.advance_notice_in_seconds),callback:function ($$v) {_vm.$set(_vm.createInputs, "advance_notice_in_seconds", $$v)},expression:"createInputs.advance_notice_in_seconds"}}),_c('v-autocomplete',{attrs:{"label":"Compensation Unit","items":_vm.compensationOptions,"item-text":"display","item-value":"value","rules":[_vm.requiredField],"clearable":"","hint":"Hourly compensation requires the Payments team to manually process each consult with this type.","persistent-hint":""},model:{value:(_vm.createInputs.compensation_unit),callback:function ($$v) {_vm.$set(_vm.createInputs, "compensation_unit", $$v)},expression:"createInputs.compensation_unit"}})],1)],1),_c('v-card-actions',[(_vm.modalError)?_c('span',{staticClass:"modal-error"},[_vm._v(_vm._s(_vm.modalError))]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","rounded":"","loading":_vm.saving,"disabled":!_vm.valid},on:{"click":_vm.handleConfirm}},[_vm._v(" Confirm ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }