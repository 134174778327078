import { keys as mutations } from './mutations'
import axios from 'axios'

const actions = {
  async getOperatingHoursByClientId({ commit }, { clientId, params }) {
    try {
      const response = await axios.get(
        `/admin/clients/${clientId}/operating_hours`,
        { params }
      )
      commit(mutations.set, response.data)
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  },
  async operatingHoursForClient({ commit, dispatch }, { clientId, params }) {
    await dispatch('getOperatingHoursByClientId', { clientId, params })
    await dispatch('clients/index', null, { root: true })
  },
  async getOperatingHoursByConsultRateId({ commit }, consultRateId) {
    try {
      const response = await axios.get(
        `/admin/consult_rates/${consultRateId}/operating_hours`
      )
      commit(mutations.set, response.data)
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  },
  async createOperatingHourClient({ commit }, { clientId, payload }) {
    try {
      const response = await axios.post(
        `/admin/clients/${clientId}/operating_hours/`,
        payload
      )
      commit(mutations.set, response.data)
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  },
  async saveOperatringHoursForClient(
    { commit },
    { clientId, operatingHourId, payload }
  ) {
    try {
      const response = await axios.patch(
        `/admin/clients/${clientId}/operating_hours/${operatingHourId}`,
        payload
      )
      commit(mutations.set, response.data)
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  }
}

export default actions
