<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    @click:outside="$refs.addCollab.close()"
  >
    <template #activator="{ on }">
      <v-btn
        color="primary"
        depressed
        rounded
        :disabled="addButtonDisabled"
        v-on="on"
      >
        Add Collab
      </v-btn>
    </template>
    <add-collab ref="addCollab" :dialog="dialog" @close="closeDialog" />
  </v-dialog>
</template>

<script>
import AddCollab from '@/components/collab-agreements/AddCollab.vue'

export default {
  name: 'AddCollabDialog',
  components: {
    AddCollab
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    addButtonDisabled() {
      return this.$route.path === '/collabs/state-requirements'
    }
  },

  methods: {
    closeDialog(value) {
      this.dialog = value
      this.$emit('close')
    }
  }
}
</script>
