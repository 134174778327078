<template>
  <div class="clinicians-mini-search">
    <search v-model="searchTerm" @input="search" />
    <v-data-table
      v-model="selected"
      disable-sort
      hide-default-footer
      :headers="headers"
      :options.sync="options"
      :items="clinicians"
      :loading="loading"
    >
      <template #item.name="{ item }">
        <clinician-label :name="item.name" />
      </template>
      <template #item.select="{ item }">
        <v-radio-group v-model="radioSelected" @change="radioSelect">
          <v-radio :value="item" />
        </v-radio-group>
      </template>
      <template #item.email="{ item }">
        <span class="text-lowercase">{{ item.email }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ClinicianLabel from '@/components/common/ClinicianLabel.vue'
import Search from '@/components/common/Search.vue'

export default {
  name: 'CliniciansMiniSearch',
  components: {
    ClinicianLabel,
    Search
  },
  props: {
    consultId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      selected: [],
      radioSelected: null,
      clinicians: [],
      headers: [
        { text: '', value: 'select' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Status', value: 'status' }
      ],
      options: {},
      searchTerm: '',
      page: 1
    }
  },
  watch: {
    selected() {
      this.$emit('input', this.selected[0])
    }
  },
  created() {
    this.loadClinicians()
  },
  methods: {
    async loadClinicians() {
      this.loading = true
      if (this.searchTerm) {
        const params = {
          search: this.searchTerm
        }
        this.clinicians = (
          await this.$consults.potentialAssignments(this.consultId, params)
        ).data
      } else {
        this.clinicians = (
          await this.$consults.potentialAssignments(this.consultId)
        ).data
      }
      this.loading = false
    },
    search() {
      this.loadClinicians()
    },
    radioSelect(clinician) {
      this.selected = [clinician]
    }
  }
}
</script>

<style lang="scss">
.clinicians-mini-search {
  height: 100%;

  .v-data-table {
    text-transform: capitalize;

    img {
      height: 0.75rem;
      width: 0.75rem;
    }
  }
}
</style>
