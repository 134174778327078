<template>
  <v-app>
    <Snackbar />
    <drawer v-if="allowDrawer" :toggle="toggleDrawer" />
    <v-main>
      <v-container fluid class="app-container">
        <v-btn
          v-if="$vuetify.breakpoint.mdAndDown"
          icon
          @click.stop="toggleDrawer = !toggleDrawer"
        >
          <v-icon>menu</v-icon>
        </v-btn>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Drawer from '@/components/app/Drawer'
import Snackbar from '@/components/common/Snackbar'
import axios from 'axios'
import authConfig from '@/config/auth'

export default {
  name: 'App',
  components: {
    Snackbar,
    Drawer
  },
  data: () => ({
    toggleDrawer: null,
    name: null
  }),
  computed: {
    allowDrawer() {
      if (this.$route.name === undefined) return false
      return !(this.$route.name === 'Login')
    }
  },
  created() {
    const apiOverride = localStorage.getItem('__ON_DEMAND_API_OVERRIDE__')
    axios.defaults.crossdomain = true
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.headers.common['Okta-Client-Id'] = authConfig.oidc.clientId
    axios.defaults.baseURL = apiOverride || process.env.VUE_APP_ON_DEMAND_API
    axios.interceptors.request.use(async (config) => {
      const useAuth0 = await this.$auth0.useAuth0Async()
      const accessToken = useAuth0
        ? await this.$auth0.getTokenSilently()
        : this.$auth.getAccessToken()
      config.headers.Authorization = 'Bearer ' + accessToken
      return config
    })
  },
  updated() {
    this.initializeUser()
  },
  methods: {
    async initializeUser() {
      const useAuth0 = await this.$auth0.useAuth0Async()

      if (await this.$auth.isAuthenticated()) {
        const user = useAuth0 ? this.$auth0.user : await this.$auth.getUser()
        this.$dd.setUser(user.email)
        this.$store.commit('SET_USER', user)
      }
    }
  }
}
</script>

<style lang="scss"></style>
