import Regex from '@/utils/Regex'

const Validation = {
  methods: {
    noDecimalsAllowed(v) {
      return (
        !v || /^[0-9]*$/.test(v) || 'This field does not allow decimal values'
      )
    },
    twoDecimalsAllowed(v) {
      return (
        !v ||
        Regex.MAXIMUM_TWO_DECIMAL_PLACES.test(v) ||
        'Please provide 2 or fewer decimals if adding a decimal'
      )
    },
    slaLength(v, modality) {
      const isInstant = modality === 'instant'
      return (
        !v ||
        (!isInstant && v >= 300) ||
        (isInstant && v >= 0) ||
        (isInstant
          ? 'SLA should be a valid time'
          : 'SLA should be at least 5 minutes')
      )
    },
    instantType(v, modality) {
      const isInstant = modality === 'instant'
      return (
        !v ||
        (v === 'lab' && isInstant) ||
        (v !== 'lab' && !isInstant) ||
        'lab type and instant modality must be selected together'
      )
    },
    requiredField(v) {
      return !!v || 'This field is required!'
    },
    positiveValue(v, fieldName) {
      return !v || v >= 0 || `${fieldName} must be greater than or equal to 0!`
    },
    isUuid(v) {
      return !v || Regex.UUID.test(v) || 'value must be a uuid'
    }
  }
}

export default Validation
