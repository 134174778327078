<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        depressed
        v-bind="attrs"
        rounded
        color="primary"
        :disabled="!clinicians.length && isProduction"
        v-on="on"
      >
        Actions
      </v-btn>
    </template>
    <v-list>
      <add-clinician-dialog v-if="!isProduction" />
      <client-dialog
        :clinicians="clinicians"
        :in-menu="true"
        :disabled="!clinicians.length && !isProduction"
      />
      <cohort-dialog
        :clinicians="clinicians"
        :disabled="!clinicians.length && !isProduction"
      />
      <suspend-dialog
        :clinicians="clinicians"
        :disabled="!clinicians.length"
        @reloadClinicians="$emit('reloadClinicians', $event)"
      />
    </v-list>
  </v-menu>
</template>

<script>
import ClientDialog from '@/components/clinicians/ClientDialog.vue'
import CohortDialog from '@/components/clinicians/CohortDialog.vue'
import SuspendDialog from '@/components/clinicians/SuspendDialog.vue'
import AddClinicianDialog from '@/components/clinicians/AddClinicianDialog.vue'

export default {
  name: 'ActionsDropdown',
  components: {
    ClientDialog,
    CohortDialog,
    SuspendDialog,
    AddClinicianDialog
  },
  props: {
    clinicians: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    isProduction() {
      return process.env.VUE_APP_APPLICATION_ENVIRONMENT === 'production'
    }
  }
}
</script>

<style lang="scss" scoped></style>
