import { keys as mutations } from './mutations'

import { createNamespacedHelpers } from 'vuex'
import { mapKeys } from '@/store/helpers'
import { NAMESPACE } from './state'
import axios from 'axios'

const { mapActions } = createNamespacedHelpers(NAMESPACE)

const actions = {
  async getConsultAttributes({ getters, commit }) {
    try {
      let response = structuredClone(getters.attributes.consultAttributes)
      if (!response) {
        response = await axios.get('/admin/consult_attributes')
        commit(mutations.set, response)
        return response.data
      }
      return response
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  },

  async getCategories({ getters, commit }) {
    try {
      let categories = structuredClone(getters.attributes.categories)
      return categories
    } catch (error) {
      this._vm.$dd?.addError(error)
      throw error
    }
  }
}

export const mappedActions = mapActions(Object.keys(actions))
export const actionKeys = mapKeys(actions, NAMESPACE)
export default actions
