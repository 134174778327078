<template>
  <v-dialog v-model="dialog" max-width="900" content-class="client-dialog">
    <template #activator="{ on, attrs }">
      <v-list-item v-if="inMenu" :disabled="disabled" v-bind="attrs" v-on="on">
        <v-list-item-title class="add-to-client flex align-center mr-2"
          ><v-icon class="mr-2">mdi-plus</v-icon> Add to
          Client</v-list-item-title
        >
      </v-list-item>
      <v-btn v-else depressed v-bind="attrs" color="primary" rounded v-on="on">
        Add to Client
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex align-center">
        <v-btn icon class="mr-3" @click="close">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
        <span class="header"> Add to Client </span>
        <v-btn
          class="primary ml-auto"
          rounded
          depressed
          :loading="loading"
          :disabled="!consultRates.length"
          @click="enroll"
        >
          Add
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="group-title">Clinicians</div>
        <v-chip
          v-for="clinician in clinicians"
          :key="clinician.id"
          class="mr-2 mb-2 clinician-chip"
          :ripple="false"
        >
          {{ clinician.name }}
        </v-chip>
        <div class="group-title">Client</div>
        <v-autocomplete
          v-model="selectedClient"
          :items="clients.data"
          :loading="clients.loading"
          :search-input.sync="search"
          prepend-inner-icon="mdi-magnify"
          return-object
          hide-no-data
          hide-selected
          no-filter
          outlined
          label="Clients"
          placeholder="Start typing to search for a client"
          clearable
        >
          <template #selection="{ item }">
            <client-label v-bind="item" />
          </template>
          <template #item="{ item }">
            <v-list-item-content>
              <client-label v-bind="item" />
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <consult-rates-select-table
          v-if="selectedClient"
          v-model="consultRates"
          class="client-dialog__rates"
          :client-id="selectedClient.id"
          auto-select
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ClientLabel from '@/components/common/ClientLabel'
import ConsultRatesSelectTable from '@/components/clients/ConsultRatesSelectTable'

const clientsDefaults = {
  loading: false,
  data: []
}

export default {
  name: 'ClientDialog',
  components: {
    ConsultRatesSelectTable,
    ClientLabel
  },
  props: {
    clinicians: {
      type: Array,
      required: true
    },
    inMenu: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      clients: { ...clientsDefaults },
      dialog: false,
      consultRates: [],
      search: null,
      selectedClient: null
    }
  },
  watch: {
    search(term) {
      if (!term || !term.length || this.clients.loading) {
        return
      }

      this.searchClients(term.trim())
    }
  },
  methods: {
    close() {
      this.dialog = false
      this.consultRates = []
      this.clients = { ...clientsDefaults }
      this.selectedClient = null
      this.search = null
    },
    async searchClients(search) {
      this.clients.loading = true
      this.clients.data = (await this.$clients.index({ search })).data || []
      this.clients.loading = false
    },
    async enroll() {
      try {
        this.loading = true
        const data = this.clinicians.flatMap((clinician) =>
          this.consultRates.map((rate) => ({
            clinician_id: clinician.id,
            consult_rate_id: rate.id
          }))
        )

        const {
          meta: { successes, failures }
        } = await this.$enrollments.batchCreate(data)

        if (failures && !successes) {
          this.$store.commit('SET_SNACKBAR', {
            message: 'No enrollments were created',
            show: true
          })
        } else if (successes) {
          this.$store.commit('SET_SNACKBAR', {
            message: `${successes} enrollment${
              successes > 1 ? 's' : ''
            } created`,
            show: true
          })

          this.$router.push(`/clients/${this.selectedClient.id}`)
        }
      } catch (e) {
        let message = 'Something went wrong, please try again. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.clinician-chip {
  pointer-events: none;
}

.client-dialog > .v-card {
  overflow-y: scroll;
  max-height: 90vh;
}

.client-dialog.v-dialog .v-card {
  padding: 24px;
}

.header {
  @include text-h5;
}

.add-to-client {
  font-size: size(16) !important;
}

.group-title {
  @include text-h6;
  font-size: size(16);
  color: #16162c;
  margin: 32px 0 8px;
}

.client-dialog__rates {
  ::v-deep td {
    font-weight: 400;
    @include text-body-1;
    font-size: size(18) !important;
  }
}
</style>
