import { createNamespacedHelpers } from 'vuex'
import { NAMESPACE } from './state'
const { mapGetters } = createNamespacedHelpers(NAMESPACE)

const getters = {
  attributes({ data }) {
    return data
  }
}

export const mappedGetters = mapGetters(Object.keys(getters))
export default getters
