<template>
  <ol class="routing-report-strategies">
    <p class="routing-report-strategies__header">Strategies</p>
    <p class="routing-report-strategies__description">
      The following is a list of clinicians who would have been selected if an
      experiement was turned on.
    </p>
    <li v-for="(result, strategy) in strategies" :key="result.selected">
      <data-row class="mini" :label="strategy">
        <router-link :to="`/${subjectType}s/${result.id || result.selected}`">
          {{ result.name || result.id || result.selected }}
        </router-link>
      </data-row>
    </li>
  </ol>
</template>

<script>
import DataRow from '@/components/common/DataRow.vue'
import { SnakeToTitle } from '@/filters/SnakeToTitle'

export default {
  name: 'RoutingReport',
  components: {
    DataRow
  },
  filters: {
    SnakeToTitle
  },
  props: {
    strategies: {
      type: Object,
      required: true
    },
    subjectType: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.routing-report-strategies {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: size(4);

    &:last-child {
      margin-bottom: size(16);
    }
  }

  &__description {
    color: rgba(24, 24, 24, 0.6);
    font-size: size(14);
    font-style: italic;
    margin: size(4) 0;
  }

  &__header {
    font-weight: bold;
    text-transform: uppercase;
    font-size: size(14);
    margin-bottom: size(8);
  }
}
</style>
