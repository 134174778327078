<template>
  <v-stepper-content :step="step">
    <v-alert v-if="serverError" type="warning"> Server Error </v-alert>
    <v-form ref="form">
      <v-card>
        <v-card-text>
          <a
            :href="`data:application/octet-stream,${encodeURIComponent(
              template
            )}`"
            :download="`${templateFileName}.csv`"
          >
            Download Template
          </a>
          <csv-file-input :loading="loading" @onChange="parsedCsv = $event" />
        </v-card-text>
        <v-card-actions>
          <span v-if="chunk">
            Chunk {{ chunk }} of {{ totalChunks }} completed.
          </span>
          <v-spacer />
          <v-btn
            depressed
            rounded
            color="primary"
            :disabled="disableUpload"
            :loading="loading"
            @click="upload"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-stepper-content>
</template>

<script>
import CsvFileInput from '@/components/files/CsvFileInput.vue'
import Papa from 'papaparse'

const PARTITION_SIZE = 25

export default {
  name: '2',
  components: {
    CsvFileInput
  },
  props: {
    templateFileName: {
      type: String,
      default: 'template'
    },
    step: {
      type: [Number, String],
      required: true
    },
    getTemplate: {
      type: Function,
      required: true
    },
    handleUpload: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      chunk: null,
      template: '',
      parsedCsv: {
        data: [],
        errors: [],
        meta: []
      },
      uploadResults: [],
      uploadErrors: 0,
      uploadSuccesses: 0,
      serverError: false,
      loading: false
    }
  },
  computed: {
    disableUpload() {
      return !this.partitioned.length
    },
    errors() {
      return this.uploadErrors > 0
        ? Papa.unparse(
            this.uploadResults
              .filter((result) => !this.isSuccess(result)) // eslint-disable-next-line camelcase
              .map(({ message, request_body }) => ({
                error: message,
                ...request_body
              }))
          )
        : ''
    },
    partitioned() {
      const { data } = this.parsedCsv
      if (!data?.length) {
        return []
      }
      const partitions = []
      for (
        let startIndex = 0;
        startIndex < data.length;
        startIndex += PARTITION_SIZE
      ) {
        const sliced = data.slice(startIndex, startIndex + PARTITION_SIZE)
        partitions.push(sliced)
      }
      return partitions
    },
    totalChunks() {
      return this.partitioned.length
    }
  },
  async created() {
    this.template = await this.getTemplate()
  },
  methods: {
    async upload() {
      this.chunk = 0
      this.loading = true
      this.uploadResults = []
      this.uploadErrors = 0
      this.uploadSuccesses = 0
      this.serverError = false

      try {
        for (const chunk of this.partitioned) {
          const response = await this.handleUpload(chunk)
          this.chunk++
          this.uploadResults = this.uploadResults.concat(response.data)
          this.uploadErrors += response.meta.failures
          this.uploadSuccesses += response.meta.successes
        }

        this.$emit('upload-results', this.uploadResults)
        this.$emit('upload-errors', this.errors)
        this.$emit('upload-success-count', this.uploadSuccesses)
      } catch (_) {
        this.serverError = true
      } finally {
        this.loading = false
        this.chunk = null
      }
    },
    isSuccess(item) {
      return item.status >= 200 && item.status < 300
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .misc-transfer-upload {
  &__table > .v-data-table__wrapper > table > tbody > tr > td {
    white-space: nowrap;
  }
}
</style>
