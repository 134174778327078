const Enrollments = {
  methods: {
    deleteEnrollment(enrollmentId) {
      const index = this.enrollments.findIndex(
        (item) => item.id === enrollmentId
      )
      this.enrollments.splice(index, 1)
    },
    updateEnrollment(enrollment) {
      const index = this.enrollments.findIndex(
        (item) => item.id === enrollment.id
      )
      const update = {
        ...this.enrollments[index],
        ...enrollment
      }

      this.enrollments.splice(index, 1, update)
    }
  }
}

export default Enrollments
