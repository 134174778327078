import Vue from 'vue'
import { createAuth0Client } from '@auth0/auth0-spa-js'

import { getExperimentFlag } from '@/services/Statsig'

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export class Auth0 {
  static _instance
  constructor({
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    redirectUri = window.location.origin,
    ...options
  }) {
    if (Auth0._instance) return Auth0._instance

    // The 'instance' is simply a Vue object
    Auth0._instance = new Vue({
      data() {
        return {
          loading: true,
          isAuthenticated: false,
          user: {},
          auth0Client: null,
          popupOpen: false,
          error: null,
          isFeatureFlagLoading: true,
          isAuth0Enabled: false
        }
      },
      /** Use this lifecycle method to instantiate the SDK client */
      computed: {
        isPluginLoading() {
          return this.loading || this.isFeatureFlagLoading
        }
      },
      async created() {
        await this.useAuth0Async()
        // Create a new instance of the SDK client using members of the given options object
        this.auth0Client = await createAuth0Client({
          ...options,
          authorizationParams: {
            redirect_uri: redirectUri,
            ...options.authorizationParams
          }
        })

        try {
          // If the user is returning to the app after authentication, route them to the location they were initially trying to reach
          if (
            window.location.search.includes('code=') &&
            window.location.search.includes('state=')
          ) {
            // handle the redirect and retrieve tokens
            const { appState } = await this.auth0Client.handleRedirectCallback()

            this.error = null

            // Notify subscribers that the redirect callback has happened, passing the appState
            // (useful for retrieving any pre-authentication state)
            onRedirectCallback(appState)
          }
        } catch (e) {
          this.error = e
        } finally {
          // Initialize our internal authentication state
          this.isAuthenticated = await this.auth0Client.isAuthenticated()
          this.user = await this.auth0Client.getUser()
          this.loading = false
        }
      },
      methods: {
        async useAuth0Async() {
          if (!this.isFeatureFlagLoading) {
            return this.isAuth0Enabled
          }

          this.isAuth0Enabled = sessionStorage.getItem('auth0Tester') || false

          this.isFeatureFlagLoading = false
          return this.isAuth0Enabled
        },
        /** Handles the callback when logging in using a redirect */
        async handleRedirectCallback() {
          this.loading = true
          try {
            await this.auth0Client.handleRedirectCallback()
            this.user = await this.auth0Client.getUser()
            this.isAuthenticated = true
            this.error = null
          } catch (e) {
            this.error = e
          } finally {
            this.loading = false
          }
        },
        /** Authenticates the user using the redirect method */
        loginWithRedirect(o) {
          return this.auth0Client.loginWithRedirect(o)
        },
        /** Returns all the claims present in the ID token */
        getIdTokenClaims(o) {
          return this.auth0Client.getIdTokenClaims(o)
        },
        /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
        getTokenSilently(o) {
          return this.auth0Client.getTokenSilently(o)
        },
        /** Gets the access token using a popup window */

        getTokenWithPopup(o) {
          return this.auth0Client.getTokenWithPopup(o)
        },
        /** Logs the user out and removes their session on the authorization server */
        logout(o) {
          return this.auth0Client.logout(o)
        }
      }
    })

    return Auth0._instance
  }
}

/** Returns the current instance of the auth0 vue plugin */
export const getInstance = () => Auth0._instance

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth0 = new Auth0(options)
  }
}
