/*
Sets a $feedback variable on Vue.prototype allowing it to be accessed throughout app
*/

import axios from 'axios'
import { deserialize } from 'deserialize-json-api'

const Feedback = {
  install(Vue) {
    Vue.prototype.$feedback = new Vue({
      methods: {
        async index(params) {
          const { data } = await axios.get('/admin/clinician-feedbacks', {
            params
          })
          return deserialize(data)
        },
        async show(id) {
          const { data } = await axios.get(`/admin/clinician-feedbacks/${id}`)
          return deserialize(data)
        },
        async create(payload) {
          const { data } = await axios.post(
            '/admin/clinician-feedbacks',
            payload
          )
          return deserialize(data)
        },
        async update(id, payload) {
          const { data } = await axios.patch(
            `/admin/clinician-feedbacks/${id}`,
            payload
          )
          return deserialize(data)
        },
        resolve(id) {
          return axios.patch(`/admin/clinician-feedbacks/${id}/resolve`)
        },
        async report(id, payload = {}) {
          const { data } = await axios.patch(
            `/admin/clinician-feedbacks/${id}/report`,
            payload
          )
          return deserialize(data)
        },
        async delete(id) {
          const { data } = await axios.delete(
            `/admin/clinician-feedbacks/${id}`
          )
          return data.deleted
        },
        async fetchReporters() {
          const { data } = await axios.get(
            '/admin/clinician-feedbacks/reporters'
          )
          return data
        }
      }
    })
  }
}

export default Feedback
