<template>
  <div class="clients-view">
    <h2 class="mb-2">Clients</h2>
    <p class="clients-view__results">
      <span v-if="loading">Loading results&hellip;</span>
      <span v-else-if="error" class="clients-view__error">
        Error loading results: "{{ error }}". Please try again later&hellip;
      </span>
      <span v-else>{{ total }} results</span>
    </p>
    <hr />
    <search v-model="search" @input="searchRecords" />
    <navigating-data-table
      :navigate="(item) => `/clients/${item.id}`"
      disable-sort
      :headers="headers"
      :options.sync="options"
      :footer-props="footer"
      :server-items-length="total"
      :items="clients"
      :loading="loading"
      @update:page="pageChanged"
    >
      <template #item.name="{ item }">
        <client-label v-bind="item" />
      </template>
    </navigating-data-table>
  </div>
</template>

<script>
import ClientLabel from '@/components/common/ClientLabel.vue'
import Search from '@/components/common/Search.vue'
import NavigatingDataTable from '@/components/tables/NavigatingDataTable.vue'
import GridSearch from '@/mixins/GridSearch'

export default {
  name: 'ClientsView',
  components: {
    NavigatingDataTable,
    ClientLabel,
    Search
  },
  mixins: [GridSearch],
  data() {
    return {
      clients: [],
      headers: [
        { text: 'Client ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status' }
      ],
      footer: {
        itemsPerPageOptions: [],
        itemsPerPageText: null,
        disableItemsPerPage: true
      }
    }
  },
  methods: {
    async loadData() {
      const response = await this.$clients.index({
        ...this.$route.query
      })

      this.clients = response.data
      return response
    }
  }
}
</script>

<style lang="scss">
.clients-view {
  height: 100%;
  padding-top: 4rem;

  .clients-view__results {
    color: rgba(24, 24, 24, 0.6);
  }

  .clients-view__error {
    color: #bf211e;
  }

  .v-data-table {
    text-transform: capitalize;

    img {
      height: 0.75rem;
      width: 0.75rem;
    }
  }
}
</style>
