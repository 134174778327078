<template>
  <v-card>
    <v-card-title class="headline"> Create new consult rate </v-card-title>
    <v-card-text class="alert">
      <v-alert color="red" dense outlined text icon="mdi-alert-circle-outline">
        Confirmation with Engineering, Payments, and Legal is required before
        creating a new consult rate!
      </v-alert>
    </v-card-text>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="createInputs.display_name"
          label="Consult Rate Name"
          :rules="[requiredField]"
          clearable
        />
        <v-text-field
          v-model="createInputs.uuid"
          label="Consult Rate UUID"
          :rules="[isUuid]"
          clearable
        />
        <v-autocomplete
          v-model="createInputs.modality"
          label="Modality"
          :items="modalityOptions"
          item-text="display"
          item-value="value"
          :rules="[requiredField]"
          clearable
          hint="Modality is not editable after creation. Selecting 'Instant (Labs) will set the consult as a lab type."
          persistent-hint
        />
        <v-autocomplete
          v-model="createInputs.consult_type"
          label="Type"
          :items="typeOptions"
          item-text="display"
          item-value="value"
          :rules="[
            instantType(createInputs.consult_type, createInputs.modality)
          ]"
          clearable
          hint="Consult Type: Determines the workflow of the consult."
          persistent-hint
        />
        <v-select
          v-model="createInputs.practitioner_types"
          label="Practitioner Types"
          :items="practitionerTypesOptions"
          :rules="[requiredField]"
          chips
          multiple
          hint="Determines what practitioner types can enroll in this consult rate."
          persistent-hint
        />
        <v-text-field
          v-model="createInputs.cost_to_client"
          label="Cost to Client"
          :rules="[
            requiredField,
            positiveValue(createInputs.cost_to_client, 'Cost to client'),
            twoDecimalsAllowed
          ]"
          clearable
          prefix="$"
          hint="Determines the base cost to clients per consult once completed."
          persistent-hint
        />
        <v-text-field
          v-model="createInputs.paid_to_provider"
          label="Paid to Provider"
          :rules="[
            requiredField,
            positiveValue(createInputs.paid_to_provider, 'Paid to provider'),
            twoDecimalsAllowed
          ]"
          clearable
          prefix="$"
          hint="Determines the base pay per clinician per consult once completed."
          persistent-hint
        />
        <v-text-field
          v-model="createInputs.sla_in_seconds"
          label="SLA (seconds)"
          type="number"
          :rules="[
            requiredField,
            slaLength(createInputs.sla_in_seconds, createInputs.modality),
            noDecimalsAllowed
          ]"
          clearable
          hint="Determines the SLA given per consult."
          persistent-hint
        />
        <v-text-field
          v-model="createInputs.advance_notice_in_seconds"
          label="Advance Notice (seconds)"
          type="number"
          :rules="[
            positiveValue(
              createInputs.advance_notice_in_seconds,
              'Advance notice'
            ),
            noDecimalsAllowed
          ]"
          clearable
          hint="Determines the advance notice for a consult"
          persistent-hint
        />
        <!-- Is this a required field  -->
        <v-autocomplete
          v-model="createInputs.compensation_unit"
          label="Compensation Unit"
          :items="compensationOptions"
          item-text="display"
          item-value="value"
          :rules="[requiredField]"
          clearable
          hint="Hourly compensation requires the Payments team to manually process each consult with this type."
          persistent-hint
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <span v-if="modalError" class="modal-error">{{ modalError }}</span>
      <v-spacer />
      <v-btn text @click="close"> Cancel </v-btn>
      <v-btn
        color="primary"
        rounded
        :loading="saving"
        :disabled="!valid"
        @click="handleConfirm"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Modal from '@/mixins/Modal'
import PractitionerTypes from '@/utils/PractitionerTypes'
import ValidationMixin from '@/mixins/Validation'

export default {
  name: 'ConsultRateCreateModal',
  mixins: [Modal, ValidationMixin],
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: 'Unable to create consult rate. Please try again.',
      modalityOptions: [
        { value: 'async', display: 'Async' },
        { value: 'chat', display: 'Chat' },
        { value: 'instant', display: 'Instant (Labs)' },
        { value: 'phone', display: 'Phone' },
        { value: 'video', display: 'Video' }
      ],
      typeOptions: [
        { value: 'lab', display: 'Lab' },
        { value: 'task', display: 'Task' }
      ],
      practitionerOptions: [],
      compensationOptions: [
        { value: 'per consult', display: 'Per Consult' },
        { value: 'hourly', display: 'Hourly' }
      ],
      practitionerTypesOptions: PractitionerTypes,
      createInputs: {},
      results: {},
      valid: false
    }
  },
  async created() {
    this.fetchClinicianTypes()
  },
  methods: {
    async fetchClinicianTypes() {
      const types = await this.$clinicians.getClinicianFilters()
      this.practitionerOptions = types.practitioner_types.sort((a, b) =>
        a.display.localeCompare(b.display)
      )
    },
    async action() {
      try {
        this.results = await this.$clients.createConsultRate(
          this.clientId,
          this.createInputs
        )
        this.$router.push({ path: `/consult-rates/${this.results.id}/details` })

        this.$store.commit('SET_SNACKBAR', {
          message: 'Success! Consult rate was created.',
          show: true
        })
      } catch (e) {
        let message = this.editError
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        return this.results
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  margin-top: 32px;
}

.modal-error {
  color: #bf211e;
}
</style>
