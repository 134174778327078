import axios from 'axios'

const Forecast = {
  install(Vue) {
    Vue.prototype.$forecast = new Vue({
      methods: {
        async getForecast(params) {
          const { data } = await axios.get('/admin/forecasts', {
            params
          })
          return data
        }
      }
    })
  }
}

export default Forecast
