<template>
  <div class="config-values-view">
    <header>
      <div class="d-flex justify-space-between align-center">
        <h2 class="mb-2">Configuration Values</h2>
      </div>
    </header>
    <hr />
    <div>
      <div>
        <v-dialog
          v-model="showWarningModal"
          max-width="600px"
          @click:outside="handleClose"
        >
          <warning-modal
            v-model="showWarningModal"
            :title="warningTitle"
            :content="warningMessage"
            @close="handleClose"
            @confirm="handleClose"
          />
        </v-dialog>
      </div>
      <v-row v-for="item in configValues" :key="item.id">
        <v-col class="d-flex justify-left align-center">
          {{ item.name | snakeToTitle }}
        </v-col>
        <v-col>
          <v-form ref="form">
            <div class="d-flex">
              <v-text-field v-model="item.value" :hint="item.description" />
              <footer class="d-flex justify-end">
                <v-btn plain rounded @click="onCancel(item.id)"
                  ><v-icon>mdi-cancel</v-icon></v-btn
                >
                <v-btn
                  depressed
                  rounded
                  color="primary"
                  :loading="saving"
                  @click="handleConfirm(item.id, item.value)"
                  ><v-icon>mdi-check</v-icon></v-btn
                >
              </footer>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { SnakeToTitle } from '@/filters/SnakeToTitle'
import WarningModal from '@/components/common/WarningModal.vue'
import ValidationMixin from '@/mixins/Validation'

export default {
  name: 'ConfigValuesView',
  filters: {
    SnakeToTitle
  },
  components: {
    WarningModal
  },
  mixins: [ValidationMixin],
  data() {
    return {
      originalConfigValues: [],
      configValues: [],
      loading: false,
      error: false,
      saving: false,
      showWarningModal: true,
      warningTitle: 'Configuration Values',
      warningMessage:
        'Caution: Advanced Configuration! Changing these values can significantly impact system performance and stability. Only proceed if you fully understand the implications. Incorrect settings may disrupt functionality and cause extensive issues.'
    }
  },
  created() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    init: async function () {
      try {
        const response = await this.loadConfigValues()
        this.configValues = JSON.parse(JSON.stringify(response.data))
        this.originalConfigValues = JSON.parse(JSON.stringify(response.data))
      } catch (e) {
        this.error = e
      }
    },
    loadConfigValues: async function () {
      return this.$configValues.getConfigValues()
    },
    async onCancel(configId) {
      const originalValue = this.originalConfigValues.find(
        (x) => x.id === configId
      )
      const index = this.configValues.findIndex((x) => x.id === configId)

      this.$set(this.configValues, index, { ...originalValue })
    },
    handleClose() {
      this.showWarningModal = false
    },
    async handleConfirm(configId, updatedValue) {
      try {
        this.saving = true
        const results = await this.$configValues.edit(configId, updatedValue)

        const index = this.originalConfigValues.findIndex(
          (x) => x.id === configId
        )
        this.$set(this.originalConfigValues, index, { ...results })

        this.$store.commit('SET_SNACKBAR', {
          message: 'Success! Config value was successfully edited.',
          show: true
        })
      } catch (e) {
        let message = this.editError
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.saving = false
      }
    }
  }
}
</script>
<style>
.config-values-view {
  height: 100%;
  padding-top: 4rem;
}
</style>
