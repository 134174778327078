<template>
  <v-stepper v-model="step" class="pa-10">
    <v-card-title class="headline pt-0 px-0">
      {{ title }}
      <v-spacer />
      <v-btn icon aria-label="close button" @click="close">
        <v-icon color="black">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-stepper-items>
      <v-stepper-content step="1" class="pa-0">
        <v-card-text v-if="isTotalCollabLimit" class="px-0 subtitle-1">
          Please review and resolve the following Supervising Physicians with
          more than {{ supervisionValues.currentTotalSupervisions }} collabs.
        </v-card-text>
        <v-card-text v-else class="px-0 subtitle-1">
          Please review and resolve the following Supervising Physicians with
          more than
          <span v-if="supervisionValues.countPrescribingSuperviorsOver">
            {{ supervisionValues.currentPrescribingSupervisions }}
            prescribing
          </span>
          <span
            v-if="
              supervisionValues.countPrescribingSuperviorsOver &&
              supervisionValues.countNonPrescribingSuperviorsOver
            "
            >and
          </span>
          <span v-if="supervisionValues.countNonPrescribingSuperviorsOver"
            >{{
              supervisionValues.currentNonPrescribingSupervisions
            }}
            non-prescribing </span
          >collabs.
        </v-card-text>
        <v-card-text v-if="isTotalCollabLimit" class="px-0">
          <v-alert
            color="red"
            dense
            outlined
            text
            icon="mdi-alert-circle-outline"
            class="pa-4 ma-0"
          >
            <span class="font-weight-bold px-0"
              >{{ supervisionValues.countTotalSuperviorsOver }} Supervising
              physicians</span
            >
            have more than
            {{ supervisionValues.currentTotalSupervisions }}
            collabs and need to be fixed.
          </v-alert>
        </v-card-text>
        <v-card-text
          v-if="
            !isTotalCollabLimit &&
            supervisionValues.countPrescribingSuperviorsOver
          "
          class="px-0"
        >
          <v-alert
            color="red"
            dense
            outlined
            text
            icon="mdi-alert-circle-outline"
            class="pa-4 ma-0"
          >
            <span class="font-weight-bold px-0"
              >{{
                supervisionValues.countPrescribingSuperviorsOver
              }}
              Supervising physicians</span
            >
            have more than
            {{ supervisionValues.currentPrescribingSupervisions }}
            prescribing collabs and need to be fixed.
          </v-alert>
        </v-card-text>
        <v-card-text
          v-if="
            !isTotalCollabLimit &&
            supervisionValues.countNonPrescribingSuperviorsOver
          "
          class="px-0"
        >
          <v-alert
            color="red"
            dense
            outlined
            text
            icon="mdi-alert-circle-outline"
            class="pa-4 ma-0"
          >
            <span class="font-weight-bold px-0"
              >{{
                supervisionValues.countNonPrescribingSuperviorsOver
              }}
              Supervising physicians</span
            >
            have more than
            {{ supervisionValues.currentNonPrescribingSupervisions }}
            non-prescribing collabs and need to be fixed.
          </v-alert>
        </v-card-text>
        <v-card-actions class="px-0 pb-0">
          <v-spacer />
          <v-btn text rounded @click="close"> Cancel </v-btn>
          <v-btn color="primary" rounded @click="nextStep"> Next </v-btn>
        </v-card-actions>
      </v-stepper-content>
      <v-stepper-content step="2" class="pa-0">
        <span v-if="Object.entries(collabData)[innerStep]">
          <v-card-text class="px-0 font-weight-bold">
            <v-alert
              color="red"
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              class="pa-4"
            >
              {{
                Object.entries(collabData)[innerStep][1]
                  .supervisingClinicianName
              }}
              has more than the maximum
              <span v-if="isTotalCollabLimit"
                >{{ supervisionValues.currentTotalSupervisions }}
              </span>
              <span
                v-if="
                  !isTotalCollabLimit &&
                  Object.entries(collabData)[innerStep][1].collabsType ===
                    collabTypePrescribing
                "
                >{{ supervisionValues.currentPrescribingSupervisions }}
                prescribing
              </span>
              <span
                v-if="
                  !isTotalCollabLimit &&
                  Object.entries(collabData)[innerStep][1].collabsType ===
                    collabTypeNonPrescribing
                "
                >{{ supervisionValues.currentNonPrescribingSupervisions }}
                non-prescribing
              </span>
              collabs for {{ state }}.
            </v-alert>
          </v-card-text>
          <v-card-text class="pa-0 subtitle-1">
            <p>
              Select
              <span class="font-weight-bold">{{ leftToRemove }} </span>
              <span
                v-if="
                  !isTotalCollabLimit &&
                  Object.entries(collabData)[innerStep][1].collabsType ===
                    collabTypePrescribing
                "
                >prescribing
              </span>
              <span
                v-if="
                  !isTotalCollabLimit &&
                  Object.entries(collabData)[innerStep][1].collabsType ===
                    collabTypeNonPrescribing
                "
                >non-prescribing
              </span>
              collab(s) to remove:
            </p>
            <span
              v-for="row in Object.entries(collabData)[innerStep][1].collabs"
              :key="row.collabId"
            >
              <input
                v-model="
                  Object.entries(collabData)[innerStep][1].removedConsultsData
                "
                type="checkbox"
                :value="row"
                class="mb-5 text-color--wheel-green wheel-accent"
              />
              <router-link
                :to="`/collabs/agreements/${row.collabId}`"
                class="meta flex-shrink-0 text-color--wheel-green"
                target="_blank"
              >
                <label class="pl-5">{{ row.supervisiedClinicianName }}</label>
              </router-link>
              <v-icon class="text-color--wheel-green remove-text-decoration"
                >mdi-arrow-top-right</v-icon
              >
              <br />
            </span>
            <br />
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-spacer />
            <v-btn text rounded @click="backStep">Back</v-btn>
            <v-btn
              color="primary"
              rounded
              :disabled="!isValid"
              @click="nextInnerStep"
              >Next</v-btn
            >
          </v-card-actions>
        </span>
        <span v-else>
          <v-card-text class="px-0">
            <v-alert
              dense
              outlined
              icon="mdi-alert-circle-outline"
              class="text-color--orange-900 warning-alert"
            >
              Confirmation with Ops, Legal, and Payments is required before
              editing a state’s requirements!
            </v-alert>
          </v-card-text>
          <v-card-text class="pa-0 subtitle-1">
            <p class="text-lg-body-1 mb-0">
              Are you sure you want to change
              <span class="font-weight-bold">{{ state }}</span> maximum
              supervisions?
            </p>
            <ul>
              <li v-if="isTotalCollabLimit">
                Total maximum supervisions changing from
                <span class="font-weight-bold">{{
                  supervisionValues.previousTotalSupervisions
                }}</span>
                to
                <span class="font-weight-bold">{{
                  supervisionValues.currentTotalSupervisions
                }}</span>
              </li>
              <li
                v-if="
                  !isTotalCollabLimit &&
                  supervisionValues.countPrescribingSuperviorsOver
                "
              >
                Prescribing from
                <span class="font-weight-bold">{{
                  supervisionValues.previousPrescribingSupervisions
                }}</span>
                to
                <span class="font-weight-bold">{{
                  supervisionValues.currentPrescribingSupervisions
                }}</span>
              </li>
              <li
                v-if="
                  !isTotalCollabLimit &&
                  supervisionValues.countNonPrescribingSuperviorsOver
                "
              >
                Non-Prescribing from
                <span class="font-weight-bold">{{
                  supervisionValues.previousNonPrescribingSupervisions
                }}</span>
                to
                <span class="font-weight-bold">{{
                  supervisionValues.currentNonPrescribingSupervisions
                }}</span>
              </li>
            </ul>
            <p class="pt-6 mb-0">Which will impact the following physicians:</p>
            <ul>
              <li
                v-for="name in supervisingClinicianNames"
                :key="name"
                class="pa-0 ma-0"
              >
                {{ name }}
              </li>
            </ul>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-spacer />
            <v-btn text rounded @click="backStep">Back</v-btn>
            <v-btn color="primary" rounded @click="handleConfirm">Save</v-btn>
          </v-card-actions>
        </span>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Modal from '@/mixins/Modal'
import COLLAB_AGREEMENT_TYPES from '@/utils/CollabAgreementTypes'

export default {
  name: 'StateCollabMaxRelationshipWarningModal',
  mixins: [Modal],
  props: {
    isTotalCollabLimit: {
      type: Boolean,
      required: true
    },
    state: {
      type: String,
      required: true
    },
    supervisionValues: {
      type: Object,
      required: true
    },
    collabData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      step: 1,
      innerStep: 0,
      title: 'Changing Maximum Supervisions',
      supervisingClinicianNames: [
        ...new Set(
          Object.values(this.collabData).map((x) => x.supervisingClinicianName)
        )
      ],
      collabTypeTotal: COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_TYPE.TOTAL,
      collabTypePrescribing:
        COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_TYPE.PRESCRIBING,
      collabTypeNonPrescribing:
        COLLAB_AGREEMENT_TYPES.COLLAB_AGREEMENT_TYPE.NON_PRESCRIBING
    }
  },
  computed: {
    isValid() {
      const maxSupervisionCount = this.setMaxSupervisionCount()

      if (
        Object.entries(this.collabData)[this.innerStep][1].removedConsultsData
          ?.length < 1
      )
        return false

      return (
        Object.entries(this.collabData)[this.innerStep][1].collabs.length -
          Object.entries(this.collabData)[this.innerStep][1].removedConsultsData
            ?.length <=
        maxSupervisionCount
      )
    },
    leftToRemove() {
      const maxSupervisionCount = this.setMaxSupervisionCount()
      const leftToRemoveValue =
        Object.entries(this.collabData)[this.innerStep][1].collabs.length -
        Object.entries(this.collabData)[this.innerStep][1].removedConsultsData
          .length -
        maxSupervisionCount
      return leftToRemoveValue >= 0 ? leftToRemoveValue : 0
    }
  },
  methods: {
    async action() {
      const removedConsultIds = Object.values(this.collabData)
        .map((obj) =>
          obj.removedConsultsData.map((subObj) => subObj.collabUuid)
        )
        .flat(1)
      this.$emit('confirm')
      this.$emit('getRemovedConsultIds', removedConsultIds)
    },
    backStep() {
      if (this.innerStep != 0) this.innerStep -= 1
      else this.step -= 1
    },
    nextStep() {
      this.step += 1
    },
    nextInnerStep() {
      this.innerStep += 1

      if (this.innerStep >= Object.entries(this.collabData).length) {
        this.title = 'Save changes to Maximum Supervisions'
      }
    },
    setMaxSupervisionCount() {
      if (
        Object.entries(this.collabData)[this.innerStep][1].collabsType ===
        this.collabTypeTotal
      )
        return this.supervisionValues.currentTotalSupervisions
      if (
        Object.entries(this.collabData)[this.innerStep][1].collabsType ===
        this.collabTypePrescribing
      )
        return this.supervisionValues.currentPrescribingSupervisions
      if (
        Object.entries(this.collabData)[this.innerStep][1].collabsType ===
        this.collabTypeNonPrescribing
      )
        return this.supervisionValues.currentNonPrescribingSupervisions
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 0 24px 0px;
}

.warning-alert {
  background-color: $orange-50 !important;
  border-color: $orange-600 !important;
}

.wheel-accent {
  accent-color: #2e4f25;
}

.remove-text-decoration {
  text-decoration: none;
}
</style>
