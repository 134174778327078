<template>
  <profile-form
    :action-function="actionFunction"
    hide-header-when-editing
    hide-clear
    class="clinician-info-form"
    @submit="(event) => $emit('submit', event)"
  >
    <template #header>
      <div class="flex-column ml-4">
        <h3>{{ clinicianLocal.name }}</h3>
      </div>
    </template>
    <template #default="{ editMode, loading }">
      <profile-field
        v-slot="attrs"
        v-model="clinicianLocal.name"
        :edit-mode="editMode"
        hide-values
      >
        <v-text-field
          v-bind="attrs"
          label="Full Name"
          outlined
          name="name"
          :loading="loading"
        />
      </profile-field>
    </template>
  </profile-form>
</template>

<script>
import ProfileField from '@/components/clinicians/profile/ProfileField'
import ProfileForm from '@/components/clinicians/profile/ProfileForm'
import { PractitionerType } from '@/filters/Clinician'

export default {
  name: 'ClinicianInfo',
  components: {
    ProfileForm,
    ProfileField
  },
  filters: {
    PractitionerType
  },
  props: {
    clinician: {
      type: Object,
      required: true
    },
    actionFunction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    clinicianLocal: {
      get: function () {
        return this.clinician
      },
      set: function (value) {
        return this.$emit('update:clinician', value)
      }
    }
  }
}
</script>

<style lang="scss">
.clinician-info {
  &-form {
    margin-right: 36px;
    flex: 1;
  }
}
</style>
