<template>
  <div>
    <profile-form
      v-slot="formAttrs"
      header="Specializations & Skills"
      :action-function="updateClinician"
      @submit="onSubmit"
    >
      <profile-autocomplete
        v-model="clinicianLocal.specialties"
        field-name="specialties"
        :items="getFieldValues('specialties')"
        v-bind="formAttrs"
      />
      <profile-autocomplete
        v-model="clinicianLocal.populations"
        field-name="populations"
        :items="getFieldValues('populations')"
        v-bind="formAttrs"
      />
      <profile-autocomplete
        v-model="clinicianLocal.languages"
        field-name="languages"
        :items="getFieldValues('languages')"
        v-bind="formAttrs"
      />
      <profile-autocomplete
        v-model="clinicianLocal.treatment_methods"
        field-name="treatment_methods"
        :items="getFieldValues('treatment_methods')"
        v-bind="formAttrs"
      />
    </profile-form>
    <profile-background
      :clinician="clinician"
      :action-function="updateClinician"
      @submit="onSubmit"
    />
    <profile-form
      v-slot="formAttrs"
      class="mt-6"
      header="About"
      :action-function="updateClinician"
      @submit="onSubmit"
    >
      <profile-field
        v-slot="attrs"
        v-model="clinicianLocal.about"
        v-bind="formAttrs"
      >
        <v-textarea
          v-bind="attrs"
          name="about"
          :counter="textAreaLimit"
          :rules="textAreaRules"
          placeholder="Add a short summary about the clinician."
        />
      </profile-field>
    </profile-form>
    <profile-form
      :action-function="updateClinicianEducation"
      :clear-function="clearClinicianEducation"
      @submit="onSubmit"
    >
      <template #header>
        <h4 class="h6 mb-3">Education</h4>
      </template>
      <template #default="{ editMode }">
        <template v-if="clinician.educations.length">
          <div v-for="(_, index) in clinician.educations" :key="index">
            <v-row class="align-baseline">
              <v-col :cols="editMode ? 4 : 5">
                <profile-autocomplete
                  v-model="clinicianLocal.educations[index].degree"
                  label="degree"
                  not-empty
                  :field-name="`educations[${index}].degree`"
                  :items="degreeList"
                  :edit-mode="editMode"
                  placeholder="Select a degree"
                />
              </v-col>
              <v-col :cols="3">
                <profile-text-field
                  v-model="clinicianLocal.educations[index].startYear"
                  label="Start Year"
                  not-empty
                  :edit="editMode"
                  :field-name="`educations[${index}].startYear`"
                  :rules="startYearRules"
                  placeholder="Year degree was started"
                />
              </v-col>
              <v-col :cols="4">
                <profile-text-field
                  v-model="clinicianLocal.educations[index].institution"
                  label="Institution"
                  not-empty
                  :edit="editMode"
                  :field-name="`educations[${index}].institution`"
                  placeholder="Please enter the institution"
                />
              </v-col>
              <v-col v-if="editMode" cols="1">
                <v-icon @click="handleRemoveEducation(index)">
                  mdi-minus-circle-outline
                </v-icon>
              </v-col>
            </v-row>
          </div>
        </template>
        <v-btn
          v-if="editMode"
          class="text-caption d-flex mb-8"
          outlined
          color="primary"
          rounded
          height="32px"
          @click="handleAddEducation"
        >
          <v-icon class="mr-2" size="small">mdi-plus</v-icon>
          <span>Add Education</span>
        </v-btn>
        <span v-if="!clinician.educations.length && !editMode">None</span>
      </template>
    </profile-form>
    <profile-form
      v-if="showConsultPreferences"
      v-slot="formAttrs"
      header="Consult Preferences"
      :action-function="updateClinician"
      @submit="onSubmit"
    >
      <profile-field v-bind="formAttrs">
        <v-select
          class="pt-2"
          label="Comfortable with non-english speaking consults"
          outlined
          :items="englishTranslationValues"
          name="english_translation"
        />
        <template #view>
          <data-row
            label="Comfortable with non-english speaking consults"
            :value="showEnglishTranslationDisplayValue"
          />
        </template>
      </profile-field>
    </profile-form>
    <profile-form
      v-slot="formAttrs"
      class="mt-6"
      header="Notification Preferences"
      :action-function="updateClinician"
      @submit="onSubmit"
    >
      <profile-field
        v-model="clinicianLocal.notification_preferences"
        v-bind="formAttrs"
      >
        <v-select
          v-model="clinicianLocal.notification_preferences"
          class="pt-2"
          label="Consult notifications"
          outlined
          chips
          multiple
          :items="notificationOptions.allOptions"
          name="notification_preferences"
        />
        <template #view>
          <data-row
            label="Consult notifications"
            class="text-capitalize"
            :value="notificationPreferences"
          />
        </template>
      </profile-field>
    </profile-form>
    <profile-form
      v-slot="formAttrs"
      class="mt-6"
      header="Attributes"
      :action-function="updateClinician"
      :has-errors="!!stripeError"
      @submit="onSubmit"
    >
      <profile-field v-model="clinicianLocal.litmos_id" v-bind="formAttrs">
        <template #default="fieldAttrs">
          <v-text-field
            v-bind="fieldAttrs"
            name="litmos_id"
            :rules="maxLength50Rule"
            placeholder="Litmos ID"
            clearable
          />
        </template>
        <template #view>
          <data-row label="Litmos ID" :value="clinicianLocal.litmos_id" />
        </template>
      </profile-field>
      <profile-field v-model="clinicianLocal.skilljar_id" v-bind="formAttrs">
        <template #default="fieldAttrs">
          <v-text-field
            v-bind="fieldAttrs"
            name="skilljar_id"
            :rules="maxLength50Rule"
            placeholder="Skilljar ID"
            clearable
          />
        </template>
        <template #view>
          <data-row label="Skilljar ID" :value="clinicianLocal.skilljar_id" />
        </template>
      </profile-field>
      <profile-field v-model="clinicianLocal.stripe_id" v-bind="formAttrs">
        <template #default="fieldAttrs">
          <v-text-field
            :error-messages="stripeError"
            v-bind="fieldAttrs"
            name="stripe_id"
            placeholder="Stripe ID"
            clearable
            @input="onChange"
          />
        </template>
        <template #view>
          <data-row label="Stripe ID" :value="clinicianLocal.stripe_id" />
        </template>
      </profile-field>
    </profile-form>
  </div>
</template>

<script>
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import ProfileTextField from '@/components/clinicians/profile/ProfileTextField.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import ProfileAutocomplete from '@/components/clinicians/profile/ProfileAutocomplete.vue'
import ProfileBackground from '@/components/clinicians/profile/ProfileBackground.vue'
import DataRow from '@/components/common/DataRow.vue'

export default {
  name: 'ClinicianProfile',
  components: {
    DataRow,
    ProfileBackground,
    ProfileAutocomplete,
    ProfileForm,
    ProfileField,
    ProfileTextField
  },
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      textAreaLimit: 500,
      showConsultPreferences: process.env.VUE_APP_CONSULT_PREFERENCES == 'true',
      degreeList: [
        'Bachelor of Science',
        'Bachelor of Arts',
        'Doctor of Medicine',
        'Doctor of Nursing Practice (DNP)',
        'Doctor of Osteopathy',
        'Bachelor on Science - Nursing',
        'Master of Science - Nursing',
        'Post-Master Nurse Practitioner',
        'Registered Dietician',
        'Registered Dietician Nutritionist'
      ],
      englishTranslationValues: [
        { text: 'No', value: false },
        { text: 'Yes', value: true }
      ],
      startYearRules: [
        (v) => {
          const startYear = Number(v)
          const thisYear = new Date().getFullYear()
          return (
            (!Number.isNaN(startYear) &&
              startYear > 1900 &&
              startYear < thisYear + 1) ||
            `Year must be between 1900 - ${thisYear}`
          )
        }
      ],
      textAreaRules: [
        (v) => !v || v.length <= this.textAreaLimit || 'Max 500 characters'
      ],
      notificationOptions: {
        allOptions: [
          { text: 'Text', value: 'sms' },
          { text: 'Email', value: 'email' },
          { text: 'Voice messages', value: 'voice' }
        ],
        formatting: { sms: 'Text', email: 'Email', voice: 'Voice messages' }
      },
      stripeError: null,
      maxLength50Rule: [(v) => !v || v.length <= 50 || 'Max 50 characters']
    }
  },
  computed: {
    clinicianLocal: {
      get: function () {
        return this.clinician
      },
      set: function (value) {
        return this.$emit('update:clinician', value)
      }
    },
    showEnglishTranslationDisplayValue: function () {
      return 'Yes'
    },
    notificationPreferences: function () {
      if (!this.clinician.notification_preferences?.length) {
        return 'None'
      } else {
        return this.clinician.notification_preferences
          .map((p) => this.notificationOptions.formatting[p])
          .join(', ')
      }
    }
  },
  methods: {
    handleAddEducation() {
      this.clinicianLocal.educations.push({ degree: null, institution: null })
    },
    handleRemoveEducation(index) {
      this.clinicianLocal.educations.splice(index, 1)
    },
    clearClinicianEducation() {
      return this.$clinicians.update(this.clinician.id, { educations: [] })
    },
    updateClinicianEducation(data) {
      let payload = data
      if (!Object.keys(data).length) {
        payload = { educations: [] }
      }
      return this.$clinicians.update(this.clinician.id, payload)
    },
    async updateClinician(data) {
      const { stripe_id: stripeId, ...rest } = data
      const payload = rest
      if (stripeId !== this.clinician.stripe_id) {
        try {
          if (stripeId !== null) {
            await this.$stripe.getAccount(stripeId)
          }
          payload.stripe_id = stripeId
        } catch (e) {
          this.stripeError = `This Stripe ID is not associated with any account. ${e}`
        }
      }

      if (payload.notification_preferences !== undefined) {
        payload.notification_preferences =
          payload.notification_preferences?.split(',') || []
      }

      return this.$clinicians.update(this.clinician.id, payload)
    },
    getFieldValues(fieldName) {
      return () => this.$clinicians.getFieldValues(fieldName)
    },
    onSubmit(data) {
      const payload = { ...this.clinician, ...data }
      this.$emit('update:clinician', payload)
    },
    onChange() {
      this.stripeError = null
    }
  }
}
</script>

<style lang="scss" scoped></style>
