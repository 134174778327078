<template>
  <div
    class="clinician-details mt-4 mx-4"
    :class="{ 'force-hscroll__wrapper': showTable }"
  >
    <div class="d-block d-lg-flex">
      <div
        :class="{ 'clinician-details__summary__bordered pr-lg-4': showTable }"
      >
        <v-toolbar flat>
          <v-btn icon exact @click="$emit('close')">
            <v-icon medium>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="pl-0"
            ><h6>Clinician Enrollments</h6></v-toolbar-title
          >
          <v-spacer />
          <v-btn
            :class="{ 'clinician-details__view-table-btn__active': showTable }"
            class="ml-4"
            rounded
            text
            @click="toggleTable"
            ><v-icon>mdi-table</v-icon>View Table</v-btn
          >
        </v-toolbar>
        <router-link
          :to="`/clinicians/${clinician.id}`"
          class="d-flex black--text text-decoration-none"
        >
          <clinician-availability :clinician="clinician" />
          <div class="d-flex flex-column pl-4">
            <h4>{{ clinician.name }}</h4>
            <span class="text-subtitle-1 text--secondary text-capitalize">{{
              clinician.practitioner_type | practitionerType
            }}</span>
          </div>
        </router-link>
        <v-divider class="my-4 mx-auto" />
        <div :class="{ 'd-none d-lg-block': showTable }">
          <clinician-enrollment-summary class="mt-2" :clinician="clinician" />
          <v-divider class="mt-0 mx-auto" />
          <div class="pl-4">
            <h6>State Licenses</h6>
            <state-chips :states="clinician.licenses" />
          </div>
        </div>
      </div>
      <clinician-enrollments
        v-show="showTable"
        class="ml-lg-4 force-hscroll"
        :clinician="clinician"
      />
    </div>
  </div>
</template>

<script>
import ClinicianEnrollmentSummary from '@/components/clinicians/ClinicianEnrollmentSummary'
import ClinicianEnrollments from '@/components/clinicians/ClinicianEnrollments'
import ClinicianAvailability from '@/components/clinicians/ClinicianAvailability'
import { PractitionerType } from '@/filters/Clinician'
import StateChips from '@/components/common/StateChips'
export default {
  name: 'ClinicianDetails',
  components: {
    ClinicianAvailability,
    ClinicianEnrollments,
    StateChips,
    ClinicianEnrollmentSummary
  },
  filters: {
    PractitionerType
  },
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showTable: false
    }
  },
  methods: {
    toggleTable() {
      this.showTable = !this.showTable
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables.scss';
.force-hscroll {
  // HACK:
  // This combination of black magic forces a position:fixed element to properly handle horizontal scrolling
  // Basically you have to give it some height to trick the browser into imposing the width requirement.
  // Yeah I don't understand either.
  &__wrapper {
    // mt-4
    min-height: calc(100% - 16px + 1px);
  }
  overflow-x: auto;
}
.clinician-details {
  overflow: hidden;

  &__summary {
    &__bordered {
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        border-right: 2px solid rgba(22, 22, 44, 0.12);
      }
    }
  }

  &__view-table-btn {
    &__active {
      background: rgba(46, 79, 37, 0.12);
    }
  }
  ::v-deep .v-toolbar__content {
    padding-right: 0;
  }

  ::v-deep .clinician-licenses {
    display: inline-grid;
    grid-template-columns: repeat(8, auto);
  }
}
</style>
