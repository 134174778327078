<template>
  <v-card-text class="px-0">
    <v-alert dense outlined text class="pa-4" icon="mdi-alert-circle-outline">
      <span>
        <span class="font-weight-bold"> Needs Operating Hours </span>
        <br />
        <span>
          Please add client hours for clinicians to be able to schedule shifts
          for {{ clientName }}
        </span>
        <br />
      </span>
    </v-alert>
  </v-card-text>
</template>

<script>
export default {
  name: 'CoverageBlocksUploadMessage',
  props: {
    clientName: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
