import axios from 'axios'
import Pagination from '@/utils/Pagination'

const ConsultRates = {
  install(Vue) {
    Vue.prototype.$consultRates = new Vue({
      methods: {
        async get() {
          const { data } = await axios.get('/admin/consult_rates')
          return data
        },
        async getConsultRateModalities() {
          const { data } = await axios.get('/admin/consult_rates/modalities')
          return data
        },
        async getById(id) {
          const { data } = await axios.get(`/admin/consult_rates/${id}`)
          return data
        },
        async getClinicians(id, params) {
          const response = await axios.get(
            `/admin/consult_rates/${id}/clinicians`,
            { params }
          )
          return response.data
        },
        async getEnrollments(id) {
          const response = await Pagination.getAll(
            `/admin/consult_rates/${id}/enrollments`
          )
          return response.data
        },
        async getShiftBlocks(id, start, end, state) {
          const { data } = await axios.get(
            `/admin/consult_rates/${id}/shift_blocks`,
            { params: { start, end, state } }
          )
          return data
        },
        async getCoverageBlocks(id, start, end, state) {
          const { data } = await axios.get(
            `/admin/consult_rates/${id}/coverage_blocks`,
            { params: { start, end, state } }
          )
          return data
        },
        async getForecast(id, params) {
          const response = await axios.get(
            `/admin/consult_rates/${id}/forecasts`,
            {
              params
            }
          )
          return response.data
        },
        async getConsultRateTiers(id) {
          const { data } = await Pagination.getAll(
            `/admin/consult_rates/${id}/consult_rate_tiers`
          )
          return data
        },
        async getActivityRates(id) {
          const { data } = await Pagination.getAll(
            `/admin/consult_rates/${id}/activity_rates`
          )
          return data
        },
        async createConsultRateTier(id, consult_rate_tier) {
          const response = await axios.post(
            `/admin/consult_rates/${id}/consult_rate_tiers`,
            { consult_rate_tier }
          )
          return response.data
        },
        async createActivityRate(id, activity_rate) {
          const response = await axios.post(
            `/admin/consult_rates/${id}/activity_rates`,
            { activity_rate }
          )
          return response.data
        },
        async edit(id, consult_rate) {
          const response = await axios.patch(`/admin/consult_rates/${id}`, {
            consult_rate
          })
          return response.data
        }
      }
    })
  }
}

export default ConsultRates
