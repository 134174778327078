// DO NOT CHANGE - If changes are needed note that this will require backend and other downstream changes!

const Tiers = {
  tier_1: {
    states: ['CA', 'TX', 'FL', 'NY', 'IL', 'PA', 'OH', 'NC', 'MI', 'WA'],
    name: 'Tier 1'
  },
  tier_2: {
    states: ['GA', 'NJ', 'VA', 'AZ', 'MA', 'MD', 'WI', 'CO', 'MN', 'IA', 'DC'],
    name: 'Tier 2'
  },
  tier_3: {
    states: ['TN', 'IN', 'KY', 'OR', 'OK', 'CT', 'UT', 'NE', 'ME', 'MT'],
    name: 'Tier 3'
  },
  tier_4: {
    states: [
      'MO',
      'SC',
      'AL',
      'LA',
      'RI',
      'NV',
      'AR',
      'MS',
      'KS',
      'NM',
      'WV',
      'ID',
      'HI',
      'NH',
      'DE',
      'SD',
      'ND',
      'AK',
      'VT',
      'WY'
    ],
    name: 'Tier 4'
  }
}

export default Tiers
