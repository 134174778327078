import { render, staticRenderFns } from "./ClientView.vue?vue&type=template&id=4823538b&"
import script from "./ClientView.vue?vue&type=script&lang=js&"
export * from "./ClientView.vue?vue&type=script&lang=js&"
import style0 from "./ClientView.vue?vue&type=style&index=0&id=4823538b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports