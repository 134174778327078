<template>
  <div>
    <div class="mt-3">
      <search v-model="search" placeholder="Search by State" />
    </div>
    <navigating-data-table
      disable-sort
      :headers="headers"
      :items="visibleStateReqs"
      :loading="loading"
      :footer-props="footer"
      :navigate="(item) => `/collabs/state-requirements/${item.state}`"
    >
      <template #item.state="{ item }">
        <state-chips :states="[item.state]" />
      </template>
      <template #item.max_total_supervisions="{ item }">
        <span
          v-if="
            item.max_total_supervisions !== '-' ||
            (item.max_prescribing_supervisions == '-' &&
              item.max_nonprescribing_supervisions == '-')
          "
          >{{ item.max_total_supervisions }}</span
        >
        <span v-else>
          {{ formatSupervisions(item) }}
        </span>
      </template>
    </navigating-data-table>
  </div>
</template>

<script>
import NavigatingDataTable from '@/components/tables/NavigatingDataTable'
import Search from '@/components/common/Search'
import StateChips from '@/components/common/StateChips.vue'
import UNITED_STATES from '@/utils/UnitedStates'

export default {
  name: 'StateCollabRequirementsTable',
  components: {
    NavigatingDataTable,
    Search,
    StateChips
  },
  props: {
    loading: Boolean,
    stateCollabRequirements: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'State', value: 'state' },
        { text: 'Collab Required', value: 'collab_required_type' },
        {
          text: 'Max Number of Supervisions',
          value: 'max_total_supervisions'
        },
        { text: 'Max Payback Amount', value: 'max_payback_amount' },
        { text: 'Chart Reviews', value: 'chart_review' },
        {
          text: 'Meeting Requirements',
          value: 'meeting_requirements',
          width: '35%'
        }
      ],
      footer: {
        itemsPerPageOptions: [],
        itemsPerPageText: null,
        disableItemsPerPage: true
      }
    }
  },
  computed: {
    visibleStateReqs() {
      return this.stateCollabRequirements
        .map((obj) => {
          Object.keys(obj).forEach((key) => {
            if (!obj[key] || obj[key] === '') {
              obj[key] = '-'
            }
          })
          return obj
        })
        .map((obj) => ({
          ...obj,
          state_name: UNITED_STATES[obj.state.toUpperCase()].name
        }))
        .filter(
          (el) =>
            this.search === '' ||
            el.state.toUpperCase().includes(this.search.toUpperCase()) ||
            el.state_name.toUpperCase().includes(this.search.toUpperCase())
        )
    }
  },
  methods: {
    formatSupervisions(item) {
      return `${
        item.max_prescribing_supervisions !== '-'
          ? `${item.max_prescribing_supervisions} Prescribing`
          : ''
      }${
        item.max_prescribing_supervisions !== '-' &&
        item.max_nonprescribing_supervisions !== '-'
          ? ', '
          : ''
      }${
        item.max_nonprescribing_supervisions !== '-'
          ? `${item.max_nonprescribing_supervisions} Non-Prescribing`
          : ''
      }`
    }
  }
}
</script>
