<template>
  <div class="manually-reassign">
    <v-dialog v-model="dialog" max-width="900">
      <template #activator="{ on, attrs }">
        <slot name="activator" :on="on" :attrs="attrs">
          <v-btn depressed fab small v-bind="attrs" color="primary" v-on="on">
            <v-icon color="white">compare_arrows</v-icon>
          </v-btn>
        </slot>
      </template>
      <v-card v-if="!toggleModal">
        <v-card-title class="headline">Manually Reassign</v-card-title>
        <v-container class="manually-reassign__modal-container">
          <v-alert
            v-for="(message, index) in alert.messages"
            :key="index"
            type="error"
          >
            {{ message }}
          </v-alert>
          <clinicians-mini-search v-model="clinician" :consult-id="consultId" />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn text rounded depressed @click="dialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            rounded
            depressed
            :disabled="!clinician"
            :loading="loading"
            @click="showConfirmModal"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="toggleModal">
        <v-card-title class="headline text-capitalize">
          Assign Consult
        </v-card-title>
        <v-card-text>
          Are you sure you want to reassign this consult?
        </v-card-text>
        <v-textarea
          v-model="reasonText"
          label="Describe the issue"
          outlined
          class="mb-4"
          auto-grow
        />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="text-capitalize text-color--success"
            rounded
            depressed
            @click="dialog = false"
          >
            Go back
          </v-btn>
          <v-btn
            :disabled="!clinician || invalidAssignReason"
            class="text-capitalize text-color--danger"
            rounded
            depressed
            :loading="loading"
            @click="submit"
          >
            Assign
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CliniciansMiniSearch from '@/components/common/CliniciansMiniSearch'

export default {
  name: 'ManuallyReassign',
  components: {
    CliniciansMiniSearch
  },
  props: {
    consultId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      clinician: null,
      loading: false,
      alert: {},
      toggleModal: false,
      reasonText: null
    }
  },
  computed: {
    invalidAssignReason() {
      return this.reasonText == null || this.reasonText == ''
    }
  },
  mounted() {},
  methods: {
    showConfirmModal() {
      this.toggleModal = true
    },
    async submit() {
      this.loading = true
      try {
        await this.$consults.createAssignment(
          this.consultId,
          this.clinician.id,
          this.reasonText
        )
        this.$emit('reassigned', this.clinician)
      } catch (e) {
        if (e.response) {
          this.alert = e.response.data
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.theme--light .manually-reassign__modal-container {
  .v-icon {
    color: inherit;
  }
}
</style>
