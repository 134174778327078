<template>
  <span>{{ name }}</span>
</template>

<script>
export default {
  name: 'ClinicianName',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      name: '-'
    }
  },
  mounted() {
    this.loadClinician()
  },
  methods: {
    async loadClinician() {
      const clinician = await this.$clinicians.show(this.id)
      this.name = clinician.name
    }
  }
}
</script>
