<template>
  <profile-field v-model="fieldValue" :edit-mode="edit">
    <template #default>
      <label class="profile-image mb-4">
        <auto-avatar
          v-if="previewImage"
          v-model="fieldValue"
          large
          :name="fieldValue"
          :class="{ placeholder: !previewImage }"
        >
          <v-img :src="previewImage" alt="" />
        </auto-avatar>
        <v-icon v-else class="circle-icon placeholder">
          mdi-image-search-outline
        </v-icon>
        <input
          ref="profileImage"
          type="file"
          :name="fieldName"
          required
          accept="image/*"
          @input="handleInput"
        />
      </label>
    </template>
    <template #view>
      <auto-avatar
        v-model="displayValue"
        large
        :name="displayValue"
        class="mr-4"
      />
    </template>
  </profile-field>
</template>

<script>
import ProfileField from './ProfileField.vue'
import AutoAvatar from '@/components/common/AutoAvatar.vue'

export default {
  name: 'ProfileImage',
  components: {
    AutoAvatar,
    ProfileField
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    edit: {
      type: Boolean
    },
    fieldName: {
      type: String,
      required: true
    },
    validations: {
      type: Array,
      default: () => []
    },
    previewImage: {
      type: String,
      default: ''
    },
    clearImage: {
      type: Boolean
    }
  },
  computed: {
    fieldValue() {
      return this.previewImage || ''
    },
    displayValue() {
      return this.value
    }
  },
  watch: {
    edit: {
      handler() {
        if (this.edit === false) {
          this.$emit('update:previewImage', null)
        }
      }
    },
    clearImage: function () {
      this.clearInput()
    }
  },
  methods: {
    clearInput() {
      this.$refs.profileImage.value = null
    },
    runValidations(file) {
      const validationResults = []
      for (const v of this.validations) {
        const result = v(file)
        if (typeof result === 'string') {
          validationResults.push(result)
        }
      }
      return validationResults
    },
    async enableCropperModal(file) {
      if (file && file[0]) {
        const fileToRead = file[0]
        const reader = new FileReader()

        reader.addEventListener('load', async () => {
          this.$emit('showModal', {
            image: reader.result,
            fileName: fileToRead.name,
            fileType: fileToRead.type
          })
        })

        reader.readAsDataURL(fileToRead)
      }
    },
    handleInput() {
      const file = this.$refs.profileImage.files
      const validationResults = this.runValidations(file)
      if (validationResults.length) {
        this.$emit('validate', validationResults)
        this.clearInput()
      } else {
        this.enableCropperModal(file)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-image {
  display: block;
  text-align: center;
  cursor: pointer;

  input {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

.placeholder {
  border: 1px dashed #000 !important;
}

.circle-icon {
  background: rgb(236, 236, 236);
  padding: 30px;
  border-radius: 50%;
}
</style>
