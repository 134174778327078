<template>
  <section class="performance-view">
    <header>
      <div class="d-flex justify-space-between align-center">
        <h2 class="mb-2">Performance</h2>
      </div>
      <p class="performance-view__results">
        <span v-if="loading">Loading results&hellip;</span>
        <span v-else-if="error" class="performance-view__error">
          Error loading results: "{{ error }}". Please try again later&hellip;
        </span>
        <span v-else>{{ total }} results</span>
      </p>
    </header>
    <hr />

    <div class="search-row">
      <search v-model="search" @input="searchRecords" />
    </div>
    <div class="d-flex align-left pb-3">
      <span class="bullet-green px-3"> &#9679; </span>
      <div class="pill px-2 good">
        <v-icon size="small" fill="currentColor">mdi-star-four-points</v-icon>
        <span class="ml-1">Top Performer</span>
      </div>
      <div class="pill px-2 ml-2 good">
        <v-icon size="small" fill="currentColor">mdi-star-four-points</v-icon>
        <span class="ml-1">Great</span>
      </div>
      <span class="bullet-orange px-3"> &#9679; </span>
      <div class="pill px-2 ok">
        <v-icon size="small" fill="currentColor">mdi-alert</v-icon>
        <span class="ml-1">Needs improvement</span>
      </div>
      <span class="bullet-red px-3"> &#9679; </span>
      <div class="pill px-2 poor">
        <v-icon size="small" fill="currentColor">mdi-alert-circle</v-icon>
        <span class="ml-1">Warning: Poor</span>
      </div>
    </div>

    <navigating-data-table
      disable-sort
      :headers="headers"
      :options.sync="options"
      :footer-props="footer"
      :server-items-length="total"
      :items="parsedMetrics"
      :loading="loading"
      :navigate="(item) => `/clinicians/${item.clinician_id}`"
      @update:page="pageChanged"
    >
      <template #item.clinician_name="{ item }">
        {{ item.clinician_name }}
      </template>

      <template
        v-for="def in definitions"
        #[`item.${def.name}_long`]="{ item }"
      >
        <span v-if="item[`${def.name}_long`]" :key="def.name + '_long'">
          <span :class="bulletClass(def.name, item[`${def.name}_long`].value)">
            &#9679;
          </span>
          {{ item[`${def.name}_long`].formatted }}
        </span>
        <span v-else :key="def.name + '_long'">-</span>
      </template>

      <template
        v-for="def in definitions"
        #[`item.${def.name}_short`]="{ item }"
      >
        <span v-if="item[`${def.name}_short`]" :key="def.name + '_short'"
          >{{ item[`${def.name}_short`].formatted }}
        </span>
        <span v-else :key="def.name + '_short'">-</span>
      </template>
    </navigating-data-table>
  </section>
</template>

<script>
import {
  getPerformanceType,
  formatValue,
  formatDurationText
} from '@/utils/MetricTypes'
import Search from '@/components/common/Search'
import NavigatingDataTable from '@/components/tables/NavigatingDataTable'
import GridSearch from '@/mixins/GridSearch'

export default {
  name: 'PerformanceView',
  components: {
    NavigatingDataTable,
    Search
  },
  mixins: [GridSearch],
  data() {
    return {
      cliniciansMetrics: [],
      footer: {
        itemsPerPageOptions: [],
        itemsPerPageText: null,
        disableItemsPerPage: true
      },
      headers: [{ text: 'Clinician Name', value: 'clinician_name' }],
      definitions: [],
      parsedMetrics: []
    }
  },
  async created() {},
  methods: {
    async loadData() {
      const response = await this.$clinicians.getCliniciansMetrics(
        this.$route.query
      )

      this.cliniciansMetrics = response.data
      this.definitions = JSON.parse(response.meta.definitions)
      this.buildHeaders()
      this.parseData()

      return response
    },
    parseQueryString() {
      const { page, search, ...filters } = this.$route.query

      this.options.page = page ? Number(page) : 1
      this.search = search
    },
    buildHeaders() {
      let array = [{ text: 'Clinician Name', value: 'clinician_name' }]
      for (let def of this.definitions) {
        let short_term_header = {
          text: `${def.title} - ${formatDurationText(
            def.count_unit,
            def.short_term_count
          )}`,
          value: def.name + '_short'
        }
        array.push(short_term_header)
        let long_term_header = {
          text: `${def.title} - ${formatDurationText(
            def.count_unit,
            def.long_term_count
          )}`,
          value: def.name + '_long'
        }
        array.push(long_term_header)
      }
      this.headers = array
    },
    parseData() {
      let array = []
      for (let clinician of this.cliniciansMetrics) {
        let parsed = {
          clinician_name: clinician.name,
          clinician_id: clinician.id
        }
        for (let def of this.definitions) {
          let metric = clinician.metrics.filter(
            (metric) => metric.name == def.name
          )[0]
          if (metric) {
            parsed[def.name + '_short'] = {
              value: metric.short_term_value,
              formatted: formatValue(
                metric.short_term_value,
                metric.count_unit
              ),
              unit: metric.count_unit
            }
            parsed[def.name + '_long'] = {
              value: metric.long_term_value,
              formatted: formatValue(metric.long_term_value, metric.count_unit),
              unit: metric.count_unit
            }
          }
        }
        array.push(parsed)
      }
      this.parsedMetrics = array
    },
    bulletClass(name, value) {
      switch (getPerformanceType(name, value)) {
        case 'poor':
          return 'bullet-red'
        case 'needs-improvement':
          return 'bullet-orange'
        default:
          return 'bullet-green'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.performance-view {
  height: 100%;
  padding-top: 4rem;

  &__results {
    color: rgba(24, 24, 24, 0.6);
  }

  &__error {
    color: #bf211e;
  }

  &__avatar-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .v-data-table {
    text-transform: capitalize;

    img {
      height: 0.75rem;
      width: 0.75rem;
    }
  }

  ::v-deep .v-simple-checkbox {
    width: 40px;
    display: flex;
    justify-content: center;
  }
}

.search-row {
  display: flex;
  flex-flow: nowrap;

  > :first-child {
    flex: 1;
  }
}

.bullet-green {
  color: #00a800;
}

.bullet-orange {
  color: #b24b01;
}

.bullet-red {
  color: #c0033e;
}

.pill-container {
  width: 82px;
}

.pill {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  border-radius: 40px;
  align-items: center;
}
.good {
  background-color: #e9ffe9;
  color: #0b5b0b;
}
.good > i.v-icon.v-icon {
  color: #0b5b0b;
}
.ok {
  background-color: #fff0e5;
  color: #b24b01;
}
.ok > i.v-icon.v-icon {
  color: #b24b01;
}
.poor {
  background-color: #fff0f5;
  color: #c0033e;
}
.poor > i.v-icon.v-icon {
  color: #c0033e;
}

.positive {
  background-color: #e9ffe9;
  color: #0b5b0b;
}

.positive > i {
  color: #34c759;
}

.neutral {
  background-color: #fff7ee;
  color: #b34700;
}

.neutral > i {
  color: #b34700;
}

.negative {
  background-color: #fff0f5;
  color: #c0033e;
}

.negative > i {
  color: #ff3b30;
}
</style>
