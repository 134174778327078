<template>
  <div>
    <div v-if="error" class="red--text">{{ error }}</div>
    <v-sheet tile height="54" class="d-flex">
      <v-btn
        icon
        class="ma-2"
        aria-label="Previous Week"
        @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <router-query-controller
        v-slot="{ updateQuery, queryValue }"
        query-name="state"
      >
        <state-selector
          :loading="loading"
          :default-value="queryValue"
          @change="filters.state = updateQuery($event)"
        />
      </router-query-controller>
      <v-spacer />
      <consult-rate-selector
        :multi-client="false"
        :loading="loading"
        :consult-rates="consultRates"
        @change="filters[$event.type] = $event.id"
      />
      <v-btn
        icon
        class="ma-2"
        aria-label="Next Week"
        @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet>
      <div v-if="loading" class="loader">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        />
      </div>
      <v-calendar
        ref="calendar"
        v-model="date"
        class="mt-4"
        type="week"
        :events="events"
        event-overlap-mode="stack"
        :event-color="getEventColor"
        @change="onChange"
      >
        <template #event="{ event }">
          <strong class="pl-1">{{ getEventName(event) }}</strong>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import moment from 'moment'
import StateSelector from '@/components/common/StateSelector.vue'
import RouterQueryController from '@/components/common/RouterQueryController.vue'
import { RATE } from '@/components/forecasts/ForecastCalendar'
import ConsultRateSelector from '@/components/common/ConsultRateSelector.vue'

export default {
  name: 'ShiftsCalendar',
  components: {
    RouterQueryController,
    ConsultRateSelector,
    StateSelector
  },
  props: {
    clientId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      consultRatesError: null,
      shiftBlocksError: null,
      coverageBlocksError: null,
      shifts: [],
      coverage: [],
      consultRates: [],
      loadingConsultRates: false,
      loadingShiftBlocks: false,
      loadingCoverageBlocks: false,
      filters: {
        state: null,
        [RATE]: null
      }
    }
  },
  computed: {
    date: {
      set(date) {
        if (this.$route.query.date !== date) {
          this.$router.replace({ query: { ...this.$route.query, date: date } })
        }
      },
      get() {
        return this.$route.query.date
      }
    },
    coverageEvents() {
      return this.coverage.reduce((acc, val) => {
        acc[`${val.start_at}|${val.shift_type.type}`] = {
          start: Date.parse(val.start_at),
          end: Date.parse(val.end_at),
          timed: true,
          ...val
        }
        return acc
      }, {})
    },
    shiftEvents() {
      return this.shifts.reduce((acc, val) => {
        if (acc[`${val.start_at}|${val.shift_type.type}`]) {
          acc[`${val.start_at}|${val.shift_type.type}`] = {
            ...val,
            ...acc[`${val.start_at}|${val.shift_type.type}`]
          }
        } else {
          acc[`${val.start_at}|${val.shift_type.type}`] = {
            start: Date.parse(val.start_at),
            end: Date.parse(val.end_at),
            timed: true,
            ...val
          }
        }
        return acc
      }, this.coverageEvents)
    },
    events() {
      return Object.values(this.shiftEvents)
    },
    loading() {
      return this.loadingShiftBlocks || this.loadingCoverageBlocks
    },
    error() {
      return this.shiftBlocksError || this.coverageBlocksError
    }
  },
  watch: {
    filters: {
      handler() {
        if (this.$refs.calendar) {
          this.onChange({
            start: this.$refs.calendar.lastStart,
            end: this.$refs.calendar.lastEnd
          })
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getConsultRates()
  },
  methods: {
    async getConsultRates() {
      try {
        this.loadingConsultRates = true
        const consultRates = await this.$clients.getConsultRates(this.clientId)
        // need the ids to be strings otherwise the query param -> select conversion doesn't work well
        this.consultRates = consultRates.data.map((rate) => ({
          ...rate,
          id: rate.id.toString()
        }))
      } catch (e) {
        this.consultRatesError = e
      } finally {
        this.loadingConsultRates = false
      }
    },
    onChange(param) {
      this.getShifts(param)
      this.getCoverageBlocks(param)
    },
    async getShifts({ start, end }) {
      try {
        this.loadingShiftBlocks = true
        const startTime = moment(start.date).startOf('day').toISOString()
        const endTime = moment(end.date).endOf('day').toISOString()
        this.shifts = []
        const response = this.filters[RATE]
          ? await this.$consultRates.getShiftBlocks(
              this.filters[RATE],
              startTime,
              endTime,
              this.filters.state
            )
          : await this.$clients.getShiftBlocks(
              this.clientId,
              startTime,
              endTime,
              this.filters.state
            )

        this.shifts = response.data
      } catch (e) {
        this.shiftBlocksError = e
      } finally {
        this.loadingShiftBlocks = false
      }
    },
    async getCoverageBlocks({ start, end }) {
      try {
        this.loadingCoverageBlocks = true
        const startTime = moment(start.date).startOf('day').toISOString()
        const endTime = moment(end.date).endOf('day').toISOString()
        this.coverage = []
        const response = this.filters[RATE]
          ? await this.$consultRates.getCoverageBlocks(
              this.filters[RATE],
              startTime,
              endTime,
              this.filters.state
            )
          : await this.$clients.getCoverageBlocks(
              this.clientId,
              startTime,
              endTime,
              this.filters.state
            )

        this.coverage = response.data
      } catch (e) {
        this.coverageBlocksError = e
      } finally {
        this.loadingCoverageBlocks = false
      }
    },
    getEventName(event) {
      return this.loading
        ? ''
        : `${event.clinician_shifts?.length || 0} Clinicians`
    },
    getEventColor(event) {
      const hasClinicians = event.clinician_shifts?.length
      const majorColor =
        event.shift_type.type === 'on_call' ? 'orange' : 'primary'
      const unfilledColor =
        event.shift_type.type === 'on_call' ? 'purple' : 'red'
      return this.loading || hasClinicians ? majorColor : unfilledColor
    }
  }
}
</script>

<style lang="scss" scoped></style>
