import { render, staticRenderFns } from "./RoutingScore.vue?vue&type=template&id=fdd0e754&"
import script from "./RoutingScore.vue?vue&type=script&lang=js&"
export * from "./RoutingScore.vue?vue&type=script&lang=js&"
import style0 from "./RoutingScore.vue?vue&type=style&index=0&id=fdd0e754&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports