<template>
  <div v-if="error">
    <span class="earnings-history__error">
      Error loading results: "{{ error }}".
    </span>
  </div>
  <div v-else>
    <div class="d-flex justify-start mt-6 mb-4 align-center">
      <v-btn
        class="mr-2"
        icon
        exact
        :to="`/clinicians/${clinician.id}/earnings`"
      >
        <v-icon medium> mdi-arrow-left </v-icon>
      </v-btn>
      <div class="text-center mr-6 font-weight-bold">
        {{ period | monthName }} {{ summaryYear }}
      </div>
      <div class="text-center mr-6">
        <span>Monthly Earnings: </span>
        <span class="font-weight-bold">${{ summaryEarnings }}</span>
      </div>
      <div class="text-center">
        <span>Consults completed: </span>
        <span class="font-weight-bold">{{ consultCount }}</span>
      </div>
    </div>
    <navigating-data-table
      disable-sort
      hide-default-footer
      :headers="headers"
      :options.sync="options"
      :server-items-length="total"
      :items="earnings"
      :loading="loading"
      @click:row="handleClick"
      @update:page="pageChanged"
    >
      <template #item.consult="{ item }">
        {{ formatLink(item.consult) || 'N/A' }}
      </template>
      <template #item.client="{ item }">
        {{ clientName(item.client) || 'Misc Transfer' }}
      </template>
      <template #item.completed_at="{ item }">
        {{ completedTime(item.completed_at) }}
      </template>
      <template #item.description="{ item }">
        {{ item.description }}
      </template>
      <template #item.paid_to_provider="{ item }">
        {{ item.paid_to_provider | currency }}
      </template>
    </navigating-data-table>
    <div class="d-inline-flex align-baseline mt-4">
      <div class="mr-4">
        <strong
          >{{ currentDisplayedElementsStart }}-{{
            currentDisplayedElementsEnd
          }}</strong
        >
        of <strong>{{ totalCount }}</strong>
      </div>
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="0"
        :disabled="loading"
        @previous="loadData('previous')"
        @next="loadData('next')"
      />
    </div>
  </div>
</template>

<script>
import NavigatingDataTable from '@/components/tables/NavigatingDataTable'
import { formatDate, toUtc, getMonthName } from '@/utils/Date'
import GridSearch from '@/mixins/GridSearch'
import { clientHelpers as clients } from '@/store/clients/index'
import Pagination from '@/utils/Pagination'

export default {
  name: 'EarningsHistory',
  components: { NavigatingDataTable },
  filters: {
    monthName: getMonthName
  },
  mixins: [GridSearch],
  props: {
    clinician: {
      type: Object,
      required: true
    },
    summary: {
      type: Object,
      required: true
    },
    pageSize: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      loading: false,
      earnings: [],
      meta: [],
      currentPage: 1,
      period: this.$route.query.period,
      summaryYear: this.$route.query.period.split('-')[0],
      summaryMonth: this.$route.query.period.split('-')[1],
      headers: [
        { text: 'Consult Link', value: 'consult', sortable: false },
        { text: 'Source', value: 'client', sortable: false },
        { text: 'Completed', value: 'completed_at', sortable: false },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Amount', value: 'paid_to_provider', sortable: false }
      ],
      error: ''
    }
  },
  computed: {
    clients: clients.mappedGetters.clientMap,
    summaryEarnings() {
      const summary = this.getPeriodSummary()
      return parseFloat(summary.paid_to_provider).toFixed(2)
    },
    consultCount() {
      const summary = this.getPeriodSummary()
      return summary.count
    },
    totalCount() {
      return this.meta?.total
    },
    previous() {
      return this.links?.previous
    },
    next() {
      return this.links?.next
    },
    totalPages() {
      return this.meta?.total_pages
    },
    currentDisplayedElementsStart() {
      return Pagination.getCurrentPageStartElement(
        this.currentPage,
        this.pageSize
      )
    },
    currentDisplayedElementsEnd() {
      return this.currentPage !== this.totalPages
        ? this.currentPage * this.pageSize
        : this.totalCount
    }
  },
  mounted() {
    this.getClients().then(() => {
      this.loadData()
    })
  },
  methods: {
    getClients: clients.mappedActions.index,
    getPeriodSummary() {
      return this.summary[this.summaryYear].find(
        (earning) => earning.year_month === this.period
      )
    },
    async loadData(cursor = null) {
      let next, previous

      if (cursor === 'next') {
        next = this.links?.next
      } else if (cursor === 'previous') {
        previous = this.links?.previous
      }

      this.loading = true

      try {
        const response = await this.$clinicians.getEarnings(
          this.clinician.id,
          this.summaryYear,
          this.summaryMonth,
          this.$route.query.page,
          true,
          next,
          previous
        )
        this.earnings = response.data
        this.links = response.links
        this.meta = response.meta
        this.error = ''
        this.loading = false
        return response
      } catch (error) {
        this.error = error
        this.loading = false
      }
    },
    completedTime(time) {
      const date = toUtc(time)
      return formatDate(date, 'MMMM DD, YYYY h:mma')
    },
    handleClick({ consult }) {
      if (consult.uuid !== null) {
        this.$router.replace({ path: `/consults/${consult.uuid}` })
      } else {
        this.$router.replace({
          path: `/clinicians/${this.clinician.id}`,
          query: { tab: 'earnings', period: this.period }
        })
      }
    },
    clientName(client) {
      return this.clients[client.uuid]
    },
    formatLink(consult) {
      return consult ? 'Consult Link' : undefined
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep td {
  cursor: pointer;
}

.earnings-history__error {
  color: #bf211e;
}

::v-deep .v-pagination .v-pagination__navigation {
  -webkit-box-shadow: none;
  box-shadow: none;
  display: initial;
}
</style>
