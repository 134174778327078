import axios from 'axios'

const MiscTransfers = {
  install(Vue) {
    Vue.prototype.$miscTransfers = new Vue({
      methods: {
        async getTemplateText() {
          const { data } = await axios.get('/admin/misc_transfers/template', {
            responseType: 'text'
          })
          return data
        },
        async pending(params) {
          const response = await axios.get('/admin/misc_transfers/pending', {
            params: params
          })
          return response.data
        },
        async batchCreate(transfers) {
          const { data } = await axios.post('/admin/misc_transfers/batch', {
            misc_transfers: transfers
          })
          return data
        },
        async processPending() {
          const { data } = await axios.post(
            '/admin/misc_transfers/pending/process'
          )
          return data
        }
      }
    })
  }
}

export default MiscTransfers
