import axios from 'axios'

/**
 * @typedef {Object} Consult
 * @property {number} id
 * @property {string} uuid
 * @property {string} state
 * @property {string} status
 * @property {Array<Object>} permitted_transitions
 * @property {Object} client
 * @property {string} client.name
 * @property {Object} assignment - The current assignment
 * @property {number} id
 * @property {number} clinician_id
 * @property {Object} consult_rate
 * @property {string} consult_rate.display_name
 * @property {string} consult_rate.modality
 * @property {number} consult_rate.cost_to_client
 * @property {number} consult_rate.paid_to_provider
 * @property {number} consult_rate.sla_in_seconds
 * @property {number} consult_rate.assignment_lifetime
 * @property {number} consult_rate.autocomplete_in_days
 */
const Consults = {
  install(Vue) {
    Vue.prototype.$consults = new Vue({
      methods: {
        async index(params) {
          const response = await axios.get('/admin/consults', {
            params: params
          })
          return response.data
        },
        async unhealthy(params) {
          const response = await axios.get('/admin/consults/unhealthy', {
            params: params
          })
          return response.data
        },
        /**
         * @param id
         * @returns { Consult }
         */
        async show(id) {
          const response = await axios.get(`/admin/consults/${id}`)
          return response.data
        },
        /**
         * @param id
         * @param action
         * @returns { Consult }
         */
        async patch_action(id, action, statusReason) {
          const { data } = await axios.patch(
            `/admin/consults/${id}/${action}`,
            { status_reason: statusReason }
          )
          return data
        },
        async void(id, voidCode, statusReason) {
          const response = await axios.patch(`/admin/consults/${id}/void`, {
            status_reason: statusReason,
            void_code: voidCode
          })
          return response.data
        },
        async hold(id, statusReason) {
          return this.patch_action(id, 'hold', statusReason)
        },
        async start(id, statusReason) {
          return this.patch_action(id, 'start', statusReason)
        },
        async complete(id, statusReason) {
          return this.patch_action(id, 'complete', statusReason)
        },
        async unassign(id, statusReason) {
          return this.patch_action(id, 'unassign', statusReason)
        },
        async cancel(id) {
          const { data } = await axios.patch(`/admin/consults/${id}/cancel`)
          return data
        },
        async createAssignment(id, clinicianId, statusReason) {
          const response = await axios.post(
            `/admin/consults/${id}/assignments`,
            {
              assignment: { clinician_id: clinicianId },
              status_reason: statusReason
            }
          )
          return response.data
        },
        async potentialAssignments(id, params) {
          const response = await axios.get(
            `/admin/consults/${id}/assignments/potential`,
            { params: params }
          )
          return response.data
        },
        async history(id, page) {
          let config = {}
          if (page) {
            config = { params: { page } }
          }

          const { data } = await axios.get(
            `/admin/consults/${id}/history`,
            config
          )
          return data
        },
        async rebook(id, clinicianId) {
          const response = await axios.patch(`/admin/consults/${id}/rebook`, {
            booking: { clinician_id: clinicianId }
          })
          return response.data
        },
        async fetchConsultStatuses() {
          const response = await axios.get('/admin/consult_statuses')
          return response.data
        }
      }
    })
  }
}

export default Consults
