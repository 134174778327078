<template>
  <section class="clinicians-view">
    <header>
      <div class="d-flex justify-space-between align-center">
        <h2 class="mb-2">Clinicians</h2>
        <div class="mb-2">
          <span v-if="selected.length" class="clinicians-view__results mr-6">
            {{ selected.length }} selected
          </span>
          <actions-dropdown
            :clinicians="selected"
            @reloadClinicians="loadData()"
          />
        </div>
      </div>
      <p class="clinicians-view__results">
        <span v-if="loading">Loading results&hellip;</span>
        <span v-else-if="error" class="clinicians-view__error">
          Error loading results: "{{ error }}". Please try again later&hellip;
        </span>
        <span v-else>{{ total }} results</span>
      </p>
    </header>
    <hr />
    <div class="search-row">
      <search v-model="search" @input="searchRecords" />
      <filters
        :current-filters="filters"
        :filter-options="filterOptions"
        @apply="applyFilters"
        @reset="resetFilters"
      />
    </div>
    <selectable-data-table
      v-model="selected"
      disable-sort
      :headers="headers"
      :options.sync="options"
      :footer-props="footer"
      :server-items-length="total"
      :items="clinicians"
      :loading="loading"
      :navigate="(item) => `/clinicians/${item.id}`"
      @update:page="pageChanged"
    >
      <template #item.name="{ item }">
        <div class="clinicians-view__avatar-icon">
          <auto-avatar :name="item.photo || item.name" />
          {{ item.name }}
        </div>
      </template>

      <template #item.available="{ item }">
        <img
          v-if="item.available"
          src="~@/assets/images/indicator-filled.svg"
        />
        <img v-else src="~@/assets/images/indicator-empty.svg" />
      </template>

      <template #item.paused_until="{ item }">
        <clinician-paused :until="item.paused_until" />
      </template>

      <template #item.licenses="{ item }">
        <state-chips :limit="3" :states="item.licenses" />
      </template>

      <template #item.email="{ item }">
        <copy :value="item.email" :icon="false" />
      </template>

      <template #item.phone="{ item }">
        <span> {{ item.phone | PhoneNumber }} </span>
      </template>
    </selectable-data-table>
  </section>
</template>

<script>
import ClinicianPaused from '@/components/clinicians/ClinicianPaused'
import StateChips from '@/components/common/StateChips'
import Search from '@/components/common/Search'
import Filters from '@/components/common/Filters.vue'
import SelectableDataTable from '@/components/tables/SelectableDataTable'
import AutoAvatar from '@/components/common/AutoAvatar'
import GridSearch from '@/mixins/GridSearch'
import { UNITED_STATES_ARRAY } from '@/utils/UnitedStates'
import Copy from '@/components/common/Copy'
import { PhoneNumber } from '@/filters/PhoneNumber'
import ActionsDropdown from '@/components/clinicians/ActionsDropdown.vue'

export default {
  name: 'CliniciansView',
  components: {
    AutoAvatar,
    // CohortDialog,
    SelectableDataTable,
    // ClientDialog,
    Filters,
    StateChips,
    ClinicianPaused,
    Search,
    Copy,
    ActionsDropdown
  },
  filters: {
    PhoneNumber
  },
  mixins: [GridSearch],
  data() {
    return {
      clinicians: [],
      filters: {
        status: [],
        async_status: [],
        practitioner_type: [],
        licenses: []
      },
      filterOptions: {
        status: {
          options: [
            { value: 'active', display: 'active' },
            { value: 'suspended', display: 'suspended' }
          ],
          name: 'status'
        },
        async_status: {
          options: [
            { value: 'online', display: 'online' },
            { value: 'offline', display: 'offline' },
            { value: 'paused', display: 'paused' }
          ],
          name: 'activity'
        },
        practitioner_type: {
          options: [],
          name: 'clinician_type'
        },
        licenses: {
          options: UNITED_STATES_ARRAY,
          name: 'state_licenses'
        }
      },
      footer: {
        itemsPerPageOptions: [],
        itemsPerPageText: null,
        disableItemsPerPage: true
      },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'Available', value: 'available' },
        { text: 'Paused', value: 'paused_until' },
        { text: 'Routable Licenses', value: 'licenses' },
        { text: 'Email', value: 'email' },
        { text: 'Phone Number', value: 'phone' }
      ],
      selected: []
    }
  },
  async created() {
    this.fetchFilters()
  },
  methods: {
    applyFilters(filters) {
      this.update({
        ...this.$route.query,
        page: 1,
        ...filters
      })
    },
    resetFilters(filters) {
      this.update({
        ...this.$route.query,
        page: 1,
        ...filters
      })
    },
    async fetchFilters() {
      const filtersToSort = ['practitioner_types']

      const filters = await this.$clinicians.getClinicianFilters()
      this.filterOptions.practitioner_type.options = this.sortFilters(
        filters,
        filtersToSort
      ).practitioner_types
    },
    sortFilters(filters, whitelist) {
      const sortedFilters = {}
      Object.entries(filters).forEach(([filterName, filterToSort]) => {
        if (whitelist.includes(filterName)) {
          filterToSort.sort((a, b) => a.display.localeCompare(b.display))
        }
        sortedFilters[filterName] = filterToSort
      })
      return sortedFilters
    },
    async loadData() {
      const response = await this.$clinicians.index({
        ...this.$route.query,
        ...this.filters
      })
      this.clinicians = response.data
      return response
    },
    parseQueryString() {
      const { page, search, ...filters } = this.$route.query

      this.options.page = page ? Number(page) : 1
      this.search = search

      Object.entries(filters).forEach(([key, value]) => {
        this.filters[key] = [value].flat()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.clinicians-view {
  height: 100%;
  padding-top: 4rem;

  &__results {
    color: rgba(24, 24, 24, 0.6);
  }

  &__error {
    color: #bf211e;
  }

  &__avatar-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .v-data-table {
    text-transform: capitalize;

    img {
      height: 0.75rem;
      width: 0.75rem;
    }
  }

  ::v-deep .v-simple-checkbox {
    width: 40px;
    display: flex;
    justify-content: center;
  }
}

.search-row {
  display: flex;
  flex-flow: nowrap;

  > :first-child {
    flex: 1;
  }
}
</style>
