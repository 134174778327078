import axios from 'axios'

const ConfigValues = {
  install(Vue) {
    Vue.prototype.$configValues = new Vue({
      methods: {
        async getConfigValues() {
          const { data } = await axios.get('/admin/config_values')
          return data
        },
        async edit(id, value) {
          const response = await axios.patch(`/admin/config_values/${id}`, {
            value
          })
          return response.data
        }
      }
    })
  }
}

export default ConfigValues
