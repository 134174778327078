<template>
  <v-card-text class="px-0">
    <v-alert
      dense
      outlined
      text
      color="red"
      class="pa-4"
      icon="mdi-alert-circle-outline"
    >
      <span>
        <span class="font-weight-bold">{{ title }}</span>
        <br />
        <span>{{ content }} </span>
        <br />
      </span>
    </v-alert>
  </v-card-text>
</template>

<script>
export default {
  name: 'WarningBanner',
  props: {
    content: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>
