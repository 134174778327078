import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import MaterialIcon from '@/components/icons/MaterialIcon'

// https://fonts.google.com/icons

function missingMaterialIcons(ids) {
  const icons = {}
  for (const id of ids) {
    for (const suffix of ['fill', 'outline', 'two-tone', 'round', 'sharp']) {
      const name = `${id}_${suffix}`
      icons[name] = {
        component: MaterialIcon,
        props: {
          name
        }
      }
    }
  }
  return icons
}

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#2E4F25',
        secondary: '#F5F1E9',
        anchor: '#181818'
      }
    }
  },
  icons: {
    values: {
      ...missingMaterialIcons([
        'house',
        'people_alt',
        'assignment',
        'edit',
        'delete_forever'
      ]) // https://vuetifyjs.com/en/features/icon-fonts/#missing-material-icons
    }
  }
}

export default new Vuetify(opts)
