import Vue from 'vue'

export const PhoneNumber = Vue.filter('phoneNumber', function (phone) {
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }

  return phone
})
