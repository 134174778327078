import axios from 'axios'

const OptOuts = {
  install(Vue) {
    Vue.prototype.$optOuts = new Vue({
      methods: {
        async updateOptOut(id, params) {
          const response = await axios.patch(`/admin/opt_outs/${id}`, {
            default_opt_out: false,
            ...params
          })
          return response
        },
        async createOptOut(params) {
          const response = await axios.post(`/admin/opt_outs`, {
            default_opt_out: false,
            ...params
          })
          return response
        },
        async deleteOptOut(id) {
          const response = await axios.delete(`/admin/opt_outs/${id}`, {
            data: { default_opt_out: false }
          })
          return response
        }
      }
    })
  }
}

export default OptOuts
