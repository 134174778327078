const ShiftModal = {
  data() {
    return {
      saving: false,
      modalError: '',
      shiftTypes: {
        standard: {
          className: '--standard',
          display: 'standard'
        },
        on_call: {
          className: '--on-call',
          display: 'on-call'
        }
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async handleConfirm() {
      try {
        this.saving = true
        this.modalError = ''
        await this.action()
        this.close()
        this.$emit('confirm')
      } catch (e) {
        this.modalError =
          this.error || 'Unable to perform action on shift. Please try again.'
      } finally {
        this.saving = false
      }
    }
  }
}

export default ShiftModal
