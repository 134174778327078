<template>
  <profile-field v-model="itemValue" :edit-mode="canEdit">
    <template #default="attrs">
      <v-autocomplete
        :label="normalizedFieldName"
        :rules="rules"
        :chips="isMultiple"
        :multiple="isMultiple"
        clearable
        v-bind="attrs"
        :name="fieldName"
        :items="autocompleteItems"
        :loading="!autocompleteItems.length || loading"
      />
    </template>
    <template v-if="!hideValues" #view>
      <slot name="view">
        <data-row
          :label="normalizedFieldName"
          :value="displayValue"
          :value-class="isPlaceholder ? 'placeholder' : ''"
        />
      </slot>
    </template>
  </profile-field>
</template>

<script>
import DataRow from '@/components/common/DataRow'
import ProfileField from '@/components/clinicians/profile/ProfileField'
export default {
  name: 'ProfileAutocomplete',
  components: {
    DataRow,
    ProfileField
  },
  props: {
    items: {
      type: [Function, Array, Promise],
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    hideValues: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: [Array, String],
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    editMode: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean
    },
    notEmpty: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      autocompleteItems: [],
      rules: this.notEmpty ? [(v) => !!v?.length || 'Must not be empty'] : [],
      error: false
    }
  },
  computed: {
    canEdit() {
      return !this.error && this.editMode
    },
    isPlaceholder() {
      return !(this.value && this.value.length)
    },
    displayValue() {
      return this.isPlaceholder
        ? this.displayPlaceholder
        : this.isMultiple
        ? this.value.join(', ')
        : this.value
    },
    displayPlaceholder() {
      return (
        this.placeholder ||
        (this.isMultiple
          ? `Add ${this.normalizedFieldName}`
          : `Select ${this.normalizedFieldName}`)
      )
    },
    isMultiple() {
      return Array.isArray(this.value)
    },
    itemValue() {
      const item = (val) => {
        const item = this.autocompleteItems.find(
          (item) => item === val || item.text === val
        )
        return item?.value || item
      }

      return this.isMultiple
        ? this.valueLocal.map((val) => item(val))
        : item(this.valueLocal)
    },
    normalizedFieldName() {
      if (this.label) {
        return this.label
      }
      return this.fieldName.replaceAll('_', ' ')
    },
    valueLocal: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('update:value', value)
      }
    }
  },
  async created() {
    try {
      this.autocompleteItems =
        typeof this.items === 'function' ? await this.items() : await this.items
    } catch (e) {
      this.error = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  text-transform: capitalize;
}
</style>
