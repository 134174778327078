<template>
  <enrollments-table
    v-slot="{ enrollment }"
    :loading="loading"
    class="clinician-enrollments"
    :states="clinician.licenses"
    :enrollments="enrollments"
    :clinician="clinician"
    disable-pagination
    hide-search
    :first-column="firstHeader"
    :filter="enrollmentsFilter"
    @delete:enrollment="deleteEnrollment"
    @update:enrollment="updateEnrollment"
  >
    <router-link
      class="black--text text-decoration-none"
      :to="`/consult-rates/${enrollment.consult_rate.id}`"
    >
      <client-label
        :name="enrollment.consult_rate.client.name"
        :uuid="enrollment.consult_rate.client.uuid"
        tooltip
      >
        <div class="pl-2 text-sm-body-2">
          {{ enrollment.consult_rate.name }}
        </div>
      </client-label>
    </router-link>
  </enrollments-table>
</template>

<script>
import EnrollmentsTable from '@/components/enrollments/EnrollmentsTable'
import ClientLabel from '@/components/common/ClientLabel'
import EnrollmentsMixin from '@/mixins/Enrollments'

export default {
  name: 'ClinicianEnrollments',
  components: {
    ClientLabel,
    EnrollmentsTable
  },
  mixins: [EnrollmentsMixin],
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      enrollments: [],
      loading: false
    }
  },
  computed: {
    firstHeader() {
      return {
        text: `Enrollments (${this.enrollments.length})`,
        sortable: true,
        value: 'consult_rate.name',
        class: 'horizontal-fixed'
      }
    }
  },
  watch: {
    clinician: {
      handler: 'loadEnrollments',
      immediate: true
    }
  },
  methods: {
    enrollmentsFilter: function (item) {
      return [
        item.consult_rate.name,
        item.consult_rate.modality,
        item.consult_rate.client.name,
        item.consult_rate.id
      ]
    },
    async loadEnrollments() {
      this.loading = true
      this.enrollments = []
      const response = await this.$clinicians.getEnrollments(this.clinician.id)
      this.enrollments = response.data
      this.loading = false
    }
  }
}
</script>
