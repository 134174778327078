<template>
  <v-card>
    <v-card-title class="headline"> Create new consult rate tier </v-card-title>
    <v-card-text class="alert">
      <v-alert color="red" dense outlined text icon="mdi-alert-circle-outline">
        Confirmation with Engineering, Payments, and Legal is required before
        creating a new consult rate tier!
      </v-alert>
    </v-card-text>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-autocomplete
          v-model="createInputs.states"
          label="Select a Tier"
          :items="filterTiers"
          item-text="display"
          item-value="value"
          :rules="[rules.required]"
          clearable
          hint="Select which tier to create this consult rate tier for.  No duplicate tiers can be created on the same consult rate.  This is not editable after creation."
          persistent-hint
        />
        <v-text-field
          v-model="createInputs.assignment_lifetime"
          label="Assignment Lifetime (seconds)"
          :rules="[rules.required, rules.assignment_lifetime]"
          clearable
          hint="The time allowed for assignment."
          persistent-hint
        />
        <v-text-field
          v-model="createInputs.cost_to_client"
          label="Cost to Client"
          :rules="[rules.required]"
          clearable
          prefix="$"
          hint="Determines the base cost to clients per consult once completed."
          persistent-hint
        />
        <v-text-field
          v-model="createInputs.paid_to_provider"
          label="Paid to Provider"
          :rules="[rules.required]"
          clearable
          prefix="$"
          hint="Determines the base pay per clinician per consult once completed."
          persistent-hint
        />
        <v-text-field
          v-model="createInputs.sla_in_seconds"
          label="SLA (seconds)"
          :rules="[
            rules.required,
            slaLength(createInputs.sla_in_seconds, consultRateModality)
          ]"
          clearable
          hint="Determines the SLA given per consult."
          persistent-hint
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <span v-if="modalError" class="modal-error">{{ modalError }}</span>
      <v-spacer />
      <v-btn text @click="close"> Cancel </v-btn>
      <v-btn
        color="primary"
        rounded
        :loading="saving"
        :disabled="!valid"
        @click="handleConfirm"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Modal from '@/mixins/Modal'
import ConsultRateTiers from '@/mixins/ConsultRateTiers'
import ValidationMixin from '@/mixins/Validation'

export default {
  name: 'ConsultRateTierCreateModal',
  mixins: [Modal, ConsultRateTiers, ValidationMixin],
  props: {
    consultRateId: {
      type: String,
      required: true
    },
    consultRateTiers: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    consultRateModality: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: 'Unable to create consult rate tier. Please try again.',
      rules: {
        required: (v) => !!v || 'This field is required!',
        assignment_lifetime: (v) =>
          v >= 60 || 'Assignment lifetime should be at least 1 minute.'
      },
      tierOptions: [
        { value: 'tier_1', display: 'Tier 1' },
        { value: 'tier_2', display: 'Tier 2' },
        { value: 'tier_3', display: 'Tier 3' },
        { value: 'tier_4', display: 'Tier 4' }
      ],
      createInputs: {},
      results: {},
      valid: false
    }
  },
  computed: {
    getTierNames() {
      return this.consultRateTiers.map((tier) =>
        this.findTier(tier.states[0].toUpperCase())
      )
    },
    filterTiers() {
      return this.tierOptions.filter(
        (opt) => !this.getTierNames.includes(opt.display)
      )
    }
  },
  methods: {
    async action() {
      this.createInputs.states = this.getTierStates(this.createInputs.states)

      this.results = await this.$consultRates.createConsultRateTier(
        this.consultRateId,
        this.createInputs
      )
      this.$router.push({
        path: `/consult-rate-tiers/${this.results.id}/details`
      })
      this.$store.commit('SET_SNACKBAR', {
        message: 'Success! Consult rate tier was created.',
        show: true
      })
      return this.results
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  margin-top: 32px;
}

.modal-error {
  color: #bf211e;
}
</style>
