<template>
  <v-tooltip top :disabled="!tooltip" open-delay="500">
    <template #activator="{ on, attrs }">
      <v-img
        :src="icon"
        :max-width="size"
        :max-height="size"
        :min-width="size"
        v-bind="attrs"
        v-on="on"
        @error="error = true"
      />
    </template>
    {{ name }}
  </v-tooltip>
</template>

<script>
export default {
  name: 'ClientIcon',
  props: {
    uuid: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    tooltip: Boolean,
    size: {
      type: Number,
      default: 40
    }
  },
  data() {
    return {
      error: false,
      fallback:
        'https://on-demand-public.s3.amazonaws.com/partner-icons/default.png'
    }
  },
  computed: {
    icon() {
      return this.error
        ? this.fallback
        : `https://on-demand-public.s3.amazonaws.com/partner-icons/${this.uuid}.png`
    }
  }
}
</script>
