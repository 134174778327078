import { mapKeys } from '@/store/helpers'

const mutations = {
  set(state, { data }) {
    state.data = data
  },
  update(state, data) {
    const index = state.data.data.findIndex((client) => client.id === data.id)
    state.data.data[index] = data
  },
  add(state, data) {
    state.data = [...state.data, data]
  },
  error(state, data) {
    state.error = data
  }
}

export const keys = mapKeys(mutations)
export default mutations
