<template>
  <v-data-table v-bind="$attrs" v-on="$listeners" @click:row="go">
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-data-table>
</template>
<script>
import NavigateTableRow from '@/mixins/NavigateTableRow'

export default {
  name: 'NavigatingDataTable',
  mixins: [NavigateTableRow]
}
</script>

<style lang="scss" scoped>
::v-deep td {
  cursor: pointer;
}
::v-deep td.text-start:empty::after {
  content: '-';
}
</style>
