<template>
  <div class="payments-and-transfers">
    <header>
      <div class="d-flex justify-space-between align-center">
        <h2>Payments &amp; Transfers</h2>
        <div class="mb-2">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn depressed v-bind="attrs" rounded color="primary" v-on="on">
                Actions
              </v-btn>
            </template>
            <v-list>
              <misc-transfer-upload />
              <run-ledger-payments />
            </v-list>
          </v-menu>
        </div>
      </div>
    </header>
    <tabs>
      <template #misc-transfers>
        <misc-transfers-table />
      </template>
      <template #payments>
        <p class="mt-4">This page will be built in the future</p>
      </template>
    </tabs>
  </div>
</template>

<script>
import Loading from '@/mixins/Loading'
import MiscTransferUpload from '@/components/misc-transfers/MiscTransferUpload.vue'
import MiscTransfersTable from '@/components/misc-transfers/MiscTransfersTable.vue'
import RunLedgerPayments from '@/components/payments/RunLedgerPayments.vue'
import Tabs from '@/components/common/Tabs'

export default {
  name: 'PaymentsAndTransfersView',
  components: {
    Tabs,
    MiscTransferUpload,
    MiscTransfersTable,
    RunLedgerPayments
  },
  mixins: [Loading],
  data() {
    return {
      loading: true,
      valid: false
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.payments-and-transfers {
  height: 100%;
  padding-top: 4rem;
}
</style>
