<template>
  <div class="consults-view">
    <div class="d-flex justify-space-between align-center">
      <h2 class="mb-2">Consults</h2>
      <div class="mb-2">
        <consult-import-upload @uploaded="fetchData" />
      </div>
    </div>
    <p class="consults-view__results">
      <span v-if="loading">Loading results&hellip;</span>
      <span v-else-if="error" class="consults-view__error">
        Error loading results: "{{ error }}". Please try again later&hellip;
      </span>
      <span v-else>{{ total }} results</span>
    </p>
    <hr />
    <div class="search-row">
      <search v-model="search" :is-loading="isLoading" @input="searchRecords" />
      <consult-filters
        :filters="filters"
        @apply="applyFilters"
        @reset="resetFilters"
      />
    </div>
    <navigating-data-table
      disable-sort
      :navigate="(item) => `/consults/${item.id}`"
      :headers="headers"
      :options.sync="options"
      :footer-props="footer"
      :server-items-length="total"
      :items="consults"
      :loading="loading"
      @update:page="pageChanged"
    >
      <template #item.created_at="{ item }">
        {{ new Date(item.created_at).toLocaleDateString() }}
      </template>

      <template #item.client.name="{ item }">
        <client-label v-bind="item.client" />
      </template>

      <template #item.state="{ item }">
        <span class="text-uppercase">{{ item.state }}</span>
      </template>

      <template #item.consult_rate.sla_in_seconds="{ item }">
        {{ item.consult_rate.sla_in_seconds | duration }}
      </template>

      <template #item.assignment.clinician_id="{ item }">
        <clinician-name
          v-if="item.assignment"
          :id="item.assignment.clinician_id"
        />
        <span v-else>-</span>
      </template>
    </navigating-data-table>
  </div>
</template>

<script>
import ClinicianName from '@/components/common/ClinicianName'
import ClientLabel from '@/components/common/ClientLabel'
import Search from '@/components/common/Search'
import ConsultImportUpload from '@/components/consult-imports/ConsultImportUpload.vue'
import ConsultFilters from '@/components/common/ConsultFilters.vue'
import NavigatingDataTable from '@/components/tables/NavigatingDataTable'
import GridSearch from '@/mixins/GridSearch'
import { Duration } from '@/filters/Moment'

export default {
  name: 'ConsultsView',
  components: {
    ClinicianName,
    ClientLabel,
    NavigatingDataTable,
    Search,
    ConsultFilters,
    ConsultImportUpload
  },
  filters: {
    Duration
  },
  mixins: [GridSearch],
  data() {
    return {
      headers: [
        { text: 'Consult ID', value: 'id' },
        { text: 'Created On', value: 'created_at' },
        { text: 'Status', value: 'status' },
        { text: 'Client', value: 'client.name' },
        { text: 'Modality', value: 'consult_rate.modality' },
        { text: 'SLA', value: 'consult_rate.sla_in_seconds' },
        { text: 'State', value: 'state' },
        { text: 'Assigned Clinician', value: 'assignment.clinician_id' }
      ],
      footer: {
        itemsPerPageOptions: [],
        itemsPerPageText: null,
        disableItemsPerPage: true
      },
      consults: [],
      filters: {
        status: [],
        client: [],
        modality: [],
        state: []
      }
    }
  },
  computed: {
    isLoading() {
      if (this.loading) return true
      return false
    }
  },
  methods: {
    applyFilters(filters) {
      this.update({
        ...this.$route.query,
        page: 1,
        ...filters
      })
    },
    resetFilters(filters) {
      this.update({
        ...this.$route.query,
        page: 1,
        ...filters
      })
    },
    async loadData() {
      const response = await this.$consults.index({
        ...this.$route.query,
        ...this.filters
      })

      this.consults = response.data
      return response
    },
    parseQueryString() {
      const { page, search, ...filters } = this.$route.query

      this.options.page = page ? Number(page) : 1
      this.search = search

      Object.entries(filters).forEach(([key, value]) => {
        if (!Number(value)) this.filters[key] = [value].flat()
        else this.filters[key] = [Number(value)].flat()
      })
    }
  }
}
</script>

<style lang="scss">
.consults-view {
  height: 100%;
  padding-top: 4rem;

  .consults-view__results {
    color: rgba(24, 24, 24, 0.6);
  }

  .consults-view__error {
    color: #bf211e;
  }

  .v-data-table {
    text-transform: capitalize;

    .no-text-transform {
      text-transform: none;
    }

    img {
      height: 0.75rem;
      width: 0.75rem;
    }
  }

  .practitioner-type--np {
    text-transform: uppercase;
  }

  .search-row {
    display: flex;
    flex-flow: nowrap;

    > :first-child {
      flex: 1;
    }
  }
}
</style>
