import { render, staticRenderFns } from "./ConsultRateCreateModal.vue?vue&type=template&id=856df21a&scoped=true&"
import script from "./ConsultRateCreateModal.vue?vue&type=script&lang=js&"
export * from "./ConsultRateCreateModal.vue?vue&type=script&lang=js&"
import style0 from "./ConsultRateCreateModal.vue?vue&type=style&index=0&id=856df21a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "856df21a",
  null
  
)

export default component.exports