<template>
  <div class="enrollments-table">
    <div v-if="error" class="enrollments_error">
      <span> Error updating enrollment: "{{ error }}" </span>
    </div>
    <search v-model="search" :debounce="false" />
    <div class="d-flex flex-column">
      <span
        ><v-icon class="available-icon">mdi-square</v-icon> Online/On
        Shift</span
      >
      <span
        ><v-icon class="unavailable-icon">mdi-square</v-icon> Offline/Off
        Shift</span
      >
    </div>
    <v-data-table
      ref="enrollments"
      class="mt-4 grid"
      :custom-filter="enrollmentsFilter"
      :headers="headers"
      :items="enrollments"
      :items-per-page="10"
      :search="search"
      :loading="loading"
      multi-sort
      fixed-header
      :disable-pagination="disablePagination"
      :hide-default-footer="disablePagination"
      :mobile-breakpoint="0"
    >
      <template v-for="state in stateColumns" #[`header.${state}`]="{ header }">
        <div :key="state" class="d-flex flex-column text-center">
          <span>{{ header.text }}</span>
          <span :class="{ invisible: !licensedStates.has(state) }">{{
            stateTotals[state] || 0
          }}</span>
        </div>
      </template>
      <template #item="{ item }">
        <enrollments-table-row
          :enrollment="item"
          :states="stateColumns"
          :error-message.sync="error"
          @delete:enrollment="confirmDelete"
          @update:enrollment="updateEnrollment"
        >
          <slot :enrollment="item" />
        </enrollments-table-row>
      </template>
    </v-data-table>
    <v-dialog
      v-model="deleteEnrollment.showDialog"
      content-class="confirm-remove"
      width="400"
      @input="resetDeleteEnrollment"
    >
      <v-card>
        <v-card-title class="d-flex align-center">
          <span class="confirm-remove__header">Remove Enrollment</span>
        </v-card-title>
        <v-card-text>
          <p>
            Are you sure you want to remove the enrollment
            <strong>{{ deleteEnrollment.name }}</strong> for
            <strong>{{ deleteEnrollment.clinician }}</strong
            >?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn depressed rounded @click="resetDeleteEnrollment"> No </v-btn>
          <v-btn
            :disabled="deleteEnrollment.deleting"
            color="text-color--danger"
            rounded
            depressed
            @click="handleDeleteEnrollment"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import debounce from 'debounce'
import UNITED_STATES from '@/utils/UnitedStates'
import Search from '@/components/common/Search'
import EnrollmentsTableRow from '@/components/enrollments/EnrollmentsTableRow'

export default {
  name: 'EnrollmentsTable',
  components: {
    EnrollmentsTableRow,
    Search
  },
  props: {
    loading: Boolean,
    show: Boolean,
    filter: {
      // A function that accepts an item and returns an array of fields to filter on.
      type: Function,
      required: true
    },
    disablePagination: Boolean,
    enrollments: {
      type: Array,
      required: true
    },
    consultRate: {
      type: Object,
      default() {
        return {}
      }
    },
    clinician: {
      type: Object,
      default() {
        return {}
      }
    },
    states: {
      type: Array,
      default: () => Object.keys(UNITED_STATES)
    },
    firstColumn: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      search: null,
      deleteEnrollment: {
        showDialog: false,
        deleting: false,
        id: null,
        clinician: '',
        name: ''
      },
      error: ''
    }
  },
  computed: {
    stateColumns() {
      return this.states.flatMap((key) =>
        UNITED_STATES[key.toUpperCase()] ? [key.toLowerCase()] : []
      )
    },
    stateTotals() {
      return this.enrollments
        .flatMap((enrollment) => enrollment.included_states)
        .reduce((acc, state) => {
          acc[state] = (acc[state] || 0) + 1
          return acc
        }, {})
    },
    licensedStates() {
      if (this.$options.propsData.states) {
        return new Set(this.stateColumns)
      } else {
        return new Set(
          this.enrollments.flatMap(
            (enrollment) => enrollment.clinician?.licenses
          )
        )
      }
    },
    headers() {
      const headers = [
        {
          ...this.firstColumn,
          class: this.firstColumn.class + ' --header'
        },
        {
          text: 'Priority',
          sortable: true,
          value: 'priority',
          class: 'enrollments-table-row__priority horizontal-fixed --header'
        }
      ]

      for (const key of this.stateColumns) {
        const state = UNITED_STATES[key.toUpperCase()]
        if (state) {
          headers.push({
            text: state.abbreviationUpper,
            value: state.abbreviationLower,
            sortable: false
          })
        }
      }

      return headers
    }
  },
  mounted() {
    this.setTableHeight()
    this.debouncedResize = debounce(this.setTableHeight, 500)
    window.addEventListener('resize', this.debouncedResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.debouncedResize)
  },
  methods: {
    setTableHeight() {
      const enrollmentsTable = this.$refs.enrollments.$el
      const rem = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      )
      enrollmentsTable.style.height = `${
        window.innerHeight -
        enrollmentsTable.getBoundingClientRect().top -
        3 * rem
      }px`
    },
    enrollmentsFilter: function (value, search, item) {
      if (
        value != null &&
        search != null &&
        typeof value !== 'boolean' &&
        isNaN(value)
      ) {
        const term = search.toLowerCase()

        const searchables = this.filter(item)

        return searchables.some((searchable) => {
          return searchable.toString().toLowerCase().indexOf(term) !== -1
        })
      }
      return false
    },
    confirmDelete(enrollment) {
      const { clinician, consult_rate: consultRate, id } = enrollment

      this.deleteEnrollment = {
        id,
        showDialog: true,
        clinician: clinician?.name || this.clinician.name,
        name: consultRate?.name || this.consultRate.name
      }
    },
    async handleDeleteEnrollment() {
      const { id, name } = this.deleteEnrollment

      this.deleteEnrollment.deleting = true
      let message = ''
      try {
        await this.$enrollments.destroy(id)
        this.$emit('delete:enrollment', id)
        message = `Enrollment ${name} removed`
      } catch (e) {
        let message = 'Could not delete Enrollment. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
      } finally {
        this.resetDeleteEnrollment()
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      }
    },
    resetDeleteEnrollment() {
      this.deleteEnrollment = {
        showDialog: false,
        deleting: false,
        id: null,
        clinician: '',
        name: ''
      }
    },
    updateEnrollment(enrollment) {
      this.$emit('update:enrollment', enrollment)
    },
    updateErrorMessage(message) {
      this.error = message || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.enrollments-table {
  ::v-deep {
    & .available-icon {
      background-color: $clinician-available;
      color: $clinician-available;
    }
    & .unavailable-icon {
      background-color: $clinician-unavailable;
      color: $clinician-unavailable;
    }

    .horizontal-fixed {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      z-index: 5 !important;
      &.--header {
        z-index: 6 !important;
      }
    }

    thead th:first-child,
    tbody th:first-child {
      left: 0;
      min-width: 160px;
      max-width: 160px;
    }

    thead th:nth-child(2),
    tbody th:nth-child(2) {
      left: 160px;
      border-right: 1px solid #ccc;
    }

    thead th {
      white-space: nowrap;
      min-width: 57px;
    }

    tbody td:not(:last-child) {
      border-right: 1px dashed #eee;
    }
  }
}

.confirm-remove {
  &.v-dialog .v-card {
    padding: size(24);

    &__text {
      font-size: size(16);
    }
  }

  &__header {
    @include text-h5;
  }
}

.grid {
  display: grid;
  min-height: 250px;
}

.enrollments_error {
  color: #bf211e;
  margin: size(24);
}
</style>
