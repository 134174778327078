const COLLAB_REQUIRED_TYPES = {
  NO: 'No',
  YES: 'Yes',
  YES_TRANSITION_TO_INDEPENDENCE: 'Yes, Transition to Independence',
  YES_GEOGRAPHIC_SUPERVISION: 'Yes, Geographic Supervision'
}

const CHART_REVIEW_OPTIONS = { NO: 'No', YES: 'Yes' }

const COLLAB_LIMIT_TYPES = {
  TOTAL_COLLAB: 'Total Collab',
  COLLAB_TYPE: 'Collab Type'
}

export default {
  COLLAB_REQUIRED_TYPES,
  CHART_REVIEW_OPTIONS,
  COLLAB_LIMIT_TYPES
}
