<template>
  <div class="summary-layout">
    <section class="summary-layout__section">
      <slot name="default" />
    </section>
    <aside v-if="hasAsideSlot" class="summary-layout__aside">
      <slot name="aside" />
    </aside>
  </div>
</template>

<script>
export default {
  name: 'SummaryLayout',
  computed: {
    hasAsideSlot() {
      return !!this.$slots.aside
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-layout {
  height: 100%;
  display: flex;
  flex-direction: row;
  &__section {
    flex-grow: 1;
    padding: size(48) size(48) size(48) 0;
  }
  &__aside {
    border-left: 1px solid rgba(24, 24, 24, 0.12);
    padding: 3rem;
    min-width: 30%;
  }
}
</style>
