<template>
  <div>
    <span v-if="showInfo" class="mr-2 text-body-2">
      <slot> Showing {{ count }} of {{ total }} {{ type }} </slot>
    </span>
    <v-btn
      v-if="showButton"
      class="mb-2"
      color="primary"
      rounded
      outlined
      @click="click"
    >
      <slot name="button">Load More</slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'LoadMore',
  props: {
    showInfo: {
      type: Boolean,
      default: true
    },
    showButton: {
      type: Boolean
    },
    count: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: ''
    }
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>
