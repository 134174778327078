<template>
  <div class="clinician-header">
    <div class="d-flex justify-space-between align-center">
      <div class="three-quarter-width">
        <div class="d-flex align-center">
          <clinician-availability :clinician="clinician" :status="false" />
          <div class="clinician-info__wrapper">
            <clinician-identity-info
              :clinician="clinician"
              :action-function="updateClinician"
              @submit="onSubmit"
            />
          </div>
        </div>
      </div>
      <client-dialog :clinicians="[clinician]" />
    </div>
  </div>
</template>

<script>
import ClinicianAvailability from '@/components/clinicians/ClinicianAvailability'
import ClinicianIdentityInfo from './ClinicianIdentityInfo'
import ClientDialog from '@/components/clinicians/ClientDialog'

export default {
  name: 'ClinicianHeader',
  components: {
    ClientDialog,
    ClinicianAvailability,
    ClinicianIdentityInfo
  },
  props: {
    clinician: {
      type: Object,
      required: true
    }
  },
  methods: {
    /* eslint-disable camelcase */
    updateClinician({ ...rest }) {
      return this.$clinicians.update(this.clinician.id, {
        ...rest
      })
    },
    onSubmit(data) {
      const payload = { ...this.clinician, ...data }
      this.$emit('update:clinician', payload)
    }
  }
}
</script>

<style lang="scss">
.clinician {
  &-status {
    & > * {
      margin-right: size(18);
    }
    p {
      margin-bottom: 0;
      text-transform: capitalize;
    }

    .v-input--selection-controls {
      margin-top: 0;
    }
  }
  &-info {
    &__wrapper {
      display: flex;
      width: 100%;
      & h6 {
        margin-bottom: 8px;
      }
    }
  }
}

.consults {
  .v-card__title {
    font-size: size(16);
  }
}
.three-quarter-width {
  width: 75%;
}
.status-label {
  color: $heading-grey;
}
</style>
