import axios from 'axios'

/**
 * Enrollment DTO
 * @typedef {Object} enrollment
 * @property {number} clinician_id
 * @property {number} consult_rate_id
 */

const Enrollments = {
  install(Vue) {
    Vue.prototype.$enrollments = new Vue({
      methods: {
        async show(id) {
          const response = await axios.get(`/admin/enrollments/${id}`)
          return response.data
        },
        async update(id, enrollment) {
          const response = await axios.patch(
            `/admin/enrollments/${id}`,
            enrollment
          )
          return response.data
        },
        async addState(id, state) {
          const response = await axios.put(
            `/admin/enrollments/${id}/included_states/add`,
            { state: state }
          )
          return response.data
        },
        async removeState(id, state) {
          const response = await axios.put(
            `/admin/enrollments/${id}/included_states/remove`,
            { state: state }
          )
          return response.data
        },

        /**
         * Deletes an enrollment
         *
         * @param {Number} id The enrollment id
         */
        async destroy(id) {
          return await axios.delete(`/admin/enrollments/${id}`)
        },

        /**
         * Create enrollments for a clinician and client
         *
         * @async
         * @param {enrollment|enrollment[]} enrollments
         */
        async batchCreate(enrollments) {
          if (!Array.isArray(enrollments)) {
            enrollments = [enrollments]
          }

          const { data } = await axios.post('/admin/enrollments/batch', {
            enrollments
          })

          return data
        }
      }
    })
  }
}

export default Enrollments
