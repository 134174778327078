<template>
  <summary-layout>
    <clinician-summary-info
      :clinician.sync="clinicianLocal"
      :action-function="updateClinician"
      @submit="onSubmit"
    />
    <clinician-summary-credentialing :clinician="clinician" />
    <clinician-opt-out-preferences
      class="mt-12"
      :opt-outs-info.sync="optOutsInfo"
      :clinician-id="clinician.id"
      :clients="clinician.clients"
      @submit="onSubmit"
    />
    <clinician-contact-info
      class="mt-12"
      :contact-info.sync="contactInfo"
      :action-function="updateClinician"
      @submit="onSubmit"
    />
    <clinician-cohorts
      class="mt-12"
      :clinician.sync="clinicianLocal"
      :action-function="updateClinician"
      @submit="onSubmit"
    />
    <template #aside>
      <p class="text-h6">Activity Status</p>
      <div class="d-flex flex-column">
        <strong class="mb-2">Shift:</strong>
        <div
          v-if="upcomingShift"
          class="mb-5"
          :class="{
            'text-color--orange-700':
              upcomingShift.onShift && upcomingShift.shiftType === 'on_call',
            'text-color--wheel-green':
              upcomingShift.onShift && upcomingShift.shiftType === 'standard'
          }"
        >
          <strong>{{ upcomingShift.label }}:</strong>
          <p class="mb-0">{{ upcomingShift.time }}</p>
        </div>
        <p v-else class="mb-5">No upcoming shifts</p>
        <label for="availability-toggle" class="font-weight-bold"
          >Availability</label
        >
        <div class="d-flex align-end mb-5">
          <v-switch
            id="availability-toggle"
            v-model="clinicianLocal.available"
            inset
            :loading="loading"
            hide-details="true"
            class="mt-2"
            @change="availabilityChange"
          />
          <span
            class="availability-status text-no-wrap"
            :class="{ 'text-color--wheel-green': clinician.available }"
          >
            {{ toggleAvailability }}
            <vue-countdown
              v-if="clinician.on_break_until"
              v-slot="{ minutes, seconds }"
              :time="timeDiff"
              :transform="transformTime"
            >
              (on break - {{ minutes }}:{{ seconds }} left)
            </vue-countdown>
          </span>
        </div>
        <label for="pause-toggle" class="font-weight-bold">Pause</label>
        <div class="d-flex align-end">
          <v-switch
            id="pause-toggle"
            v-model="pause"
            inset
            :loading="loading"
            hide-details="true"
            :disabled="!pause"
            class="mt-2"
            @change="unpause"
          />
          <span class="pauseStatus">{{ togglePauseStatus }}</span>
        </div>
      </div>
      <p class="text-h6 mt-16">Current Consults</p>
      <div class="d-flex flex-column">
        <clinician-consults :consults="consults" />
      </div>
    </template>
  </summary-layout>
</template>

<script>
import moment from 'moment'
import { formatDate, getTimezone } from '@/utils/Date'
import VueCountdown from '@chenfengyuan/vue-countdown'
import { Format } from '@/filters/Moment'
import { PractitionerType } from '@/filters/Clinician'
import ClinicianContactInfo from '@/components/clinician/ClinicianContactInfo.vue'
import ClinicianSummaryInfo from '@/components/clinician/ClinicianSummaryInfo.vue'
import ClinicianSummaryCredentialing from '@/components/clinician/ClinicianSummaryCredentialing.vue'
import ClinicianCohorts from '@/components/clinician/ClinicianCohorts.vue'
import ClinicianConsults from '@/components/clinician/ClinicianConsults.vue'
import ClinicianOptOutPreferences from '@/components/clinician/ClinicianOptOutPreferences.vue'
import SummaryLayout from '@/components/layout/Summary.vue'

export default {
  name: 'ClinicianSummary',
  components: {
    VueCountdown,
    SummaryLayout,
    ClinicianContactInfo,
    ClinicianCohorts,
    ClinicianConsults,
    ClinicianSummaryInfo,
    ClinicianSummaryCredentialing,
    ClinicianOptOutPreferences
  },
  filters: {
    Format,
    PractitionerType
  },
  props: {
    consults: {
      type: Array,
      required: true
    },
    clinician: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      pause: this.isPaused(),
      nextShift: null
    }
  },
  computed: {
    toggleAvailability() {
      return this.clinician.available ? 'Online' : 'Offline'
    },
    togglePauseStatus() {
      return this.isPaused() ? 'Paused' : 'Not Paused'
    },
    timeDiff() {
      return moment(this.clinician.on_break_until).diff(moment())
    },
    contactInfo() {
      const { phone, email, address } = this.clinician
      return { phone, email, ...address }
    },
    optOutsInfo() {
      const { opt_outs } = this.clinician
      return { opt_outs }
    },
    upcomingShift() {
      if (!this.nextShift) {
        return null
      }
      const { start_at: start, end_at: end } = this.nextShift
      let startFormat = 'h:mma'
      if (moment(this.nextShift.start_at).isAfter(moment().endOf('day'))) {
        startFormat = 'MMM Do [@] h:mma'
      }
      const tz = getTimezone(start)

      const onShift = moment().isBetween(start, end)

      return {
        onShift,
        shiftType: this.nextShift.shift_type.type,
        label: `${onShift ? 'On' : 'Next'} ${
          this.nextShift.shift_type.name
        } Shift`,
        time: `${formatDate(moment(start), startFormat)} - ${formatDate(
          moment(end),
          'h:mma'
        )} ${tz}`
      }
    },
    clinicianLocal: {
      get: function () {
        return this.clinician
      },
      set: function (value) {
        this.$emit('update:clinician', value)
      }
    }
  },
  watch: {
    $route: 'getNextShift'
  },
  async mounted() {
    await this.getNextShift()
  },
  methods: {
    async unpause() {
      this.loading = true
      const updated = await this.$clinicians.unpause(this.clinician.id)
      this.$emit('update:clinician', updated)
      this.loading = false
    },
    async getNextShift() {
      const to = moment().add(2, 'months').toISOString()
      const nextShift = await this.$clinicians.getNextShift(
        this.clinician.id,
        to
      )
      this.nextShift = nextShift
    },
    async availabilityChange(newValue) {
      this.loading = true
      if (newValue) {
        await this.$clinicians.available(this.clinician.id)
      } else {
        await this.$clinicians.unavailable(this.clinician.id)
      }
      this.loading = false
    },
    transformTime({ seconds, ...props }) {
      seconds = seconds < 10 ? `0${seconds}` : seconds
      return { seconds, ...props }
    },
    isDefined(value) {
      return value !== undefined
    },
    isPaused() {
      return moment().isBefore(this.clinician.paused_until)
    },
    onSubmit(data) {
      const payload = { ...this.clinicianLocal, ...data }
      this.$emit('update:clinician', payload)
    },
    updateClinician({
      city,
      state,
      postal_code: postalCode,
      address1,
      address2,
      ...rest
    }) {
      const address = {
        address1,
        address2,
        city,
        state,
        postal_code: postalCode
      }

      const addressIsDefined = Object.values(address).some(this.isDefined)

      return this.$clinicians.update(this.clinician.id, {
        ...rest,
        ...(addressIsDefined && { address })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.availability-status {
  max-width: 160px;
}
</style>
