<template>
  <profile-form
    v-slot="{ editMode, loading }"
    :action-function="actionFunction"
    hide-clear
    class="clinician-info-form ml-n4 pl-4 mr-n4"
    @submit="(event) => $emit('submit', event)"
  >
    <h6>Contact Information</h6>
    <profile-field
      v-for="field in contactFields"
      :key="field.name"
      v-model="contactInfoLocal[field.name]"
      :edit-mode="editMode"
    >
      <template #default="attrs">
        <v-text-field
          v-bind="attrs"
          :label="field.label"
          :rules="field.name == 'email' ? [rules.required, rules.email] : []"
          outlined
          :name="field.name"
          :loading="loading"
          :type="field.type"
        />
      </template>
      <template #view="{ value }">
        <div
          class="my-4 static-field-view"
          :class="{ hide: field.name !== 'email' }"
        >
          <data-row label="Email" :value="value">
            <template #prepend
              ><v-icon color="grey darken-1"
                >mdi-email-outline</v-icon
              ></template
            >
          </data-row>
        </div>
        <div
          class="my-4 static-field-view"
          :class="{ hide: field.name !== 'phone' }"
        >
          <data-row label="Phone" :value="value">
            <template #prepend
              ><v-icon color="grey darken-1"
                >mdi-phone-outline</v-icon
              ></template
            >
          </data-row>
        </div>
      </template>
    </profile-field>
    <v-row>
      <v-col>
        <profile-field
          v-slot="attrs"
          v-model="contactInfoLocal.state"
          :edit-mode="editMode"
          hide-values
        >
          <v-select
            v-bind="attrs"
            label="State"
            outlined
            name="state"
            :loading="loading"
            :items="states"
          />
        </profile-field>
      </v-col>
      <v-col>
        <profile-field
          v-slot="attrs"
          v-model="contactInfoLocal.postal_code"
          :edit-mode="editMode"
          hide-values
        >
          <v-text-field
            v-bind="attrs"
            label="Zipcode"
            outlined
            name="postal_code"
            :loading="loading"
          />
        </profile-field>
      </v-col>
    </v-row>
  </profile-form>
</template>

<script>
import ProfileField from '@/components/clinicians/profile/ProfileField.vue'
import ProfileForm from '@/components/clinicians/profile/ProfileForm.vue'
import DataRow from '@/components/common/DataRow.vue'
import Regex from '@/utils/Regex'
import { UNITED_STATES_ARRAY } from '@/utils/UnitedStates'

export default {
  name: 'ClinicianContactInfo',
  components: {
    ProfileForm,
    DataRow,
    ProfileField
  },
  props: {
    contactInfo: {
      type: Object,
      required: true
    },
    actionFunction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      states: UNITED_STATES_ARRAY.map((state) => state.abbreviationUpper),
      contactFields: [
        {
          inputType: 'email',
          label: 'Email',
          name: 'email'
        },
        {
          inputType: 'tel',
          label: 'Phone Number',
          name: 'phone'
        },
        {
          inputType: 'text',
          label: 'Home Address',
          name: 'address1'
        },
        {
          inputType: 'text',
          label: 'Apartment, suite, etc. (Optional)',
          name: 'address2'
        },
        {
          inputType: 'text',
          label: 'City',
          name: 'city'
        }
      ],
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => Regex.EMAIL.test(value) || 'Invalid email.'
      }
    }
  },
  computed: {
    contactInfoLocal: {
      get: function () {
        return this.contactInfo
      },
      set: function (value) {
        return this.$emit('update:contactInfo', value)
      }
    }
  }
}
</script>

<style lang="scss">
.clinician-info {
  &-form {
    width: calc(100% + size(32)) !important;
    margin-right: 36px;
    flex: 1;
  }
}
.static-field-view {
  & i {
    margin-right: 20px;
  }
}
</style>
