export const PAYMENT_MODELS_ENUM = {
  PAID_BY_WHEEL: {
    value: 0,
    display: 'Paid By Wheel'
  },
  TWO_PER_CONSULT: {
    value: 2,
    display: '$2 Per Consult'
  }
}

export const PAYMENT_MODELS_ARRAY = Object.values(PAYMENT_MODELS_ENUM)

const PAYMENT_MODELS = Object.entries(PAYMENT_MODELS_ENUM).reduce(
  (acc, [, { value, display }]) => {
    acc[value] = { value, display }
    return acc
  },
  {}
)

export default PAYMENT_MODELS
