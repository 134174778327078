<template>
  <div class="forecast-view">
    <h2>Forecast Based On Historical Data</h2>
    <p>Demand: Average number of consults sent to us in the past 4 weeks.</p>
    <p>
      Supply: Estimated number of clinicians needed based on demand and average
      consult duration.
    </p>
    <hr />
    <forecast-calendar />
  </div>
</template>

<script>
import ForecastCalendar from '@/components/forecasts/ForecastCalendar'

export default {
  name: 'ForecastView',
  components: {
    ForecastCalendar
  }
}
</script>

<style lang="scss" scoped>
.forecast-view {
  height: 100%;
  padding-top: 4rem;
}
</style>
