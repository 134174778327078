<template>
  <div class="state-chips">
    <v-tooltip
      v-for="state in shownStates"
      :key="state"
      :disabled="!tooltips[state]"
      top
      :open-delay="500"
    >
      <template #activator="{ on, attrs }">
        <v-chip
          class="text-uppercase"
          :class="{ error: error }"
          v-bind="attrs"
          v-on="on"
          >{{ state }}</v-chip
        >
      </template>
      {{ !tooltips[state] || tooltips[state].name }}
    </v-tooltip>
    <v-chip v-if="overflow > 0" outlined>+ {{ overflow }}</v-chip>
  </div>
</template>

<script>
import UNITED_STATES from '@/utils/UnitedStates'

export default {
  name: 'StateChips',
  props: {
    limit: {
      type: Number,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    states: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    shownStates() {
      return this.limit ? this.states.slice(0, this.limit) : this.states
    },
    tooltips() {
      return this.states.reduce((acc, cur) => {
        acc[cur] = UNITED_STATES[cur.toUpperCase()]
        return acc
      }, {})
    },
    overflow() {
      return this.limit ? this.states.length - this.limit : 0
    }
  }
}
</script>

<style lang="scss">
.state-chips {
  padding-top: 0.25rem;
  @include font-monospaced;

  .theme--light.v-chip {
    background: rgba(225, 232, 223, 1);
    color: rgba(13, 26, 9, 1);
    margin: 0 0.5rem 0.25rem 0;

    &:not(.v-chip--active) {
      background: rgba(225, 232, 223, 0.9);
    }

    &.error {
      background: rgba(242, 220, 220, 1) !important;
      color: rgba(79, 0, 0, 1);

      &:not(.v-chip--active) {
        background: rgba(242, 220, 220, 0.9) !important;
      }
    }
  }
}
</style>
