<template>
  <div class="collab-agreements-table">
    <div class="search-row">
      <search
        v-model="search"
        placeholder="Search by Status, State or Clinician"
        @input="searchRecords"
      />
      <collab-agreement-filters
        :filters.sync="filters"
        @apply="applyFilters"
        @reset="resetFilters"
      />
    </div>
    <navigating-data-table
      class="mt-4"
      :navigate="(collab) => `/collabs/agreements/${collab.id}`"
      :headers="headers"
      :options.sync="options"
      :server-items-length="total"
      :items="collabAgreements"
      :loading="loading"
      :footer-props="footer"
      disable-sort
      @update:page="pageChanged"
    >
      <template #item.status="{ item }">
        <div v-if="showNeedsReview(item)" class="status-container">
          <v-tooltip color="#141419" bottom>
            <template #activator="{ on, attrs }">
              <div>
                <v-icon color="#BF211E" dark v-bind="attrs" v-on="on">
                  warning
                </v-icon>
              </div>
            </template>
            <span>Collab will be set to</span>
            <br />
            <span>{{ getToolTipText(item) }}</span>
          </v-tooltip>
          <span class="ml-2">Needs Review</span>
        </div>
        <span v-else>{{ item.status }}</span>
      </template>
      <template #item.external="{ item }">
        <span>{{ collabCategoryDisplayText(item.external) }}</span>
      </template>
      <template #item.state="{ item }">
        <state-chips :states="[item.state]" />
      </template>
      <template #item.supervising_clinician="{ item }">
        <router-link
          v-if="!item.external && item.supervising_clinician"
          :to="`/clinicians/${item.supervising_clinician.id}`"
          class="mr-2 meta flex-shrink-0 text-color--wheel-green"
        >
          {{ item.supervising_clinician.name }}
        </router-link>
        <span v-else>{{
          item.supervising_clinician ? item.supervising_clinician.name : '-'
        }}</span>
      </template>
      <template #item.supervised_clinician="{ item }">
        <router-link
          :to="`/clinicians/${item.supervised_clinician.id}`"
          class="mr-2 meta flex-shrink-0 text-color--wheel-green"
        >
          {{ item.supervised_clinician.name }}
        </router-link>
      </template>
      <template #item.payback_model="{ item }">
        <span v-if="!item.external">{{
          paybackModel(item.payback_model)
        }}</span>
      </template>
      <template #item.supervising_clinician_payment="{ item }">
        <span v-if="!item.external">{{
          item.supervising_clinician_payment | currency
        }}</span>
      </template>
      <template #item.max_payback_amount="{ item }">
        <span
          v-if="
            item.state_requirement &&
            item.state_requirement.max_payback_amount &&
            !item.external
          "
        >
          {{ maxPaybackAmount(item) | currency }}
        </span>
        <span v-else> - </span>
      </template>
    </navigating-data-table>
  </div>
</template>

<script>
import NavigatingDataTable from '@/components/tables/NavigatingDataTable'
import StateChips from '../common/StateChips'
import CollabAgreementFilters from '@/components/collab-agreements/CollabAgreementFilters.vue'
import Search from '@/components/common/Search'
import PAYMENT_MODELS from '@/utils/PaymentModels'
import { COLLAB_AGREEMENT_CATEGORY } from '@/utils/CollabCategory'
import GridSearch from '@/mixins/GridSearch'
import moment from 'moment'
import CollabAgreementTypes from '@/utils/CollabAgreementTypes'

const LIMIT = 20

export default {
  name: 'CollabAgreementsTable',
  components: {
    NavigatingDataTable,
    StateChips,
    CollabAgreementFilters,
    Search
  },
  mixins: [GridSearch],
  data() {
    return {
      headers: [
        {
          text: 'Collab Status',
          value: 'status'
        },
        {
          text: 'Category',
          value: 'external'
        },
        {
          text: 'State',
          value: 'state'
        },
        {
          text: 'Supervising Physician',
          value: 'supervising_clinician'
        },
        {
          text: 'Nurse Practitioner',
          value: 'supervised_clinician'
        },
        {
          text: 'Payback Model',
          value: 'payback_model'
        },
        {
          text: 'Supervising Physician Payment',
          value: 'supervising_clinician_payment'
        },
        {
          text: 'NP Monthly Payback Maximum',
          value: 'max_payback_amount'
        }
      ],
      footer: {
        itemsPerPageOptions: [],
        itemsPerPageText: null,
        disableItemsPerPage: true
      },
      filters: {
        status: [],
        supervising_clinician: [],
        supervised_clinician: [],
        payback_model: [],
        state: []
      },
      search: '',
      collabAgreements: []
    }
  },
  methods: {
    showNeedsReview(item) {
      return (
        item.review_status ===
          CollabAgreementTypes.REVIEW_STATUS.NEEDS_REVIEW &&
        item.status !==
          CollabAgreementTypes.COLLAB_AGREEMENT_STATUS.COLLAB_REMOVED
      )
    },
    async loadData() {
      try {
        this.loadingAgreements = true

        const { page = 1, search } = this.$route.query
        const offset = Number(page) === 1 ? undefined : LIMIT * (page - 1)

        this.filters.payback_model = this.filters.payback_model.map((id) =>
          parseInt(id)
        )

        const response = await this.$collabAgreements.getAgreements({
          limit: LIMIT,
          search,
          offset,
          ...this.filters
        })
        this.collabAgreements = response.data
        return response
      } catch (e) {
        let message = 'Unable to load Agreements Table. '
        if (e.response) {
          message += e.response.data.messages[0]
        } else {
          message += e.message
        }
        this.$store.commit('SET_SNACKBAR', {
          message,
          show: true
        })
      } finally {
        this.loadingAgreements = false
      }
    },
    paybackModel(val) {
      val = parseInt(val)
      return PAYMENT_MODELS[val].display
    },
    async applyFilters(filters) {
      this.update({
        ...this.$route.query,
        page: 1,
        ...filters
      })
    },
    async resetFilters(filters) {
      this.update({
        ...this.$route.query,
        page: 1,
        ...filters
      })
    },
    parseQueryString() {
      const { page, search, ...filters } = this.$route.query

      this.options.page = page ? Number(page) : 1
      this.search = search

      Object.entries(filters).forEach(([key, value]) => {
        this.filters[key] = [value].flat()
      })
    },
    getToolTipText(item) {
      const remainingHours = Math.abs(2 - moment.utc().hour())
      return `Removed ${moment(item.review_status_updated_at)
        .add(4, 'days')
        .add(remainingHours, 'hours')
        .fromNow()}`
    },
    maxPaybackAmount({
      max_payback_amount_override,
      state_requirement: { max_payback_amount }
    }) {
      return max_payback_amount_override || max_payback_amount
    },
    collabCategoryDisplayText(category) {
      return category
        ? COLLAB_AGREEMENT_CATEGORY.EXTERNAL.display
        : COLLAB_AGREEMENT_CATEGORY.INTERNAL.display
    }
  }
}
</script>

<style lang="scss" scoped>
.search-row {
  display: flex;
  flex-flow: nowrap;
  margin-top: 16px;

  > :first-child {
    flex: 1;
  }
}
.status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tootlip {
  opacity: 1;
}
</style>
