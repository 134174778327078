const Loading = {
  methods: {
    async withLoading(caller, func) {
      try {
        caller.loading = true
        return await func.call(caller)
      } finally {
        caller.loading = false
      }
    }
  }
}

export default Loading
