import Vue from 'vue'

const shiftTypes = {
  standard: {
    className: '--standard',
    display: 'standard'
  },
  on_call: {
    className: '--on-call',
    display: 'on-call'
  }
}

export function getCoverageDisplay(value) {
  return shiftTypes[value].display
}

export function getCoverageClassName(value) {
  return shiftTypes[value].className
}

export const CoverageDisplay = Vue.filter('coverageDisplay', getCoverageDisplay)
export const CoverageClass = Vue.filter('coverageClass', getCoverageClassName)
