<template>
  <section v-if="consultRateTier">
    <view-header :breadcrumbs="breadcrumbs" :loading="loading">
      {{ tierName }}
      <template slot="actions">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" rounded v-on="on">
              Actions
            </v-btn>
          </template>
          <v-list>
            <div>
              <v-dialog
                v-model="editWarningModal"
                max-width="600px"
                @click:outside="handleClose"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title class="text-capitalize">
                      <v-icon class="mr-2"> mdi-pencil </v-icon>
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <edit-warning-modal
                  v-if="editWarningModal"
                  v-model="editWarningModal"
                  type-title="consult rate tier"
                  :name="tierName"
                  @close="handleClose"
                  @confirm="editDetails"
                />
              </v-dialog>
            </div>
          </v-list>
        </v-menu>
      </template>
    </view-header>
    <tabs>
      <template #details>
        <div class="details">
          <consult-rate-tier-details
            :consult-rate-tier="consultRateTier"
            :consult-rate-tier-details="consultRateTierDetails"
            :edit-mode="editMode"
            @cancel="handleCancel"
            @confirm="handleConfirm"
          />
        </div>
      </template>
    </tabs>
  </section>
</template>

<script>
import ViewHeader from '@/components/common/ViewHeader.vue'
import Tabs from '@/components/common/Tabs'
import UnitedStates from '@/utils/UnitedStates'
import ConsultRateTiers from '@/mixins/ConsultRateTiers'
import EditWarningModal from '@/components/common/editWarningModal'
import ConsultRateTierDetails from '@/components/consult-rate-tiers/ConsultRateTierDetails'
import { Duration } from '@/filters/Moment'

export default {
  name: 'ConsultRateTierView',
  components: {
    Tabs,
    ViewHeader,
    EditWarningModal,
    ConsultRateTierDetails
  },
  filters: {
    Duration
  },
  mixins: [ConsultRateTiers],
  props: {
    consultRateTierId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      consultRateTier: null,
      loading: true,
      editWarningModal: false,
      editMode: false
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { to: '/clients', display: 'Clients' },
        {
          to: `/clients/${this.consultRateTier.consult_rate.client.id}`,
          display: `${this.consultRateTier.consult_rate.client.name}`
        },
        {
          to: `/consult-rates/${this.consultRateTier.consult_rate.id}?tab=consult-rate-tiers`,
          display: `${this.consultRateTier.consult_rate.name}`
        }
      ]
    },
    tierName() {
      return this.findTier(this.consultRateTier.states[0].toUpperCase())
    },
    consultRateTierDetails() {
      const {
        id,
        consult_rate,
        states,
        tier,
        sla_in_seconds,
        assignment_lifetime,
        cost_to_client,
        paid_to_provider,
        ...rest
      } = this.consultRateTier
      return [
        {
          label: 'Id',
          value: id
        },
        {
          label: 'Consult Rate',
          value: consult_rate.name
        },
        {
          label: 'Tier',
          value: this.tierName
        },
        {
          label: 'States',
          value: states
            .map((state) => UnitedStates[state.toUpperCase()].name)
            .join(', ')
        },
        {
          label: 'SLA ',
          value: this.$options.filters.Duration(sla_in_seconds)
        },
        {
          label: 'Assignment Lifetime',
          value: this.$options.filters.Duration(assignment_lifetime)
        },
        {
          label: 'Cost to Client',
          value: this.$options.filters.currency(cost_to_client)
        },
        {
          label: 'Paid to Provider',
          value: this.$options.filters.currency(paid_to_provider)
        },
        ...Object.entries(rest).map(([key, value]) => ({
          label: key,
          value
        }))
      ]
    }
  },
  created() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    init: async function () {
      try {
        this.consultRateTier = await this.$consultRateTiers.getById(
          this.consultRateTierId
        )
        this.consultRateTier.tier = this.tierName
      } catch (e) {
        this.$router.replace('/404')
      }
    },
    handleClose() {
      this.editWarningModal = false
    },
    editDetails() {
      this.editMode = true
      if (
        this.$router.currentRoute.path !==
        `/consult-rate-tiers/${this.consultRateTierId}/details`
      ) {
        this.$router.push({
          path: `/consult-rate-tiers/${this.consultRateTierId}/details`
        })
      }
    },
    handleCancel() {
      this.editMode = false
    },
    handleConfirm(results) {
      this.consultRateTier = results
      this.editMode = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
