import axios from 'axios'

const Payments = {
  install(Vue) {
    Vue.prototype.$payments = new Vue({
      methods: {
        async runPayments(since, until) {
          const response = await axios.post(`/admin/payments/commands/pay`, {
            since: since,
            until: until
          })
          return response.data
        },
        async previewAmount(since, until) {
          const response = await axios.get(
            `/admin/payments/commands/preview_amount`,
            {
              params: { since: since, until: until }
            }
          )
          return response.data
        }
      }
    })
  }
}

export default Payments
